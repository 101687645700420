import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import PageHeader from "components/page-header/PageHeader";
import SchoolCard from "./SchoolCard";
import classes from "./SchoolList.module.scss";
import { schoolsDataQuery } from "query";
import { ISchoolInfo } from "utils/apiDataTypes/AccountManagementDataTypes";
import EmptyPage from "components/empty-page/EmptyPage";
import NoSchools from "assets/images/dashboard/no-schools.png";

const SchoolList = () => {
  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, ISchoolInfo[]>({
    ...schoolsDataQuery()
  });

  const hasData = data && data.length > 0;

  return (
    <>
      {hasData && (
        <PageHeader
          hasData
          title="Schools"
          itemsAmountMsg={data.length}
          hasSearch={false}
          hasSort={false}
        />
      )}
      {isLoading && <Loader size="lg" hasText withPadding />}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <div className={classes["cards-wrapper"]}>
          {data.map((school) => {
            return (
              <SchoolCard
                key={school._id}
                _id={school._id}
                name={school.name}
                pic={school.pic}
                website={school.website}
                is_blacklisted={school.is_blacklisted}
              />
            );
          })}
        </div>
      )}
      {!hasData && !isLoading && !error && (
        <EmptyPage
          title="No school yet."
          message="Seems like you don’t have school yet. Once your profile is added to a course, a school info will appear here."
          icon={NoSchools}
        />
      )}
    </>
  );
};

export default SchoolList;
