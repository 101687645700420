import React, { FormEvent, useContext, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormData } from "hooks/useFormData";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { popupContext } from "context/popupContext";
import { userContext } from "context/userContext";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import GenericPopup from "components/popups/GenericPopup";
import { InputField } from "components/input/Input";
import ImageInput from "components/image-input/ImageInput";
import Button from "components/buttons/Button";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import classes from "../EditProfile.module.scss";
import { fetchApi } from "utils/requests";
import { meDataQuery } from "query";
import {
  IFetchedMeData,
  IProfile
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { yupSchemas } from "utils/yupSchemas";
import { hasErrors } from "utils/form";
import { fileInstructions, titleOptions } from "utils/staticOptions";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";

const validationSchema: Yup.SchemaOf<IProfile> = Yup.object().shape({
  title: yupSchemas.labelAndValue.nullable(),
  first_name: yupSchemas.requiredStr,
  last_name: yupSchemas.requiredStr,
  wallet: yupSchemas.wallet,
  pic: Yup.string()
});

const defaultFormData: IProfile = {
  title: null,
  first_name: "",
  last_name: "",
  wallet: "",
  pic: ""
};

const EditProfile = () => {
  const queryClient = useQueryClient();
  const fetchedMeData = useGetFetchQuery(meDataQuery()) as IFetchedMeData;
  const { userData } = useContext(userContext);

  const {
    formData,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    formErrors,
    setFormErrors
  } = useFormData(defaultFormData, validationSchema);
  const { setPopup } = useContext(popupContext);
  const ref = useRef(null);
  const { state } = useLocation();

  const { address } = useAccount();

  // Populate form values from cache
  useEffect(() => {
    if (fetchedMeData)
      setFormData((prevState) => ({
        ...prevState,
        title: fetchedMeData.title
          ? { label: fetchedMeData.title, value: fetchedMeData.title }
          : null,
        first_name: fetchedMeData.first_name,
        last_name: fetchedMeData.last_name,
        wallet: fetchedMeData.wallet || null,
        pic: fetchedMeData.pic
          ? `${FILES_PUBLIC_DOMAIN}${fetchedMeData.pic}`
          : ""
      }));
  }, [fetchedMeData, setFormData]);

  // Fetch info in case cache data is missing
  const { isLoading, error } = useQuery<boolean, Error>({
    ...meDataQuery(),
    enabled: !fetchedMeData
  });

  // Edit data
  const { isLoading: isEditLoading, mutate: handleEdit } = useMutation({
    mutationKey: ["edit-profile"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      let data: any = { ...formData };
      data.title = data.title?.label;

      // Pic
      if (data.pic.includes(FILES_PUBLIC_DOMAIN)) {
        delete data.pic;
      }

      // Send `Wallet address` only for student
      if (!userData.is_student) {
        delete data.wallet;
      }

      // No title selected
      if (!data.title) {
        data.title = null;
      }

      await fetchApi("account", "/user/me", {
        method: "PUT",
        auth: true,
        data: data
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me-data"] });
      setPopup(
        <GenericPopup
          type="success"
          msg="You have successfully updated your profile!"
          redirectPath="/profile"
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  useEffect(() => {
    if (ref?.current && !!state?.focusWalletInput) {
      window.scrollTo(0, ref.current.offsetTop);
    }
  }, [ref?.current, state?.focusWalletInput]);

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <form className={classes["form-layout"]}>
      <ImageInput
        title="Profile Picture"
        subtitle={fileInstructions.pfp}
        fileType="image/png, image/jpeg, image/jpg"
        pic={formData.pic}
        onChange={(e: FormEvent) => handleUploadedFileBase64("pic", e)}
        onClear={() => {
          setFormData({
            ...formData,
            pic: ""
          });
          setFormErrors({ ...formErrors, pic: "" });
        }}
        error={formErrors.pic}
      />
      <ReactSelectWrapper
        name="title"
        title="Title"
        placeholder="Choose Title"
        options={titleOptions.map((name) => {
          return { label: name, value: name };
        })}
        value={formData.title}
        onChange={(e) =>
          handleChange({
            target: {
              name: "title",
              value: e
            }
          })
        }
        isClearable
      />
      <InputField
        title="First Name"
        isRequired
        name="first_name"
        placeholder="Enter First Name"
        onChange={handleChange}
        value={formData.first_name}
        error={formErrors.first_name}
      />
      <InputField
        title="Last Name"
        isRequired
        name="last_name"
        placeholder="Enter Last Name"
        onChange={handleChange}
        value={formData.last_name}
        error={formErrors.last_name}
      />
      {userData && userData.is_student && (
        <InputField
          refIs={ref}
          title="Wallet Address"
          name="wallet"
          placeholder="Enter Wallet Address"
          onChange={handleChange}
          value={formData.wallet}
          error={formErrors.wallet}
          isDisabled={!!address}
          showTooltipIcon={
            !!address &&
            "If you want to manually set your wallet address, please disconnect your wallet."
          }
          autoFocus={!!state?.focusWalletInput}
        />
      )}
      <Button
        type="submit"
        minWidth="md"
        onClick={handleEdit}
        icon={checkIcon}
        iconPosition="right"
        isDisabled={
          hasErrors(formErrors) || !formData.first_name || !formData.last_name
        }
        isFetching={isEditLoading}
      >
        Save Changes
      </Button>
    </form>
  );
};
export default EditProfile;
