import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import NoDiplomas from "assets/images/dashboard/no-templates.png";
import EmptyPage from "components/empty-page/EmptyPage";
import Slideshow from "../student/Slideshow";
import { IStudentDiploma } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./StudentDiplomas.module.scss";

interface IDiplomasSlideshow {
  isLoading: boolean;
  hasData: boolean;
  error: any;
  diplomas: IStudentDiploma[];
}

const DiplomasSlideshow = ({
  isLoading,
  hasData,
  error,
  diplomas
}: IDiplomasSlideshow) => {
  const { theme } = useContext(themeContext);

  return (
    <div className={classes["content-wrapper--slideshow"]} data-theme={theme}>
      {isLoading && <Loader size="lg" hasText withPadding />}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && <Slideshow diplomas={diplomas} />}
      {!hasData && !isLoading && !error && (
        <EmptyPage title="No certificates yet." icon={NoDiplomas} />
      )}
    </div>
  );
};

export default DiplomasSlideshow;
