import { useContext } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { userContext } from "context/userContext";
import { clearTokenFromStorage, tokenData } from "utils/storage";
import { tokenExpired } from "utils/requests";
import {
  IDecodedToken,
  ROLE_FLAG
} from "utils/apiDataTypes/AccountManagementDataTypes";
import jwt_decode from "jwt-decode";
import queryString from "query-string";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";

export const UnprotectedLayout = () => {
  const location = useLocation();
  const { diploma_id, opusedu_redirect_token } = queryString.parse(
    location.search
  );
  const isRedirectedFromOpusEdu = !!diploma_id && !!opusedu_redirect_token;

  const token = localStorage.getItem("token");
  const data: tokenData = JSON.parse(token);

  let is_super_admin: boolean = false;
  if (token) {
    const decoded: IDecodedToken = jwt_decode(token);
    const { role_flag } = decoded;
    is_super_admin = role_flag === ROLE_FLAG["SUPER_ADMIN"];
  }

  if (tokenExpired()) clearTokenFromStorage();

  // Public pages are visible the same way for logged and non logged in users
  const isPublicPage =
    // Public diplomas pages - preview or slideshow
    (location.pathname.startsWith("/students/") &&
      (location.pathname.endsWith("/preview") ||
        location.pathname.endsWith("/slideshow"))) ||
    // Public verify diploma pages
    location.pathname.startsWith("/verify-diploma");

  if (
    // Exclude landing page route
    location.pathname !== "/" &&
    // Exclude public diplomas route -> `/students/{id}/diplomas/preview` AND `/students/{id}/diplomas/slideshow`
    !isPublicPage &&
    // Exclude being redirected to diploma screen from OpusEDU
    !isRedirectedFromOpusEdu &&
    // Check for logged user data and non expired token
    data &&
    data.token &&
    !tokenExpired()
  ) {
    return (
      <Navigate
        to={is_super_admin ? "/main-dashboard" : "/school"}
        replace={true}
      />
    );
  }

  return <Outlet />;
};

export const ProtectedLayout = () => {
  const { userData } = useContext(userContext);
  const { setPopup, clearPopup } = useContext(popupContext);
  const data: tokenData = JSON.parse(localStorage.getItem("token"));
  const location = useLocation();
  const navigate = useNavigate();

  if (tokenExpired()) {
    clearTokenFromStorage();
    navigate(`/login?redirect_url=${location.pathname}`);
    setPopup(
      <GenericPopup
        type="info"
        title="Timeout"
        msg="You session has expired."
        buttonAction={() => clearPopup()}
        buttonName="Close"
        buttonVariant="neutral"
        buttonMinWidth="md"
      />
    );
  }

  if (data && data.token && !tokenExpired()) return <Outlet />;

  if (userData === null || userData === undefined) {
    return (
      <Navigate
        to={`/login?redirect_url=${location.pathname}`}
        replace={true}
      />
    );
  }

  return <Outlet />;
};
