import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { teamDataQuery } from "query";
import EmptyPage from "components/empty-page/EmptyPage";
import Table from "components/table/Table";
import { ManagersCols } from "components/table/ManagersCols";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import { popupContext } from "context/popupContext";
import { ITeamData } from "utils/apiDataTypes/AccountManagementDataTypes";
import { ReactComponent as User } from "assets/icons/user.svg";
import { colors } from "styles";
import { windowContext } from "context/windowsContext";

const ManagersTable = ({
  showConfirmPopup,
  setSelected,
  selected,
  passDataUp,
  managers
}) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const { clearPopup } = useContext(popupContext);

  const [page, setPage] = useState(0);

  const { isLoading, error, data } = useQuery<boolean, Error, ITeamData>(
    teamDataQuery()
  );

  const setSelectedHandler = (
    ids: { index: number; id: string; userId: string }[]
  ) => {
    setSelected(ids);
  };

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const teamCols = useMemo(
    () =>
      ManagersCols(selected, setSelectedHandler, showConfirmPopup, managers),
    [selected]
  );

  useEffect(() => {
    setSelected([]);
  }, [page]);

  const { result } = data || {};
  const filteredResult =
    result && result.length > 0
      ? result.filter((member) => member.role?.role_flag === 2 && member.active)
      : [];
  const hasData = filteredResult && filteredResult.length > 0;

  useEffect(() => {
    passDataUp(filteredResult);
  }, [data]);

  if (isLoading) return <Loader size="lg" hasText withPadding />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <>
      {hasData && (
        <>
          <Table
            type="managers"
            tableData={filteredResult}
            columns={teamCols}
            hiddenColumns={isMobile ? ["names"] : ["mobile"]}
            activePage={page}
            passActivePageUp={activePageHandler}
            // Im case we need pagination we should pass table data filtered on the BE side and remove FE filtration
            // withPagination
            className="table-sm"
            helperData={managers}
          />
        </>
      )}
      {!hasData && !isLoading && (
        <EmptyPage
          title="There are no course managers yet."
          message="Add a new team member and set his role on the platform. If a Course Manager is not specified the Admin and the Registrar will
          be able to administer the course"
          icon={
            <User fill={`rgb(${colors.orange60})`} width={192} height={192} />
          }
          buttonName="Add Member"
          onClick={() => {
            clearPopup();
            navigate("/team/add-member");
          }}
          className="empty-wrapper-modal"
        />
      )}
    </>
  );
};

export default ManagersTable;
