import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import NoCourses from "assets/images/dashboard/no-courses.png";
import classes from "./CourseTemplates.module.scss";
import { ICourseData } from "utils/apiDataTypes/CourseModuleDataTypes";
import CoursesList from "../components/CoursesList";
import Pagination from "components/pagination/Pagination";
import EmptyPage from "components/empty-page/EmptyPage";
import NoResults from "components/no-results/NoResults";
import { courseTemplatesDataQuery } from "query";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { sortDirEnum } from "utils/staticOptions";

export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "Name",
    value: "name",
    dir: "asc"
  }
];

const CourseTemplates = () => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir
  } = queryString.parse(urlParams);

  const navigate = useNavigate();
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;
  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState((search as string) || "");

  const pageSize = 12;

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    courseTemplatesDataQuery(
      `?&limit=${pageSize}&page=${page}${
        sortFilter && sortFilter.value
          ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
          : ""
      }${searchValue.length > 1 ? `&search[name]=${searchValue}` : ""}`
    )
  );

  const { result: templates, total_results, all_records } = data || {};
  const hasData = templates && templates.length > 0;

  return (
    <div className={classes["content-wrapper"]}>
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortFilter) ||
        page > 1) && (
        <PageHeader
          hasData={true}
          title="Course Templates"
          itemsAmountMsg={calcItemsAmount({
            page: page,
            perPage: pageSize,
            result: templates,
            total_results,
            all_records: all_records
          })}
          hasSearch
          searchPlaceholder="Search by Template Name"
          searchValue={searchValue}
          passSearchValueUp={(value: string) => {
            setSearchValue(value);
          }}
          setPage={setPage}
          hasSort
          passSortValueUp={(value: ISortOption) => {
            setSortFilter(value);
          }}
          sortValue={sortFilter}
          setSortValue={setSortFilter}
          sortOptions={sortOptions}
        />
      )}
      {isLoading && <Loader size="lg" hasText withPadding />}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          <CoursesList courses={templates} isTemplate />
          <Pagination
            currentPage={page}
            totalCount={total_results}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            itemsAmountMsg={calcItemsAmount({
              type: "Templates ",
              page: page,
              perPage: pageSize,
              result: templates,
              total_results,
              all_records: all_records
            })}
          />
        </>
      )}
      {!hasData && searchValue && !isLoading && <NoResults />}
      {!hasData && !searchValue && !isLoading && !error && (
        <EmptyPage
          title="There are no course templates yet."
          message="You can create a new course, then save it as a template, or you can chose from one of the already created courses and turn it to template."
          icon={NoCourses}
          buttonName="Create Course"
          onClick={() => navigate("/courses/create")}
        />
      )}
    </div>
  );
};

export default CourseTemplates;
