import { Outlet, useLocation, Navigate } from "react-router-dom";
import DashboardLayout from "layouts/dashboard";

const Dashboard = () => {
  const location = useLocation();
  return (
    <DashboardLayout>
      {location.pathname === "/" ? (
        <Navigate to="/school" replace />
      ) : (
        <Outlet />
      )}
    </DashboardLayout>
  );
};
export default Dashboard;
