import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import PopupTitleAndMessage from "components/popups/common/PopupTitleAndMessage";
import classes from "./RejectReasonPopup.module.scss";

interface IRejectReasonPopupProps {
  note: string;
}

const RejectReasonPopup = ({ note = "-" }: IRejectReasonPopupProps) => {
  const { theme } = useContext(themeContext);

  return (
    <ModalWrapper size="md">
      <div data-theme={theme}>
        <div className={classes["reject-reason-popup"]}>
          <PopupTitleAndMessage title="Deactivation Reason" msg={note} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RejectReasonPopup;
