import classes from "./FormWrapper.module.scss";

interface IEditFormProps {
  title?: string;
  children: React.ReactNode;
}

const FormWrapper = (props: IEditFormProps) => {
  const { title, children } = props;
  return (
    <div className={classes["form-wrapper"]}>
      {title && (
        <div
          className={`${classes["u-bold"]} ${classes["u-text--primary"]} ${classes["u-text--h4"]}`}
        >
          {title}
        </div>
      )}
      <div className={classes["form-body"]}>{children}</div>
    </div>
  );
};

export default FormWrapper;
