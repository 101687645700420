import React, { useContext, Dispatch } from "react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import { InputField } from "components/input/Input";
import classes from "./AddAttendeesPopup.module.scss";
import AddAttendeesPopupStaticContent from "./AddAttendeesPopupStaticContent";

interface IAddAttendeesPopup {
  attendeesData: string[][];
  setAttendeesData: Dispatch<string[][]>;
  setFile: (e) => void;
  formData: any;
  setFormData: any;
  formErrors: any;
  handleUploadedFileBase64: any;
  isOpen: boolean;
}

const AddAttendeesPopup = ({
  attendeesData,
  setAttendeesData,
  setFile,
  formData,
  setFormData,
  formErrors,
  handleUploadedFileBase64,
  isOpen
}: IAddAttendeesPopup) => {
  const { theme } = useContext(themeContext);

  return (
    <ModalWrapper size="lg">
      <div data-theme={theme}>
        <AddAttendeesPopupStaticContent isOpen={isOpen} />
        <div className={classes["form-container"]}>
          <InputField
            type="file"
            fileType=".csv, .xlsx"
            fileName={
              typeof formData.attendees !== "string" && formData.attendees.name
                ? formData.attendees.name
                : ""
            }
            onClear={() => {
              setFormData({
                ...formData,
                attendees: ""
              });
              setAttendeesData(undefined);
            }}
            error={formErrors["attendees"] as string}
            name="attendees"
            onChange={(e: React.FormEvent<HTMLFormElement>) => {
              handleUploadedFileBase64("attendees", e);
              setFile(e);
            }}
            maxWidth={160}
            className={
              attendeesData && attendeesData.length > 0 ? "centered" : ""
            }
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddAttendeesPopup;
