export async function imagesToBase64(files: Blob[], onLoadHandler: any) {
  let readers = [];

  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.onload = onLoadHandler;
    readers.push(reader.readAsDataURL(files[i]));
  }

  return Promise.all(readers);
}
