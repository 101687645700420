import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePagination, DOTS } from "hooks/usePagination";
import { Chevron } from "components/icons/Chevron";
import Button from "components/buttons/Button";
import { ReactComponent as TeamIcon } from "assets/icons/team.svg";
import { navigateWithParams } from "utils/format";
import classes from "./Pagination.module.scss";

interface IPagination {
  className?: string;
  onPageChange?: (i: number) => void;
  totalCount?: number;
  siblingCount?: number;
  currentPage?: number;
  pageSize?: number;
  itemsAmountMsg?: React.ReactNode;
  perPage?: number;
  setPerPage?: (amount: number) => void;
  showIs?: "Show All" | "Show Pages" | "none";
}

const Pagination = ({
  className,
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  itemsAmountMsg,
  perPage,
  setPerPage,
  showIs
}: IPagination) => {
  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const navigateToPage = (page: number | "all") => {
    navigateWithParams(urlParams, { page }, navigate, pathname);
  };

  const onNext = () => {
    if (lastPage > currentPage) {
      onPageChange(currentPage + 1);
      navigateToPage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
      navigateToPage(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={classes["pagination-wrapper"]}>
      {currentPage > 0 && paginationRange.length >= 2 && (
        <>
          {itemsAmountMsg && (
            <div className={classes["items-amount"]}>{itemsAmountMsg}</div>
          )}
          <div
            className={`${classes["pagination-container"]} ${
              className ? className : ""
            }`}
          >
            <Chevron
              variant="contrast"
              isOpen={false}
              onClick={onPrevious}
              rotate={90}
              isDisabled={currentPage === 1}
            />
            {paginationRange.map((pageNumber, i) => {
              if (pageNumber === DOTS) {
                return (
                  <div key={`${pageNumber}${i}`} className={classes["dots"]}>
                    &#8230;
                  </div>
                );
              }

              return (
                <Button
                  key={pageNumber}
                  className={
                    pageNumber !== currentPage ? classes["inactive"] : ""
                  }
                  variant="neutral"
                  onClick={() => {
                    onPageChange(pageNumber);
                    navigateToPage(pageNumber);
                  }}
                >
                  {pageNumber}
                </Button>
              );
            })}
            <Chevron
              variant="contrast"
              isOpen={false}
              onClick={onNext}
              rotate={270}
              isDisabled={lastPage === currentPage}
            />
          </div>
        </>
      )}
      {showIs === "Show All" &&
        currentPage > 0 &&
        paginationRange.length >= 2 && (
          <Button
            variant="link"
            icon={TeamIcon}
            onClick={() => {
              setPerPage(10000);
              navigateToPage("all");
            }}
          >
            Show All
          </Button>
        )}
      {showIs === "Show Pages" && (
        <Button
          className={classes["show-pages-btn"]}
          variant="link"
          icon={TeamIcon}
          onClick={() => {
            setPerPage(8);
            navigateToPage(1);
          }}
        >
          Show Pages
        </Button>
      )}
    </div>
  );
};

export default Pagination;
