import React from "react";
import Button from "components/buttons/Button";
import classes from "../Landing.module.scss";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";

const AccordionItem = ({
  question,
  answer,
  isOpen,
  i
}: {
  question: string;
  answer: string;
  isOpen: boolean;
  i: number;
}) => {
  return (
    <>
      <div className={classes["accordion__q"]}>
        <div>
          <div
            className={`${classes["accordion__q__number"]} ${
              isOpen ? classes["accordion__q__number--active"] : ""
            }`}
          >
            {i + 1}
          </div>
          <h2>{question}</h2>
        </div>
        <Button
          className={classes[`chevron${isOpen ? "--active" : ""}`]}
          variant={"link"}
          size="medium"
          isIconBtn
          icon={ChevronIcon}
          iconRotate={isOpen ? 180 : 0}
        />
      </div>
      <p
        className={`${classes["accordion__a"]} ${
          isOpen ? classes["open"] : ""
        }`}
      >
        {answer}
      </p>
    </>
  );
};

export default AccordionItem;
