import { colors } from "styles";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import classes from "./PasswordTooltip.module.scss";

const tooltipConditions = {
  mustConditions: [
    "At least 8 characters",
    "At least 1 letter (a, A, b, B, c, C...)",
    "A mix of uppercase and lowercase letters",
    "At least 1 number (1, 2, 3...)",
    "At least 1 special symbol (!, @, #...)"
  ],
  mustNotConditions: [
    "Your name, username, or company name",
    "Easy-to-guess words"
  ]
};

// Values extracted from PASSWORD_PATTERN
const tooltipChecks = [
  {
    value: /^(?=.{8,})/
  },
  { value: /^(?=.*[a-z])|(?=.*[A-Z])/ },
  { value: /^(?=.*[a-z])(?=.*[A-Z])/ },
  { value: /^(?=.*[0-9])/ },
  { value: /^(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])(?=.{8,})/ }
];

interface IPasswordTooltip {
  value: string;
  isTemporaryTooltip?: boolean;
}

const PasswordTooltip = ({ value, isTemporaryTooltip }: IPasswordTooltip) => {
  if (isTemporaryTooltip)
    return (
      <div
        className={`${classes["pwd-tooltip"]} ${classes["u-text--primary"]} ${classes["tooltip-lg"]}`}
      >
        <div className={classes["arrow"]} />
        <div className={`${classes["title"]} ${classes["u-bold"]}`}>
          Check your email.
        </div>
        <div className={`${classes["row"]} ${classes["u-text--xsmall"]}`}>
          Fill in the automatically generated password we have sent to your
          email address.
        </div>
      </div>
    );

  return (
    <div
      className={`${classes["pwd-tooltip"]} ${classes["u-text--primary"]} ${classes["tooltip-lg"]}`}
    >
      <div className={classes["arrow"]} />
      <div className={classes["conditions"]}>
        <div className={`${classes["title"]} ${classes["u-bold"]}`}>
          Password must contain:
        </div>
        {tooltipConditions.mustConditions.map((condition, i) => {
          return (
            <div
              className={`${classes["row"]} ${classes["u-text--xsmall"]}`}
              key={i}
            >
              {tooltipChecks[i].value.test(value) ? (
                <CheckIcon
                  fill={`rgb(${colors.success50})`}
                  height={24}
                  width={24}
                />
              ) : (
                "• "
              )}
              {condition}
            </div>
          );
        })}
      </div>
      <div className={classes["conditions"]}>
        <div className={`${classes["title"]} ${classes["u-bold"]}`}>
          Must not contain:
        </div>
        {tooltipConditions.mustNotConditions.map((condition, i) => {
          return (
            <div
              className={`${classes["row"]} ${classes["u-text--xsmall"]}`}
              key={i}
            >
              • {condition}
            </div>
          );
        })}
      </div>
    </div>
  );
};

PasswordTooltip.defaultProps = {
  isTemporaryTooltip: false
};

export default PasswordTooltip;
