import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { popupContext } from "context/popupContext";
import { ReactComponent as DiplomaIcon } from "assets/icons/diploma.svg";
import { ReactComponent as MenuDots } from "assets/icons/menu-dots.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeHiddenIcon } from "assets/icons/eye-hidden.svg";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { IPFS_GATEWAY } from "utils/constants";
import { IStudentDiploma } from "utils/apiDataTypes/CourseModuleDataTypes";
import { fetchApi } from "utils/requests";
import useComponentVisible from "hooks/useComponentVisible";
import classes from "./DiplomaCard.module.scss";

interface ICard
  extends Pick<IStudentDiploma, "_id" | "token_id" | "is_public"> {
  onClick?: () => void;
  name: string;
  image: string;
  isPublicPage: boolean;
}

const Card = ({
  _id,
  token_id,
  name,
  image,
  is_public,
  onClick,
  isPublicPage
}: ICard) => {
  const navigate = useNavigate();

  const { setPopup, popupIsLoading, setPopupLoading } =
    useContext(popupContext);
  const queryClient = useQueryClient();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOnClick = () => {
    onClick && onClick();
  };

  useEffect(() => {
    if (popupIsLoading)
      setPopup(
        <GenericPopup
          type="loading"
          msg="Updating visibility options..."
          hideActionBtn
        />
      );
  }, [popupIsLoading]);

  const changeVisibility = async (id) =>
    await fetchApi("courses", "/course-student/my-diplomas", {
      method: "PUT",
      auth: true,
      data: {
        token_ids: [id],
        is_public: !is_public
      }
    });

  // Update diploma mutation
  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["student-diplomas-data-edit-visibility", { id: _id }],
    mutationFn: async (id: number) => {
      setPopupLoading(true);
      await changeVisibility(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["student-diplomas-data"]
      });
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully updated visibility options!"
        />
      );
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  const VisibilityIcon = is_public ? EyeIcon : EyeHiddenIcon;

  return (
    <div
      className={`${classes["card"]} ${
        isPublicPage ? classes["card--public"] : ""
      }`}
      onClick={handleOnClick}
    >
      {image ? (
        <div className={classes["card-pic"]}>
          <img src={`${IPFS_GATEWAY}${image}`} alt="Certificate" />
        </div>
      ) : (
        <div className={`${classes["card-pic"]} ${classes["card-pic--empty"]}`}>
          <DiplomaIcon />
        </div>
      )}
      <div className={classes["card-content"]}>
        <div>
          <h6
            className={`${classes["u-title"]} ${classes["u-text--medium"]} ${classes["u-bold"]} ${classes["m-0"]}`}
          >
            {name} {!isPublicPage && <VisibilityIcon />}
          </h6>
        </div>
        {!isPublicPage && (
          <>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setIsComponentVisible(!isComponentVisible);
              }}
              variant={"link"}
              size="medium"
              isIconBtn
              icon={MenuDots}
            />
            {isComponentVisible && (
              <div className={classes["c-menu-wrapper"]} ref={ref}>
                <div className={classes["c-menu"]}>
                  <div
                    className={classes["c-menu-item"]}
                    onClick={() => navigate(_id)}
                  >
                    Open
                  </div>
                  <div
                    className={classes["c-menu-item"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdate(token_id);
                    }}
                  >
                    {`Make ${is_public ? "private" : "public"}`}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
