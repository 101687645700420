import BlockiesComp from "components/blockies/Blockies";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import DepositPopup from "components/popups/DepositPopup";
import { popupContext } from "context/popupContext";
import { Web3Context } from "context/web3Context";
import { useContext } from "react";
import { formatAddress } from "utils/format";
import ActionButtons from "./ActionButtons";
import classes from "./Connect.module.scss";
import { windowContext } from "context/windowsContext";

interface IWalletInfo {
  isVisible: boolean | number;
}

const WalletInfo = ({ isVisible }: IWalletInfo) => {
  const { web3UserData, usdPrice } = useContext(Web3Context);
  const { setPopup } = useContext(popupContext);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  return (
    <>
      {
        <div
          className={`${
            classes[`wallet-info-wrapper${isMobile ? "-mobile" : ""}`]
          } ${classes[!isMobile ? (!isVisible ? "hide" : "") : ""]} ${
            classes[isMobile && isVisible ? "open" : ""]
          }`}
        >
          <div className={classes[`wallet-info${isMobile ? "-mobile" : ""}`]}>
            {!isMobile && (
              <div className={classes["address-wrapper"]}>
                <div className={classes["address"]}>
                  <BlockiesComp
                    seed={web3UserData.wallet_address}
                    size={12}
                    scale={4}
                  />
                  <span
                    className={`${classes["u-text--primary"]} ${classes["u-text--medium"]} ${classes["u-bold"]}`}
                  >
                    {formatAddress(web3UserData?.wallet_address)}
                  </span>
                </div>
                <ActionButtons />
              </div>
            )}
            <div className={classes[`balance${isMobile ? "-mobile" : ""}`]}>
              <div
                className={`${classes["u-text--primary"]} ${classes["balance-big"]}`}
              >
                <span className={classes["u-semibold"]}>
                  {web3UserData?.balanceInMatic}
                </span>
                <span className={classes["u-light"]}>MATIC</span>
              </div>
              <div
                className={`${classes["u-text--content"]} ${classes["u-text--medium"]}`}
              >
                {`$${web3UserData?.balanceInUsd} USD`}
              </div>
            </div>
            {!isMobile && (
              <Button
                onClick={() =>
                  setPopup(
                    <DepositPopup walletAddress={web3UserData.wallet_address} />
                  )
                }
                variant="outline"
                minWidth="full"
              >
                Add Funds
              </Button>
            )}
            {isMobile && (
              <div className={classes["address-wrapper-mobile"]}>
                <ActionButtons />
                <Divider orientation={"vertical"} />
                <Button
                  onClick={() =>
                    setPopup(
                      <DepositPopup
                        walletAddress={web3UserData.wallet_address}
                      />
                    )
                  }
                  variant="outline"
                  size="medium"
                >
                  Add Funds
                </Button>
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default WalletInfo;
