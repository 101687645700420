import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./StatsCard.module.scss";
import { colors } from "styles";
import { IStatsItem } from "./StatisticsList";

const StatsCard = ({ name, number, icon, link }: IStatsItem) => {
  const navigate = useNavigate();
  const Icon = icon;
  return (
    <div
      className={`${classes["card"]} ${classes[link ? "card--clickable" : ""]}`}
      onClick={() => (link ? navigate(link) : null)}
    >
      <Icon width={36} height={36} fill={`rgb(${colors.orange60})`} />
      <div>
        <p className={`${classes["u-text--xsmall"]} ${classes["m-0"]}`}>
          {name}
        </p>
        <h6 className={`${classes["u-bold"]} ${classes["m-0"]}`}>{number}</h6>
      </div>
    </div>
  );
};

export default StatsCard;
