import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { ReactComponent as arrowNav } from "assets/icons/filter-arrow.svg";
import { routes } from "routes/Routes";
import classes from "./Breadcrumbs.module.scss";
import { windowSizes } from "utils/windowSizes";
import TooltipComp from "components/tooltip/TooltipComp";
import useComponentVisible from "hooks/useComponentVisible";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";

const ArrowIcon = arrowNav;

interface IBreadcrumbs {
  selectedSkips?: number[];
}

const Breadcrumbs = ({ selectedSkips }: IBreadcrumbs) => {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const breadcrumbs = useReactRouterBreadcrumbs(routes(userData));
  const { windowSize } = useContext(windowContext);
  const { isMobile, width } = windowSize;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  let filteredCrumbs = breadcrumbs.filter(function (value, index) {
    return selectedSkips.indexOf(index) === -1;
  });

  const renderName = (i: number, name) => {
    if (width <= windowSizes.screenXlMax && i < filteredCrumbs.length - 1) {
      return (
        <TooltipComp id={`breadcrumb-${i}`} text={name}>
          ...
        </TooltipComp>
      );
    }
    return name;
  };

  const clickHandler = (pathname?: string) => {
    // Desktop
    if (pathname && !isMobile) return navigate(pathname);
    // Mobile breadcrums nav click
    if (!pathname && isMobile) {
      return setIsComponentVisible(!isComponentVisible);
    }
    // Mobile link click from dropdown
    if (isComponentVisible) return navigate(pathname);
  };

  const maxCrumbWidth = `${100 / filteredCrumbs.length}%`;

  return (
    <>
      {filteredCrumbs.length > 1 && (
        <div className={classes["nav"]} onClick={() => clickHandler()}>
          {filteredCrumbs?.map((item, i) => {
            return (
              <Fragment key={i}>
                {
                  <div className={classes["nav-link"]}>
                    <span
                      className={
                        i < filteredCrumbs.length - 1
                          ? classes["link"]
                          : classes["u-text--primary"]
                      }
                      onClick={() =>
                        i < filteredCrumbs.length - 1 &&
                        clickHandler(item.match.pathname)
                      }
                    >
                      {renderName(i, item.breadcrumb)}
                    </span>
                    {i < filteredCrumbs.length - 1 && (
                      <ArrowIcon className={classes["arrow"]} />
                    )}
                  </div>
                }
              </Fragment>
            );
          })}
          {isMobile && isComponentVisible && (
            <div ref={ref} className={classes["links-dropdown"]}>
              {filteredCrumbs?.map((item, i) => {
                return (
                  <Fragment key={i}>
                    {
                      <div className={classes["mobile-nav-link"]}>
                        <div
                          className={classes["u-text--primary"]}
                          onClick={() =>
                            i < filteredCrumbs.length - 1 &&
                            clickHandler(item.match.pathname)
                          }
                        >
                          {item.breadcrumb}
                        </div>
                      </div>
                    }
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

Breadcrumbs.defaultProps = {
  selectedSkips: []
};

export default Breadcrumbs;
