import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import Button from "components/buttons/Button";
import TitleAndMsg from "components/forms/form-title-and-msg/TitleAndMsg";
import { InputField } from "components/input/Input";
import GenericPopup from "components/popups/GenericPopup";
import { popupContext } from "context/popupContext";
import { useFormData } from "hooks/useFormData";
import FormPage from "layouts/form-page";
import { hasErrors } from "utils/form";
import { fetchApi } from "utils/requests";
import { yupSchemas } from "utils/yupSchemas";
import classes from "./ResetPassword.module.scss";

interface IDefaultFormData {
  new_pass: string;
  confirm_new_pass: string;
}

const defaultFormData = {
  new_pass: "",
  confirm_new_pass: ""
};

const validationSchema: Yup.SchemaOf<IDefaultFormData> = Yup.object().shape({
  new_pass: yupSchemas.new_pass,
  confirm_new_pass: yupSchemas.confirm_new_pass
});

const ResetPassword = () => {
  const { setPopup } = useContext(popupContext);
  const { formData, handleChange, formErrors, setFormData } = useFormData(
    defaultFormData,
    validationSchema
  );

  const [searchParams] = useSearchParams();
  const uid = searchParams.get("u_id");
  const hash = searchParams.get("hash");

  const {
    isLoading,
    mutate: submitHandler,
    error
  } = useMutation({
    mutationKey: ["reset-password"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      await fetchApi("account", `/user/resetPass/${uid}/${hash}`, {
        method: "POST",
        data: {
          new_pass: formData.new_pass
        }
      });
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          type="success"
          msg="You have successfully changed your password! You can now log in using your new password!"
          redirectPath="/login"
          isClosable={false}
        />
      );
      setFormData(defaultFormData);
    },
    onError: () => {
      setPopup(
        <GenericPopup type="error" msg={`An Error occurred! ${error}`} />
      );
    }
  });

  return (
    <FormPage headerVariant="only-logo">
      <form>
        <div className={`${classes["container"]} ${classes["form-sm"]}`}>
          <TitleAndMsg
            title="Create New Password"
            msg="Your new password must be different from previous used passwords."
          />
          <InputField
            type="password"
            title="New Password*"
            name="new_pass"
            placeholder="Enter Password"
            onChange={handleChange}
            value={formData.new_pass}
            error={formErrors.new_pass}
            showTooltipIcon={
              "This will be the new password you must use to log in."
            }
          />
          <InputField
            type="password"
            title="Confirm Password*"
            name="confirm_new_pass"
            placeholder="Enter Password"
            onChange={handleChange}
            value={formData.confirm_new_pass}
            error={formErrors.confirm_new_pass}
          />
          <Button
            type="submit"
            minWidth="full"
            isDisabled={
              hasErrors(formErrors) ||
              !formData.new_pass.length ||
              !formData.confirm_new_pass.length
            }
            onClick={submitHandler}
            isFetching={isLoading}
          >
            Reset Password
          </Button>
        </div>
      </form>
    </FormPage>
  );
};
export default ResetPassword;
