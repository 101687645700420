import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as searchIcon } from "assets/icons/search.svg";
import { DebounceInput } from "react-debounce-input";
import { themeContext } from "context/themeContext";
import { colors } from "styles";
import clearSearchLight from "assets/icons/search/cancel-search-light.svg";
import clearSearchDark from "assets/icons/search/cancel-search-dark.svg";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";

interface IInputProps {
  // Functional
  value?: any;
  onChange: any;
  isDisabled?: boolean;
  debounceTimeout: number;
  passIsOpenUp?: (isOpen: boolean) => void;
  isMobile?: boolean;

  // Customization of component
  placeholder?: string;

  // Styling
  className?: string;
  theme?: string;

  open?: boolean;
}

interface IInputWrapperProps {
  theme: string;
  isDisabled: boolean;
  open?: boolean;
  isMobile?: boolean;
}

const SInputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) =>
    theme === "dark"
      ? `rgb(${colors.greyishBlue90})`
      : `rgb(${colors.lightBlue30})`};
  border-radius: 100px;
  font-family: Poppins;
  width: ${({ open, isMobile }) =>
    isMobile
      ? open
        ? "100%"
        : "40px"
      : !isMobile
      ? open
        ? "50%"
        : "40px"
      : "40px"};
  height: 40px;
  overflow: hidden;
  transition: background-color 350ms, width 350ms;
  &:hover::after {
      pointer-events: none;
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      border: ${({ theme }) =>
        theme === "dark"
          ? `1px solid rgb(${colors.greyishBlue60})`
          : `1px solid rgb(${colors.lightBlue90})`};
      border-radius: 100px;
    }
  }
  &:focus-within {
    transition: none 0s;
    background-color: ${({ theme }) =>
      theme === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
  }
  &:focus-within::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: ${({ theme }) =>
      theme === "dark"
        ? `1px solid rgb(${colors.greyishBlue60})`
        : `1px solid rgb(${colors.lightBlue90})`};
    border-radius: 100px;
  }
  &:disabled: {
    border: unset;
  }
`;

const SInput = styled(DebounceInput)<IInputProps>`
  display: flex; 
  font-family: Poppins;
  font-size: 14px;
  padding: ${({ open }) => (open ? "10px 12px 10px 2px" : "0px")};
  border-radius: 100px;
  background-color: ${({ theme }) =>
    theme === "dark"
      ? `rgb(${colors.greyishBlue90})`
      : `rgb(${colors.lightBlue30})`};
  border: none;
  width: ${({ open }) => (open ? "100%" : "0%")}}; 
  height: 40px;
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: background-color 350ms;
  color: ${({ theme, isDisabled }) =>
    theme === "dark" && !isDisabled
      ? "white"
      : theme === "dark" && isDisabled
      ? "#565656"
      : theme !== "dark" && !isDisabled
      ? "black"
      : "#949494"};
  ::-webkit-search-cancel-button {
    z-index: 1;
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: ${({ theme }) =>
      theme === "dark"
        ? `url(${clearSearchDark})`
        : `url(${clearSearchLight})`};
    cursor: pointer;
    opacity: ${({ open }) => (open ? "1" : "0")};
    transition: opacity 350ms;
  }
  ::placeholder {
    color: ${({ theme, isDisabled }) =>
      theme === "dark" && !isDisabled
        ? `rgb(${colors.greyishBlue50})`
        : theme === "dark" && isDisabled
        ? `rgb(${colors.greyishBlue70})`
        : theme !== "dark" && !isDisabled
        ? `rgb(${colors.greyishBlue30})`
        : `rgb(${colors.greyishBlue10})`};
    opacity: 1;
  }
  &:focus {
    background-color: ${({ theme }) =>
      theme === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
    transition: none 0s;
  }
`;

const SSearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
`;

const SDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${({ theme }) =>
    theme === "dark"
      ? `rgb(${colors.neutral10})`
      : `rgb(${colors.neutral100})`};
`;

const SIconsWrapper = styled.div<{ open }>`
  display: ${({ open }) => (open ? "flex" : "none")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  align-items: center;
  gap: 12px;
  padding-right: 16px;
  transition: opacity 350ms;
`;

export const InputFieldDebounced = (props: IInputProps) => {
  const { passIsOpenUp, isMobile } = props;
  const { theme } = useContext(themeContext);
  const [isOpen, setIsOpen] = useState(Boolean(props.value));

  useEffect(() => {
    passIsOpenUp(isOpen);
  }, [isOpen]);

  const Icon = searchIcon;
  const renderIcon = () => {
    return (
      <Icon
        width={20}
        height={20}
        fill={
          theme === "dark"
            ? props.isDisabled
              ? `rgb(${colors.greyishBlue70})`
              : `rgb(${colors.neutral10})`
            : props.isDisabled
            ? `rgb(${colors.greyishBlue10})`
            : `rgb(${colors.neutral100})`
        }
      />
    );
  };

  return (
    <SInputWrapper
      isDisabled={props.isDisabled}
      theme={theme}
      open={isOpen}
      isMobile={isMobile}
    >
      {/* Render Search Icon for type = search */}
      <SSearchIconContainer onClick={() => setIsOpen(true)}>
        {renderIcon()}
      </SSearchIconContainer>

      <SInput
        theme={theme}
        type={"search"}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        debounceTimeout={props.debounceTimeout}
        open={isOpen}
      />
      <SIconsWrapper open={isOpen}>
        <SDivider theme={theme} />
        <ChevronIcon
          fill={
            theme === "dark"
              ? `rgb(${colors.neutral10})`
              : `rgb(${colors.neutral100})`
          }
          style={{ rotate: isMobile ? "90deg" : "-90deg", cursor: "pointer" }}
          onClick={() => setIsOpen(false)}
        />
      </SIconsWrapper>
    </SInputWrapper>
  );
};

InputFieldDebounced.defaultProps = {
  variant: "light",
  isDisabled: false
};
