import React, { useContext, useEffect, useState } from "react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import classes from "./MintDiplomasPopup.module.scss";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import MintDiplomasTable from "components/table/MintDiplomasTable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "utils/requests";
import GenericPopup from "components/popups/GenericPopup";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import { IStudent } from "utils/apiDataTypes/CourseModuleDataTypes";
import { ethers } from "ethers";
import { diplomaContract } from "contracts/contracts";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { BLOCK_EXPLORER_URL, MINT_FEE } from "utils/constants";
import { Web3Context } from "context/web3Context";
import MintFee from "components/popups/common/MintFee";
import { useAccount } from "wagmi";

interface IMintDiplomasTable {
  students: IStudent[];
  courseId: string;
  templateId: string;
  hasMints: boolean;
  clearSelection: () => void;
}

const MintDiplomasPopup = ({
  students,
  courseId,
  templateId,
  hasMints,
  clearSelection
}: IMintDiplomasTable) => {
  const { theme } = useContext(themeContext);
  const { web3UserData } = useContext(Web3Context);
  const { clearPopup, setPopup } = useContext(popupContext);
  const [studentsInTable, setStudentsInTable] = useState<IStudent[]>(students);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { connector: activeConnector } = useAccount();

  const mintFee = (studentsInTable.length * MINT_FEE).toFixed(4);

  const { mutate: mintDiplomasHandler } = useMutation({
    mutationKey: ["mint-diplomas"],
    mutationFn: async () => {
      setIsLoading(true);
      let txReceipt;
      if (!templateId)
        throw Error(
          "A certificate template must be selected for the given course."
        );
      if (!web3UserData?.wallet_address)
        throw Error("Please connect your wallet.");

      setPopup(
        <GenericPopup
          type="loading"
          size="sm"
          title="Approve Mint"
          msg="Approve this mint transaction from your wallet."
        />
      );
      // 1. Submit mint to BE
      const response = await fetchApi(
        "courses",
        `/course-student/diplomas/mint`,
        {
          method: "POST",
          auth: true,
          data: {
            student_ids: studentsInTable.map((student) => student._id)
          }
        }
      )
        // 2. Use BE response for transaction
        .then(
          async (response: {
            ids: string[];
            signatures: string[];
            to: string[];
            tokensIds: number[];
          }) => {
            if (
              !response.ids.length ||
              !response.to.length ||
              !response.tokensIds.length ||
              !response.signatures.length
            )
              throw Error(
                "A certificate cannot be minted for the given student(s)."
              );

            let walletProvider;
            await activeConnector.getProvider().then((provider) => {
              walletProvider = provider;
            });

            let provider = new ethers.providers.Web3Provider(
              walletProvider,
              "any"
            );
            let signer = provider.getSigner(web3UserData.wallet_address);

            let contract = new ethers.Contract(
              diplomaContract.address,
              diplomaContract.abi,
              signer
            );

            const fee = ethers.utils.parseEther(mintFee.toString());

            try {
              const tx = await contract[
                "mintBatch(address[],uint256[],bytes[])"
              ](response.to, response.tokensIds, response.signatures, {
                value: fee
              });
              const receipt = await tx.wait();
              txReceipt = receipt;
              return receipt;
            } catch (e: any) {
              await fetchApi("courses", `/course-student/diplomas/reset-mint`, {
                method: "POST",
                auth: true,
                data: {
                  student_ids: studentsInTable.map((student) => student._id)
                }
              });
              if (e.code === "ACTION_REJECTED") {
                throw Error("Transaction cancelled.");
              }
              if (
                e?.data?.code === -32000 ||
                e?.code === "INSUFFICIENT_FUNDS"
              ) {
                throw Error("Insufficient funds.");
              }
              throw Error(e.message);
            }
          }
        );
      return txReceipt;
    },
    onSuccess: (receipt) => {
      setIsLoading(false);
      setPopup(
        <GenericPopup
          title="Success!"
          msg="Certificates minted!"
          bellowBtnComp={
            <Button
              onClick={() =>
                window.open(
                  `${BLOCK_EXPLORER_URL}/tx/${receipt.transactionHash}`,
                  "_blank"
                )
              }
              icon={LinkIcon}
              variant={"link"}
              isIconBtn
              minWidth="full"
            >
              View in Explorer
            </Button>
          }
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["courses-data", { id: courseId }]
      });
      queryClient.invalidateQueries({ queryKey: ["course-students-data"] });
      clearSelection();
    },
    onError: (err: Error) => {
      setIsLoading(false);
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  const showFirstMintPopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Initial Mint Process"
        msg="You are about to mint the first certificate for this course.
              Once the first mint is performed, the course details can no longer be changed
              and new attendees cannot be added. Are you sure you want to continue?"
        buttonName="Confirm First Mint"
        buttonAction={mintDiplomasHandler}
        bellowBtnComp={
          <Button variant="link" onClick={() => clearPopup()}>
            Cancel
          </Button>
        }
      />
    );
  };

  useEffect(() => {
    if (studentsInTable.length === 0) clearPopup();
  }, [studentsInTable]);

  return (
    <ModalWrapper size="lg">
      <div data-theme={theme} className={classes["managers-popup"]}>
        <h4
          className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
        >
          Confirm Mint NFT Certificate
        </h4>
        <div
          className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
        >
          Confirm that you want to send the NFT Certificate to the listed attendees.
          If there is an attendant without a wallet address he’ll receive an
          E-mail informing him that he must set his wallet address in order to
          receive the NFT Certificate.
          <br />
          <br />
          <MintFee fee={mintFee} />
        </div>
        <div className={classes["content"]}>
          <MintDiplomasTable
            students={studentsInTable}
            setStudents={setStudentsInTable}
          />
        </div>
        <div className={classes["buttons-container"]}>
          <Button
            variant="link"
            icon={ArrowIcon}
            iconRotate={180}
            onClick={() => {
              clearPopup();
            }}
          >
            Back
          </Button>
          <Button
            icon={CheckCircle}
            onClick={!hasMints ? showFirstMintPopup : mintDiplomasHandler}
            isFetching={isLoading}
          >
            Confirm Mint
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default MintDiplomasPopup;
