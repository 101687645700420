import { useContext } from "react";
import { themeContext } from "context/themeContext";
import classes from "./Dashboard.module.scss";
import Header from "components/header/Header";
import Sidebar from "components/sidebar/Sidebar";
import { windowContext } from "context/windowsContext";

const DashboardLayout = (props: any) => {
  const { theme } = useContext(themeContext);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  return (
    <div className={classes["wrapper"]} data-theme={theme}>
      <div className={classes["main-grid"]}>
        <Sidebar />
        <div className={classes["col-right"]}>
          <Header
            variant={isMobile ? "both" : "only-buttons"}
            breadcrumbSkips={[0]}
            withBreadcrumbs
          />
          <div className={classes["children-components"]}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
