import { useDrop } from "react-dnd";
import {
  ICreateDiplomaFormData,
  ITemplateFieldOption
} from "../form/CreateDiplomaForm";
import { Box, ItemTypes } from "./Box";
import classes from "./DragContainer.module.scss";
import { dndAreaPadding } from "../CreateDiploma";

interface IDragContainer {
  selectedField: ITemplateFieldOption;
  setSelectedField: (field: ITemplateFieldOption) => void;
  formData: ICreateDiplomaFormData;
  setFormData: any;
}

const DragContainer = ({
  selectedField,
  setSelectedField,
  formData,
  setFormData
}: IDragContainer) => {
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item: any, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        const fields = formData.template_fields;
        const [targetField] = fields.filter(
          (field) => field.name === item.name
        );
        targetField.left = left;
        targetField.top = top;
        setFormData((prevState) => ({ ...prevState, template_fields: fields }));
      }
    }),
    [formData]
  );

  const setDimensionsHandler = (
    fieldName:
      | "school-name"
      | "student-name"
      | "course-name"
      | "logo"
      | "teacher-1"
      | "teacher-2"
      | "cert-date",
    dimensions: { width: number; height: number }
  ) => {
    const arr = [...formData.template_fields];
    const [targetField] = arr
      .map((item, i) => {
        if (item.name === fieldName) {
          return { index: i, field: item };
        }
      })
      .filter((item) => item !== undefined);
    arr[targetField.index] = {
      ...arr[targetField.index],
      width: dimensions.width,
      height: dimensions.height,
      left: +arr[targetField.index].left,
      top: arr[targetField.index].top
    };

    setFormData((prevState) => {
      const prevTemplateFields = [...prevState.template_fields];
      prevTemplateFields[targetField.index] = arr[targetField.index];
      return { ...prevState, template_fields: prevTemplateFields };
    });
  };

  return (
    <>
      {formData.template_fields && (
        <div
          ref={drop}
          className={classes["box-wrapper"]}
          style={{ padding: `${dndAreaPadding}px` }}
        >
          {formData.template_fields.map((field, i) => {
            return (
              <Box
                key={field.name}
                hideSourceOnDrag={true}
                setDimensions={setDimensionsHandler}
                isSelected={selectedField?.name === field.name}
                setIsSelected={(field) => setSelectedField(field)}
                field={field}
                formData={formData}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
export default DragContainer;
