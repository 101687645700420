import React, { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { popupContext } from "context/popupContext";
import { userContext } from "context/userContext";
import { ReactComponent as CheckIconCircle } from "assets/icons/check-circle.svg";
import Button from "components/buttons/Button";
import TooltipComp from "components/tooltip/TooltipComp";
import GenericPopup from "components/popups/GenericPopup";
import { fetchApi } from "utils/requests";
import {
  ICourse,
  ATTENDANCE_STATUS
} from "utils/apiDataTypes/CourseModuleDataTypes";

interface IVerifyAttendance extends Pick<ICourse, "attendance_hash"> {
  courseId: string;
  attendance_status: ATTENDANCE_STATUS;
  is_blacklisted: boolean;
}

const VerifyAttendance = ({
  courseId,
  attendance_hash,
  attendance_status,
  is_blacklisted
}: IVerifyAttendance) => {
  const { userData } = useContext(userContext);
  const { setPopup } = useContext(popupContext);
  const queryClient = useQueryClient();

  // Verify attendance
  const verify = async () =>
    await fetchApi(
      "courses",
      `/course-student/attend/${courseId}/${attendance_hash}`,
      {
        method: "PUT",
        auth: true
      }
    );

  // Verify attendance mutation
  const { isLoading: isLoadingUpdate, mutate: handleVerify } = useMutation({
    mutationKey: ["verify-attendance", { courseId, userId: userData.id }],
    mutationFn: async () => await verify(),
    onSuccess: async (data) => {
      await localStorage.removeItem("attendance_hash");
      queryClient.invalidateQueries({
        queryKey: [`student-courses-data`, { id: courseId }]
      });
      return setPopup(
        <GenericPopup
          title="Attendance Verified!"
          msg={`You attendance for course "${data.course_name}" has been confirmed.`}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <TooltipComp
      id="verify-attendance"
      text={is_blacklisted ? "This school has deactivated your account." : null}
    >
      <Button
        onClick={handleVerify}
        isFetching={isLoadingUpdate}
        isDisabled={attendance_status !== 0 || is_blacklisted}
        icon={CheckIconCircle}
        minWidth="full"
      >
        {attendance_status !== 0 ? "Attendance Verified" : "Verify Attendance"}
      </Button>
    </TooltipComp>
  );
};

export default VerifyAttendance;
