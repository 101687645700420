import VerifyUserForm from "./components/VerifyUserForm";
import FormPage from "layouts/form-page";

const VerifyUser = () => {
  return (
    <FormPage headerVariant="only-logo" breadcrumbSkips={[1]}>
      <VerifyUserForm />
    </FormPage>
  );
};

export default VerifyUser;
