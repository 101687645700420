import React from "react";
import { useQuery } from "@tanstack/react-query";
import { adminSingleSchoolStatsDataQuery } from "query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import StatisticsList from "./StatisticsList";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as StudentsIcon } from "assets/icons/students.svg";
import { ReactComponent as DiplomaIcon } from "assets/icons/diploma.svg";
import { ISchoolReport } from "utils/apiDataTypes/AccountManagementDataTypes";

interface IStatisticsProps {
  id: string;
}

const SchoolStatistics = ({ id }: IStatisticsProps) => {
  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, ISchoolReport>(
    adminSingleSchoolStatsDataQuery(id)
  );

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) {
    return <ErrorComponent error={error} />;
  }

  const { courses, students, mintedDiplomas, pendingDiplomas } = data;

  return (
    <StatisticsList
      items={[
        {
          id: 1,
          name: "All Courses",
          number: courses,
          icon: BookIcon,
          link: `/admin-schools/${id}/courses`
        },
        {
          id: 2,
          name: "School Students",
          number: students,
          icon: StudentsIcon,
          link: `/admin-schools/${id}/students`
        },
        {
          id: 3,
          name: "Minted NFT Certificates",
          number: mintedDiplomas,
          icon: DiplomaIcon
        },
        {
          id: 4,
          name: "Pending NFT Certificates",
          number: pendingDiplomas,
          icon: DiplomaIcon
        }
      ]}
    />
  );
};

export default SchoolStatistics;
