import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import classes from "./TooltipComp.module.scss";
import React from "react";

type ITooltipProps = {
  children?: any;
  text: string;
  tooltipContent?: React.ReactNode;
  id: string;
  position?: "left" | "right" | "top" | "bottom";
  width?: "full";
};

const TooltipComp = ({
  children,
  text,
  tooltipContent,
  id,
  position,
  width
}: ITooltipProps) => {
  return (
    <div
      className={`${classes["wrapper"]} ${
        classes[width === "full" ? "full-width" : ""]
      }`}
    >
      <a
        id={id}
        className={`${classes["tooltip-comp"]} ${
          classes[width === "full" ? "full-width" : ""]
        }`}
        data-tooltip-place={position}
      >
        {children}
      </a>
      <Tooltip
        anchorId={id}
        content={text}
        className={classes["tooltip-wrapper"]}
      >
        {tooltipContent}
      </Tooltip>
    </div>
  );
};

export default TooltipComp;
