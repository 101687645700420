import classes from "./PopupTitleAndMessage.module.scss";
import Button from "components/buttons/Button";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

interface IPopupTitleAndMessage {
  title: string;
  msg?: string;
  msgSecondary?: string | React.ReactNode;
  hasBackBtn?: boolean;
  onBackBtnClick?: () => void;
}

const PopupTitleAndMessage = ({
  title,
  msg,
  msgSecondary,
  hasBackBtn,
  onBackBtnClick
}: IPopupTitleAndMessage) => {
  return (
    <div className={classes["wrapper"]}>
      {hasBackBtn && (
        <Button
          onClick={onBackBtnClick}
          variant={"link"}
          size="large"
          isIconBtn
          icon={ArrowIcon}
          iconRotate={180}
        />
      )}
      <h5
        className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${
          classes["u-text--medium"]
        } ${hasBackBtn ? classes["u-text--center"] : ""}`}
      >
        {title}
      </h5>
      {msg && (
        <p
          className={`${classes["u-text--content"]} ${classes["u-text--small"]}`}
        >
          {msg}
          {/*Currently used only for `Deposit popup`*/}
          {msgSecondary && (
            <>
              <br />
              {msgSecondary}
            </>
          )}
        </p>
      )}
    </div>
  );
};

export default PopupTitleAndMessage;
