import { colors } from "styles";
import { ReactComponent as Check } from "assets/icons/check-circle.svg";
import { ReactComponent as Clock } from "assets/icons/clock.svg";
import Button from "components/buttons/Button";
import Avatar from "components/avatar/Avatar";
import { ReactComponent as ErrorIcon } from "assets/icons/error-big.svg";
import classes from "./TableCols.module.scss";
import PermissionsGate from "roles/PermissionsGate";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import React from "react";
import RenderCell from "./RenderCell";
import { tableColumnsWidths } from "./utils/table-utils";

const selectDropdownItems = [
  { id: 0, name: "All", value: "0" },
  { id: 1, name: "Assigned", value: "1" },
  { id: 2, name: "Not Assigned", value: "2" }
];

export const iconMapping = {
  Active: <Check fill={`rgb(${colors.success50})`} width={16} height={16} />,
  Pending: <Clock fill={`rgb(${colors.warning60})`} width={16} height={16} />,
  Disabled: <ErrorIcon width={16} height={16} />
};

interface IManagersCols {
  selectedItems: { index: number; id: string; userId: string }[];
  handleSelectedItem: (
    item: { index: number; id: string; userId: string }[]
  ) => void;
  showConfirmPopup: (
    obj: { index: number; id: string; userId: string }[],
    assign: boolean
  ) => void;
  managers: any[];
}

export const ManagersCols = (
  selectedItems: IManagersCols["selectedItems"],
  handleSelectedItem: IManagersCols["handleSelectedItem"],
  showConfirmPopup: IManagersCols["showConfirmPopup"],
  managers: IManagersCols["managers"]
) => {
  const selectAllHandler = (rows) => {
    const allIds = rows.map((row) => {
      return {
        index: row.id,
        id: row.original.id,
        userId: row.original.userId
      };
    });
    let selectedArr = new Array(...selectedItems);
    const filtered = allIds.filter(({ id: id1 }) => {
      return !selectedArr.some(({ id: id2 }) => {
        return id1 === id2;
      });
    });
    if (filtered.length) {
      handleSelectedItem(allIds);
    } else {
      handleSelectedItem([]);
    }
  };

  const selectHandler = (itemObj: {
    index: number;
    id: string;
    userId: string;
  }) => {
    let arr = new Array(...selectedItems);

    if (arr.some((item) => item.id === itemObj.id)) {
      arr = arr.filter((item) => item.id !== itemObj.id);
    } else {
      arr.push(itemObj);
    }
    handleSelectedItem(arr);
  };

  const selectAllOfStatusTypeHandler = (rows, statusName: string) => {
    const ids = rows
      .filter((row) => {
        const isAssigned =
          managers &&
          managers.filter((m) => m.user_id === row.original.id).length > 0;
        return statusName === "Assigned" ? isAssigned : !isAssigned;
      })
      .map((row) => {
        return {
          index: row.id,
          id: row.original.id,
          userId: row.original.userId
        };
      });
    handleSelectedItem(ids);
  };

  return [
    {
      Header: (tableProps) => (
        <PermissionsGate permissions={["COURSE.UPDATE"]}>
          <div className={classes["header-checkbox-wrapper"]}>
            <label
              className={`${classes["custom-checkbox"]} ${classes["checkbox"]} `}
            >
              <input
                type="checkbox"
                onChange={() => {}}
                checked={tableProps.rows.length === selectedItems.length}
                onClick={() => {
                  selectAllHandler(tableProps.rows);
                }}
              />
              <span className={classes["mark"]} />
            </label>
            <ReactSelectWrapper
              name="select"
              placeholder=""
              options={selectDropdownItems.map((item) => {
                return { label: item.name, value: item.value };
              })}
              onChange={(option) => {
                option.label === "All"
                  ? selectAllHandler(tableProps.rows)
                  : selectAllOfStatusTypeHandler(tableProps.rows, option.label);
              }}
              value={{ label: "", value: "Asd" }}
              onlyChevron
              isSort
              left="-30px"
              top="44px"
            />
          </div>
        </PermissionsGate>
      ),
      accessor: "selected",
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      Cell: (tableProps) => (
        <PermissionsGate permissions={["COURSE.UPDATE"]}>
          <label className={classes["custom-checkbox"]}>
            <>
              <input
                type="checkbox"
                checked={
                  !!selectedItems.filter(
                    (obj) => obj.id === tableProps.row.original.id
                  )[0]
                }
                onClick={() =>
                  selectHandler({
                    index: tableProps.row.id,
                    id: tableProps.row.original.id,
                    userId: tableProps.row.original.userId
                  })
                }
                onChange={() => {}}
              />
              <span className={classes["mark"]} />
            </>
          </label>
        </PermissionsGate>
      )
    },
    {
      Header: "Course Manager",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="manager_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "First/Last Name",
      accessor: "names",
      minWidth: tableColumnsWidths.nameMin,
      Cell: (tableProps) => {
        const isAssigned =
          managers &&
          managers.filter((m) => m.user_id === tableProps.row.original.id)
            .length > 0;
        return (
          <div className={classes["names-container"]}>
            <Avatar size="sm" src={tableProps.row.original.pic} isActive />
            {`${tableProps.row.original.title || ""} ${
              tableProps.row.original.names
            }`}
            {isAssigned && (
              <span
                className={`${classes["u-text--content"]} ${classes["u-text--xsmall"]}`}
              >
                Active
              </span>
            )}
          </div>
        );
      }
    },
    {
      Header: "",
      accessor: "action",
      maxWidth: tableColumnsWidths.actionsExtMax,
      minWidth: tableColumnsWidths.actionsExtMin,
      width: tableColumnsWidths.actionsExt,
      Cell: (tableProps) => {
        const assignedManager = managers
          ? managers.filter((m) => m.user_id === tableProps.row.original.id)
          : [];
        const isAssigned = assignedManager.length > 0;
        return (
          <div className={classes["actions-container"]}>
            {isAssigned ? (
              <PermissionsGate permissions={["COURSE.UPDATE"]}>
                <Button
                  size="medium"
                  variant="neutral"
                  onClick={() => {
                    handleSelectedItem([
                      {
                        index: tableProps.row.id,
                        id: tableProps.row.original.id,
                        userId: isAssigned ? assignedManager[0].user_id : ""
                      }
                    ]);
                    showConfirmPopup(
                      [
                        {
                          index: tableProps.row.id,
                          id: tableProps.row.original.id,
                          userId: isAssigned ? assignedManager[0]._id : ""
                        }
                      ],
                      false
                    );
                  }}
                >
                  Remove
                </Button>
              </PermissionsGate>
            ) : (
              <PermissionsGate permissions={["COURSE.UPDATE"]}>
                <Button
                  size="medium"
                  variant="outline"
                  onClick={() => {
                    handleSelectedItem([
                      {
                        index: tableProps.row.id,
                        id: tableProps.row.original.id,
                        userId: isAssigned ? assignedManager[0].user_id : ""
                      }
                    ]);
                    showConfirmPopup(
                      [
                        {
                          index: tableProps.row.id,
                          id: tableProps.row.original.id,
                          userId: isAssigned ? assignedManager[0].user_id : ""
                        }
                      ],
                      true
                    );
                  }}
                >
                  Assign
                </Button>
              </PermissionsGate>
            )}
          </div>
        );
      }
    }
  ];
};
