import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import Avatar from "components/avatar/Avatar";
import Banner from "components/banner/Banner";
import classes from "./School.module.scss";
import {
  adminSingleSchoolDataQuery,
  schoolDataQuery,
  singleSchoolDataQuery
} from "query";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ISchoolInfo } from "utils/apiDataTypes/AccountManagementDataTypes";
import { formatSchoolAddress } from "utils/format";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import SchoolActions from "./SchoolActions";
import SchoolStatistics from "../../mainDashboard/components/SchoolStatistics";
import { userContext } from "context/userContext";
import PermissionsGate from "roles/PermissionsGate";

interface ISchoolProps {
  schoolId?: string;
  requestId?: string;
}

const School = ({ schoolId, requestId }: ISchoolProps) => {
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};

  const fetchedSingleSchoolData = useGetFetchQuery(
    is_super_admin
      ? adminSingleSchoolDataQuery(schoolId)
      : singleSchoolDataQuery(schoolId)
  ) as ISchoolInfo;

  const fetchedSchoolData = useGetFetchQuery(schoolDataQuery()) as ISchoolInfo;

  const { isLoading, error, data } = useQuery<boolean, Error, ISchoolInfo>({
    ...(schoolId
      ? is_super_admin
        ? adminSingleSchoolDataQuery(schoolId)
        : singleSchoolDataQuery(schoolId)
      : schoolDataQuery()),
    enabled:
      is_super_admin !== undefined
        ? schoolId
          ? !fetchedSingleSchoolData
          : !fetchedSchoolData
        : false
  });

  if (isLoading) return <Loader size="lg" hasText />;

  if (error) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );
  }

  const {
    _id,
    name,
    country,
    state,
    city,
    zip,
    addr1,
    addr2,
    phone,
    fax,
    website,
    description,
    CERP,
    PACE,
    pic,
    banner,
    activation_status,
    deactivate_note,
    is_deactivated
  } = data;

  // Handle opening a pdf in new tab
  const handleOpenPdf = (pdf: string) => window.open(pdf);

  return (
    <>
      <Banner src={banner} />
      <div className={classes["flex-wrapper"]}>
        <div className={classes["title-wrapper"]}>
          <Avatar
            className={classes["school-logo"]}
            src={pic}
            size="xl"
            type="school"
            isActive
          />
          <h3 className={`${classes["u-title"]} ${classes["u-bold"]}`}>
            {name}
          </h3>
        </div>
        <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
          <div className={classes["actions-wrapper"]}>
            <SchoolActions
              schoolId={schoolId}
              requestId={requestId}
              activation_status={activation_status}
              note={deactivate_note}
              isDeactivated={is_deactivated}
            />
          </div>
        </PermissionsGate>
      </div>
      <div className={classes["school-details"]}>
        {description && (
          <div>
            <div>
              <h6 className={`${classes["u-title"]} ${classes["u-bold"]}`}>
                School Details
              </h6>
              <p className={classes["u-text--xsmall"]}>{description}</p>
            </div>
          </div>
        )}
        <div>
          <div>
            <h6 className={`${classes["u-title"]} ${classes["u-bold"]}`}>
              School Address
            </h6>
            <p className={classes["u-text--xsmall"]}>
              {formatSchoolAddress(
                addr1,
                addr2,
                city,
                zip,
                state ? state.code : null,
                country
              )}
            </p>
          </div>
          <div>
            <h6 className={`${classes["u-title"]} ${classes["u-bold"]}`}>
              School Contacts
            </h6>
            <p className={classes["u-text--xsmall"]}>
              Phone: <a href={`tel: ${phone}`}>{phone}</a>
              <br />
              {fax && (
                <>
                  Fax: <a href={`tel: ${fax}`}>{fax}</a>
                  <br />
                </>
              )}
              Website:{" "}
              <a
                className={classes["u-underline"]}
                href={website.includes("http") ? website : `//${website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
            </p>
          </div>
          {CERP && (
            <div>
              <h6 className={`${classes["u-title"]} ${classes["u-bold"]}`}>
                CERP Certification
              </h6>
              <p
                className={`${classes["u-text--xsmall"]} ${classes["u-cursor--pointer"]} ${classes["u-underline"]}`}
                onClick={() =>
                  handleOpenPdf(`${FILES_PUBLIC_DOMAIN}${data.CERP}`)
                }
              >
                View Certificate
              </p>
            </div>
          )}
          {PACE && (
            <div>
              <h6 className={`${classes["u-title"]} ${classes["u-bold"]}`}>
                PACE Certification
              </h6>
              <p
                className={`${classes["u-text--xsmall"]} ${classes["u-cursor--pointer"]} ${classes["u-underline"]}`}
                onClick={() =>
                  handleOpenPdf(`${FILES_PUBLIC_DOMAIN}${data.PACE}`)
                }
              >
                View Certificate
              </p>
            </div>
          )}
        </div>
      </div>
      {!requestId && (
        <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
          <SchoolStatistics id={_id} />
        </PermissionsGate>
      )}
    </>
  );
};

export default School;
