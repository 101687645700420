import React, { useContext, useState } from "react";
import { ReactComponent as copyIcon } from "assets/icons/copy.svg";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import Button from "components/buttons/Button";
import { toastContext } from "context/toastContext";
import { formatAddress } from "utils/format";
import classes from "./Wallet.module.scss";
import { windowContext } from "context/windowsContext";

interface IWallet {
  wallet: string;
  isShort?: boolean;
  className?: any;
}

const Wallet = ({ wallet, isShort, className }: IWallet) => {
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const [copied, setCopied] = useState<boolean>(false);
  const { setToast } = useContext(toastContext);
  return (
    <span className={`${classes["wallet-short"]} ${className}`}>
      {wallet ? (isMobile || isShort ? formatAddress(wallet) : wallet) : "-"}
      {wallet && (
        <Button
          size="small"
          variant="link"
          onClick={() => {
            navigator.clipboard.writeText(wallet);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
            setToast({
              type: "info",
              msg: "Address copied successfully.",
              isSelfClosing: true,
              withTitle: false
            });
          }}
          isIconBtn
          icon={!copied ? copyIcon : checkIcon}
        />
      )}
    </span>
  );
};

export default Wallet;
