import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import useComponentVisible from "hooks/useComponentVisible";
import classes from "./Filter.module.scss";
import Button from "components/buttons/Button";
import TooltipComp from "components/tooltip/TooltipComp";

export interface IFilterOption {
  id: number;
  label: string;
  value: string;
}

interface IFilter {
  options: IFilterOption[];
  value: IFilterOption;
  setValue: (value: IFilterOption) => void;
  filterLabel?: string;
}

const Filter = ({ options, value, setValue, filterLabel }: IFilter) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOptionClick = (item: IFilterOption) => {
    setValue(item);
    setIsComponentVisible(false);
  };

  return (
    <div className={classes["filter-wrapper"]} ref={ref}>
      <TooltipComp
        id="filter-btn"
        text={
          value.id === 0
            ? `Filter by${filterLabel ? ` ${filterLabel}` : ""}`
            : `Filtered by${filterLabel ? ` ${filterLabel}` : ""}: ${
                value.label
              }`
        }
        position="bottom"
      >
        <Button
          variant={value?.id !== 0 ? "contrast" : "neutral"}
          isIconBtn
          icon={FilterIcon}
          size="medium"
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        />
      </TooltipComp>
      {isComponentVisible && (
        <div
          className={`${classes["c-menu-wrapper"]} ${classes["filter-menu"]}`}
        >
          <div className={classes["c-menu"]}>
            {options.map((option, i) => (
              <div
                key={i}
                className={`${classes["c-menu-item"]} ${
                  value.id === option.id ? classes["selected"] : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
