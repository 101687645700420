import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import { popupContext } from "context/popupContext";
import { useFormData } from "hooks/useFormData";
import { useContext } from "react";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/question-big.svg";
import Button from "components/buttons/Button";
import { InputField } from "components/input/Input";
import TitleAndMsg from "components/forms/form-title-and-msg/TitleAndMsg";
import GenericPopup from "components/popups/GenericPopup";
import { hasErrors } from "utils/form";
import { fetchApi } from "utils/requests";
import { yupSchemas } from "utils/yupSchemas";
import { colors } from "styles";
import classes from "./ForgotPasswordForm.module.scss";
import { PROJECT_ID } from "utils/constants";

interface IDefaultFormData {
  email: string;
}

const defaultFormData = {
  email: ""
};

const validationSchema: Yup.SchemaOf<IDefaultFormData> = Yup.object().shape({
  email: yupSchemas.email
});

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { formData, setFormData, formErrors, handleChange } = useFormData(
    defaultFormData,
    validationSchema
  );

  const { setPopup } = useContext(popupContext);
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = async (data) =>
    await fetchApi("account", "/user/forgetPass", {
      method: "POST",
      data: { ...data, project_id: PROJECT_ID.OPUS_NFT }
    });

  // Handle forgotten pass mutation
  const { isLoading, mutate: handleForgottenPass } = useMutation({
    mutationKey: ["forgotten-pass"],
    mutationFn: async (event: HTMLFormElement) => {
      emailSent &&
        setPopup(
          <GenericPopup
            type="loading"
            title="Sending..."
            msg="Email sending..."
          />
        );
      if (event) {
        event.preventDefault();
      }
      await sendEmail(formData);
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          icon={<EmailIcon fill={`rgb(${colors.orange60})`} />}
          size="md"
          title="Success!"
          msg="You will receive an e-mail with the next steps if the entered e-mail is associated with an account in our system. Please, follow the instructions in the e-mail to complete the password recovery process."
          redirectPath="/login"
          bellowBtnComp={
            !emailSent ? (
              <Button
                variant="link"
                onClick={() => {
                  setPopup(
                    <GenericPopup
                      icon={<QuestionIcon fill={`rgb(${colors.orange60})`} />}
                      size="md"
                      title="Didn't receive an email?"
                      msg="If you are still not receiving an email, please contact our support."
                      buttonName="Resend"
                      buttonVariant="contrast"
                      buttonAction={handleForgottenPass}
                      redirectPath="/login"
                    />
                  );
                }}
              >
                Didn't receive an email?
              </Button>
            ) : (
              <></>
            )
          }
        />
      );
      setFormData(defaultFormData);
      setEmailSent(true);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <form>
      <div className={`${classes["container"]} ${classes["form-sm"]}`}>
        <TitleAndMsg
          title="Forgot Password?"
          msg="That's okay, it happens! Enter your email below and click on the button to be sent a link to create a new password."
          hasBackBtn
          onClick={() => navigate("/login")}
        />
        <InputField
          title="E-mail"
          name="email"
          onChange={handleChange}
          value={formData.email}
          error={formErrors.email}
        />
        <Button
          type="submit"
          minWidth="full"
          onClick={handleForgottenPass}
          isDisabled={hasErrors(formErrors) || !formData.email.length}
          isFetching={isLoading}
        >
          Reset Password
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
