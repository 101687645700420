export const colors = {
  white: "255, 255, 255",
  black: "0, 0, 0",

  orange10: "255, 238, 224",
  orange20: "255, 205, 166",
  orange30: " 255, 185, 134",
  orange40: " 255, 161, 94",
  orange50: "255, 144, 64",
  orange60: "255, 119, 21",
  orange70: "204, 85, 0",
  orange80: "160, 67, 0",
  orange90: "112, 47, 0",
  orange100: "88, 37, 0",

  darkBlue10: "93, 104, 248",
  darkBlue20: "72, 81, 216",
  darkBlue30: "54, 62, 176",
  darkBlue40: "49, 56, 147",
  darkBlue50: "40, 45, 117",
  darkBlue60: "32, 36, 96",
  darkBlue70: "27, 30, 75",
  darkBlue80: "22, 24, 57",
  darkBlue90: "15, 16, 41",
  darkBlue100: "8, 9, 24",

  greyishBlue10: "213, 216, 243",
  greyishBlue20: "188, 191, 223",
  greyishBlue30: "166, 169, 196",
  greyishBlue40: "143, 146, 177",
  greyishBlue50: "111, 116, 158",
  greyishBlue60: "79, 85, 130",
  greyishBlue70: "65, 69, 108",
  greyishBlue80: "49, 52, 81",
  greyishBlue90: "36, 38, 58",
  greyishBlue100: "22, 23, 37",

  lightBlue10: "251, 252, 255",
  lightBlue20: "249, 250, 255",
  lightBlue30: "245, 247, 255",
  lightBlue40: "237, 239, 255",
  lightBlue50: "230, 232, 255",
  lightBlue60: "220, 223, 255",
  lightBlue70: "206, 210, 255",
  lightBlue80: "188, 193, 255",
  lightBlue90: "159, 166, 255",
  lightBlue100: "133, 141, 255",

  neutral10: "255, 255, 255",
  neutral20: "225, 225, 225",
  neutral30: "195, 195, 195",
  neutral40: "148, 148, 148",
  neutral50: "113, 112, 112",
  neutral60: "86, 86, 86",
  neutral70: "60, 60, 60",
  neutral80: "29, 29, 29",
  neutral90: "19, 19, 19",
  neutral100: "0, 0, 0",

  success10: "227, 255, 235",
  success20: "166, 243, 187",
  success30: "124, 230, 157",
  success40: "83, 212, 127",
  success50: "41, 188, 99",
  success60: "13, 160, 72",
  success70: "4, 139, 58",
  success80: "0, 114, 45",
  success90: "0, 87, 32",
  success100: "0, 58, 20",

  warning10: "255, 247, 228",
  warning20: "255, 240, 203",
  warning30: "255, 232, 172",
  warning40: "255, 217, 122",
  warning50: "255, 205, 78",
  warning60: "255, 190, 22",
  warning70: "231, 166, 0",
  warning80: "152, 109, 0",
  warning90: "95, 68, 0",
  warning100: "53, 38, 0",

  error10: "255, 245, 247",
  error20: "255, 204, 210",
  error30: "255, 124, 146",
  error40: "255, 83, 118",
  error50: "252, 43, 96",
  error60: "234, 21, 72",
  error70: "196, 14, 58",
  error80: "157, 9, 44",
  error90: "102, 3, 27",
  error100: "40, 17, 23"
};

export const fonts = {
  size: {
    tiny: "10px",
    xsmall: "12px",
    small: "14px",
    medium: "16px",
    large: "18px",
    h1: "64px",
    h2: "48px",
    h3: "32px",
    h4: "24px",
    h5: "18px",
    h6: "14px"
  },
  weight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800
  },
  family: {
    primary: "Poppins",
    diplomasDefault: "EBGaramond Semibold",
    diplomasSecondary: "Poppins Semibold"
  }
};

export const transitions = {
  short: "all 0.1s ease-in-out",
  base: "all 0.2s ease-in-out",
  long: "all 0.3s ease-in-out",
  button: "all 0.15s ease-in-out"
};

export const globalStyle = ({ theme }) => `
  
    html, body, #root {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
    }  

    html {
      /* width */
      ::-webkit-scrollbar {
        width: 4px;
 
      }
      ::-webkit-scrollbar-track {
        border-radius: 5px;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${
          theme === "dark"
            ? `rgb(${colors.greyishBlue50})`
            : `rgb(${colors.greyishBlue10})`
        };
        border-radius: 5px;
      }
    }
  
    body {
      font-family: ${fonts.family.primary};
      font-style: normal;
      font-stretch: normal;
      font-weight: ${fonts.weight.normal};
      font-size: ${fonts.size.medium};
      background: ${
        theme === "dark"
          ? `rgb(${colors.greyishBlue100})`
          : `rgb(${colors.white})`
      };
      color: rgb(${colors.black});
      overflow-y:auto;
      text-rendering: optimizeLegibility;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: 100%;
      -webkit-overflow-scrolling: touch;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;  
      ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 5px;
      }
      --wcm-z-index: 100 !important;
    }
  
    img {
      margin: 0;
    }

    button {
      border-style: none;
      line-height: 1em;
      background-image: none;
      outline: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
  
    [tabindex] {
      outline: none;
      // width: 100%;
      height: 100%;
    }

    .react-datepicker{
      border-radius:0
    }
    .react-datepicker__day{
      border-radius:0;
    }

    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
      background:black;
    }
    .react-datepicker__day:hover{
      border-radius:0
    }
  
    a, p, h1, h2, h3, h4, h5, h6 {
        text-decoration: none;
        margin: 0;
      padding: 0;
      margin: 0.7em 0;
      font-weight: normal;
    }
  
    h1 {
      font-size: ${fonts.size.h1}
    }
    h2 {
      font-size: ${fonts.size.h2}
    }
    h3 {
      font-size: ${fonts.size.h3}
    }
    h4 {
      font-size: ${fonts.size.h4}
    }
    h5 {
      font-size: ${fonts.size.h5}
    }
    h6 {
      font-size: ${fonts.size.h6}
    }
  
    a {
      background-color: transparent;
      -webkit-text-decoration-skip: objects;  
      text-decoration: none;
      color: inherit;
      outline: none;
    }
  
    b,
    strong {
      font-weight: inherit;
      font-weight: bolder;
    }
  
    ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
  
    * {
      box-sizing: border-box !important;
    }
  
  
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }
    audio,
    canvas,
    progress,
    video {
      display: inline-block;
    }

    textarea:focus, input:focus{
      outline: none;
    }
  
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    input[type="checkbox"],
    select:focus,
    textarea {

    }

    ::-webkit-calendar-picker-indicator {
      // filter: invert(1);
  }


    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}
  `;

export const border = {
  radius: {
    small: "5px",
    medium: "15px",
    big: "25px",
    rounded: "50px"
  }
};
