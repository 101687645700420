import { fetchApi } from "../utils/requests";
import { ethers } from "ethers";

export const countriesDataQuery = () => {
  return {
    queryKey: ["countries-data"],
    queryFn: () =>
      fetchApi("courses", "/location/countries", {
        method: "GET"
      })
  };
};

export const citiesDataQuery = (id: string) => {
  return {
    queryKey: ["cities-data", { stateId: id }],
    queryFn: () =>
      fetchApi("courses", `/location/states/${id}/cities`, {
        method: "GET"
      })
  };
};

export const meDataQuery = () => {
  return {
    queryKey: ["me-data"],
    queryFn: () =>
      fetchApi("account", "/user/me", {
        method: "GET",
        auth: true
      })
  };
};

export const schoolsRegRequestsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["school-reg-requests", queryKeyParams]
      : ["school-reg-requests"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/admin/registration-request${searchParams}`
          : "/admin/registration-request",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const schoolDataQuery = () => {
  return {
    queryKey: ["school-data"],
    queryFn: () =>
      fetchApi("courses", "/school", {
        method: "GET",
        auth: true
      })
  };
};

export const schoolsDataQuery = () => {
  return {
    queryKey: ["schools-data"],
    queryFn: () =>
      fetchApi("courses", "/school/my-schools", {
        method: "GET",
        auth: true
      })
  };
};

export const singleSchoolDataQuery = (id: string) => {
  return {
    queryKey: ["schools-data", { id }],
    queryFn: () =>
      fetchApi("courses", `/school/my-schools/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const adminSingleSchoolDataQuery = (id: string) => {
  return {
    queryKey: ["schools-data", { id }],
    queryFn: () =>
      fetchApi("courses", `/admin/schools/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const schoolMemberDataQuery = (id: string) => {
  return {
    queryKey: ["team-data", { id }],
    queryFn: () =>
      fetchApi("courses", `/school/members/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const permissionsDataQuery = () => {
  return {
    queryKey: ["permissions-data"],
    queryFn: () =>
      fetchApi("roles", "/role/permissions/all", {
        method: "GET",
        auth: true
      })
  };
};

// Build query keys from search params
export const buildQueryKey = (params) => {
  if (!params) return {};

  const splittedParams = params.split("&").slice(1);
  const arr = splittedParams.map((item) => {
    const [k, v] = item.split("=");
    if (v) {
      return {
        [k]: v
      };
    } else {
      return false;
    }
  });
  return arr.reduce(
    (a, v) => ({
      ...a,
      ...v
    }),
    {}
  );
};

export const teamDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams ? ["team-data", queryKeyParams] : ["team-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/school/members${searchParams}`
          : "/school/members",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const getAllRolesQuery = () => {
  return {
    queryKey: ["roles-data"],
    queryFn: () =>
      fetchApi("roles", "/role/school/roles", {
        method: "GET",
        auth: true
      })
  };
};

export const coursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["courses-data", queryKeyParams]
      : ["courses-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/course${searchParams}` : "/course",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const coursesDraftsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["courses-drafts-data", queryKeyParams]
      : ["courses-drafts-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/draft${searchParams}` : "/draft",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentCoursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["student-courses-data", queryKeyParams]
      : ["student-courses-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course-student/courses${searchParams}`
          : "/course-student/courses",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const singleCourseDataQuery = (id: string) => {
  return {
    queryKey: [`courses-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/course/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleCourseDraftDataQuery = (id: string) => {
  return {
    queryKey: [`courses-drafts-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/draft/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleStudentCourseDataQuery = (id: string) => {
  return {
    queryKey: [`student-courses-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/course-student/courses/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleCourseStudentsDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? [`course-students-data`, { id, ...queryKeyParams }]
      : [`course-students-data`, { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course/${id}/students${searchParams}`
          : `/course/${id}/students`,
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const courseTemplatesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["course-templates-data", queryKeyParams]
      : ["course-templates-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course${searchParams}&is_template=true`
          : "/course?is_template=true",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const nftTemplatesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["nft-templates-data", queryKeyParams]
      : ["nft-templates-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/template${searchParams}` : "/template",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const mintRequestsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["mint-requests", queryKeyParams]
      : ["mint-requests"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/mint-request${searchParams}` : "/mint-request",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const readyToMintDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["ready-to-mint", queryKeyParams]
      : ["ready-to-mint"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course-student/ready-to-mint${searchParams}`
          : "/course-student/ready-to-mint",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["students-data", queryKeyParams]
      : ["students-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/school/students${searchParams}`
          : "/school/students",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentDataQuery = (id: string) => {
  return {
    queryKey: ["student-data", { id }],
    queryFn: () =>
      fetchApi("courses", `/school/students/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const studentDiplomasDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["student-diplomas-data", queryKeyParams]
      : ["student-diplomas-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course-student/my-diplomas${searchParams}`
          : "/course-student/my-diplomas",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentDiplomasPublicDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["student-diplomas-data", { id, ...queryKeyParams }]
      : ["student-diplomas-data", { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course-student/public/${id}/diplomas${searchParams}`
          : `/course-student/public/${id}/diplomas`,
        {
          method: "GET"
        }
      )
  };
};

export const studentSingleDiplomaDataQuery = (id: string) => {
  return {
    queryKey: [`student-diplomas-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/course-student/my-diplomas/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleDiplomaDataQuery = (userId: string, tokenId: string) => {
  return {
    queryKey: [`diplomas-data`, { userId, tokenId }],
    queryFn: () =>
      fetchApi("courses", `/course-student/diplomas/${userId}/${tokenId}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleDiplomaDataPublicQuery = (
  userId: string,
  tokenId: string
) => {
  return {
    queryKey: [`diplomas-data`, { userId, tokenId }],
    queryFn: () =>
      fetchApi(
        "courses",
        `/course-student/public/${userId}/diplomas/${tokenId}`,
        {
          method: "GET"
        }
      )
  };
};

export const getDiplomaPreviewLink = (studentId: string) => {
  return {
    queryKey: ["diploma-preview", { id: studentId }],
    queryFn: () =>
      fetchApi("courses", `/course-student/diplomas/preview/${studentId}`, {
        method: "GET",
        auth: true
      })
  };
};

export const connectWalletMutation = (
  isStudent: boolean,
  invalidateFn?: (resp: { message: string; wallet: string }) => void
) => {
  const route = isStudent
    ? "/user/student-wallet/connect"
    : "/user/wallet/connect";
  return {
    mutationKey: ["school-wallet"],
    mutationFn: async (walletAddress: string) => {
      const wallet = ethers.utils.getAddress(walletAddress);
      await fetchApi("account", route, {
        method: "POST",
        auth: true,
        data: {
          wallet
        }
      }).then((resp) => invalidateFn && invalidateFn(resp));
    }
  };
};

export const disconnectWalletMutation = (
  isStudent?: boolean,
  invalidateFn?: (resp?: { message: string; wallet: string }) => void
) => {
  const route = isStudent
    ? "/user/student-wallet/disconnect"
    : "/user/wallet/disconnect";
  return {
    mutationKey: ["school-wallet"],
    mutationFn: async (walletAddress: string) =>
      await fetchApi("account", route, {
        method: "POST",
        auth: true,
        data: {
          wallet: walletAddress
        }
      }).then(() => invalidateFn && invalidateFn())
  };
};

export const singleDiplomaTemplateQuery = (id: string) => {
  return {
    queryKey: [`diploma-template`, { id }],
    queryFn: () =>
      fetchApi("courses", `/template/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const adminGetAllSchools = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["schools-data", queryKeyParams]
      : ["schools-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/admin/schools${searchParams}` : "/admin/schools",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const adminStudentsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["students-data", queryKeyParams]
      : ["students-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/admin/students${searchParams}`
          : "/admin/students",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const adminStudentsBySchoolDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["students-data", { id, ...queryKeyParams }]
      : ["students-data", { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/admin/schools/${id}/students${searchParams}`
          : `/admin/schools/${id}/students`,
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const adminStudentDataQuery = (id: string) => {
  return {
    queryKey: ["student-data", { id }],
    queryFn: () =>
      fetchApi("courses", `/admin/student/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const adminSchoolMemberDataQuery = (id: string) => {
  return {
    queryKey: ["team-data", { id }],
    queryFn: () =>
      fetchApi("courses", `/admin/student/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const adminSingleSchoolStatsDataQuery = (id: string) => {
  return {
    queryKey: [`school-stats-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/admin/schools/${id}/report`, {
        method: "GET",
        auth: true
      })
  };
};

export const adminPlatformStatsDataQuery = () => {
  return {
    queryKey: [`platform-stats-data`],
    queryFn: () =>
      fetchApi("courses", "/admin/platform/report", {
        method: "GET",
        auth: true
      })
  };
};

export const adminCoursesDataQuery = (id: string, searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["admin-courses-data", { id, ...queryKeyParams }]
      : ["admin-courses-data", { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/admin/schools/${id}/courses${searchParams}`
          : `/admin/schools/${id}/courses`,
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const adminTeamDataQuery = (id: string, searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["team-data", { id, ...queryKeyParams }]
      : ["team-data", { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/admin/schools/${id}/members${searchParams}`
          : `/admin/schools/${id}/members`,
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const adminSingleCourseDataQuery = (id: string) => {
  return {
    queryKey: [`courses-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/admin/courses/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const adminSingleCourseStudentsDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? [`course-students-data`, { id, ...queryKeyParams }]
      : [`course-students-data`, { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/admin/courses/${id}/students${searchParams}`
          : `/admin/courses/${id}/students`,
        {
          method: "GET",
          auth: true
        }
      )
  };
};
