import React from "react";
import FormWrapper from "components/forms/form-wrapper/FormWrapper";
import MemberForm from "../components/MemberForm";
import { IMemberFormData } from "utils/apiDataTypes/AccountManagementDataTypes";

const CreateMember = () => {
  const defaultFormData: IMemberFormData = {
    role_type: null,
    title: null,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    pic: ""
  };

  return (
    <FormWrapper title="Add Team member">
      <MemberForm defaultFormData={defaultFormData} />
    </FormWrapper>
  );
};

export default CreateMember;
