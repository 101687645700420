import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { themeContext } from "context/themeContext";
import { userContext } from "context/userContext";
import Error404 from "assets/images/404-page/404.png";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import Header from "components/header/Header";
import ThemeSwitcher from "components/theme-switcher/ThemeSwitcher";
import Button from "components/buttons/Button";
import classes from "./Page404.module.scss";

const Page404 = () => {
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};
  return (
    <div className={classes["wrapper"]} data-theme={theme}>
      <Header variant="only-logo" isTransparent isFullWidth />
      <ThemeSwitcher className={classes["switcher"]} />
      <div className={classes["container"]}>
        <img src={Error404} width={300} alt="404" />
        <div className={classes["description"]}>
          <div
            className={`${classes["u-extralight"]} ${classes["u-text--primary"]} ${classes["u-title"]}`}
          >
            <b>404</b> Page Not Found
          </div>
        </div>
        <Button
          onClick={() =>
            navigate(is_super_admin ? "/main-dashboard" : "/school")
          }
          icon={ArrowIcon}
          iconRotate={180}
        >
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default Page404;
