import React, { FormEvent, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormData } from "hooks/useFormData";
import { popupContext } from "context/popupContext";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import { InputField } from "components/input/Input";
import ImageInput from "components/image-input/ImageInput";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { fetchApi } from "utils/requests";
import { hasErrors } from "utils/form";
import {
  IProfile,
  ROLE_FLAG
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { yupSchemas } from "utils/yupSchemas";
import { fileInstructions, titleOptions } from "utils/staticOptions";
import classes from "./ActivateUserForm.module.scss";
import { Navigate, useSearchParams } from "react-router-dom";
import Loader from "components/loader/Loader";
import { IMemberFormFields } from "utils/apiDataTypes/AccountManagementDataTypes";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { PROJECT_ID } from "utils/constants";

interface IExtendedProfile extends IProfile {
  email: string;
  password: string;
  new_pass: string;
  confirm_new_pass: string;
}

const validationSchema: Yup.SchemaOf<IExtendedProfile> = Yup.object().shape({
  title: yupSchemas.labelAndValue,
  email: yupSchemas.email,
  password: yupSchemas.requiredStr,
  new_pass: yupSchemas.new_pass,
  confirm_new_pass: yupSchemas.confirm_new_pass,
  first_name: yupSchemas.requiredStr,
  last_name: yupSchemas.requiredStr,
  wallet: yupSchemas.wallet,
  pic: Yup.string()
});

const formValues = {
  title: null,
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  new_pass: "",
  confirm_new_pass: "",
  wallet: "",
  pic: ""
};

interface IFetchedUserData extends IMemberFormFields {
  active: boolean;
  wallet: string;
  role_flag: ROLE_FLAG;
}

const ActivateUserForm = () => {
  const {
    formData,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    formErrors,
    setFormErrors
  } = useFormData(formValues, validationSchema);
  const { setPopup } = useContext(popupContext);

  const [searchParams] = useSearchParams();
  const uid = searchParams.get("u_id");
  const hash = searchParams.get("hash");

  const {
    isLoading: userDataIsLoading,
    error: userDataError,
    data: userData
  } = useQuery<boolean, Error, IFetchedUserData>({
    queryKey: ["user-data"],
    queryFn: () =>
      fetchApi("account", `/user/activate/${uid}/${hash}`, {
        method: "GET"
      })
  });

  useEffect(() => {
    if (userData) {
      const { first_name, last_name, email, phone, title, wallet } = userData;
      setFormData({
        first_name,
        last_name,
        email,
        phone,
        title: title ? { label: title, value: title } : null,
        wallet
      });
    }
  }, [userData]);

  const register = async (formData: IExtendedProfile) => {
    const data = {
      password: formData.password,
      new_pass: formData.new_pass,
      title: formData.title?.value || null,
      first_name: formData.first_name,
      last_name: formData.last_name,
      pic: formData.pic,
      wallet: formData.wallet || null,
      project_id: PROJECT_ID.OPUS_NFT
    };
    await fetchApi("account", `/user/activate/${uid}`, {
      method: "PUT",
      data
    });
  };

  // Register mutation
  const { isLoading, mutate: handleRegister } = useMutation({
    mutationKey: ["user-register"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      await register(formData);
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          msg="Your profile has been successfully updated."
          size="sm"
          redirectPath="/login"
          buttonName="Go to Login"
          buttonVariant="contrast"
          isClosable={false}
        />
      );
      setFormData(formValues);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  if (userDataIsLoading) return <Loader size="lg" hasText withPadding />;

  if (userDataError && !userData) return <Navigate to="/login" />;

  return (
    userData &&
    !userData.active && (
      <div className={`${classes["wrapper"]} ${classes["form-md"]}`}>
        <div className={classes["form-layout"]}>
          <div>
            <h3 className={classes["title"]}>Finish Registration</h3>
            <div
              className={`${classes["desc"]} ${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
            >
              (* Required Information)
            </div>
          </div>
          <form>
            <div className={classes["body"]}>
              <ReactSelectWrapper
                name="title"
                title="Title"
                placeholder="Choose Title"
                options={titleOptions.map((name) => {
                  return { label: name, value: name };
                })}
                value={formData.title}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: "title",
                      value: e
                    }
                  })
                }
                isClearable
              />
              <InputField
                title="First Name*"
                name="first_name"
                placeholder="Enter First Name"
                onChange={handleChange}
                value={formData.first_name}
                error={formErrors.first_name}
              />
              <InputField
                title="Last Name*"
                name="last_name"
                placeholder="Enter Last Name"
                onChange={handleChange}
                value={formData.last_name}
                error={formErrors.last_name}
              />
              <InputField
                title="E-mail*"
                name="email"
                placeholder="Enter Email"
                onChange={handleChange}
                value={formData.email}
                error={formErrors.email}
                isDisabled
              />
              <InputField
                title="Temporary Password*"
                type="password"
                name="password"
                placeholder="Enter Temporary Password*"
                onChange={handleChange}
                value={formData.password}
                error={formErrors.password}
                showTempPasswordTooltip
              />
              <InputField
                title="New Password*"
                type="password"
                name="new_pass"
                placeholder="Enter Password"
                onChange={handleChange}
                value={formData.new_pass}
                error={formErrors.new_pass}
                showPasswordTooltip
              />
              <InputField
                title="Confirm Password*"
                type="password"
                name="confirm_new_pass"
                placeholder="Confirm Password"
                onChange={handleChange}
                value={formData.confirm_new_pass}
                error={formErrors.confirm_new_pass}
                showPasswordTooltip
              />
              {userData.role_flag === 1 && (
                <InputField
                  title="Wallet Address"
                  name="wallet"
                  placeholder="Enter Wallet Address"
                  onChange={handleChange}
                  value={formData.wallet}
                  error={formErrors.wallet}
                />
              )}
              <ImageInput
                title="Profile Picture"
                subtitle={fileInstructions.pfp}
                fileType="image/png, image/jpeg, image/jpg"
                pic={formData.pic}
                onChange={(e: FormEvent) => handleUploadedFileBase64("pic", e)}
                onClear={() => {
                  setFormData({
                    ...formData,
                    pic: ""
                  });
                  setFormErrors({ ...formErrors, pic: "" });
                }}
                error={formErrors.pic}
              />
              <Button
                type="submit"
                onClick={handleRegister}
                icon={checkIcon}
                iconPosition="right"
                isDisabled={
                  hasErrors(formErrors) ||
                  !formData.email ||
                  !formData.password ||
                  !formData.new_pass ||
                  !formData.confirm_new_pass ||
                  !formData.first_name ||
                  !formData.last_name
                }
                isFetching={isLoading}
              >
                Finish Registration
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};
export default ActivateUserForm;
