import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import PageHeader from "components/page-header/PageHeader";
import { calcItemsAmount } from "components/table/utils/table-utils";
import SchoolRegistrationRequestsTable from "./components/SchoolRegistrationRequestsTable";
import Button from "components/buttons/Button";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classes from "./MainDashboard.module.scss";
import { useQuery } from "@tanstack/react-query";
import { schoolsRegRequestsDataQuery } from "query";
import { ISchoolRegRequestsData } from "utils/apiDataTypes/AccountManagementDataTypes";
import PlatformStatistics from "./components/PlatformStatistics";
import { windowContext } from "context/windowsContext";

const MainDashboard = () => {
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { page: pageNumber } = queryString.parse(urlParams);

  const [page] = useState<number>(parseInt(pageNumber as string) || 1);
  const perPage: number = 3;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ISchoolRegRequestsData
  >(schoolsRegRequestsDataQuery(`?limit=${perPage}&page=${page}&status=0`));

  const { result, all_records, total_results } = data || {};

  return (
    <div className={classes["content-wrapper"]}>
      <PageHeader
        hasData
        title="Platform Summary"
        hasSearch={false}
        hasSort={false}
      />
      <PlatformStatistics />
      <PageHeader
        hasData={true}
        title={`${!isMobile ? "School" : ""} Registration Requests`}
        itemsAmountMsg={calcItemsAmount({
          page,
          perPage,
          result,
          total_results,
          all_records
        })}
        hasSearch={false}
        hasSort={false}
      >
        {total_results > 0 && (
          <Button
            onClick={() => navigate("/admin-registration-requests")}
            icon={ArrowIcon}
            iconPosition={"right"}
            variant={"link"}
          >
            View All
          </Button>
        )}
      </PageHeader>
      <SchoolRegistrationRequestsTable
        data={{ data, isLoading, error }}
        isPaginationHidden
      />
    </div>
  );
};

export default MainDashboard;
