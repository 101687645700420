import { createContext, useEffect } from "react";
import useLocalStorage from "use-local-storage";

interface IThemeCtx {
  theme?: "dark" | "light" | string;
  switchTheme?: () => void;
  isCollapsed?: boolean;
  setIsCollapsed?: (isOpen: boolean) => void;
}

export const themeContext = createContext<IThemeCtx>({});

export function ThemeProvider(props: any) {
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    "sidebar_collapsed",
    false
  );

  const switchTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  return (
    <themeContext.Provider
      value={{ theme, switchTheme, isCollapsed, setIsCollapsed }}
    >
      {props.children}
    </themeContext.Provider>
  );
}
