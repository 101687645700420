import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Button from "components/buttons/Button";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import Badge from "components/badge/Badge";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import SinglePrimaryInfo from "../../components/SinglePrimaryInfo";
import classes from "../../components/Single.module.scss";
import { singleCourseDataQuery } from "query";

const SingleTemplate = () => {
  let { courseId: id } = useParams();
  const navigate = useNavigate();

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, ICourse>(
    singleCourseDataQuery(id)
  );

  if (isLoading) return <Loader size="lg" hasText />;

  if (error) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );
  }

  return (
    <div className={classes["content-wrapper"]}>
      <div className={classes["course-wrapper"]}>
        {data.image ? (
          <div className={classes["card-pic"]}>
            <img
              src={`${FILES_PUBLIC_DOMAIN}${data.image}`}
              alt="Course template"
            />
          </div>
        ) : (
          <div
            className={`${classes["card-pic"]} ${classes["card-pic--empty"]}`}
          >
            <BookIcon />
          </div>
        )}
        <div>
          <div>
            <div className={classes["card-labels"]}>
              <Badge text="Template" />
              {data.is_open && <Badge text="Open Course" isOpen />}
            </div>
            <h3
              className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["m-0"]}`}
            >
              {data.name}
            </h3>
          </div>
          <SinglePrimaryInfo
            start_date={data.start_date}
            end_date={data.end_date}
            managers={data.managers}
            instructors={data.instructors}
            lecture_dates={data.lecture_dates}
            AGD={data.AGD}
            location={data.location}
            credit_hours={data.credit_hours}
            school={data.school}
            isTemplate
            _id={id}
            has_mints={data.has_mints}
          />
          <div className={classes["divider"]} />
          <Button
            onClick={() => navigate(`/courses/${id}/createFromTemplate`)}
            icon={PlusIcon}
            iconPosition="right"
          >
            Create New Course from this Template
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SingleTemplate;
