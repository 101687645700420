import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import TooltipComp from "components/tooltip/TooltipComp";
import PermissionsGate from "roles/PermissionsGate";
import { primaryItems, superAdminItems } from "./Sidebar";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";
import classes from "./Tabs.module.scss";
import Loader from "../loader/Loader";

interface ITabs {
  isCollapsed?: boolean;
  activeItem: number;
  setActiveItem: (item: number) => void;
  onClose?: () => void;
}

const Tabs = ({ isCollapsed, activeItem, setActiveItem, onClose }: ITabs) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const { userData } = useContext(userContext);

  if (!userData) return <Loader withPadding />;

  const { is_super_admin } = userData;
  const items = is_super_admin ? superAdminItems : primaryItems;

  return (
    <>
      {items.map((item, i: number) => {
        return (
          <PermissionsGate
            key={i}
            permissions={
              !is_super_admin
                ? i === 2
                  ? ["SCHOOL.GET"]
                  : i === 3
                  ? ["STUDENT.GET"]
                  : []
                : []
            }
          >
            <TooltipComp
              text={isCollapsed ? item.name : ""}
              id={item.name}
              position="right"
              width="full"
            >
              <div
                className={`${classes[`c-item`]} ${
                  item.id === activeItem ? classes["c-item--active"] : ""
                }`}
                onClick={() => {
                  setActiveItem(item.id);
                  navigate(item.link);
                  isMobile && onClose();
                }}
              >
                <div className={classes["icon"]}>
                  <item.icon />
                </div>
                {!isCollapsed && (
                  <div className={classes["title"]}>{item.name}</div>
                )}
              </div>
            </TooltipComp>
          </PermissionsGate>
        );
      })}
    </>
  );
};

export default Tabs;
