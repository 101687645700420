import React, { useContext, useState } from "react";
import moment from "moment";
import { popupContext } from "context/popupContext";
import { ReactComponent as TeamIcon } from "assets/icons/team.svg";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import ManagersPopup from "../single/components/ManagersPopup";
import PermissionsGate from "roles/PermissionsGate";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";
import { formatCourseLocation, formatSchoolAddress } from "utils/format";
import { fieldTitles } from "./CourseForm";
import classes from "./Single.module.scss";
import { renderAGD, renderInstructors } from "components/utils/renderComponent";
import { dateFormatDisplay } from "utils/staticOptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "utils/requests";
import { userContext } from "context/userContext";
import GenericPopup from "components/popups/GenericPopup";

interface ISinglePrimaryInfo
  extends Pick<
    ICourse,
    | "start_date"
    | "end_date"
    | "managers"
    | "instructors"
    | "lecture_dates"
    | "AGD"
    | "location"
    | "credit_hours"
    | "school"
    | "_id"
    | "has_mints"
  > {
  isTemplate?: boolean;
}

const SinglePrimaryInfo = ({
  start_date,
  end_date,
  managers,
  instructors,
  lecture_dates,
  AGD,
  location,
  credit_hours,
  school,
  isTemplate,
  _id: id,
  has_mints
}: ISinglePrimaryInfo) => {
  const { setPopup, clearPopup } = useContext(popupContext);
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData;
  const [hasMints, setHasMints] = useState(has_mints);
  const queryClient = useQueryClient();

  const { isLoading, mutate: updateCourse } = useMutation({
    mutationKey: ["course-update"],
    mutationFn: async () => {
      if (is_super_admin) {
        return await fetchApi("courses", `/admin/courses/${id}/unlock`, {
          method: "PUT",
          auth: true
        });
      } else {
        return await fetchApi("courses", `/course/${id}/unlock`, {
          method: "PUT",
          auth: true
        });
      }
    },
    onSuccess: () => {
      setHasMints(false);
      queryClient.invalidateQueries({ queryKey: ["course-update"] });
      setPopup(
        <GenericPopup
          type="success"
          title={"Successfully unlocked"}
          msg="The course is now unlocked and can be edited."
        />
      );
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  const renderCourseManagers = () => {
    const hasManagers = managers && managers.length > 0;

    if (!hasManagers) return <div className={classes["detail-desc"]}>-</div>;

    return managers.map((d) => (
      <div key={d._id} className={classes["detail-desc"]}>{`${d.title || ""} ${
        d.first_name
      } ${d.last_name}`}</div>
    ));
  };

  const renderCourseManagersWrapper = () => (
    <>
      <div className={classes["detail-block-wrapper"]}>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>
            {fieldTitles.course_managers}
          </div>
          {renderCourseManagers()}
          {!isTemplate && (
            <PermissionsGate permissions={["COURSE.UPDATE"]}>
              <Button
                size="medium"
                icon={TeamIcon}
                variant="link"
                onClick={() =>
                  setPopup(<ManagersPopup managers={managers} _id={id} />)
                }
                minWidth="sm"
              >
                Change Managers
              </Button>
            </PermissionsGate>
          )}
        </div>
        {hasMints && (
          <PermissionsGate
            permissions={[
              is_super_admin ? "ROLE.SUPER_ADMIN" : "COURSE.UNLOCK"
            ]}
          >
            <Button
              size="medium"
              variant="contrast"
              isFetching={isLoading}
              onClick={() =>
                setPopup(
                  <GenericPopup
                    type="info"
                    title="Unlock Course?"
                    msg="Unlocking a course allows the school to edit the course info and add new attendees. Do you want to continue?"
                    buttonVariant="contrast"
                    buttonName="Unlock Course"
                    buttonAction={updateCourse}
                    bellowBtnComp={
                      <Button
                        variant="neutral"
                        onClick={clearPopup}
                        size="medium"
                        minWidth="md"
                      >
                        Cancel
                      </Button>
                    }
                  />
                )
              }
              minWidth="sm"
            >
              Unlock
            </Button>
          </PermissionsGate>
        )}
      </div>
      <Divider orientation="horizontal" />
    </>
  );

  // Hide all `Lecture Dates` related logic
  const renderLectureDates = () => {
    const hasLectureDates = lecture_dates && lecture_dates.length > 0;

    if (!hasLectureDates)
      return <div className={classes["detail-desc"]}>-</div>;

    return lecture_dates.map((d, i) => (
      <div
        key={`${d.start_date}-${i}`}
        className={classes["detail-desc"]}
      >{`${moment(d.start_date).format(dateFormatDisplay)}, ${moment(
        d.start_date
      ).format("HH:mm")} - ${moment(d.end_date).format("HH:mm")} ${
        d.title
      }`}</div>
    ));
  };

  return (
    <div className={classes["details-wrapper"]}>
      <PermissionsGate permissions={["COURSE.UPDATE"]}>
        {renderCourseManagersWrapper()}
      </PermissionsGate>
      <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
        {renderCourseManagersWrapper()}
      </PermissionsGate>
      {!isTemplate && (
        <div className={classes["detail-block-wrapper"]}>
          <div className={classes["detail-block"]}>
            <div className={classes["detail-title"]}>
              {fieldTitles.start_date}
            </div>
            <div className={classes["detail-desc"]}>
              {start_date ? moment(start_date).format(dateFormatDisplay) : "-"}
            </div>
          </div>
          <div className={classes["detail-block"]}>
            <div className={classes["detail-title"]}>
              {fieldTitles.end_date}
            </div>
            <div className={classes["detail-desc"]}>
              {end_date ? moment(end_date).format(dateFormatDisplay) : "-"}
            </div>
          </div>
        </div>
      )}
      <div className={classes["detail-block-wrapper"]}>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>
            {`${fieldTitles.primary_instructor}${
              instructors.filter((ins) => ins.primary).length > 1 ? "s" : ""
            }`}
          </div>
          {renderInstructors(true, instructors)}
        </div>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>
            {`${fieldTitles.secondary_instructor}${
              instructors.filter((ins) => !ins.primary).length > 1 ? "s" : ""
            }`}
          </div>
          {renderInstructors(false, instructors)}
        </div>
      </div>
      <div className={classes["detail-block-wrapper"]}>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>{fieldTitles.AGD}</div>
          {renderAGD(AGD)}
        </div>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>
            {fieldTitles.credit_hours}
          </div>
          <div className={classes["detail-desc"]}>{credit_hours || "-"}</div>
        </div>
      </div>
      <div className={classes["detail-block-wrapper"]}>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>CERP Provider Number</div>
          <div className={classes["detail-desc"]}>
            {school.CERP_provider_number || "-"}
          </div>
        </div>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>PACE Provider Number</div>
          <div className={classes["detail-desc"]}>
            {school.PACE_provider_number || "-"}
          </div>
        </div>
      </div>
      <div className={classes["detail-block-wrapper"]}>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>School Name</div>
          <div className={classes["detail-desc"]}>{school.name}</div>
        </div>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>School Address</div>
          <div className={classes["detail-desc"]}>
            {formatSchoolAddress(
              school.addr1,
              school.addr2,
              school.city,
              school.zip,
              school.state ? school.state.code : null,
              school.country
            )}
          </div>
        </div>
      </div>
      <div className={classes["detail-block-wrapper"]}>
        <div className={classes["detail-block"]}>
          <div className={classes["detail-title"]}>{fieldTitles.location}</div>
          <div className={classes["detail-desc"]}>
            {location
              ? formatCourseLocation(
                  location.city,
                  location.state ? location.state.code : null
                )
              : "-"}
          </div>
        </div>
      </div>
      {/* Hide all `Lecture Dates` related logic */}
      {/*<div className={classes["detail-block"]}>*/}
      {/*  <div className={classes["detail-title"]}>Lecture Dates</div>*/}
      {/*  {renderLectureDates()}*/}
      {/*</div>*/}
    </div>
  );
};

export default SinglePrimaryInfo;
