import { ReactComponent as Check } from "assets/icons/check-circle.svg";
import { ReactComponent as Clock } from "assets/icons/clock.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/error-big.svg";
import { ReactComponent as Cross } from "assets/icons/clear.svg";
import { colors } from "styles";

export const statusIconMapping = {
  Inactive: <Cross fill={`rgb(${colors.error40})`} width={16} height={16} />,
  Active: <Check fill={`rgb(${colors.success50})`} width={16} height={16} />,
  Pending: <Clock fill={`rgb(${colors.warning60})`} width={16} height={16} />,
  Disabled: <ErrorIcon width={16} height={16} />
};
