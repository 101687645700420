import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { studentDataQuery } from "query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import FormWrapper from "components/forms/form-wrapper/FormWrapper";
import StudentForm from "../components/StudentForm";
import {
  IStudent,
  IStudentFormData
} from "utils/apiDataTypes/CourseModuleDataTypes";

const EditStudent = () => {
  const { studentId } = useParams();

  const {
    isLoading: studentLoading,
    error: studentError,
    data: studentData
  } = useQuery<boolean, Error, IStudent>(studentDataQuery(studentId));

  if (studentLoading) return <Loader size="lg" hasText withPadding />;

  if (studentError) {
    return <ErrorComponent error={studentError} />;
  }

  const defaultFormData: IStudentFormData = {
    title: studentData.title
      ? { label: studentData.title, value: studentData.title }
      : null,
    first_name: studentData.first_name,
    last_name: studentData.last_name,
    email: studentData.email
  };

  return (
    <FormWrapper title="Edit Student Details">
      <StudentForm defaultFormData={defaultFormData} studentId={studentId} />
    </FormWrapper>
  );
};

export default EditStudent;
