import React, { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import Avatar from "components/avatar/Avatar";
import Button from "components/buttons/Button";
import Status from "components/status/Status";
import Wallet from "components/wallet/Wallet";
import {
  adminSchoolMemberDataQuery,
  adminStudentDataQuery,
  meDataQuery,
  schoolMemberDataQuery,
  studentDataQuery
} from "query";
import classes from "./Profile.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { statusIconMapping } from "components/icons/IconMappings";
import { ReactComponent as editIcon } from "assets/icons/edit.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { fetchApi } from "utils/requests";
import { IPFS_GATEWAY } from "utils/constants";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import StudentSchoolStatus from "components/status/StudentSchoolStatus";
import PermissionsGate from "roles/PermissionsGate";
import { userContext } from "context/userContext";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";

interface IProfile {
  page: "my-profile" | "member" | "student";
}

const statusMapping = {
  active: { img: statusIconMapping.Active, title: "Active" },
  disabled: { img: statusIconMapping.Disabled, title: "Disabled" },
  pending: { img: statusIconMapping.Pending, title: "Pending" }
};

const Profile = ({ page }: IProfile) => {
  const { memberId, studentId } = useParams();
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { userData, tenant } = useContext(userContext);
  const { role_flag, is_super_admin } = userData || {};
  const isAdmin = role_flag === 4;

  const {
    isLoading: changeMembersStatusIsLoading,
    mutate: changeMembersStatusHandler
  } = useMutation({
    mutationKey: ["edit-member-status"],
    mutationFn: async (data: { id: string; status: boolean }) => {
      setPopupLoading(true);
      await fetchApi("courses", "/school/members", {
        method: "PUT",
        auth: true,
        data: {
          members: [data.id],
          active: !data.status
        }
      });
    },
    onSuccess: () => {
      setPopupLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["team-data", { id: memberId }]
      });
      setPopup(
        <GenericPopup
          type="success"
          title={active ? "Deactivated!" : "Activated!"}
          msg={`This member was ${active ? "deactivated!" : "activated"}`}
        />
      );
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  const {
    isLoading: changeStudentStatusIsLoading,
    mutate: changeStudentStatusHandler
  } = useMutation({
    mutationKey: ["edit-student-status"],
    mutationFn: async (data: { id: string; status: boolean }) => {
      const response = await fetchApi(
        "courses",
        "/blacklisted-users/blacklist",
        {
          method: data.status ? "POST" : "DELETE",
          auth: true,
          data: {
            id_list: [data.id]
          }
        }
      );
      return response;
    },
    onSuccess: (response: { message: string }) => {
      queryClient.invalidateQueries({
        queryKey: ["student-data", { id: studentId }]
      });
      setPopup(<GenericPopup type="success" msg={response.message} />);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  const showConfirmMemberPopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title={`${active ? "Deactivate" : "Activate"} member?`}
        msg={`Are you sure you  want to                 ${
          active ? "deactivate" : "activate"
        }
        this member?`}
        buttonName={`${active ? "Deactivate" : "Activate"}`}
        buttonVariant="outline"
        buttonAction={() =>
          changeMembersStatusHandler({ id: _id, status: active })
        }
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            onClick={() => clearPopup()}
            minWidth="md"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const showConfirmStudentPopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title={`${!is_blacklisted ? "Deactivate" : "Activate"} student?`}
        msg={`Are you sure you  want to                 ${
          !is_blacklisted ? "deactivate" : "activate"
        }
        this student?`}
        buttonName={`${!is_blacklisted ? "Deactivate" : "Activate"}`}
        buttonVariant="outline"
        buttonAction={() =>
          changeStudentStatusHandler({ id: _id, status: !is_blacklisted })
        }
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            onClick={() => clearPopup()}
            minWidth="md"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const buildQuery = (type) => {
    let query;
    switch (type) {
      case "my-profile":
        query = meDataQuery();
        break;
      case "student":
        query = is_super_admin
          ? adminStudentDataQuery(studentId)
          : studentDataQuery(studentId);
        break;
      default:
        query = is_super_admin
          ? adminSchoolMemberDataQuery(memberId)
          : schoolMemberDataQuery(memberId);
    }
    return query;
  };

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, any>({
    ...buildQuery(page)
  });

  if (isLoading) return <Loader size="lg" hasText />;

  if (error) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );
  }

  const {
    _id,
    first_name,
    last_name,
    title,
    role,
    role_flag: profile_role_flag,
    email,
    phone,
    pic,
    wallet,
    registered,
    active,
    courses,
    schools
  } = data;

  const is_blacklisted =
    courses && courses.length > 0 ? courses[0].is_blacklisted : false;

  const coursesWithDiploma =
    courses && courses.length > 0
      ? courses.filter((course) => course.diploma_status === 2)
      : [];

  return (
    <div className={classes["content-wrapper"]}>
      <div className={classes["flex-wrapper"]}>
        <div className={classes["headline-wrapper"]}>
          <Avatar
            className={classes["user-avatar"]}
            src={pic}
            size="xl"
            type="user"
            isActive={
              is_super_admin
                ? active && registered
                : ROLE_FLAG["STUDENT"] === profile_role_flag
                ? !is_blacklisted
                : !!((registered && active) || (!registered && !active))
            }
            role_flag={profile_role_flag}
          />
          <div className={classes["title-wrapper"]}>
            <h3
              className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["m-0"]}`}
            >
              {first_name && last_name
                ? `${title || ""} ${first_name} ${last_name}`
                : "-"}
            </h3>
            {role && (
              <div
                className={`${classes["u-text--primary"]} ${classes["u-bold"]}`}
              >
                {role.name}
              </div>
            )}
          </div>
        </div>
        {page === "member" && memberId !== tenant && (
          <PermissionsGate permissions={["SCHOOL.UPDATE"]}>
            <div className={classes["headline-buttons"]}>
              <Button
                size="medium"
                variant="neutral"
                icon={editIcon}
                onClick={() => navigate(`/team/${memberId}/edit`)}
              >
                Edit Member Details
              </Button>
              {registered && userData?.id !== memberId && (
                <Button
                  size="medium"
                  variant="neutral"
                  onClick={() => showConfirmMemberPopup()}
                  isFetching={changeMembersStatusIsLoading}
                  isDanger={active}
                >
                  {active ? "Deactivate Member" : "Activate Member"}
                </Button>
              )}
            </div>
          </PermissionsGate>
        )}
        {page === "my-profile" && (
          <Button
            variant="neutral"
            size="medium"
            icon={editIcon}
            onClick={() => navigate("/profile/edit")}
          >
            Edit My Details
          </Button>
        )}
        {page === "student" && isAdmin && (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <div className={classes["headline-buttons"]}>
              <Button
                variant="neutral"
                size="medium"
                icon={editIcon}
                onClick={() => navigate("edit")}
              >
                Edit Student Details
              </Button>
              {registered && (
                <Button
                  size="medium"
                  variant="neutral"
                  onClick={() => showConfirmStudentPopup()}
                  isFetching={changeStudentStatusIsLoading}
                  isDanger={!is_blacklisted}
                >
                  {is_blacklisted ? "Activate Student" : "Deactivate Student"}
                </Button>
              )}
            </div>
          </PermissionsGate>
        )}
      </div>
      <div className={classes["details"]}>
        {(page === "member" || page === "student") && (
          <div>
            <h6 className={classes["details-subtitle"]}>Registration Status</h6>
            <p className={classes["details-content"]}>
              {active ? (
                <>
                  {statusMapping.active.img}
                  <span>{statusMapping.active.title}</span>
                </>
              ) : (
                <>
                  {registered ? (
                    <>
                      {statusMapping.disabled.img}
                      <span>{statusMapping.disabled.title}</span>
                    </>
                  ) : (
                    <>
                      {statusMapping.pending.img}
                      <span>{statusMapping.pending.title}</span>
                    </>
                  )}
                </>
              )}
            </p>
          </div>
        )}
        {is_super_admin &&
          page === "student" &&
          schools.map((s) => (
            <div key={s._id}>
              <h6
                className={classes["details-subtitle"]}
              >{`${s.name} Status`}</h6>
              <div className={classes["details-content"]}>
                <StudentSchoolStatus is_blacklisted={s.is_blacklisted} />
              </div>
            </div>
          ))}
        {page === "student" && !is_super_admin && (
          <div>
            <h6 className={classes["details-subtitle"]}>School Status</h6>
            <div className={classes["details-content"]}>
              <StudentSchoolStatus is_blacklisted={is_blacklisted} />
            </div>
          </div>
        )}
        <div>
          <h6 className={classes["details-subtitle"]}>E-mail</h6>
          <p className={classes["details-content"]}>{email}</p>
        </div>
        {page === "student" && (
          <div>
            <h6 className={classes["details-subtitle"]}>Course</h6>
            {courses.length > 0 && (
              <div className={classes["details-content-wrapper"]}>
                {courses.map((c) => (
                  <div key={c._id} className={classes["details-content"]}>
                    <Status
                      status={c.attendance_status}
                      isIcon
                      isBold
                      id={`${c._id}-${c.attendance_status}`}
                    />{" "}
                    -{/* TODO - fix super admin link when courses PR merged */}
                    <span
                      className={`${classes["u-cursor--pointer"]} ${classes["u-underline"]}`}
                      onClick={() => navigate(`/courses/${c.course._id}`)}
                    >
                      {c.course.name}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {courses.length === 0 && (
              <p className={classes["details-content"]}>-</p>
            )}
          </div>
        )}
        {page === "member" && (
          <div>
            <h6 className={classes["details-subtitle"]}>Phone Number</h6>
            <p className={classes["details-content"]}>{phone || "-"}</p>
          </div>
        )}
        {(page === "student" || page === "my-profile") && (
          <div>
            <h6 className={classes["details-subtitle"]}>Wallet Address</h6>
            <p className={classes["details-content"]}>
              <Wallet wallet={wallet} />
            </p>
          </div>
        )}
        {page === "student" && (
          <div>
            <h6 className={classes["details-subtitle"]}>Certificate</h6>
            {coursesWithDiploma.length > 0 && (
              <div className={classes["details-content-wrapper"]}>
                {coursesWithDiploma.map((c) => {
                  return (
                    <div key={c._id} className={classes["details-content-row"]}>
                      <p
                        key={c._id}
                        className={`${classes["details-content"]} ${classes["details-content--light"]}`}
                      >
                        {c.diploma_name}
                      </p>
                      {is_super_admin ? (
                        <a
                          className={`${classes["u-underline"]} ${classes["u-link"]}`}
                          href={`${IPFS_GATEWAY}${c.ipfs_diploma_id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LinkIcon width={20} height={20} />
                        </a>
                      ) : (
                        <Button
                          variant={"link"}
                          size="medium"
                          isIconBtn
                          icon={LinkIcon}
                          onClick={() =>
                            navigate(
                              `/students/${studentId}/diplomas/${c.token_id}`
                            )
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {coursesWithDiploma.length === 0 && (
              <p className={classes["details-content"]}>-</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
