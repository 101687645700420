import useComponentVisible from "hooks/useComponentVisible";
import { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ITemplateFieldOption } from "../form/CreateDiplomaForm";
import Signature from "assets/images/singature.png";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import classes from "./DragContainer.module.scss";
import { diplomaTemplateWidth } from "../CreateDiploma";

export const ItemTypes = {
  BOX: "box"
};

enum textAlignToJustifyContent {
  "left" = "flex-start",
  "center" = "center",
  "right" = "flex-end"
}

export const checkFieldHasFixedWidth = (fieldName: string) => {
  return (
    fieldName === "school-name" ||
    fieldName === "student-name" ||
    fieldName === "course-name" ||
    fieldName === "start-end-date"
  );
};

interface IBox {
  hideSourceOnDrag: boolean;
  setDimensions: (
    field_name: string,
    dimensions: { width: number; height: number }
  ) => void;
  isSelected: boolean;
  setIsSelected: (field: ITemplateFieldOption | null) => void;
  field: ITemplateFieldOption;
  formData: any;
}

export const Box = ({
  hideSourceOnDrag,
  setDimensions,
  isSelected,
  setIsSelected,
  field,
  formData
}: IBox) => {
  const refContainer = useRef(null);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false, true);

  const {
    name,
    left,
    top,
    isLogo,
    fontStyle,
    fontColor,
    isSignature,
    fontSize,
    textAlign,
    label,
    preview,
    width,
    height,
    whiteSpace
  } = field;

  const isInstructor = field.name === "teacher-1" || field.name === "teacher-2";

  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: ItemTypes.BOX,
      item: { name, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    };
  }, [name, left, top]);

  const getSignatureSrc = (field: string) => {
    if (field === "teacher-1-sig") {
      if (formData.preview_teacher_1_sig?.value) {
        return FILES_PUBLIC_DOMAIN + formData.preview_teacher_1_sig.value;
      }
      return Signature;
    }
    if (field === "teacher-2-sig") {
      if (formData.preview_teacher_2_sig?.value) {
        return FILES_PUBLIC_DOMAIN + formData.preview_teacher_2_sig.value;
      }
      return Signature;
    }
  };

  // Deselect component on double click away
  useEffect(() => {
    if (!isComponentVisible) {
      setIsSelected(null);
    }
  }, [isComponentVisible]);

  const handleResize = (e) => {
    const isResizing = e.target.id === `box-${name}`;
    if ((isLogo || isSignature) && isResizing) {
      const image = e.target.childNodes[0];

      // Get the nested image tag dimensions
      const imageWidth = image.offsetWidth;
      const imageHeight = image.offsetHeight;

      // Set the wrapping div dimensions to the size of the image
      e.target.style.width = `${imageWidth}px`;
      e.target.style.height = `${imageHeight}px`;

      return setDimensions(name, { width: imageWidth, height: imageHeight });
    }

    const { offsetWidth: width, offsetHeight: height } = e.target;
    setDimensions(name, { width, height });
  };

  const handleImageLoad = (e: any) => {
    setDimensions(name, {
      width: e.target.offsetWidth,
      height: e.target.offsetHeight
    });
  };

  const renderText = () => {
    if (name !== "course-name") return preview?.label || label;

    // NOTE: if the field is course-name and includes course.start_date in its label,
    // then detect the patterb and remove the course.start_date from the label
    // this pattern detects the date format      " - Oct 29, 1997"
    const pattern = / - [A-Z][a-z]{2} \d{1,2}, \d{4}$/;
    let updatedLabel = "";
    // If there is a date matching the pattern in the preview field, remove it
    if (pattern.test(preview?.label)) {
      updatedLabel = preview?.label.slice(0, -15);
      return updatedLabel;
    }
    // If the course-name fields does not contain the date pattern, simply return the preview or label
    return preview?.label || label;
  };

  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />;
  }

  const renderBox = () => {
    if (isLogo || isSignature)
      return (
        <div
          className={`${classes["box"]} ${
            isLogo ? classes["logo-box"] : classes["signature-box"]
          }`}
          ref={drag}
          style={{ left, top }}
        >
          <div
            id={`box-${name}`}
            className={`${
              isLogo ? classes["logo-box"] : classes["signature-box"]
            }
        } ${isSelected ? classes["selected"] : ""}`}
            ref={refContainer}
            style={{
              color: fontColor,
              fontFamily: fontStyle,
              width: width,
              height: height,
              textAlign: textAlign,
              justifyContent: textAlignToJustifyContent[textAlign],
              // Calculate displayed font size based on original image with / container with proportion
              fontSize:
                +fontSize /
                (formData.template_background.originalDimensions.width /
                  diplomaTemplateWidth),
              overflow: "hidden",
              resize: "both"
            }}
            onMouseUp={handleResize}
          >
            {/* Case is logo */}
            {isLogo &&
              (preview?.value ? (
                <img
                  className={classes["logo"]}
                  src={FILES_PUBLIC_DOMAIN + preview?.value}
                  alt="Logo"
                  onLoad={handleImageLoad}
                />
              ) : (
                <div className={classes["no-logo"]}>
                  <SchoolIcon /> The school has no logo.
                </div>
              ))}

            {/* Case is signature */}
            {isSignature && (
              <img
                className={classes["singature"]}
                src={getSignatureSrc(name)}
                alt="Signature"
                onLoad={handleImageLoad}
              />
            )}
          </div>
        </div>
      );

    return (
      <div className={classes["box"]} ref={drag} style={{ left, top }}>
        <div
          id={`box-${name}`}
          className={`${classes["text-box"]} ${
            isInstructor ? classes["instructor-name"] : ""
          } ${isSelected ? classes["selected"] : ""}`}
          ref={refContainer}
          style={{
            color: fontColor,
            fontFamily: fontStyle,
            width: width,
            height: height,
            textAlign: textAlign,
            justifyContent: textAlignToJustifyContent[textAlign],
            resize: "both",
            overflow: "hidden",
            // Calculate displayed font size based on original image with / container with proportion
            fontSize:
              +fontSize /
              (formData.template_background.originalDimensions.width /
                diplomaTemplateWidth),
            whiteSpace: whiteSpace as any
          }}
          onMouseUp={handleResize}
        >
          {/* Case is text */}
          {renderText()}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      onDoubleClick={() => {
        setIsComponentVisible(true);
        setIsSelected(field);
      }}
    >
      {renderBox()}
    </div>
  );
};
