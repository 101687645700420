import * as Yup from "yup";

const passMsg =
  "Password must be at least 8 characters and contain uppercase letters, lowercase letters, number and symbols(e.g.!\"#$%&'()*+,-./:;<=>?@[]^_`{|}~)";

export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])(?=.{8,})/;

export const PHONE_PATTERN =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// Ref. https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict
export const WEBSITE_PATTERN =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const WALLET_PATTERN = /^0x[a-fA-F0-9]{40}$/g;

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const NAME_PATTERN = /^[A-Za-z\s]+$/;
// Supports the following patterns   MM.DD.YYYY   DD.MM.YYYY   YYYY   Month Date, Year   Month Date Year   Date Month Year   DD/MM/YYYY   MM/DD/YYYY
export const DATE_PATTERN =
  /(\d{2}\.\d{2}\.\d{4})|(\d{2}\/\d{2}\/\d{4})|(\d{2}\.\d{2}\.\d{2})|(\d{2}\/\d{2}\/\d{2})|(\d{4})|([A-Za-z]+\s+\d{1,2},?\s*\d{4})|([A-Za-z]+\s+\d{1,2}\s*\d{4})|(\d{1,2}\s*[A-Za-z]+\s*\d{4})|(\d{2}-\d{2}-\d{4})|(\d{2}-\d{2}-\d{2})/;

// Patterns
export const yupSchemas = {
  requiredStr: Yup.string().trim().required("Required"),
  labelAndValue: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string()
  }),
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required("Required"),
  new_pass: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required("Required"),
  confirm_new_pass: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required("Required")
    .test("password-match", "Passwords must match", function (value) {
      return this.options.context.new_pass === value;
    }),
  phone: Yup.string()
    .max(20, "Please enter a correct phone number")
    .matches(PHONE_PATTERN, {
      message: "Please enter a complete phone number",
      excludeEmptyString: true
    })
    .required("Required"),
  phoneNonRequired: Yup.string()
    .max(20, "Please enter a correct phone number")
    .matches(PHONE_PATTERN, {
      message: "Please enter a complete phone number",
      excludeEmptyString: true
    }),
  fax: Yup.string()
    .max(20, "Please enter a correct fax number")
    .matches(PHONE_PATTERN, {
      message: "Please enter a complete fax number",
      excludeEmptyString: true
    }),
  website: Yup.string()
    .matches(WEBSITE_PATTERN, {
      message: "Please enter a correct url",
      excludeEmptyString: true
    })
    .required("Required"),
  wallet: Yup.string().matches(WALLET_PATTERN, {
    message: "Please enter a correct wallet address",
    excludeEmptyString: true
  })
};
