import { ModalWrapper } from "./common/ModalWrapper";
import PopupTitleAndMessage from "./common/PopupTitleAndMessage";
import MetaMaskIcon from "assets/icons/metamask.svg";
import CoinbaseIcon from "assets/images/coinbase.png";
import WalletConnectIcon from "assets/images/walletconnect.png";
import classes from "./ConnectWalletPopup.module.scss";
import Button from "components/buttons/Button";
import { useConnect } from "wagmi";
import { useContext } from "react";
import { popupContext } from "context/popupContext";
import { useNavigate } from "react-router-dom";
import { userContext } from "context/userContext";
import GenericPopup from "./GenericPopup";
import { CHAIN_ID, METAMASK_LOCAL_DEEPLINK } from "utils/constants";
import { windowContext } from "context/windowsContext";

const walletIcons = {
  metamask: MetaMaskIcon,
  "coinbase wallet": CoinbaseIcon,
  walletconnect: WalletConnectIcon
};

const ConnectWallet = () => {
  const { clearPopup, setPopup } = useContext(popupContext);
  const { userData } = useContext(userContext);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const navigate = useNavigate();

  const { connectAsync, connectors } = useConnect();

  const connectHandler = async (connector) => {
    try {
      const { account, chain } = await connectAsync({ connector });
      localStorage.setItem("isWalletConnected", "true");

      // If the user is a student and the connected address IS the same as
      // the address associated with their profile, clear the popup.
      // NOTE 1: If the connected address IS NOT the same, then the web3Context will throw a
      // popup asking the user whether they want to change the profile associated address
      // with the currently connected one.
      // NOTE 2: If the chain is wrong, then the web3Context will throw a Check Your Network popup
      if (
        userData?.is_student &&
        account === userData?.wallet &&
        Number(chain.id) === Number(CHAIN_ID)
      )
        clearPopup();

      // If the user is a school member always throw a success popup
      if (!userData?.is_student && Number(chain.id) === Number(CHAIN_ID)) {
        setPopup(
          <GenericPopup
            type="success"
            title="Connected!"
            msg="Your wallet is now connected."
          />
        );
      }
    } catch (e) {
      localStorage.setItem("isWalletConnected", "false");
    }
  };

  // If the app is opened on a NON-WEB3 mobile browser, we enable
  // the Metamask connector button and deep link to the metamask app.
  const checkMobileMetamaskConnector = (connectorId: string) => {
    return isMobile && connectorId.toLowerCase() === "metamask";
  };

  return (
    <ModalWrapper size="md">
      <div>
        <PopupTitleAndMessage
          title="Connect Wallet"
          msg="Connect a wallet to get started."
        />
        <div className={classes["providers-container"]}>
          {connectors.map((connector) => (
            <Button
              key={connector.id}
              className={classes["provider-btn"]}
              variant="neutral"
              isDisabled={
                checkMobileMetamaskConnector(connector.id)
                  ? false
                  : !connector.ready
              }
              onClick={() =>
                checkMobileMetamaskConnector(connector.id) && !connector.ready
                  ? window.open(METAMASK_LOCAL_DEEPLINK, "self")
                  : connectHandler(connector)
              }
            >
              <img
                src={walletIcons[connector.name.toLocaleLowerCase()]}
                alt="wallet-provider"
                width={32}
                height={32}
              />
              <span
                className={`${classes["u-text--primary"]} ${classes["u-text--small"]}`}
              >
                {connector.name}
              </span>
            </Button>
          ))}
        </div>
      </div>
      {userData?.is_student && (
        <div className={classes["wallet-manual-msg"]}>
          Or manually set your wallet address through{" "}
          <span
            className={classes["u-underline"]}
            onClick={() => {
              navigate("/profile/edit", {
                state: { focusWalletInput: true }
              });
              clearPopup();
            }}
          >
            your profile.
          </span>
        </div>
      )}
    </ModalWrapper>
  );
};

export default ConnectWallet;
