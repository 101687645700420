import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import { calcItemsAmount } from "components/table/utils/table-utils";
import SchoolRegistrationRequestsTable from "./SchoolRegistrationRequestsTable";
import { useQuery } from "@tanstack/react-query";
import { schoolsRegRequestsDataQuery } from "query";
import { sortDirEnum } from "utils/staticOptions";
import { ISchoolRegRequestsData } from "utils/apiDataTypes/AccountManagementDataTypes";

// TODO - set correctly when BE done
export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "School Name",
    value: "school.name",
    dir: "asc"
  },
  {
    id: 2,
    label: "Request Date",
    value: "date_requested",
    dir: "asc"
  }
  // {
  //   id: 3,
  //   label: "Status",
  //   value: "active",
  //   dir: "asc"
  // }
];

const RegistrationRequests = () => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir
  } = queryString.parse(urlParams);

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState((search as string) || "");

  const [perPage, setPerPage] = useState<number>(8);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const params = `?${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }&limit=${perPage}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ""
  }&status=0`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ISchoolRegRequestsData
  >(schoolsRegRequestsDataQuery(params));

  const { result, total_results, all_records } = data || {};

  return (
    <>
      <PageHeader
        hasData={true}
        title="Registration Requests"
        hasSearch
        searchPlaceholder="Search by School Name"
        searchValue={searchValue}
        passSearchValueUp={setSearchValue}
        setPage={setPage}
        hasSort
        sortValue={sortFilter}
        setSortValue={setSortFilter}
        sortOptions={sortOptions}
        passSortValueUp={(value: ISortOption) => {
          setSortFilter(value);
        }}
        itemsAmountMsg={calcItemsAmount({
          page,
          perPage,
          result,
          total_results,
          all_records
        })}
      />
      <SchoolRegistrationRequestsTable
        page={page}
        setPage={setPage}
        itemsAmountMsg={calcItemsAmount({
          type: "Registration Requests ",
          page,
          perPage,
          result,
          total_results,
          all_records
        })}
        data={{
          data,
          isLoading,
          error
        }}
        perPage={perPage}
        setPerPage={setPerPage}
        searchValue={searchValue}
      />
    </>
  );
};

export default RegistrationRequests;
