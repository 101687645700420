import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Portal } from "react-portal";
import { Column } from "react-table";
import { ReactComponent as Dots } from "assets/icons/menu-dots.svg";
import Button from "components/buttons/Button";
import classes from "./TableCols.module.scss";
import PermissionsGate from "roles/PermissionsGate";
import TooltipComp from "components/tooltip/TooltipComp";
import { IndeterminateCheckbox } from "./InterdeterminateCheckbox";
import useComponentVisible from "hooks/useComponentVisible";
import RenderCell from "./RenderCell";
import { tableColumnsWidths } from "./utils/table-utils";
import { Web3Context } from "context/web3Context";

const dropdownItems = (rowProps) => {
  return [
    {
      link: (
        <Link
          className={classes["c-menu-item"]}
          to={`/students/${rowProps.userId}`}
          key={rowProps.userId}
        >
          View Attendant Details
        </Link>
      )
    }
  ];
};

interface IAttendeesCols {
  showConfirmPopup: (
    obj: { index: number; id: string }[],
    isChangeStatus?: boolean,
    attendance_status?: number,
    type?: "mint"
  ) => void;
  isTemplateSelected: boolean;
  getPreviewDiplomaLink: () => void;
  isOpen: boolean;
  hasNoActions: boolean;
}

export const AttendeesCols = (
  showConfirmPopup: IAttendeesCols["showConfirmPopup"],
  isTemplateSelected: IAttendeesCols["isTemplateSelected"],
  getPreviewDiplomaLink: IAttendeesCols["getPreviewDiplomaLink"],
  isOpen: IAttendeesCols["isOpen"],
  hasNoActions: IAttendeesCols["hasNoActions"]
) => {
  const cols: Column = [
    {
      Header: "Attendee",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell
          type="attendee_mobile"
          rowProps={row.original}
          isOpen={isOpen}
        />
      )
    },
    {
      Header: "First/Last Name",
      accessor: "names",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => (
        <RenderCell type="names" rowProps={row.original} isLink />
      )
    },
    {
      Header: "Email",
      accessor: "email",
      minWidth: tableColumnsWidths.emailMin,
      Cell: ({ row }) => <RenderCell type="email" rowProps={row.original} />
    },
    {
      Header: "Wallet Address",
      accessor: "wallet",
      maxWidth: tableColumnsWidths.walletMax,
      minWidth: tableColumnsWidths.walletMin,
      width: tableColumnsWidths.wallet,
      Cell: ({ row }) => <RenderCell type="wallet" rowProps={row.original} />
    },
    {
      Header: "Registration",
      accessor: "registered",
      maxWidth: tableColumnsWidths.regMax,
      minWidth: tableColumnsWidths.regMin,
      width: tableColumnsWidths.reg,
      Cell: ({ row }) => (
        <RenderCell type="registration" rowProps={row.original} />
      )
    },
    {
      Header: "School Status",
      accessor: "student_school_status",
      maxWidth: tableColumnsWidths.regMax,
      minWidth: tableColumnsWidths.regMin,
      width: tableColumnsWidths.reg,
      Cell: ({ row }) => (
        <RenderCell type="student_school_status" rowProps={row.original} />
      )
    },
    {
      Header: "Custom Certificate Date",
      accessor: "custom_diploma_date",
      maxWidth: tableColumnsWidths.dateMax,
      minWidth: tableColumnsWidths.dateMin,
      width: tableColumnsWidths.date,
      Cell: ({ row }) => row.original.custom_diploma_date || " "
    },
    {
      Header: "Certificate",
      accessor: "diploma",
      maxWidth: tableColumnsWidths.diplomaMax,
      minWidth: tableColumnsWidths.diplomaMin,
      width: tableColumnsWidths.diploma,
      Cell: ({ row }) => {
        return <RenderCell type="diploma" rowProps={row.original} />;
      }
    },
    {
      Header: "Status",
      accessor: "attendance_status",
      maxWidth: tableColumnsWidths.statusMax,
      minWidth: tableColumnsWidths.statusMin,
      width: tableColumnsWidths.status,
      Cell: ({ row }) => (
        <RenderCell type="attendance_status" rowProps={row.original} />
      )
    }
  ];

  if (!hasNoActions) {
    // Add an element to the beginning of columns
    cols.unshift({
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.getToggleAllRowsSelectedProps()}
            />
          </PermissionsGate>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.row.getToggleRowSelectedProps()}
            />
          </PermissionsGate>
        );
      }
    });

    // Add an element to the end of columns
    cols.push({
      Header: "",
      accessor: " ",
      maxWidth: tableColumnsWidths.actionsMax,
      minWidth: tableColumnsWidths.actionsMin,
      width: tableColumnsWidths.actions,
      Cell: (tableProps) => {
        const { ref, isComponentVisible, setIsComponentVisible } =
          useComponentVisible(false);
        const { web3UserData } = useContext(Web3Context);

        return (
          <div className={classes["actions-container"]}>
            <Button
              variant="link"
              size="medium"
              icon={Dots}
              isIconBtn
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            {isComponentVisible && (
              <Portal node={document && document.getElementById("table-body")}>
                <div
                  className={classes["c-menu-wrapper"]}
                  ref={ref}
                  style={{ top: `${tableProps.row.distance_from_top}px` }}
                >
                  <div className={classes["c-menu"]}>
                    {dropdownItems(tableProps.row.original).map((item, i) => (
                      <Fragment key={i}>{item.link}</Fragment>
                    ))}
                    <PermissionsGate permissions={["STUDENT.UPDATE"]}>
                      <div
                        className={`${classes["c-menu-item"]} ${
                          classes[
                            tableProps.row.original.diploma_status === 1 ||
                            tableProps.row.original.diploma_status === 2
                              ? "c-menu-item--disabled"
                              : ""
                          ]
                        }`}
                        onClick={() => {
                          if (
                            tableProps.row.original.diploma_status !== 1 &&
                            tableProps.row.original.diploma_status !== 2
                          ) {
                            tableProps.toggleAllRowsSelected(false);
                            tableProps.row.toggleRowSelected(true);
                            showConfirmPopup(
                              [
                                {
                                  index: tableProps.row.id,
                                  id: tableProps.row.original.id
                                }
                              ],
                              true,
                              tableProps.row.original.attendance_status === 0
                                ? 1
                                : 0
                            );
                            setIsComponentVisible(false);
                          }
                        }}
                      >
                        <TooltipComp
                          text={
                            tableProps.row.original.diploma_status === 1 ||
                            tableProps.row.original.diploma_status === 2
                              ? `Student has a ${
                                  tableProps.row.original.diploma_status === 1
                                    ? "pending"
                                    : "minted"
                                } certificate and their status cannot be changed.`
                              : ""
                          }
                          id="disabled-status-dropdown"
                        >
                          Change Status
                        </TooltipComp>
                      </div>
                    </PermissionsGate>
                    <div
                      className={`${classes["c-menu-item"]} ${
                        (tableProps.row.original.attendance_status === 0 ||
                          tableProps.row.original.diploma_status === 1 ||
                          tableProps.row.original.diploma_status === 2 ||
                          !isTemplateSelected) &&
                        classes["c-menu-item--disabled"]
                      }`}
                      onClick={() => {
                        if (
                          tableProps.row.original.attendance_status > 0 &&
                          tableProps.row.original.diploma_status !== 1 &&
                          tableProps.row.original.diploma_status !== 2 &&
                          isTemplateSelected
                        ) {
                          tableProps.toggleAllRowsSelected(false);
                          tableProps.row.toggleRowSelected(true);
                          getPreviewDiplomaLink();
                          setIsComponentVisible(false);
                        }
                      }}
                    >
                      <TooltipComp
                        text={
                          !isTemplateSelected
                            ? "Please select a certificate template first."
                            : tableProps.row.original.diploma_status === 1 ||
                              tableProps.row.original.diploma_status === 2
                            ? `Student has a ${
                                tableProps.row.original.diploma_status === 1
                                  ? "pending"
                                  : "minted"
                              } certificate.`
                            : tableProps.row.original.attendance_status === 0
                            ? "Student attendance not verified."
                            : ""
                        }
                        id="single-preview-diploma-btn"
                      >
                        Preview NFT Certificate
                      </TooltipComp>
                    </div>
                    <div
                      className={`${classes["c-menu-item"]} ${
                        (!tableProps.row.original.wallet ||
                          tableProps.row.original.attendance_status === 0 ||
                          tableProps.row.original.diploma_status === 1 ||
                          tableProps.row.original.diploma_status === 2 ||
                          !isTemplateSelected ||
                          !web3UserData?.wallet_address) &&
                        classes["c-menu-item--disabled"]
                      }`}
                      onClick={() => {
                        if (
                          tableProps.row.original.wallet &&
                          tableProps.row.original.attendance_status > 0 &&
                          tableProps.row.original.diploma_status !== 1 &&
                          tableProps.row.original.diploma_status !== 2 &&
                          isTemplateSelected &&
                          web3UserData?.wallet_address
                        ) {
                          tableProps.toggleAllRowsSelected(false);
                          tableProps.row.toggleRowSelected(true);
                          showConfirmPopup(
                            [
                              {
                                index: tableProps.row.id,
                                id: tableProps.row.original.id
                              }
                            ],
                            null,
                            null,
                            "mint"
                          );
                          setIsComponentVisible(false);
                        }
                      }}
                    >
                      <TooltipComp
                        text={
                          !isTemplateSelected
                            ? "Please select a certificate template first."
                            : tableProps.row.original.diploma_status === 1 ||
                              tableProps.row.original.diploma_status === 2
                            ? `Student has a ${
                                tableProps.row.original.diploma_status === 1
                                  ? "pending"
                                  : tableProps.row.original.diploma_status === 2
                                  ? "minted"
                                  : ""
                              } certificate.`
                            : !tableProps.row.original.wallet
                            ? "The student must set their wallet first."
                            : tableProps.row.original.attendance_status === 0
                            ? "Student attendance not verified."
                            : !web3UserData?.wallet_address
                            ? "Please connect your wallet."
                            : ""
                        }
                        id="single-mint-btn"
                      >
                        Mint NFT Certificate
                      </TooltipComp>
                    </div>
                    <PermissionsGate permissions={["STUDENT.DELETE"]}>
                      <div
                        className={`${classes["c-menu-item"]} ${
                          classes[
                            tableProps.row.original.diploma_status === 1 ||
                            tableProps.row.original.diploma_status === 2
                              ? "c-menu-item--disabled"
                              : ""
                          ]
                        }`}
                        onClick={() => {
                          if (
                            tableProps.row.original.diploma_status !== 1 &&
                            tableProps.row.original.diploma_status !== 2
                          ) {
                            tableProps.toggleAllRowsSelected(false);
                            tableProps.row.toggleRowSelected(true);
                            showConfirmPopup([
                              {
                                index: tableProps.row.id,
                                id: tableProps.row.original.id
                              }
                            ]);
                            setIsComponentVisible(false);
                          }
                        }}
                      >
                        <TooltipComp
                          text={
                            tableProps.row.original.diploma_status === 1 ||
                            tableProps.row.original.diploma_status === 2
                              ? `Student has a ${
                                  tableProps.row.original.diploma_status === 1
                                    ? "pending"
                                    : "minted"
                                } certificate and cannot be removed.`
                              : ""
                          }
                          id="disabled-mint-dropdown"
                        >
                          Remove Attendant
                        </TooltipComp>
                      </div>
                    </PermissionsGate>
                  </div>
                </div>
              </Portal>
            )}
          </div>
        );
      }
    });
  }

  return cols;
};
