import React, { useCallback, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Button from "components/buttons/Button";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import PermissionsGate from "roles/PermissionsGate";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import DiplomasTable from "../table/DiplomasTable";
import { ITemplatesData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { nftTemplatesDataQuery } from "query";
import { useQuery } from "@tanstack/react-query";
import { sortDirEnum } from "utils/staticOptions";
import { windowContext } from "context/windowsContext";

export const templatesSortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "Name",
    value: "name",
    dir: "asc"
  }
];

const TemplatesTab = () => {
  const { pathname, search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir
  } = queryString.parse(urlParams);

  const navigate = useNavigate();

  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...templatesSortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState((search as string) || "");
  const [perPage, setPerPage] = useState(6);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const params = `?${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }&limit=${perPage}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ITemplatesData>({
    ...nftTemplatesDataQuery(params)
  });

  const { result, total_results, all_records } = data || {};
  const hasData = data && result?.length > 0;

  const navigateHandler = () => {
    return navigate("/diplomas/create", {
      state: { schoolHasTemplates: data.result.length > 0 }
    });
  };

  const getDataStatus = useCallback(() => {
    let status: "loading" | "error" | "no-results" | "empty" | null;

    return isLoading
      ? (status = "loading")
      : error
      ? (status = "error")
      : !hasData && searchValue && !isLoading
      ? (status = "no-results")
      : !hasData && !searchValue && !isLoading
      ? (status = "empty")
      : (status = null);
  }, [isLoading, error, hasData, searchValue]);

  return (
    <>
      <PageHeader
        hasData={true}
        title="Templates"
        searchPlaceholder="Search By Template Name"
        hasSearch
        searchValue={searchValue}
        passSearchValueUp={setSearchValue}
        setPage={setPage}
        hasSort
        sortValue={sortFilter}
        setSortValue={setSortFilter}
        sortOptions={templatesSortOptions}
        passSortValueUp={(value: ISortOption) => {
          setSortFilter(value);
        }}
        itemsAmountMsg={calcItemsAmount({
          page: page,
          perPage: perPage,
          result: result,
          total_results,
          all_records: all_records
        })}
        withChildrenDivider
        className={"divider-hidden"}
      >
        {!isMobile && (
          <PermissionsGate permissions={["TEMPLATE.CREATE"]}>
            <Button onClick={navigateHandler} size="medium" icon={PlusIcon}>
              Create Template
            </Button>
          </PermissionsGate>
        )}
      </PageHeader>

      <DiplomasTable
        page={page}
        setPage={setPage}
        perPage={6}
        setPerPage={setPerPage}
        data={data}
        params={params}
        status={getDataStatus()}
        error={error}
      />
    </>
  );
};

export default TemplatesTab;
