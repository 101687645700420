import { statusIconMapping } from "components/icons/IconMappings";
import classes from "./Status.module.scss";

const textMappingRest = {
  0: "Pending",
  1: "Active",
  2: "Inactive"
};

interface ISchoolStatus {
  status: number;
  isIconOnly?: boolean;
}

const SchoolStatus = ({ status, isIconOnly }: ISchoolStatus) => {
  return (
    <div className={classes["status-container"]}>
      <div className={classes["icon-and-text"]}>
        {statusIconMapping[textMappingRest[status]]}
        {!isIconOnly && textMappingRest[status]}
      </div>
    </div>
  );
};

export default SchoolStatus;
