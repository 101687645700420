import React, { useRef } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./Datepicker.module.scss";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import InputText from "./InputText";
import Error from "./Error";
import Button from "../buttons/Button";
import TooltipComp from "../tooltip/TooltipComp";
import { navigateWithParams } from "utils/format";
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormatUrl } from "utils/staticOptions";

interface IDatepickerRangeProps {
  onChange: (value: number) => void;
  isDisabled?: boolean;
  placeholder?: string;
  error?: string;
  name?: string;
  startDate?: number;
  endDate?: number;
  displayValue?: string;
  customHeader?: React.ReactNode;
  minDate?: Date | string;
  maxDate?: Date | string;
  isClearable?: boolean;
  isFilter?: boolean;
  filterTooltip?: string;
  clearFilter?: () => void;
  children?: React.ReactNode;
}

const DatepickerRange = (props: IDatepickerRangeProps) => {
  const {
    onChange,
    isDisabled,
    placeholder,
    error,
    name,
    startDate,
    endDate,
    customHeader,
    displayValue,
    minDate,
    maxDate,
    isClearable,
    isFilter,
    filterTooltip,
    clearFilter
  } = props;

  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();

  let datepickerRef = useRef(null);

  const closeDatepicker = () => datepickerRef.current.setOpen(false);

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
    if (isFilter)
      return (
        <TooltipComp id="date-filter" text={filterTooltip} position="bottom">
          <Button
            variant={value ? "contrast" : "neutral"}
            size="medium"
            isIconBtn
            icon={CalendarIcon}
            onClick={onClick}
          />
        </TooltipComp>
      );

    return (
      <>
        <InputText
          onClick={onClick}
          ref={ref}
          type={"datepicker"}
          onChange={onChange}
          isDisabled={isDisabled}
          value={displayValue}
          placeholder={placeholder}
          error={error}
          name={name}
          readOnly
        />
        <Error text={error} />
      </>
    );
  });

  const CustomContainer = ({ className, children }) => {
    return (
      <div>
        <CalendarContainer className={className}>
          {customHeader}
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <div className={classes["c-datepicker"]}>
      <DatePicker
        ref={datepickerRef}
        calendarContainer={CustomContainer}
        onChange={(e) => {
          onChange(e);
          if (isFilter) {
            navigateWithParams(
              urlParams,
              {
                page: 1,
                startDate: moment(e[0]).format(dateFormatUrl),
                endDate: e[1] ? moment(e[1]).format(dateFormatUrl) : ""
              },
              navigate,
              pathname
            );
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={"MM/dd/yyyy"}
        customInput={<CustomInput />}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        isClearable={isClearable}
      >
        {isFilter && (
          <div className={classes["children-wrapper"]}>
            <Button
              onClick={() => {
                closeDatepicker();
                clearFilter();
                navigateWithParams(
                  urlParams,
                  { page: 1, startDate: "", endDate: "" },
                  navigate,
                  pathname
                );
              }}
              variant={"neutral"}
              minWidth="full"
            >
              Clear
            </Button>
          </div>
        )}
      </DatePicker>
    </div>
  );
};

export default DatepickerRange;
