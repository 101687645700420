import React, {
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
  useRef,
  Dispatch,
  useContext
} from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import FormWrapper from "components/forms/form-wrapper/FormWrapper";
import FormSteps from "components/forms/form-steps/FormSteps";
import { InputGroup } from "components/input/InputGroup";
import { InputField } from "components/input/Input";
import ImageInput from "components/image-input/ImageInput";
import Label from "components/input/Label";
import Summary from "./Summary";
import SuccessPopup from "./SuccessPopup";
import { useFormData } from "hooks/useFormData";
import * as Yup from "yup";
import { yupSchemas } from "utils/yupSchemas";
import { hasErrors } from "utils/form";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "components/buttons/Button";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import TooltipComp from "components/tooltip/TooltipComp";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import GenericPopup from "components/popups/GenericPopup";
import AddAttendeesPopup from "./AddAttendeesPopup";
import { popupContext } from "context/popupContext";
import { fetchApi } from "utils/requests";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { usePrompt } from "hooks/usePrompt";
import {
  ICity,
  ICountry,
  ITeamData
} from "utils/apiDataTypes/AccountManagementDataTypes";
import {
  ICourseFormData,
  ITemplatesData
} from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./CourseForm.module.scss";
import {
  teamDataQuery,
  nftTemplatesDataQuery,
  countriesDataQuery,
  citiesDataQuery
} from "query";
import moment from "moment";
import { FILES_PUBLIC_DOMAIN, USA_ID } from "utils/constants";
import { getBase64FromUrl } from "utils/format";
import {
  titleOptions,
  agdTypeOptions,
  // Hide all `Instructor Degree` related logic
  // degreeOptions,
  fileInstructions
} from "utils/staticOptions";
import { dateFormatField } from "utils/staticOptions";
import Error from "components/input/Error";
import { windowContext } from "context/windowsContext";

// Move to `utils` if needed
const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
};

const validationSchema: Yup.SchemaOf<ICourseFormData> = Yup.object().shape({
  name: yupSchemas.requiredStr,
  start_date: yupSchemas.requiredStr,
  end_date: yupSchemas.requiredStr,
  // Hide all `Lecture Dates` related logic
  // lecture_date_dates_1: Yup.string(),
  // lecture_date_startHour_1: Yup.string(),
  // lecture_date_endHour_1: Yup.string(),
  // lecture_date_lecture_1: Yup.string().test(
  //   "empty-check",
  //   "Required",
  //   (val, cn) =>
  //     cn.options.context.lecture_date_dates_1 === "" ||
  //     (cn.options.context.lecture_date_dates_1 !== "" && !!val)
  // ),
  image: Yup.string(),
  course_managers: Yup.array().of(yupSchemas.labelAndValue),
  template_id: yupSchemas.labelAndValue,
  attendees: Yup.string(),
  primary_instructor_title_1: yupSchemas.labelAndValue.nullable(),
  primary_instructor_firstName_1: yupSchemas.requiredStr,
  primary_instructor_lastName_1: yupSchemas.requiredStr,
  // Hide all `Instructor Degree` related logic
  // primary_instructor_degree_1: Yup.array()
  //   .of(yupSchemas.labelAndValue)
  //   .required(),
  primary_instructor_signature_1: yupSchemas.requiredStr,
  state: yupSchemas.labelAndValue.nullable(),
  city: yupSchemas.labelAndValue.nullable(),
  AGD_code_1: Yup.string(),
  AGD_hours_1: Yup.number().positive().transform(emptyStringToNull).nullable(),
  AGD_description_1: Yup.string(),
  AGD_type_1: yupSchemas.labelAndValue.nullable(),
  credit_hours: Yup.number().positive().transform(emptyStringToNull).nullable(),
  is_open: Yup.boolean()
});

export const fieldTitles = {
  name: "Course Name",
  date: "Course Dates",
  start_date: "Start Date",
  end_date: "End Date",
  // Hide all `Lecture Dates` related logic
  // lecture_dates: "Lecture Dates",
  image: "Course Picture",
  course_managers: "Responsible Course Managers",
  template_id: "NFT Certificate Template",
  attendees: "Add Attendees",
  primary_instructor: "Primary Instructor",
  secondary_instructor: "Additional Course Instructor",
  location: "Course Location (City, State)",
  credit_hours: "Total Credit Hours",
  AGD: "AGD Subject Codes"
};

const formSteps = ["Course Overview", "Course Details", "Confirm Creation"];

interface ICourseForm {
  title: string;
  defaultFormData: ICourseFormData;
  isEdit?: boolean;
  isCreateFromTemplate?: boolean;
  isDraft?: boolean;
  courseId?: string;
  courseDraftId?: string;
  // Hide all `Lecture Dates` related logic
  // lectureDatesEditCount?: number;
  primaryInstructorEditCount?: number;
  secondaryInstructorEditCount?: number;
  AGDEditCount?: number;
}

const CourseForm = ({
  title,
  defaultFormData,
  isEdit,
  isCreateFromTemplate,
  isDraft,
  courseId,
  courseDraftId,
  // Hide all `Lecture Dates` related logic
  // lectureDatesEditCount,
  primaryInstructorEditCount,
  secondaryInstructorEditCount,
  AGDEditCount
}: ICourseForm) => {
  const { setPopup, setPopupLoading, clearPopup } = useContext(popupContext);
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const queryClient = useQueryClient();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);
  // Hide all `Lecture Dates` related logic
  // const [lectureDatesCount, setLectureDatesCount] = useState<number>(
  //   lectureDatesEditCount || 1
  // );

  const [uploadedFile, setUploadedFile] = useState<Blob>();
  const [attendeesData, setAttendeesData] = useState<string[][]>();

  const [coursePrimaryInstructorsCount, setCoursePrimaryInstructorsCount] =
    useState<number>(primaryInstructorEditCount || 1);
  const [courseSecondaryInstructorsCount, setCourseSecondaryInstructorsCount] =
    useState<number>(secondaryInstructorEditCount || 0);
  const [courseAGDCount, setCourseAGDCount] = useState<number>(
    AGDEditCount || 1
  );

  const fetchedMembersData = useGetFetchQuery(teamDataQuery()) as ITeamData;

  // Fetch info in case cache data is missing
  const { isLoading: membersIsLoading, error: membersError } = useQuery<
    boolean,
    Error
  >({
    ...teamDataQuery(),
    enabled: !fetchedMembersData
  });

  const {
    isLoading: templatesIsLoading,
    error: templatesError,
    data: fetchedNftTemplatesData
  } = useQuery<boolean, Error, ITemplatesData>(nftTemplatesDataQuery());

  useEffect(() => {
    if (
      !isEdit &&
      fetchedNftTemplatesData &&
      !fetchedNftTemplatesData.total_results
    )
      setPopup(
        <GenericPopup
          type="info"
          size="md"
          title="NFT Certificate Template Not Found"
          msg="The are not any NFT Certificate Templates available for this school. A course can be created without one but it will be required for minting NFT Certificates later."
          buttonName="Create NFT Certificate Template"
          buttonVariant="contrast"
          buttonAction={() =>
            navigate("/diplomas/create", {
              state: { schoolHasTemplates: false }
            })
          }
          bellowBtnComp={
            <Button
              onClick={() => clearPopup()}
              variant={"link"}
              minWidth="full"
            >
              Continue without NFT Certificate Template
            </Button>
          }
        />
      );
  }, [fetchedNftTemplatesData]);

  const {
    formData,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    formErrors,
    setFormErrors
  } = useFormData(defaultFormData, validationSchema);

  // Set date range
  const [dateRange, setDateRange] = useState([
    formData.start_date,
    formData.end_date
  ]);
  const [startDateRange, endDateRange] = dateRange;

  // Set form data on date range change
  useEffect(() => {
    setFormData({
      ...formData,
      start_date: startDateRange,
      end_date: endDateRange
    });
  }, [startDateRange, endDateRange]);

  const calcCreditHours = () =>
    Object.keys(formData).reduce((acc, current) => {
      if (current.includes("AGD_hours"))
        acc += formData[current] ? parseFloat(formData[current]) : 0;
      return acc;
    }, 0);

  // Hide all `Lecture Dates` related logic
  // const buildDisplayData = (date, startHour, endHour) => {
  //   const dateStr = date ? moment(date).format(dateFormatField) : "";
  //   const startHourStr = startHour
  //     ? moment(startHour).format("HH:mm")
  //     : moment(date).format("HH:mm");
  //   const endHourStr = endHour
  //     ? moment(endHour).format("HH:mm")
  //     : moment(date).format("HH:mm");
  //   const displayData = dateStr
  //     ? `${dateStr} ${startHourStr} - ${endHourStr}`
  //     : "";
  //
  //   return displayData.trim();
  // };

  // Hide all `Lecture Dates` related logic
  // const maxLectureDates = 16;
  // const renderLectureDatesGroup = () => {
  //   return Array(lectureDatesCount)
  //     .fill(true)
  //     .map((_, i) => (
  //       <div key={i} className={classes["input-group"]}>
  //         <InputField
  //           type="datepicker"
  //           name={`lecture_date_dates_${i + 1}`}
  //           placeholder="Select Date"
  //           onChange={(e) =>
  //             handleChange({
  //               target: {
  //                 name: `lecture_date_dates_${i + 1}`,
  //                 value: e
  //               }
  //             })
  //           }
  //           value={formData[`lecture_date_dates_${i + 1}`]}
  //           displayValue={buildDisplayData(
  //             formData[`lecture_date_dates_${i + 1}`],
  //             formData[`lecture_date_startHour_${i + 1}`],
  //             formData[`lecture_date_endHour_${i + 1}`]
  //           )}
  //           minDate={new Date()}
  //           error={formErrors[`lecture_date_dates_${i + 1}`]}
  //           hasTime
  //           onConfirm={(name: string) =>
  //             setFormData({
  //               ...formData,
  //               [`lecture_date_dates_${i + 1}`]: name
  //             })
  //           }
  //         >
  //           <InputField
  //             type="timepicker"
  //             title="Start Hour"
  //             name={`lecture_date_startHour_${i + 1}`}
  //             value={formData[`lecture_date_startHour_${i + 1}`]}
  //             error={formErrors[`lecture_date_startHour_${i + 1}`]}
  //             placeholder="Select Time"
  //             onChange={(e) =>
  //               handleChange({
  //                 target: {
  //                   name: `lecture_date_startHour_${i + 1}`,
  //                   value: e
  //                 }
  //               })
  //             }
  //             filterPassedTime={(e) => {
  //               const selectedDate = new Date(e);
  //               const endHour = formData[`lecture_date_endHour_${i + 1}`];
  //               if (endHour)
  //                 return new Date(endHour).getTime() > selectedDate.getTime();
  //               return true;
  //             }}
  //             maxWidth={170}
  //           />
  //           <div className={classes["divider"]} />
  //           <InputField
  //             type="timepicker"
  //             title="End Hour"
  //             name={`lecture_date_endHour_${i + 1}`}
  //             value={formData[`lecture_date_endHour_${i + 1}`]}
  //             error={formErrors[`lecture_date_endHour_${i + 1}`]}
  //             placeholder="Select Time"
  //             onChange={(e) =>
  //               handleChange({
  //                 target: {
  //                   name: `lecture_date_endHour_${i + 1}`,
  //                   value: e
  //                 }
  //               })
  //             }
  //             filterPassedTime={(e) => {
  //               const selectedDate = new Date(e);
  //               const startHour = formData[`lecture_date_startHour_${i + 1}`];
  //               if (startHour)
  //                 return new Date(startHour).getTime() < selectedDate.getTime();
  //               return true;
  //             }}
  //             maxWidth={170}
  //           />
  //         </InputField>
  //         <InputField
  //           name={`lecture_date_lecture_${i + 1}`}
  //           placeholder="Enter Lecture Name"
  //           onChange={handleChange}
  //           value={formData[`lecture_date_lecture_${i + 1}`]}
  //           error={formErrors[`lecture_date_lecture_${i + 1}`]}
  //         />
  //         {i > 0 && (
  //           <Button
  //             onClick={() => {
  //               setLectureDatesCount(lectureDatesCount - 1);
  //               setFormData({
  //                 ...formData,
  //                 [`lecture_date_dates_${i + 1}`]: "",
  //                 [`lecture_date_lecture_${i + 1}`]: ""
  //               });
  //             }}
  //             icon={TrashIcon}
  //             isIconBtn
  //             variant={"link"}
  //             size={"small"}
  //           />
  //         )}
  //       </div>
  //     ));
  // };

  const buildManagerOptions = () => {
    if (!fetchedMembersData) return [];

    return fetchedMembersData.result
      .filter((member) => member.role_flag === 2 && member.active)
      .map((member) => {
        return {
          value: member._id,
          label: `${member.title || ""} ${member.first_name} ${
            member.last_name
          }`,
          pic: member.pic
        };
      });
  };

  const buildTemplatesOptions = () => {
    if (!fetchedNftTemplatesData) return [];

    return fetchedNftTemplatesData.result.map((template) => {
      return {
        value: template._id,
        label: template.name
      };
    });
  };

  const removeInstructor = (set, arrLength, label, i) => {
    // set(arrLength - 1);
    setFormData({
      ...formData,
      [`${label}_instructor_title_${i + 1}`]: "",
      [`${label}_instructor_firstName_${i + 1}`]: "",
      [`${label}_instructor_lastName_${i + 1}`]: "",
      // Hide all `Instructor Degree` related logic
      // [`${label}_instructor_degree_${i + 1}`]: "",
      [`${label}_instructor_signature_${i + 1}`]: ""
    });
  };

  const maxCourseInstructorsByType = 2;
  const renderInstructorsGroup = (
    arrLength: number,
    set: Dispatch<SetStateAction<number>>,
    isPrimary?: boolean
  ) => {
    const label = isPrimary ? "primary" : "secondary";
    return Array(arrLength)
      .fill(true)
      .map((_, i) => (
        <div key={i}>
          <div
            className={`${classes["input-group"]} ${classes["input-group-24-38-38"]}`}
          >
            <ReactSelectWrapper
              name={`${label}_instructor_title_${i + 1}`}
              placeholder="Choose Title"
              options={titleOptions.map((name) => {
                return { label: name, value: name };
              })}
              isClearable={formData.is_open}
              value={formData[`${label}_instructor_title_${i + 1}`]}
              error={formErrors[`${label}_instructor_title_${i + 1}`]}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: `${label}_instructor_title_${i + 1}`,
                    value: e
                  }
                })
              }
            />
            <InputField
              name={`${label}_instructor_firstName_${i + 1}`}
              placeholder="Enter First Name"
              onChange={handleChange}
              value={formData[`${label}_instructor_firstName_${i + 1}`]}
              error={formErrors[`${label}_instructor_firstName_${i + 1}`]}
            />
            <InputField
              name={`${label}_instructor_lastName_${i + 1}`}
              placeholder="Enter Last Name"
              onChange={handleChange}
              value={formData[`${label}_instructor_lastName_${i + 1}`]}
              error={formErrors[`${label}_instructor_lastName_${i + 1}`]}
            />
            {(i > 0 || !isPrimary || formData.is_open) && (
              <Button
                className={classes["trash-btn--abs"]}
                onClick={() => removeInstructor(set, arrLength, label, i)}
                icon={TrashIcon}
                isIconBtn
                variant={"link"}
                size={"small"}
              />
            )}
          </div>
          {/* Hide all `Instructor Degree` related logic */}
          {/*<div*/}
          {/*  className={`${classes["input-group"]} ${classes["input-group-100"]}`}*/}
          {/*>*/}
          {/*  <ReactSelectWrapper*/}
          {/*    name={`${label}_instructor_degree_${i + 1}`}*/}
          {/*    placeholder="Choose Degree"*/}
          {/*    options={Object.keys(degreeOptions).map((k) => {*/}
          {/*      return {*/}
          {/*        value: k,*/}
          {/*        label: k*/}
          {/*      };*/}
          {/*    })}*/}
          {/*    isMulti*/}
          {/*    value={formData[`${label}_instructor_degree_${i + 1}`]}*/}
          {/*    error={formErrors[`${label}_instructor_degree_${i + 1}`]}*/}
          {/*    onChange={(e) =>*/}
          {/*      handleChange({*/}
          {/*        target: {*/}
          {/*          name: `${label}_instructor_degree_${i + 1}`,*/}
          {/*          value: e*/}
          {/*        }*/}
          {/*      })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          <ImageInput
            title={`Instructor Signature${
              i === 0 && isPrimary && !formData.is_open ? "*" : ""
            }`}
            subtitle={fileInstructions.signature}
            fileType="image/png"
            pic={formData[`${label}_instructor_signature_${i + 1}`]}
            onChange={(e: FormEvent) =>
              handleUploadedFileBase64(
                `${label}_instructor_signature_${i + 1}`,
                e
              )
            }
            onClear={() => {
              setFormData({
                ...formData,
                [`${label}_instructor_signature_${i + 1}`]: ""
              });
              setFormErrors({
                ...formErrors,
                [`${label}_instructor_signature_${i + 1}`]: ""
              });
            }}
            isSquare
            hasRightAlignedTitles
            isSignature
          />
          {(i > 0 || !isPrimary) && (
            <Button
              className={classes["trash-btn"]}
              size="medium"
              icon={TrashIcon}
              variant="link"
              onClick={() => removeInstructor(set, arrLength, label, i)}
              minWidth="full"
            >
              Remove Instructor
            </Button>
          )}
          <Error text={formErrors[`${label}_instructor_signature_${i + 1}`]} />
        </div>
      ));
  };

  const removeAGD = (set, arrLength, i) => {
    // set(arrLength - 1);
    setFormData({
      ...formData,
      [`AGD_code_${i + 1}`]: "",
      [`AGD_hours_${i + 1}`]: "",
      [`AGD_description_${i + 1}`]: "",
      [`AGD_type_${i + 1}`]: null
    });
  };

  const maxCourseAGD = 25;
  const renderAGDGroup = (
    arrLength: number,
    set: Dispatch<SetStateAction<number>>
  ) => {
    return Array(arrLength)
      .fill(true)
      .map((_, i) => (
        <div key={i}>
          <div
            className={`${classes["input-group"]} ${classes["input-group-25-25-25-25"]}`}
          >
            <InputField
              name={`AGD_code_${i + 1}`}
              placeholder="Enter Code"
              onChange={handleChange}
              value={formData[`AGD_code_${i + 1}`]}
              error={formErrors[`AGD_code_${i + 1}`]}
            />
            <InputField
              type="number"
              name={`AGD_hours_${i + 1}`}
              placeholder="Enter Hours"
              onChange={handleChange}
              value={formData[`AGD_hours_${i + 1}`]}
              error={formErrors[`AGD_hours_${i + 1}`]}
            />
            <InputField
              name={`AGD_description_${i + 1}`}
              placeholder="Enter Description"
              onChange={handleChange}
              value={formData[`AGD_description_${i + 1}`]}
              error={formErrors[`AGD_description_${i + 1}`]}
            />
            <ReactSelectWrapper
              name={`AGD_type_${i + 1}`}
              placeholder="Choose Type"
              options={agdTypeOptions.map((name) => {
                return { label: name, value: name };
              })}
              value={formData[`AGD_type_${i + 1}`]}
              error={formErrors[`AGD_type_${i + 1}`]}
              onChange={(e) =>
                handleChange({
                  target: {
                    name: `AGD_type_${i + 1}`,
                    value: e
                  }
                })
              }
              isClearable
            />
            <Button
              className={classes["trash-btn--abs"]}
              onClick={() => removeAGD(set, arrLength, i)}
              icon={TrashIcon}
              isIconBtn
              variant={"link"}
              size={"small"}
            />
          </div>
          {i > 0 && (
            <Button
              className={classes["trash-btn"]}
              size="medium"
              icon={TrashIcon}
              variant="link"
              onClick={() => removeAGD(set, arrLength, i)}
            >
              Delete AGD Group
            </Button>
          )}
        </div>
      ));
  };

  useEffect(() => {
    if (uploadedFile) {
      processFile();
    }
  }, [uploadedFile]);

  const setFile = async (e: React.FormEvent<HTMLFormElement>) => {
    const file = e.currentTarget.files[0];
    if (file) setUploadedFile(file);
  };

  // Process `.csv/.xlsx` upload
  // Ref. https://stackblitz.com/edit/react-excel-to-json-parser?file=ExcelReader.js
  const processFile = async () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;

      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        raw: true,
        cellDates: true
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      if (data.length > 0) {
        /* Update state */
        setAttendeesData(
          data
            .filter((d: any) => d.email)
            .map((d: any) => [
              d.email,
              d.first_name || "",
              d.last_name || "",
              d.wallet || "",
              d.custom_diploma_date
                ? typeof d.custom_diploma_date === "string"
                  ? d.custom_diploma_date
                  : d.custom_diploma_date.toString()
                : ""
            ])
        );
        setPopup(
          <GenericPopup type="success" msg={`${data.length} attendees added`} />
        );
      } else {
        /* Show error */
        setPopup(
          <GenericPopup
            type="error"
            msg={"Failed processing. Add at least 1 Row."}
            buttonName="Close"
            buttonVariant="neutral"
          />
        );
      }
    };

    if (rABS) {
      reader.readAsBinaryString(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  const fetchedCountriesData = useGetFetchQuery(
    countriesDataQuery()
  ) as ICountry[];

  const { isLoading: isLoadingCountries, error: errorCountries } = useQuery<
    boolean,
    Error
  >({
    ...countriesDataQuery(),
    enabled: !fetchedCountriesData
  });

  const selectedState = formData.state ? formData.state.value : null;

  const fetchedCitiesData = useGetFetchQuery(
    citiesDataQuery(selectedState)
  ) as ICity[];

  const { isLoading: isLoadingCities, error: errorCities } = useQuery<
    boolean,
    Error
  >({
    ...citiesDataQuery(selectedState),
    enabled: !!selectedState && !fetchedCitiesData
  });

  const getSelectedCountryStates = () => {
    const country = fetchedCountriesData
      ? // TODO - Get school country (instead of `USA_ID`) once the platform is extended with more countries
        fetchedCountriesData.find((c) => c._id === USA_ID)
      : { states: [] };

    return country && country.states && country.states.length > 0
      ? country.states.map((s) => ({
          label: `${s.state_code} - ${s.name}`,
          value: s._id,
          code: s.state_code,
          name: s.name
        }))
      : [];
  };

  const renderSaveAsDraftBtn = () => (
    <div className={classes["save-as-draft"]}>
      <div className={classes["msg"]}>
        <TooltipComp
          id="draft-msg"
          text="You can save your course creation progress as a draft and continue later from where you left off."
        >
          <InfoIcon />
        </TooltipComp>
        <span>Save as draft and continue the course creation later.</span>
      </div>
      <Button
        variant="link"
        className={classes["align-self-btn"]}
        onClick={handleDraft}
        isFetching={isLoadingDraft}
        minWidth={"sm"}
        icon={CheckIcon}
        iconPosition={"left"}
      >
        Save as Draft
      </Button>
    </div>
  );

  const renderStep = (step: number) => {
    if (step === 0) {
      return (
        <div className={classes["step"]}>
          <div>
            <label
              className={`${classes["custom-checkbox"]} ${
                isEdit && !isDraft ? classes["custom-checkbox--disabled"] : ""
              }`}
            >
              <input
                type="checkbox"
                name="is_open"
                disabled={isEdit && !isDraft}
                defaultChecked={formData.is_open}
                onClick={() =>
                  setFormData({ ...formData, is_open: !formData.is_open })
                }
              />
              <span className={classes["mark"]} />
              Mark as Open Course
            </label>
            <p className={classes["custom-checkbox__desc"]}>
              Check this box if you would like to create a course for minting
              membership cards.
            </p>
          </div>
          <InputField
            title={fieldTitles.name}
            name="name"
            placeholder="Enter Course Name"
            onChange={handleChange}
            value={formData.name}
            error={formErrors.name}
            isRequired
          />
          <InputField
            type="datepicker-range"
            title={fieldTitles.date}
            isRequired={!formData.is_open}
            isClearable={formData.is_open}
            name="date_range"
            placeholder="Select Date Range"
            onChange={setDateRange}
            startDateRange={startDateRange}
            endDateRange={endDateRange}
            customHeader={
              <div
                style={{
                  ...(startDateRange && endDateRange
                    ? {}
                    : { padding: "16px 8px 0", textAlign: "center" })
                }}
                className={`${classes["u-bold"]} ${classes["u-text--medium"]}`}
              >
                {startDateRange && endDateRange
                  ? ""
                  : !startDateRange && !endDateRange
                  ? "Select Course Start Date"
                  : "Select Course End Date"}
              </div>
            }
            displayValue={
              startDateRange || endDateRange
                ? `${
                    startDateRange
                      ? moment(startDateRange).format(dateFormatField)
                      : ""
                  } ${startDateRange ? "-" : ""} ${
                    endDateRange
                      ? moment(endDateRange).format(dateFormatField)
                      : startDateRange
                      ? "--/--/----"
                      : ""
                  }`
                : null
            }
            error={formErrors.start_date || formErrors.end_date}
          />
          {/* Hide all `Lecture Dates` related logic */}
          {/*<InputGroup*/}
          {/*  title={fieldTitles.lecture_dates}*/}
          {/*  actionBtn={*/}
          {/*    <TooltipComp*/}
          {/*      id="add-dates-tooltip"*/}
          {/*      text={`You can add up to ${maxLectureDates} Lecture Dates.`}*/}
          {/*    >*/}
          {/*      <Button*/}
          {/*        onClick={() => {*/}
          {/*          if (lectureDatesCount < maxLectureDates)*/}
          {/*            setLectureDatesCount(lectureDatesCount + 1);*/}
          {/*        }}*/}
          {/*        icon={PlusIcon}*/}
          {/*        iconPosition="right"*/}
          {/*        variant={"link"}*/}
          {/*        className={classes["tooltip-btn"]}*/}
          {/*        isDisabled={lectureDatesCount === maxLectureDates}*/}
          {/*        size={"medium"}*/}
          {/*      >*/}
          {/*        Add*/}
          {/*      </Button>*/}
          {/*    </TooltipComp>*/}
          {/*  }*/}
          {/*>*/}
          {/*  {renderLectureDatesGroup()}*/}
          {/*</InputGroup>*/}
          <ImageInput
            title={fieldTitles.image}
            subtitle={fileInstructions.pfp}
            fileType="image/png, image/jpeg, image/jpg"
            pic={formData.image}
            onChange={(e: FormEvent) => handleUploadedFileBase64("image", e)}
            onClear={() => {
              setFormData({
                ...formData,
                image: ""
              });
              setFormErrors({ ...formErrors, image: "" });
            }}
            error={formErrors.image}
            isSquare
          />
          {(!isEdit || isDraft) && (
            <ReactSelectWrapper
              title={fieldTitles.course_managers}
              subtitle="If a Course Manager is not specified the Admin and the Registrar will be able to administer the course. Only the active Course Managers are listed below."
              name="course_managers"
              placeholder="Select Course Manager"
              options={buildManagerOptions()}
              isSearchable
              isMulti
              hasAvatar
              isLoading={membersIsLoading}
              value={formData.course_managers}
              error={
                membersError ? membersError.message : formErrors.course_managers
              }
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "course_managers",
                    value: e
                  }
                })
              }
            />
          )}
          <ReactSelectWrapper
            title={fieldTitles.template_id}
            name="template_id"
            placeholder="Select NFT Certificate Template"
            options={buildTemplatesOptions()}
            isSearchable
            isLoading={templatesIsLoading}
            value={formData.template_id}
            error={
              (templatesError
                ? templatesError.message
                : formErrors.template_id) as string
            }
            onChange={(e) =>
              handleChange({
                target: {
                  name: "template_id",
                  value: e
                }
              })
            }
          />
          {(!isEdit || isDraft) && (
            <>
              {attendeesData && attendeesData.length > 0 ? (
                <InputField
                  type="file"
                  fileType=".csv, .xlsx"
                  fileName={
                    typeof formData.attendees !== "string" &&
                    formData.attendees.name
                      ? formData.attendees.name
                      : ""
                  }
                  onClear={() => {
                    setFormData({
                      ...formData,
                      attendees: ""
                    });
                    setAttendeesData(undefined);
                    setUploadedFile(undefined);
                  }}
                  title={fieldTitles.attendees}
                  name="attendees"
                  onChange={(e: React.FormEvent<HTMLFormElement>) => {
                    handleUploadedFileBase64("attendees", e);
                    setFile(e);
                  }}
                  maxWidth={160}
                />
              ) : (
                <div>
                  <Label name="add-attendees" title="Add Attendees" />
                  <Button
                    variant="neutral"
                    minWidth={isMobile ? "full" : "md"}
                    onClick={() =>
                      setPopup(
                        <AddAttendeesPopup
                          attendeesData={attendeesData}
                          setAttendeesData={setAttendeesData}
                          setFile={setFile}
                          formData={formData}
                          setFormData={setFormData}
                          formErrors={formErrors}
                          handleUploadedFileBase64={handleUploadedFileBase64}
                          isOpen={formData.is_open}
                        />
                      )
                    }
                    icon={PlusIcon}
                  >
                    Add Attendees
                  </Button>
                </div>
              )}
            </>
          )}
          <div className={classes["buttons-container"]}>
            <Button
              variant="outline-neutral"
              className={classes["prev-btn"]}
              onClick={() => navigate("/courses")}
              icon={ArrowIcon}
              iconRotate={180}
            >
              Back to Courses
            </Button>
            <Button
              className={classes["next-btn"]}
              onClick={() => setCurrentStep(currentStep + 1)}
              icon={ArrowIcon}
              iconPosition="right"
              isDisabled={
                hasErrors(formErrors) ||
                !formData.name ||
                (formData.is_open
                  ? false
                  : !formData.start_date || !formData.end_date)
              }
            >
              Next
            </Button>
          </div>
          {(!isEdit || isDraft) &&
            !isCreateFromTemplate &&
            renderSaveAsDraftBtn()}
        </div>
      );
    }

    if (step === 1) {
      return (
        <div className={classes["step"]}>
          <InputGroup
            name="primary_instructor_group"
            title={fieldTitles.primary_instructor}
            actionBtn={
              <TooltipComp
                id="add-primary-instructor-tooltip"
                text={`You can add up to ${maxCourseInstructorsByType} Primary Instructors.`}
              >
                <Button
                  onClick={() => {
                    if (
                      coursePrimaryInstructorsCount < maxCourseInstructorsByType
                    )
                      setCoursePrimaryInstructorsCount(
                        coursePrimaryInstructorsCount + 1
                      );
                  }}
                  icon={PlusIcon}
                  iconPosition="right"
                  variant={"link"}
                  isDisabled={
                    coursePrimaryInstructorsCount === maxCourseInstructorsByType
                  }
                  className={classes["tooltip-btn"]}
                >
                  Add
                </Button>
              </TooltipComp>
            }
            isRequired={!formData.is_open}
          >
            <div className={classes["input-group-wrapper"]}>
              {renderInstructorsGroup(
                coursePrimaryInstructorsCount,
                setCoursePrimaryInstructorsCount,
                true
              )}
            </div>
          </InputGroup>
          <InputGroup
            name="secondary_instructor_group"
            title={fieldTitles.secondary_instructor}
            actionBtn={
              <TooltipComp
                id="add-secondary-instructor-tooltip"
                text={`You can add up to ${maxCourseInstructorsByType} additional Course Instructors.`}
              >
                <Button
                  onClick={() => {
                    if (
                      courseSecondaryInstructorsCount <
                      maxCourseInstructorsByType
                    )
                      setCourseSecondaryInstructorsCount(
                        courseSecondaryInstructorsCount + 1
                      );
                  }}
                  icon={PlusIcon}
                  iconPosition="right"
                  variant={"link"}
                  isDisabled={
                    courseSecondaryInstructorsCount ===
                    maxCourseInstructorsByType
                  }
                  className={classes["tooltip-btn"]}
                >
                  Add
                </Button>
              </TooltipComp>
            }
          >
            <div className={classes["input-group-wrapper"]}>
              {renderInstructorsGroup(
                courseSecondaryInstructorsCount,
                setCourseSecondaryInstructorsCount
              )}
            </div>
          </InputGroup>
          <ReactSelectWrapper
            title="Course State"
            name="state"
            placeholder="Enter Course State"
            options={getSelectedCountryStates()}
            value={formData.state}
            isClearable
            isLoading={isLoadingCountries}
            error={
              errorCountries ? errorCountries.message : formErrors.state.value
            }
            onChange={(e) =>
              handleChange({
                target: {
                  name: "state",
                  value: e
                }
              })
            }
            isSearchable
          />
          <ReactSelectWrapper
            title="Course City"
            name="city"
            placeholder="Enter Course City"
            options={
              fetchedCitiesData &&
              fetchedCitiesData.map((city) => {
                return { label: city.name, value: city.name };
              })
            }
            value={formData.city}
            isDisabled={!formData.state}
            isClearable
            error={errorCities ? errorCities.message : formErrors.city.value}
            onChange={(e) =>
              handleChange({
                target: {
                  name: "city",
                  value: e
                }
              })
            }
            isSearchable
          />
          <InputGroup
            name="agd_group"
            title={fieldTitles.AGD}
            actionBtn={
              <TooltipComp
                id="add-agd-tooltip"
                text={`You can add up to ${maxCourseAGD} AGD Subject Codes.`}
              >
                <Button
                  onClick={() => {
                    if (courseAGDCount < maxCourseAGD)
                      setCourseAGDCount(courseAGDCount + 1);
                  }}
                  icon={PlusIcon}
                  iconPosition="right"
                  variant={"link"}
                  isDisabled={courseAGDCount === maxCourseAGD}
                  className={classes["tooltip-btn"]}
                >
                  Add
                </Button>
              </TooltipComp>
            }
          >
            {renderAGDGroup(courseAGDCount, setCourseAGDCount)}
          </InputGroup>
          <InputField
            type="number"
            title={fieldTitles.credit_hours}
            name="credit_hours"
            placeholder="Enter Credit Hours"
            onChange={handleChange}
            value={formData.credit_hours}
            error={formErrors.credit_hours.toString()}
            isDisabled
          />
          <div className={classes["buttons-container"]}>
            <Button
              variant="outline-neutral"
              className={classes["prev-btn"]}
              onClick={() => setCurrentStep(currentStep - 1)}
              icon={ArrowIcon}
              iconRotate={180}
            >
              Back
            </Button>
            <Button
              className={classes["next-btn"]}
              onClick={() => setCurrentStep(currentStep + 1)}
              icon={ArrowIcon}
              iconPosition="right"
              isDisabled={
                hasErrors(formErrors) ||
                (formData.is_open
                  ? false
                  : !formData.primary_instructor_title_1 ||
                    !formData.primary_instructor_firstName_1 ||
                    !formData.primary_instructor_lastName_1 ||
                    !formData.primary_instructor_signature_1)
              }
            >
              Next
            </Button>
          </div>
          {(!isEdit || isDraft) &&
            !isCreateFromTemplate &&
            renderSaveAsDraftBtn()}
        </div>
      );
    }

    return (
      <div className={classes["step"]}>
        <Summary
          formData={buildSummaryData()}
          fieldTitles={fieldTitles}
          templates={
            fetchedNftTemplatesData ? fetchedNftTemplatesData.result : []
          }
        />
        <div className={classes["buttons-container"]}>
          <Button
            variant="outline-neutral"
            className={classes["prev-btn"]}
            onClick={() => setCurrentStep(currentStep - 1)}
            icon={ArrowIcon}
            iconRotate={180}
          >
            Back
          </Button>
          <Button
            className={classes["next-btn"]}
            onClick={() =>
              !isEdit || isDraft ? handleCreate(isDraft) : handleUpdate()
            }
            icon={CheckIcon}
            iconPosition="right"
            isDisabled={hasErrors(formErrors)}
            isFetching={!isEdit || isDraft ? isLoadingCreate : isLoadingUpdate}
          >
            {!isEdit
              ? "Finish"
              : isDraft
              ? "Create course"
              : "Update Course Info"}
          </Button>
        </div>
        {(!isEdit || isDraft) &&
          !isCreateFromTemplate &&
          renderSaveAsDraftBtn()}
      </div>
    );
  };

  // Scroll to top of the page on every step change
  const pageRef = useRef(null);
  const executeScroll = () => pageRef.current.scrollIntoView();

  // Reset `city` on state change (Please do the same for `state` on country change, once we have more than one country)
  useEffect(() => {
    setFormData({
      ...formData,
      city: null
    });
  }, [formData.state]);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setFinishedSteps(0);
        break;
      case 1:
        executeScroll();
        setFinishedSteps(1);
        break;
      case 2:
        executeScroll();
        setFinishedSteps(2);
        break;
      default:
        break;
    }
  }, [currentStep]);

  // Hide all `Lecture Dates` related logic
  // Validate lecture name field on lecture dates change
  // useEffect(() => {
  //   const field = "lecture_date_lecture_1";
  //   const fieldSchema = Yup.object().shape({
  //     [field]: validationSchema.fields[field]
  //   });
  //
  //   if (formData.lecture_date_dates_1) {
  //     fieldSchema
  //       .validate(
  //         { [field]: formData.lecture_date_lecture_1 },
  //         {
  //           context: formData
  //         }
  //       )
  //       .then(
  //         () => {
  //           setFormErrors({ ...formErrors, [field]: "" });
  //         },
  //         (err) => {
  //           setFormErrors({ ...formErrors, [field]: err.message });
  //         }
  //       );
  //   }
  // }, [formData.lecture_date_dates_1]);

  // Get all dynamic AGD hours fields keys
  const dynamicAGDHoursKeys = Object.keys(formData).filter((field) =>
    field.includes("AGD_hours")
  );
  // Get all dynamic AGD hours fields values
  const dynamicAGDHoursValues = dynamicAGDHoursKeys.map((f) => formData[f]);

  // Calculate credit hours on every AGD hours field change
  useEffect(() => {
    setFormData({
      ...formData,
      credit_hours: calcCreditHours()
    });
  }, [...dynamicAGDHoursValues, courseAGDCount]);

  const buildInstructorsData = () => {
    const buildInstructorsDataGroups = Object.entries(formData)
      .filter(([k, _]) => k.includes("instructor"))
      .reduce((acc, [k, v]) => {
        const [priority, groupName, fieldName, id] = k.split("_");
        if (!acc[id + priority]) acc[id + priority] = {};
        if (v) {
          if (fieldName === "title") {
            acc[id + priority][fieldName] = v.label;
          } else if (fieldName === "degree") {
            // Hide all `Instructor Degree` related logic
            // acc[id + priority][fieldName] = v.map((opt) => opt.label);
          } else if (fieldName === "firstName") {
            acc[id + priority]["first_name"] = v;
          } else if (fieldName === "lastName") {
            acc[id + priority]["last_name"] = v;
          } else {
            acc[id + priority][fieldName] = v;
          }
          acc[id + priority]["primary"] = priority === "primary";
        }

        return acc;
      }, {});

    const arr = Object.keys(buildInstructorsDataGroups).map(
      (d) => buildInstructorsDataGroups[d]
    );

    return arr.filter((element) => Object.keys(element).length !== 0);
  };

  // Hide all `Lecture Dates` related logic
  // const buildLectureDatesData = () => {
  //   const buildLectureDatesDataGroups = Object.entries(formData)
  //     .filter(([k, v]) => k.includes("lecture_date"))
  //     .reduce((acc, [k, v]) => {
  //       const [course, date, fieldName, id] = k.split("_");
  //       if (!acc[id]) acc[id] = {};
  //       if (v) {
  //         acc[id][fieldName] = v;
  //       }
  //       return acc;
  //     }, {});
  //   const arr = Object.keys(buildLectureDatesDataGroups).map((d) => {
  //     const momentDates = moment(buildLectureDatesDataGroups[d].dates).format(
  //       dateFormatField
  //     );
  //     const momentStart = moment(
  //       buildLectureDatesDataGroups[d].startHour
  //     ).format("HH:mm");
  //     const momentEnd = moment(buildLectureDatesDataGroups[d].endHour).format(
  //       "HH:mm"
  //     );
  //     const buildStart = moment(
  //       momentDates + " " + momentStart,
  //       "MM/DD/yyyy HH:mm"
  //     );
  //     const buildEnd = moment(
  //       momentDates + " " + momentEnd,
  //       "MM/DD/yyyy HH:mm"
  //     );
  //     const timestampStart = moment(buildStart).valueOf();
  //     const timestampEnd = moment(buildEnd).valueOf();
  //
  //     return {
  //       title: buildLectureDatesDataGroups[d].lecture,
  //       start_date: timestampStart,
  //       end_date: timestampEnd
  //     };
  //   });
  //
  //   return arr.filter(
  //     (element) => Object.keys(element).length !== 0 && element.title
  //   );
  // };

  const buildAGDData = () => {
    const buildAGDDataGroups = Object.entries(formData)
      .filter(([k, _]) => k.includes("AGD"))
      .reduce((acc, [k, v]) => {
        const [groupName, fieldName, id] = k.split("_");
        if (!acc[id]) acc[id] = {};
        if (v) {
          acc[id][fieldName] = v;
        }
        return acc;
      }, {});
    const arr = Object.keys(buildAGDDataGroups).map((d) => {
      return {
        code: buildAGDDataGroups[d].code,
        hours: buildAGDDataGroups[d].hours,
        description: buildAGDDataGroups[d].description,
        type: buildAGDDataGroups[d].type
          ? buildAGDDataGroups[d].type.label
          : null
      };
    });

    return arr.filter(
      (element) => Object.keys(element).length !== 0 && element.code
    );
  };

  const common = {
    is_open: formData.is_open,
    name: formData.name,
    start_date: formData.start_date
      ? moment(formData.start_date).valueOf()
      : null,
    end_date: formData.end_date ? moment(formData.end_date).valueOf() : null,
    // Hide all `Lecture Dates` related logic
    // ...(buildLectureDatesData().length > 0
    //   ? { lecture_dates: buildLectureDatesData() }
    //   : {}),
    ...(formData.template_id
      ? { template_id: formData.template_id.value }
      : {}),
    credit_hours: formData.credit_hours ? +formData.credit_hours : null
  };

  const buildSummaryData = () => ({
    ...common,
    ...(formData.image ? { image: formData.image } : {}),
    ...(formData.course_managers.length > 0
      ? {
          course_managers: formData.course_managers.map((m) => m.label)
        }
      : {}),
    ...(formData.attendees ? { attendees: formData.attendees } : {}),
    instructors: buildInstructorsData(),
    ...(formData.state
      ? {
          location: {
            state: {
              name: formData.state.name,
              code: formData.state.code
            },
            city: formData.city
          }
        }
      : {}),
    ...(buildAGDData().length > 0 ? { AGD: buildAGDData() } : {})
  });

  const buildFormDataImage = async (img) => {
    if (img && img.includes(FILES_PUBLIC_DOMAIN)) {
      return await getBase64FromUrl(img);
    }
    return img;
  };

  const instructorsFormData = buildInstructorsData();

  const buildFormData = async () => ({
    ...common,
    ...(formData.image
      ? { image: await buildFormDataImage(formData.image) }
      : { image: "" }),
    ...(formData.course_managers.length > 0
      ? {
          course_managers: formData.course_managers.map((m) => m.value)
        }
      : {}),
    ...(attendeesData && attendeesData.length > 0
      ? { students: attendeesData }
      : {}),
    ...(instructorsFormData && instructorsFormData.length > 0
      ? {
          instructors: await Promise.all(
            instructorsFormData.map(async (instructor) => {
              if (
                instructor.signature &&
                instructor.signature.includes(FILES_PUBLIC_DOMAIN)
              ) {
                if (isEdit) {
                  if (isDraft) {
                    instructor.signature = await getBase64FromUrl(
                      instructor.signature
                    );
                  } else {
                    const splitted =
                      instructor.signature.split(FILES_PUBLIC_DOMAIN);
                    instructor.signature = splitted[1];
                  }
                }
                if (!isEdit) {
                  instructor.signature = await getBase64FromUrl(
                    instructor.signature
                  );
                }
              }
              return instructor;
            })
          )
        }
      : {}),
    location: formData.state
      ? {
          state: {
            name: formData.state.name,
            code: formData.state.code
          },
          city: formData.city ? formData.city.label : null
        }
      : null,
    ...(buildAGDData().length > 0 ? { AGD: buildAGDData() } : { AGD: [] })
  });

  // Create course draft
  const createDraft = async () =>
    await fetchApi("courses", "/draft", {
      method: "POST",
      auth: true,
      data: {
        data: await buildFormData(),
        ...(courseDraftId ? { id: courseDraftId } : null)
      }
    });

  // Create course draft
  const { isLoading: isLoadingDraft, mutate: handleDraft } = useMutation({
    mutationKey: ["create-course-draft"],
    mutationFn: async () => {
      setPopupLoading(true);
      return await createDraft();
    },
    onSuccess: async (data) => {
      setPopupLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["courses-drafts-data", { id: courseDraftId }]
      });
      setPopup(
        <GenericPopup
          type="success"
          msg={`Draft successfully ${courseDraftId ? "updated" : "created"}.`}
          buttonName={"Go to drafts"}
          buttonVariant={"contrast"}
          buttonAction={() => navigate("/courses-drafts")}
          isClosable={!courseDraftId}
          bellowBtnComp={
            <Button
              onClick={() => {
                navigate("/courses");
                clearPopup();
              }}
              icon={BookIcon}
              variant={"link"}
              minWidth="full"
            >
              Show all active courses
            </Button>
          }
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  const isDirty = () => {
    if (isEdit || isCreateFromTemplate) return false;

    return (
      formData.name.length > 0 ||
      !!formData.start_date ||
      !!formData.end_date ||
      formData.image.length > 0 ||
      formData.course_managers.length > 0 ||
      formData.template_id !== null ||
      formData.primary_instructor_title_1 !== null ||
      formData.primary_instructor_firstName_1.length > 0 ||
      formData.primary_instructor_lastName_1.length > 0 ||
      formData.primary_instructor_signature_1.length > 0 ||
      formData.credit_hours > 0
    );
  };

  usePrompt(
    "Your changes will not be saved. Leave page?",
    isDirty(),
    handleDraft
  );

  // Create course
  const create = async (isDraft: boolean) =>
    await fetchApi("courses", "/course", {
      method: "POST",
      auth: true,
      data: {
        ...(await buildFormData()),
        ...(isDraft ? { draft_id: courseDraftId } : {})
      }
    });

  // Create course mutation
  const { isLoading: isLoadingCreate, mutate: handleCreate } = useMutation({
    mutationKey: ["create-course"],
    mutationFn: async (isDraft: boolean) => {
      setPopupLoading(true);
      return await create(isDraft);
    },
    onSuccess: async (data) => {
      setPopupLoading(false);
      setPopup(
        <SuccessPopup
          name={data.name}
          pic={data.image}
          startDate={data.start_date}
          id={data._id}
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  // Update course
  const update = async (courseId) =>
    await fetchApi("courses", `/course/${courseId}`, {
      method: "PUT",
      auth: true,
      data: await buildFormData()
    });

  // Update course mutation
  const { isLoading: isLoadingUpdate, mutate: handleUpdate } = useMutation({
    mutationKey: ["update-course", { id: courseId }],
    mutationFn: async () => {
      setPopupLoading(true);
      return await update(courseId);
    },
    onSuccess: async (data) => {
      setPopupLoading(false);
      setPopup(
        <SuccessPopup
          name={data.name}
          pic={data.image}
          startDate={data.start_date}
          id={data._id}
          isEdit
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <div ref={pageRef}>
      <FormWrapper>
        <FormSteps
          title={title}
          steps={formSteps}
          currentStep={currentStep}
          finishedSteps={finishedSteps}
          setCurrentStep={(step: number) => setCurrentStep(step)}
        />
        <div className={classes["form-layout"]}>
          <div className={classes["title"]}>{formSteps[currentStep]}</div>
          <div className={classes["body"]}>{renderStep(currentStep)}</div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default CourseForm;
