import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoLight from "assets/images/logo-light.svg";
import logoDark from "assets/images/logo-dark.svg";
import logoDarkAdmin from "assets/images/logo-admin-dark.svg";
import logoLightAdmin from "assets/images/logo-admin-light.svg";
import logo from "assets/images/logo.svg";
import { themeContext } from "context/themeContext";
import { userContext } from "context/userContext";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import User from "./user/User";
import Connect from "./connect/Connect";
import classes from "./Header.module.scss";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import MobileMenu from "components/sidebar/mobile/MobileMenu";
import useComponentVisible from "hooks/useComponentVisible";
import ThemeSwitcher from "../theme-switcher/ThemeSwitcher";

export interface IHeaderProps {
  variant: "only-logo" | "only-buttons" | "both";
  withBreadcrumbs: boolean;
  breadcrumbSkips?: number[];
  isTransparent: boolean;
  isFullWidth: boolean;
}

const Header = (props: IHeaderProps) => {
  const { theme } = useContext(themeContext);
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};
  const navigate = useNavigate();
  const {
    variant,
    withBreadcrumbs,
    breadcrumbSkips,
    isTransparent,
    isFullWidth
  } = props;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  // Lock background scroll on popups
  useEffect(() => {
    if (isComponentVisible) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "initial";
  }, [isComponentVisible]);

  return (
    <>
      <div
        className={`${classes["header-wrapper"]} ${
          classes[!userData ? "no-auth" : ""]
        } ${isTransparent ? classes["transparent"] : ""} ${
          isFullWidth ? classes["full-width"] : ""
        }`}
      >
        <div className={classes["logo-and-nav-container"]}>
          {(variant === "only-logo" || variant === "both") && (
            <img
              className={classes["logo"]}
              src={theme === "dark" ? logoLight : logoDark}
              height={56}
              onClick={() => navigate("/school")}
              alt="Logo"
            />
          )}
          {withBreadcrumbs && <Breadcrumbs selectedSkips={breadcrumbSkips} />}
        </div>
        {(variant === "only-buttons" || variant === "both") && (
          <div className={classes["header"]}>
            <User />
            {userData && !is_super_admin && (
              <>
                <div className={classes["divider"]} />
                <Connect />
              </>
            )}
          </div>
        )}
      </div>
      <div
        ref={ref}
        className={`${classes["header-mobile-wrapper"]} ${
          classes[!userData ? "no-auth" : ""]
        } ${classes[isComponentVisible ? "open-bg" : ""]} ${
          isTransparent ? classes["transparent"] : ""
        }`}
      >
        <div className={classes["header-container"]}>
          {(variant === "only-logo" || variant === "both") && (
            <img
              className={classes["logo"]}
              src={
                theme === "dark"
                  ? is_super_admin
                    ? logoLightAdmin
                    : logoLight
                  : is_super_admin
                  ? logoDarkAdmin
                  : logoDark
              }
              height={40}
              onClick={() =>
                navigate(is_super_admin ? "/main-dashboard" : "/school")
              }
              alt="Logo"
            />
          )}
          {!userData && <ThemeSwitcher className={classes["switcher"]} />}
          {userData && (variant === "both" || variant === "only-buttons") && (
            <>
              <Button
                variant="link"
                isIconBtn
                icon={isComponentVisible ? CrossIcon : HamburgerIcon}
                onClick={() => setIsComponentVisible(!isComponentVisible)}
              />
            </>
          )}
        </div>
        {!isComponentVisible && (
          <span
            className={`${classes["breadcrumbs-container"]} ${
              !isComponentVisible ? classes["show-breadcrumbs"] : ""
            }`}
          >
            {withBreadcrumbs && <Breadcrumbs selectedSkips={breadcrumbSkips} />}
          </span>
        )}
        {userData && (
          <div
            className={`${classes["mobile-menu-wrapper"]} ${
              classes[isComponentVisible ? "open" : ""]
            }`}
          >
            <div className={classes["mobile-menu-container"]}>
              <Divider isTransparent={!isComponentVisible} />
              {userData && !is_super_admin && (
                <>
                  <Connect isMobile />
                  <Divider />
                </>
              )}
              <User
                isMobile
                closeMobileMenu={() => setIsComponentVisible(false)}
              />
              <Divider />
              <div className={classes["tabs-container"]}>
                <MobileMenu onClose={() => setIsComponentVisible(false)} />
              </div>
            </div>
          </div>
        )}
      </div>
      {/*
        This is a copy of `header-mobile-wrapper` component in order to adjust page structure with/without breadcrumbs.
        It should be hidden and take same space as the visible header.
        Please update this component on every main header change.
      */}
      <div
        className={`${classes["header-mobile-wrapper"]} ${
          classes["header-mobile-wrapper--relative"]
        } ${classes[!userData ? "no-auth" : ""]}`}
      >
        <div className={classes["header-container"]}>
          <img
            className={classes["logo"]}
            src={
              theme === "dark"
                ? is_super_admin
                  ? logoLightAdmin
                  : logoLight
                : is_super_admin
                  ? logoDarkAdmin
                  : logoDark
            }
            height={40}
            onClick={() =>
              navigate(is_super_admin ? "/main-dashboard" : "/school")
            }
            alt="Logo"
          />
        </div>
        <span
          className={`${classes["breadcrumbs-container"]} ${classes["show-breadcrumbs"]}`}
        >
          {withBreadcrumbs && <Breadcrumbs selectedSkips={breadcrumbSkips} />}
        </span>
      </div>
    </>
  );
};

Header.defaultProps = {
  withBreadcrumbs: false,
  isTransparent: false,
  isFullWidth: false
};

export default Header;
