import React, { useState, useContext } from "react";
import { themeContext } from "context/themeContext";
import styled from "styled-components";
import { ReactComponent as eyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as eyeHiddenIcon } from "assets/icons/eye-hidden.svg";
import { ReactComponent as searchIcon } from "assets/icons/search.svg";
import { ReactComponent as calendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as clockIcon } from "assets/icons/clock.svg";
import clearSearchIcon from "assets/icons/clear.svg";
import "react-datepicker/dist/react-datepicker.css";
import { colors } from "styles";
import PasswordTooltip from "components/tooltip/PasswordTooltip";

interface IInputProps {
  // Functional
  type?:
    | "text"
    | "number"
    | "password"
    | "file"
    | "datepicker"
    | "datepicker-range"
    | "timepicker"
    | "checkbox"
    | "search"
    | "textarea"
    | "email"
    | "url";
  value?: any;
  onChange: any;
  onClick?: () => void;
  isDisabled?: boolean;
  ref?: any; // Used in case type = "date"
  error?: string;
  fileType?: string;
  fileName?: string;
  onClear?: () => void;
  autoFocus?: boolean;

  // Customization of component
  title?: string;
  placeholder?: string;
  showTooltipIcon?: string;
  icon?: any;
  showPasswordTooltip?: boolean;
  showTempPasswordTooltip?: boolean;
  readOnly?: boolean;

  // Used by useForm hook
  name?: string;

  // Styling
  className?: string;
  themeIs?: string;
  maxWidth?: number;
  isHidden?: boolean;
}

interface IInputWrapperProps {
  themeIs: string;
  isDisabled: boolean;
  error: boolean;
}

const SInputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ themeIs, error }) =>
    themeIs === "dark"
      ? !error
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.error100})`
      : !error
      ? `rgb(${colors.lightBlue30})`
      : `rgb(${colors.error10})`};
  border-radius: 16px;
  font-family: Poppins;
  transition: background-color 350ms;
  width: 100%;
  height: 48px;
  box-shadow: ${({ error }) =>
    error ? `0 0 0 1px rgb(${colors.error60})` : ""};
  &:hover {
    transition: none 0s;
    background-color: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? !error
          ? `rgb(${colors.greyishBlue80})`
          : `rgb(${colors.error100})`
        : !error
        ? `rgb(${colors.lightBlue40})`
        : `rgb(${colors.error10})`};
    box-shadow: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? error
          ? `0 0 0 1px rgb(${colors.error60})`
          : `0 0 0 1px rgb(${colors.greyishBlue60})`
        : error
        ? `0 0 0 1px rgb(${colors.error60})`
        : `0 0 0 1px rgb(${colors.lightBlue90})`};
  }
  &:active {
    transition: none 0s;
    background-color: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? !error
          ? `rgb(${colors.greyishBlue70})`
          : `rgb(${colors.error100})`
        : !error
        ? `rgb(${colors.lightBlue50})`
        : `rgb(${colors.error10})`};
  }
  &:focus-within {
    transition: none 0s;
    box-shadow: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? error
          ? `0 0 0 1px rgb(${colors.error60})`
          : `0 0 0 2px rgb(${colors.greyishBlue60})`
        : error
        ? `0 0 0 1px rgb(${colors.error60})`
        : `0 0 0 2px rgb(${colors.lightBlue90})`};
    background-color: ${({ themeIs }) =>
      themeIs === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
  }
  &:disabled: {
    border: unset;
  }
`;

const SInput = styled.input<IInputProps>`
  font-family: Poppins;
  font-size: 14px;
  padding: 13.5px 20px;
  padding-right: ${({ showPasswordTooltip }) =>
    showPasswordTooltip ? "40px" : "20px"};
  padding-left: ${({ type }) =>
    type === "datepicker" || type === "timepicker" || type === "search"
      ? "40px"
      : "20px"};
  border-radius: 16px;
  background-color: ${({ themeIs, error }) =>
    themeIs === "dark"
      ? !error
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.error100})`
      : !error
      ? `rgb(${colors.lightBlue30})`
      : `rgb(${colors.error10})`};
  border: none;
  width: 100%;
  height: 100%;
  transition: background-color 350ms;
  color: ${({ themeIs, disabled }) =>
    themeIs === "dark" && !disabled
      ? `rgb(${colors.lightBlue10})`
      : themeIs === "dark" && disabled
      ? `rgb(${colors.greyishBlue70})`
      : themeIs !== "dark" && !disabled
      ? `rgb(${colors.darkBlue100})`
      : `rgb(${colors.greyishBlue20})`};
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: url(${clearSearchIcon});
    cursor: pointer;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.lightBlue10})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.greyishBlue20})`};
    caret-color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.lightBlue10})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.greyishBlue20})`};
  }

  ::placeholder {
    color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.greyishBlue50})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.greyishBlue30})`
        : `rgb(${colors.greyishBlue10})`};
    opacity: 1;
  }
  &:focus {
    background-color: ${({ themeIs }) =>
      themeIs === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
    transition: none 0s;
  }
`;

const SRightIconContainer = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

const SLeftIconContainer = styled.div`
  position: absolute;
  left: 16px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

interface ISIconContainer {
  isDisabled: boolean;
  themeIs: "dark" | "light" | string;
}
const SIconContainer = styled.div<ISIconContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-right: 4px;
  background-color: ${({ isDisabled }) =>
    isDisabled ? "transparent" : `rgb(${colors.orange60})`};
  cursor: pointer;
  border: ${({ themeIs, isDisabled }) =>
    themeIs === "dark"
      ? isDisabled
        ? `1px solid rgb(${colors.greyishBlue70})`
        : "unset"
      : isDisabled
      ? `1px solid rgb(${colors.greyishBlue10})`
      : "unset"};
`;

const InputText = (props: IInputProps) => {
  const {
    type,
    onChange,
    onClick,
    icon,
    isDisabled,
    value,
    placeholder,
    error,
    name,
    showPasswordTooltip,
    showTempPasswordTooltip,
    readOnly,
    autoFocus
  } = props;
  const { theme } = useContext(themeContext);
  const [switchType, setSwitchType] = useState<"password" | "text">("password");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  let Icon;
  const renderIcon = () => {
    let size = 16;

    switch (type) {
      case "search":
        Icon = searchIcon;
        size = 20;
        break;
      case "password":
        Icon = switchType === "password" ? eyeIcon : eyeHiddenIcon;
        break;
      case "datepicker":
        Icon = calendarIcon;
        break;
      case "timepicker":
        Icon = clockIcon;
        break;
      default:
        Icon = eyeIcon;
    }
    return (
      <Icon
        width={size}
        height={size}
        fill={
          theme === "dark"
            ? isDisabled
              ? `rgb(${colors.greyishBlue70})`
              : `rgb(${colors.neutral10})`
            : isDisabled
            ? `rgb(${colors.greyishBlue10})`
            : icon
            ? `rgb(${colors.neutral10})`
            : `rgb(${colors.neutral100})`
        }
      />
    );
  };

  return (
    <SInputWrapper
      themeIs={theme}
      isDisabled={isDisabled}
      data-theme={theme}
      error={!!error}
    >
      <SInput
        data-theme={theme}
        type={type === "password" ? switchType : type || "text"}
        onChange={onChange}
        onFocus={() => type === "password" && setIsTooltipVisible(true)}
        onBlur={() => {
          type === "password" && setIsTooltipVisible(false);
        }}
        name={name}
        placeholder={placeholder}
        onClick={onClick}
        themeIs={theme}
        value={value}
        readOnly={readOnly}
        disabled={isDisabled}
        onWheel={type === "number" ? (e) => e.currentTarget.blur() : () => {}}
        autoFocus={autoFocus}
      />

      {type === "password" && isTooltipVisible && showPasswordTooltip && (
        <PasswordTooltip value={value} />
      )}

      {type === "password" && isTooltipVisible && showTempPasswordTooltip && (
        <PasswordTooltip value={value} isTemporaryTooltip />
      )}

      {/* Render Eye Icon for typ = password */}
      {type === "password" && (
        <SRightIconContainer
          onClick={() =>
            setSwitchType(switchType === "password" ? "text" : "password")
          }
        >
          {renderIcon()}
        </SRightIconContainer>
      )}

      {(type === "datepicker" ||
        type === "timepicker" ||
        type === "search") && (
        <SLeftIconContainer>{renderIcon()}</SLeftIconContainer>
      )}

      {/* Render Prop Icon */}
      {icon && (
        <SIconContainer isDisabled={isDisabled} themeIs={theme}>
          {renderIcon()}
        </SIconContainer>
      )}
    </SInputWrapper>
  );
};

InputText.defaultProps = {
  themeIs: "light",
  isDisabled: false
};

export default InputText;
