import React, { createContext, useEffect, useState } from "react";

export interface IToastData {
  type: "info" | "success" | "warning" | "error" | "custom";
  title?: string;
  msg: string;
  isSelfClosing?: boolean;
  withTitle?: boolean;
  icon?: any;
  btn?: React.ReactNode;
  className?: string;
}

interface IAppCtx {
  toastData: IToastData | null;
  setToast?: any;
  clearToast?: any;
}

const defaultState = {
  toastData: null
};

export const toastContext = createContext<IAppCtx>(defaultState);

export function ToastProvider(props) {
  const [toastData, setToast] = useState<any>();

  useEffect(() => {
    if (toastData && toastData.isSelfClosing) {
      let timer = setTimeout(() => setToast(null), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [toastData]);

  function clearToast() {
    setToast(null);
  }

  return (
    <toastContext.Provider value={{ toastData, setToast, clearToast }}>
      {props.children}
    </toastContext.Provider>
  );
}
