import React, { useContext, useEffect, useState } from "react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import MintDiplomasTable from "components/table/MintDiplomasTable";
import { ReactComponent as CrossIcon } from "assets/icons/clear.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import {
  ICourse,
  IMintRequest
} from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./MintRequestsPopup.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import GenericPopup from "components/popups/GenericPopup";
import { fetchApi } from "utils/requests";
import { Web3Context } from "context/web3Context";
import { ethers } from "ethers";
import { diplomaContract } from "contracts/contracts";
import { BLOCK_EXPLORER_URL, MINT_FEE } from "utils/constants";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { Link, useNavigate } from "react-router-dom";
import { buildQueryKey } from "query";
import MintFee from "components/popups/common/MintFee";
import { useAccount } from "wagmi";

interface IMintRequestsTable {
  invalidateParams: string;
  requests?: IMintRequest[];
}

interface ICustomError extends Error {
  cause: { id: string; courses: ICourse[] };
}

const MintRequestsPopup = ({
  invalidateParams,
  requests
}: IMintRequestsTable) => {
  const { theme } = useContext(themeContext);
  const { clearPopup, setPopup } = useContext(popupContext);
  const { web3UserData } = useContext(Web3Context);
  const [requestsInTable, setRequestsInTable] = useState(requests);
  const [isLoading, setIsLoading] = useState<"approve" | "reject" | "">("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { connector: activeConnector } = useAccount();

  const requestIds = requestsInTable.map((item) => item.request_id);
  const mintFee = (requestsInTable.length * MINT_FEE).toFixed(4);

  const checkAllCoursesForTemplates = () => {
    if (requests.every((request) => !!request.course.template_id)) {
      return;
    }
    // Extract each course that does not have its template_id set
    // the second .filter removes the duplicating courses
    const coursesWithoutTemplates = requests
      .filter((request) => !request.course.template_id)
      .map((request) => request.course)
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t._id === value._id && t.name === value.name)
      );
    throw Error(
      `A certificate template must be selected for - ${coursesWithoutTemplates.map(
        (course) => " " + course.name
      )}.`,
      {
        cause: {
          id: "no-course-template",
          courses: coursesWithoutTemplates
        }
      }
    );
  };

  const { mutate: mintDiplomasHandler } = useMutation({
    mutationKey: ["mint-diplomas"],
    mutationFn: async (action: "approve" | "reject") => {
      setIsLoading(action);
      let txReceipt;

      /* Approve selected mint requests */
      if (action === "approve") {
        checkAllCoursesForTemplates();

        if (!web3UserData?.wallet_address)
          throw Error("Please connect your wallet.");

        setPopup(
          <GenericPopup
            type="loading"
            size="sm"
            title={
              action === "approve" ? "Approve Request" : "Rejecting Request"
            }
            msg={
              action === "approve"
                ? "Approve this mint transaction from your wallet."
                : "The request is being rejected."
            }
          />
        );

        // 1. Submit mint to BE
        const response = await fetchApi("courses", `/mint-request/approve`, {
          method: "PUT",
          auth: true,
          data: {
            request_ids: requestIds
          }
        })
          // 2. Use BE response for transaction
          .then(
            async (response: {
              ids: string[];
              signatures: string[];
              to: string[];
              tokensIds: number[];
            }) => {
              if (
                !response.ids.length ||
                !response.to.length ||
                !response.tokensIds.length ||
                !response.signatures.length
              )
                throw Error(
                  "A certificate cannot be minted for the given student(s)."
                );

              let walletProvider;
              await activeConnector.getProvider().then((provider) => {
                walletProvider = provider;
              });

              let provider = new ethers.providers.Web3Provider(
                walletProvider,
                "any"
              );
              let signer = provider.getSigner(web3UserData.wallet_address);

              let contract = new ethers.Contract(
                diplomaContract.address,
                diplomaContract.abi,
                signer
              );
              const fee = ethers.utils.parseEther(mintFee.toString());

              try {
                const tx = await contract[
                  "mintBatch(address[],uint256[],bytes[])"
                ](response.to, response.tokensIds, response.signatures, {
                  value: fee
                });
                const receipt = await tx.wait();
                txReceipt = receipt;
                return receipt;
              } catch (e: any) {
                await fetchApi("courses", `/mint-request/reset`, {
                  method: "PUT",
                  auth: true,
                  data: {
                    request_ids: requestIds
                  }
                });
                if (e.code === "ACTION_REJECTED") {
                  throw Error("Transaction cancelled.");
                }
                if (
                  e?.data?.code === -32000 ||
                  e?.code === "INSUFFICIENT_FUNDS"
                ) {
                  throw Error("Insufficient funds.");
                }
                throw Error(e.message);
              }
            }
          );
        return { txReceipt, action };
      }

      /* Rejectect selected mint requests */
      if (action === "reject") {
        const response = await fetchApi("courses", `/mint-request/reject`, {
          method: "PUT",
          auth: true,
          data: {
            request_ids: requestIds
          }
        });
        return { action };
      }
    },
    onSuccess: (obj) => {
      setIsLoading("");
      setPopup(
        <GenericPopup
          title={`Request ${
            obj.action === "approve" ? "Approved" : "Rejected"
          }`}
          msg={
            obj.action === "approve"
              ? "The certificates are minted."
              : "The request was successfully rejected."
          }
          bellowBtnComp={
            obj.action === "approve" && (
              <Button
                onClick={() =>
                  window.open(
                    `${BLOCK_EXPLORER_URL}/tx/${obj.txReceipt.transactionHash}`,
                    "_blank"
                  )
                }
                icon={LinkIcon}
                variant={"link"}
                isIconBtn
                minWidth="full"
              >
                View in Explorer
              </Button>
            )
          }
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["mint-requests", buildQueryKey(invalidateParams)]
      });
    },
    onError: (err: ICustomError) => {
      setIsLoading("");
      if (err?.cause?.id === "no-course-template") {
        return setPopup(
          <GenericPopup
            type="error"
            msg={
              <div>
                A certificate must be selected first for the following courses:{" "}
                <br />
                {err?.cause?.courses.map((course) => (
                  <>
                    <Link
                      to={`/courses/${course._id}`}
                      style={{ textDecoration: "underline" }}
                      onClick={() => clearPopup()}
                    >
                      {course.name}
                    </Link>
                    <br />
                  </>
                ))}
              </div>
            }
            buttonVariant="contrast"
            buttonAction={() =>
              navigate(`/courses/${err.cause.courses[0]._id}`)
            }
            buttonName={
              err?.cause?.courses.length > 1
                ? "Go to first of list"
                : "Go to course"
            }
            bellowBtnComp={
              <Button
                variant="neutral"
                size="medium"
                minWidth="md"
                onClick={() => clearPopup()}
              >
                Close
              </Button>
            }
          />
        );
      }
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  useEffect(() => {
    if (requestsInTable.length === 0) clearPopup();
  }, [requestsInTable]);

  return (
    <ModalWrapper size="lg">
      <div data-theme={theme} className={classes["mint-request-popup"]}>
        <h4
          className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
        >
          Confirm Mint NFT Certificate
        </h4>
        <div
          className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
        >
          Confirm that you want to send the NFT Certificate to the listed attendees.
          If there is an attendant without a wallet address he’ll receive an
          E-mail informing him that he must set his wallet address in order to
          receive the NFT Certificate.
          <br />
          <br />
          <MintFee fee={mintFee} />
        </div>
        <div className={classes["content"]}>
          <MintDiplomasTable
            type="mint-requests"
            requests={requestsInTable}
            setRequests={setRequestsInTable}
          />
        </div>
        <div className={classes["buttons-container"]}>
          <Button
            className={classes["btn-reject"]}
            variant="outline"
            iconRotate={180}
            onClick={() => mintDiplomasHandler("reject")}
            icon={CrossIcon}
            iconPosition="right"
            isFetching={isLoading === "reject"}
          >
            Reject Mint
          </Button>
          <Button
            className={classes["btn-approve"]}
            icon={CheckCircle}
            iconPosition="right"
            onClick={() => mintDiplomasHandler("approve")}
            isFetching={isLoading === "approve"}
          >
            Approve Mint
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default MintRequestsPopup;
