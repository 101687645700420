import React from "react";
import classes from "./Error.module.scss";

interface IErrorProps {
  text: string;
}

const Error = ({ text }: IErrorProps) => {
  return <div className={classes["c-error"]}>{text}</div>;
};

export default Error;
