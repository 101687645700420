import TooltipComp from "components/tooltip/TooltipComp";
import classes from "../Team.module.scss";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import NoRoles from "assets/images/dashboard/no-roles.png";
import EmptyPage from "components/empty-page/EmptyPage";
import NoResults from "components/no-results/NoResults";
import Table from "components/table/Table";
import React, { useContext, useEffect, useMemo } from "react";
import { TeamCols } from "components/table/TeamCols";
import { userContext } from "context/userContext";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import { windowContext } from "context/windowsContext";
import { IEntityTable } from "utils/commonTypes";
import {
  ITeamData,
  ROLE_FLAG
} from "utils/apiDataTypes/AccountManagementDataTypes";
import Button from "components/buttons/Button";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

interface IMembersTable extends IEntityTable {
  showConfirmPopup: (
    obj: { id: string; active: boolean }[],
    activate: boolean
  ) => void;
  setSelected: (obj: { index: number; id: string; active: boolean }[]) => void;
  showSendEmailPopup: (userId: string) => void;
  data: { data: ITeamData; isLoading: boolean; error: Error };
  schoolId?: string;
}

const MembersTable = ({
  searchValue,
  filterValue,
  showConfirmPopup,
  setSelected,
  showSendEmailPopup,
  page,
  setPage,
  itemsAmountMsg,
  data,
  isPaginationHidden,
  hasNoActions,
  hasViewAll,
  schoolId
}: IMembersTable) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const { userData, tenant } = useContext(userContext);
  const { role_flag } = userData || {};
  const isNotManagerAndReg =
    role_flag !== ROLE_FLAG["MANAGER"] && role_flag !== ROLE_FLAG["REGISTRAR"];

  const setSelectedHandler = (
    ids: { index: number; id: string; active: boolean }[]
  ) => {
    setSelected(ids);
  };

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const teamCols = useMemo(
    () =>
      TeamCols(
        showConfirmPopup,
        userData.id,
        tenant,
        showSendEmailPopup,
        hasNoActions,
        isNotManagerAndReg
      ),
    [userData]
  );

  useEffect(() => {
    setSelected([]);
  }, [page]);

  const { data: membersData, isLoading, error } = data || {};
  const hasData = membersData?.result && membersData.result.length > 0;

  if (isLoading) return <Loader size="lg" hasText />;
  if (error) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );
  }

  return (
    <>
      {hasData && (
        <>
          <Table
            type="team"
            tableData={membersData}
            columns={teamCols}
            hiddenColumns={
              isMobile
                ? ["names", "role_name", "email", "registered"]
                : ["mobile"]
            }
            activePage={page}
            passActivePageUp={activePageHandler}
            itemsAmountMsg={itemsAmountMsg}
            showIs="none"
            passSelectedRowsUp={setSelectedHandler}
            isPaginationHidden={isPaginationHidden}
            schoolId={schoolId}
          />
          {hasViewAll && (
            <Button
              onClick={() => navigate(`/admin-schools/${schoolId}/team`)}
              icon={ArrowIcon}
              iconPosition={"right"}
              variant={"link"}
            >
              View All
            </Button>
          )}
        </>
      )}
      {((!hasData && searchValue && !isLoading) ||
        (!hasData && filterValue.id !== 0 && !isLoading)) && <NoResults />}
      {!hasData && !searchValue && filterValue.id === 0 && !isLoading && (
        <EmptyPage
          title="There is no team yet."
          message={
            <div className={classes["msg"]}>
              Add a new team member and set their role on the platform. There
              are two member types with different administrative rights:{" "}
              <div className={classes["role-types"]}>
                <div className={classes["type"]}>
                  Registrar
                  <TooltipComp
                    id="registrar-tooltip"
                    text="Registrars can invite new team members and students"
                  >
                    <InfoIcon
                      className={classes["info-icon"]}
                      width={16}
                      height={16}
                    />
                  </TooltipComp>
                </div>
                <div className={classes["type"]}>
                  and Course Manager
                  <TooltipComp
                    id="manager-tooltip"
                    text="Course managers can create courses, manage them and check attendance."
                  >
                    <InfoIcon
                      className={classes["info-icon"]}
                      width={16}
                      height={16}
                    />
                  </TooltipComp>
                </div>
              </div>
            </div>
          }
          icon={NoRoles}
          buttonName="Add Member"
          onClick={() => navigate("/team/add-member")}
        />
      )}
    </>
  );
};

export default MembersTable;
