import ActivateUserForm from "./components/ActivateUserForm";
import FormPage from "layouts/form-page";

const ActivateUser = () => {
  return (
    <FormPage headerVariant="only-logo" breadcrumbSkips={[1]}>
      <ActivateUserForm />
    </FormPage>
  );
};

export default ActivateUser;
