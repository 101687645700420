import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Avatar from "components/avatar/Avatar";
import Wallet from "components/wallet/Wallet";
import Status from "components/status/Status";
import RegisteredStatus from "components/status/RegisteredStatus";
import SchoolStatus from "components/status/SchoolStatus";
import StudentSchoolStatus from "components/status/StudentSchoolStatus";
import { FILES_PUBLIC_DOMAIN, IPFS_GATEWAY } from "utils/constants";
import { RequestType } from "utils/apiDataTypes/CourseModuleDataTypes";
import { dateFormatDisplay } from "utils/staticOptions";
import classes from "./TableCols.module.scss";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";
import Card from "routes/dashboard/courses/components/Card";
import { userContext } from "context/userContext";

interface IRenderCell {
  type:
    | "names"
    | "school-name"
    | "email"
    | "wallet"
    | "registration"
    | "diploma"
    | "attendance_status"
    | "school_status"
    | "student_school_status"
    | "menu"
    | "role"
    | "diploma_template"
    | "member_mobile"
    | "attendee_mobile"
    | "student_mobile"
    | "manager_mobile"
    | "diploma_mobile"
    | "request_mobile"
    | "mint_mobile"
    | "school_requests_mobile"
    | "schools_mobile"
    | "request"
    | "schools"
    | "school_course"
    | "ready_to_mint_mobile"
    | "school_course_mobile";
  rowProps: any;
  isLink?: boolean;
  // Attendees table related prop
  isOpen?: boolean;
}

const RenderCell = ({ type, rowProps, isLink, isOpen }: IRenderCell) => {
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};

  const {
    pic,
    names,
    school,
    schoolName,
    email,
    wallet,
    registered,
    active,
    diploma_status,
    attendance_status,
    role_flag,
    role_name,
    name,
    title,
    course,
    request_type,
    requested_by,
    request_date,
    date_requested,
    reg_date,
    custom_diploma_date,
    is_blacklisted,
    user,
    activation_status,
    userId
  } = rowProps;

  let comp;

  switch (type) {
    case "names":
      comp = (
        <div className={classes["names-container"]}>
          <Avatar
            size="sm"
            src={pic}
            isActive={
              ROLE_FLAG["STUDENT"] === role_flag
                ? !is_blacklisted
                : !!((registered && active) || (!registered && !active))
            }
          />
          <div>
            {is_super_admin && isLink ? (
              <Link
                style={{ textDecoration: "underline" }}
                to={`/admin-students/${userId}`}
              >
                {title} {names}
              </Link>
            ) : (
              <div>
                {title} {names}
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "school-name":
      comp = (
        <div className={classes["names-container"]}>
          <Avatar size="sm" src={pic} isActive />
          <div>
            <div>{schoolName}</div>
          </div>
        </div>
      );
      break;
    case "email":
      comp = <div className={classes["email-container"]}>{email}</div>;
      break;
    case "wallet":
      comp = (
        <div>
          <Wallet wallet={wallet} isShort />
        </div>
      );
      break;
    case "registration":
      comp = (
        <div>
          <RegisteredStatus
            status={{ active, registered }}
            isAttendees={Object.keys(rowProps).some(
              (key) => key === "attendance_status"
            )}
          />
        </div>
      );
      break;
    case "diploma":
      comp = (
        <div>
          {diploma_status === 2 ? (
            <a
              className={classes["u-underline"]}
              href={`${IPFS_GATEWAY}${rowProps.ipfs_diploma_id}`}
              target="_blank"
              rel="noreferrer"
            >
              Minted
            </a>
          ) : diploma_status === 1 ? (
            "Pending"
          ) : diploma_status === 3 ? (
            "Returned"
          ) : (
            "-"
          )}
        </div>
      );
      break;
    case "attendance_status":
      comp = (
        <div className={classes["status-container"]}>
          <Status status={attendance_status} />
        </div>
      );
      break;
    case "school_status":
      comp = (
        <div className={classes["status-container"]}>
          <SchoolStatus status={activation_status} />
        </div>
      );
      break;
    case "student_school_status":
      comp = <StudentSchoolStatus is_blacklisted={is_blacklisted} />;
      break;
    case "role":
      comp = <div>{role_name}</div>;
      break;
    case "diploma_template":
      comp = (
        <div className={classes["diploma-row"]}>
          <div className={classes["thumbnail-and-title"]}>
            <img
              src={FILES_PUBLIC_DOMAIN + pic}
              width={200}
              height={150}
              alt="certificate"
            />
            {name}
          </div>
        </div>
      );
      break;
    case "request":
      comp = <div>{course.name}</div>;
      break;

    case "school_course":
      comp = (
        <div className={classes["names-container"]}>
          <Card
            className={classes["course-card"]}
            _id={course.id}
            name={course.name}
            image={course.image}
            isCell
          />
          {course.name}
        </div>
      );
      break;

    case "member_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <Avatar size="sm" src={pic} isActive={active} />
          <div>
            <div className={classes["u-flex"]}>
              <span>
                {title} {names}
              </span>
              <RegisteredStatus
                status={{ active, registered }}
                isAttendees={Object.keys(rowProps).some(
                  (key) => key === "attendance_status"
                )}
                isIconOnly
              />
            </div>
            <div className={classes["u-text--content"]}>{role_name}</div>
            <div className={classes["u-text--content"]}>{email}</div>
          </div>
        </div>
      );
      break;
    case "attendee_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <Avatar size="sm" src={pic} />
          <div>
            <div className={classes["u-flex"]}>
              <span>
                {title} {names}
              </span>
              <Status status={attendance_status} isIcon />
            </div>
            <div className={classes["u-text--content"]}>{email}</div>
            <div className={classes["u-text--content"]}>
              <Wallet wallet={wallet} isShort />
            </div>
            {isOpen && (
              <div className={classes["u-text--content"]}>
                Custom Certificate Date: {custom_diploma_date || "-"}
              </div>
            )}
            <div>
              {diploma_status === 2 ? (
                <a
                  className={classes["u-underline"]}
                  href={`${IPFS_GATEWAY}${rowProps.ipfs_diploma_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Minted
                </a>
              ) : diploma_status === 1 ? (
                "Pending"
              ) : (
                ""
              )}
            </div>
            <div className={classes["u-flex"]}>
              <span>School Status:</span>{" "}
              <StudentSchoolStatus is_blacklisted={is_blacklisted} />
            </div>
          </div>
        </div>
      );
      break;
    case "student_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <Avatar
            size="sm"
            src={pic}
            isActive={is_super_admin ? registered && active : !is_blacklisted}
          />
          <div>
            <div className={classes["u-flex"]}>
              <span>
                {title} {names}
              </span>
              <RegisteredStatus
                status={{ active, registered }}
                isAttendees={Object.keys(rowProps).some(
                  (key) => key === "attendance_status"
                )}
                isIconOnly
              />
            </div>
            <div className={classes["u-text--content"]}>{email}</div>
            <div className={classes["u-flex"]}>
              <span>School Status:</span>
              <StudentSchoolStatus is_blacklisted={is_blacklisted} />
            </div>
          </div>
        </div>
      );
      break;
    case "manager_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <Avatar size="sm" src={pic} />
          <div>
            <div className={classes["u-flex"]}>
              <span>
                {title} {names}
              </span>
            </div>
          </div>
        </div>
      );
      break;
    case "diploma_mobile":
      comp = (
        <div
          className={`${classes["mobile-container"]} ${classes["mobile-container--column"]}`}
        >
          <img
            src={FILES_PUBLIC_DOMAIN + pic}
            width={200}
            height={150}
            alt="certificate"
          />
          <div>
            <div className={classes["u-flex"]}>
              <span>{name}</span>
            </div>
          </div>
        </div>
      );
      break;
    case "request_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <div>
            <div>{course?.name}</div>
            <div className={classes["u-text--content"]}>
              Type: {RequestType[request_type]}
            </div>
            <div className={classes["u-text--content"]}>
              By:{" "}
              <Link
                style={{ textDecoration: "underline" }}
                to={`/students/${requested_by._id}`}
              >
                {`${requested_by.title || ""} ${requested_by.first_name} ${
                  requested_by.last_name
                }`}
              </Link>
            </div>
            <div className={classes["u-text--content"]}>
              {moment(request_date).format(dateFormatDisplay).toString()}
            </div>
          </div>
        </div>
      );
      break;
    case "mint_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <Avatar size="sm" src={pic} />
          <div>
            <div className={classes["u-flex"]}>
              <span>
                {title} {names}
              </span>
            </div>
            <div className={classes["u-text--content"]}>
              <Wallet wallet={wallet} isShort />
            </div>
            <div
              className={`${classes["u-text--content"]} ${classes["u-semibold"]}`}
            >
              {/* ?. is required for MintDiplomasPopup, because we don't pass the course data */}
              {course?.name}
            </div>
          </div>
        </div>
      );
      break;
    case "school_requests_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <div>
            <div className={classes["names-container"]}>
              <Avatar size="sm" src={school.pic} />
              <div>
                <div>{schoolName}</div>
              </div>
            </div>
            <div className={classes["u-text--content"]}>
              {moment(date_requested).format(dateFormatDisplay).toString()}
            </div>
            <div>{requested_by}</div>
          </div>
        </div>
      );
      break;
    case "schools_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <div>
            <div className={classes["names-container"]}>
              <Avatar size="sm" src={pic} isActive />
              <div>
                <div>{schoolName}</div>
              </div>
            </div>
            <div className={classes["u-text--content"]}>
              Registration date:{" "}
              {moment(reg_date).format(dateFormatDisplay).toString()}
            </div>
            <div className={classes["u-flex"]}>
              <span className={classes["u-text--content"]}>Status:</span>
              <SchoolStatus status={activation_status} />
            </div>
            {/* <div className={classes["u-text--content"]}>
              Payment due:{" "}
              {moment(payment_due).format(dateFormatDisplay).toString()}
            </div> */}
          </div>
        </div>
      );
      break;
    case "school_course_mobile":
      const { start_date, end_date } = course;
      let element = <div>Open Course</div>;
      if (!!start_date || !!end_date) {
        element = (
          <>
            <div>
              <span className={classes["u-text--content"]}>Start Date: </span>
              {moment(start_date).format(dateFormatDisplay).toString()}
            </div>
            <div>
              <span className={classes["u-text--content"]}>End Date: </span>
              {moment(end_date).format(dateFormatDisplay).toString()}
            </div>
          </>
        );
      }

      comp = (
        <div className={classes["school-course-mobile"]}>
          <Card
            _id={course.id}
            name={course.name}
            image={course.image}
            isCell
            className={classes["school-course-card"]}
          />
          <div>
            <div>
              <span className={classes["u-text--content"]}>Course Name: </span>
              {course.name}
            </div>
            {element}
          </div>
        </div>
      );
      break;
    case "ready_to_mint_mobile":
      comp = (
        <div className={classes["mobile-container"]}>
          <div>
            <div>{course?.name}</div>
            <div className={classes["u-text--content"]}>
              <Link
                style={{ textDecoration: "underline" }}
                to={`/students/${user._id}`}
              >
                {`${user.title || ""} ${user.first_name} ${user.last_name}`}
              </Link>
              <div>{user.email}</div>
            </div>
          </div>
        </div>
      );
  }
  return comp;
};

export default RenderCell;
