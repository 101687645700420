import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import { IToastData, toastContext } from "context/toastContext";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import Button from "components/buttons/Button";
import classes from "./Toast.module.scss";
import { colors } from "styles";

export function Toast(props: IToastData) {
  const { theme } = useContext(themeContext);
  const { clearToast } = useContext(toastContext);

  let Icon;
  const renderIcon = () => {
    let fill = "";
    switch (props.type) {
      case "success":
        Icon = CheckIcon;
        fill = `${
          theme === "dark"
            ? `rgb(${colors.success40})`
            : `rgb(${colors.success70})`
        }`;
        break;
      case "warning":
        Icon = WarningIcon;
        fill = `${
          theme === "dark"
            ? `rgb(${colors.darkBlue100})`
            : `rgb(${colors.warning70})`
        }`;
        break;
      case "error":
        Icon = WarningIcon;
        fill = `${
          theme === "dark" ? `rgb(${colors.error50})` : `rgb(${colors.error70})`
        }`;
        break;
      case "info":
        Icon = InfoIcon;
        fill = `${
          theme === "dark"
            ? `rgb(${colors.lightBlue100})`
            : `rgb(${colors.darkBlue10})`
        }`;
        break;
      default:
        Icon = props.icon;
        fill = `${
          theme === "dark"
            ? `rgb(${colors.lightBlue10})`
            : `rgb(${colors.darkBlue100})`
        }`;
        break;
    }
    return <Icon width={24} height={24} fill={fill} />;
  };

  const renderTitle = () => {
    // Set title by type
    let title;
    switch (props.type) {
      case "success":
        title = "Success!";
        break;
      case "warning":
        title = "Warning!";
        break;
      case "error":
        title = "Error!";
        break;
      case "info":
        title = "Info!";
        break;
      default:
        title = props.title;
        break;
    }
    return title;
  };

  return (
    <div
      className={`${classes["toast-wrapper"]}`}
      data-theme={theme}
      onClick={clearToast}
    >
      <div
        className={`${classes["toast-content"]} ${classes[props.type]} ${
          classes[props.className]
        }`}
      >
        <div className={`${classes["msg-wrapper"]}`}>
          <span className={classes["icon-container"]}>{renderIcon()}</span>
          <div className={classes["text-wrapper"]}>
            {props.withTitle && (
              <div className={classes["title"]}>{renderTitle()}</div>
            )}
            <p className={classes["msg"]}>{props.msg}</p>
          </div>
          {props.btn || null}
        </div>
        <Button variant={"link"} size="medium" isIconBtn icon={CloseIcon} />
      </div>
    </div>
  );
}
