import React, { useContext, useMemo, useState } from "react";
import Table from "components/table/Table";
import { MintDiplomaCols } from "components/table/MintDiplomaCols";
import {
  IMintRequest,
  IReadyToMint,
  IStudent
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { windowContext } from "context/windowsContext";
import { MintRequestCols } from "./MintRequestCols";
import { ReadyToMintPopupCols } from "./ReadyToMintPopupCols";

interface IMintDiplomasTable {
  students?: IStudent[];
  setStudents?: (students: IStudent[]) => void;
  type?: "mint-diplomas" | "mint-requests" | "ready-to-mint-popup-table";
  requests?: IMintRequest[];
  setRequests?: (requests: IMintRequest[]) => void;
  readyToMintItems?: IReadyToMint[];
  setReadyToMintItems?: (items: IReadyToMint[]) => void;
}

const MintDiplomasTable = ({
  students,
  setStudents,
  type,
  requests,
  setRequests,
  readyToMintItems,
  setReadyToMintItems
}: IMintDiplomasTable) => {
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const [page, setPage] = useState(0);

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const mintCols = useMemo(
    () =>
      type === "mint-requests"
        ? MintRequestCols(requests, setRequests)
        : type === "mint-diplomas"
        ? MintDiplomaCols(students, setStudents)
        : ReadyToMintPopupCols(readyToMintItems, setReadyToMintItems),
    [requests, students, readyToMintItems]
  );

  if (type === "mint-diplomas") {
    return (
      <>
        {students.length && (
          <Table
            type={type}
            tableData={students}
            columns={mintCols}
            hiddenColumns={isMobile ? ["names", "wallet_address"] : ["mobile"]}
            activePage={page}
            passActivePageUp={activePageHandler}
            className="table-sm"
          />
        )}
      </>
    );
  }

  if (type === "mint-requests") {
    return (
      <>
        {requests?.length && (
          <Table
            type={type}
            tableData={requests}
            columns={mintCols}
            hiddenColumns={
              isMobile ? ["names", "wallet_address", "course"] : ["mobile"]
            }
            activePage={page}
            passActivePageUp={activePageHandler}
            className="table-sm"
          />
        )}
      </>
    );
  }

  return (
    <>
      {readyToMintItems?.length && (
        <Table
          type={type}
          tableData={readyToMintItems}
          columns={mintCols}
          hiddenColumns={
            isMobile ? ["names", "wallet_address", "course_name"] : ["mobile"]
          }
          activePage={page}
          passActivePageUp={activePageHandler}
          className="table-sm"
        />
      )}
    </>
  );
};

MintDiplomasTable.defaultProps = {
  type: "mint-diplomas"
};

export default MintDiplomasTable;
