import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { popupContext } from "context/popupContext";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import Table from "components/table/Table";
import { AttendeesCols } from "components/table/AttendeesCols";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import NoResults from "components/no-results/NoResults";
import AddMoreAttendeesPopup from "./AddMoreAttendeesPopup";
import { IStudentsData } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./AttendeesTable.module.scss";
import {
  ATTENDANCE_STATUS,
  DIPLOMA_STATUS
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { IEntityTable } from "utils/commonTypes";
import PermissionsGate from "roles/PermissionsGate";
import { windowContext } from "context/windowsContext";

interface IStudentTable extends IEntityTable {
  setSelected: (
    selected: {
      index: number;
      id: string;
      attendance_status?: ATTENDANCE_STATUS;
      wallet?: string;
      diploma_status?: DIPLOMA_STATUS;
    }[]
  ) => void;
  showConfirmPopup: (obj) => void;
  getPreviewDiplomaLink: () => void;

  perPage: number;
  setPerPage: (page: number) => void;
  showIs?: "Show All" | "Show Pages" | "none";
  isTemplateSelected: boolean;
  isOpen: boolean;
  data: { data: IStudentsData; isLoading: boolean; error: Error };
}

const AttendeesTable = ({
  filterValue,
  setSelected,
  showConfirmPopup,
  getPreviewDiplomaLink,
  page,
  setPage,
  perPage,
  setPerPage,
  itemsAmountMsg,
  showIs,
  isTemplateSelected,
  isOpen,
  data,
  hasNoActions
}: IStudentTable) => {
  let { courseId } = useParams();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const { setPopup } = useContext(popupContext);

  const setSelectedHandler = (
    ids: {
      index: number;
      id: string;
      attendance_status?: ATTENDANCE_STATUS;
      wallet?: string;
      diploma_status?: DIPLOMA_STATUS;
    }[]
  ) => {
    setSelected(ids);
  };

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const attendeesCols = useMemo(
    () =>
      AttendeesCols(
        showConfirmPopup,
        isTemplateSelected,
        getPreviewDiplomaLink,
        isOpen,
        hasNoActions
      ),
    [isTemplateSelected, showConfirmPopup, getPreviewDiplomaLink]
  );

  useEffect(() => {
    setSelected([]);
  }, [page]);

  const { data: attendeesData, isLoading, error } = data || {};

  const hasData = attendeesData?.result && attendeesData.result.length > 0;

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) return <ErrorComponent error={error} />;

  return (
    <>
      {hasData && (
        <>
          <Table
            type="attendees"
            tableData={attendeesData}
            columns={attendeesCols}
            hiddenColumns={
              isMobile
                ? [
                    "names",
                    "email",
                    "wallet",
                    "registered",
                    "student_school_status",
                    "custom_diploma_date",
                    "diploma",
                    "attendance_status"
                  ]
                : ["mobile", !isOpen ? "custom_diploma_date" : null]
            }
            activePage={page}
            passActivePageUp={activePageHandler}
            itemsAmountMsg={itemsAmountMsg}
            perPage={perPage}
            setPerPage={setPerPage}
            showIs={showIs}
            passSelectedRowsUp={setSelectedHandler}
          />
        </>
      )}
      {!hasData && filterValue.id !== 0 && !isLoading && <NoResults />}
      {!hasData && filterValue.id === 0 && !isLoading && (
        <div className={`${classes["empty-state"]}`}>
          <Divider orientation="horizontal" />
          <div
            className={`${classes["u-text--small"]} ${classes["u-text--content"]}`}
          >
            No Attendees added yet
          </div>
          <PermissionsGate permissions={["STUDENT.CREATE"]}>
            <Button
              variant="neutral"
              onClick={() =>
                setPopup(
                  <AddMoreAttendeesPopup courseId={courseId} isOpen={isOpen} />
                )
              }
              icon={PlusIcon}
            >
              Add Attendees
            </Button>
          </PermissionsGate>
        </div>
      )}
    </>
  );
};

export default AttendeesTable;
