import React, { useContext } from "react";
import * as Yup from "yup";
import { useFormData } from "hooks/useFormData";
import { InputField } from "components/input/Input";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import classes from "./StudentForm.module.scss";
import { yupSchemas } from "utils/yupSchemas";
import { hasErrors } from "utils/form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "utils/requests";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { titleOptions } from "utils/staticOptions";
import { IStudentFormData } from "utils/apiDataTypes/CourseModuleDataTypes";

const validationSchema: Yup.SchemaOf<IStudentFormData> = Yup.object().shape({
  title: yupSchemas.labelAndValue.nullable(),
  first_name: yupSchemas.requiredStr,
  last_name: yupSchemas.requiredStr,
  email: yupSchemas.email
});

interface IStudentForm {
  defaultFormData: IStudentFormData;
  studentId?: string;
}

const StudentForm = ({ defaultFormData, studentId }: IStudentForm) => {
  const { formData, handleChange, setFormData, formErrors } = useFormData(
    defaultFormData,
    validationSchema
  );
  const { setPopup } = useContext(popupContext);

  const queryClient = useQueryClient();

  const buildFormData = async () => ({
    ...formData,
    title: formData.title?.value || null
  });

  const { isLoading: isLoadingUpdate, mutate: handleUpdate } = useMutation({
    mutationKey: ["update-student", { id: studentId }],
    mutationFn: async (e: HTMLFormElement) => {
      e.preventDefault();
      await fetchApi("courses", `/school/students/${studentId}`, {
        method: "PUT",
        auth: true,
        data: await buildFormData()
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["students-data", { id: studentId }]
      });
      setPopup(
        <GenericPopup
          type="success"
          msg="Student's data successfully edited!"
          redirectPath={`/students/${studentId}`}
          isClosable={false}
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) =>
      setPopup(<GenericPopup type="error" msg={err.message} />)
  });

  return (
    <form className={classes["form-layout"]}>
      <ReactSelectWrapper
        title="Title"
        name="title"
        placeholder="Choose Title"
        value={formData.title}
        error={formErrors.title.value}
        options={titleOptions.map((name) => {
          return { label: name, value: name };
        })}
        onChange={(e) =>
          handleChange({
            target: {
              name: "title",
              value: e
            }
          })
        }
        isClearable
      />
      <InputField
        title="First Name*"
        name="first_name"
        value={formData.first_name}
        error={formErrors.first_name}
        onChange={handleChange}
        placeholder="Enter First Name"
      />
      <InputField
        title="Last Name*"
        name="last_name"
        value={formData.last_name}
        error={formErrors.last_name}
        onChange={handleChange}
        placeholder="Enter Last Name"
      />
      <InputField
        title="E-mail*"
        name="email"
        value={formData.email}
        error={formErrors.email}
        onChange={handleChange}
        placeholder="Enter Email"
        isDisabled
      />
      <Button
        type="submit"
        minWidth="md"
        onClick={handleUpdate}
        icon={CheckIcon}
        iconPosition="right"
        isDisabled={
          hasErrors(formErrors) ||
          !formData.email ||
          !formData.first_name ||
          !formData.last_name
        }
        isFetching={isLoadingUpdate}
      >
        Save Changes
      </Button>
    </form>
  );
};

export default StudentForm;
