import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import classes from "./FormPage.module.scss";
import Header from "components/header/Header";
import ThemeSwitcher from "components/theme-switcher/ThemeSwitcher";

export interface IFormPageWrapper {
  children: React.ReactNode;
  headerVariant: "only-logo" | "only-buttons" | "both";
  withBreadcrumbs: boolean;
  breadcrumbSkips?: number[];
}

const FormPage = (props: IFormPageWrapper) => {
  const { theme } = useContext(themeContext);
  const { children, headerVariant, withBreadcrumbs, breadcrumbSkips } = props;

  return (
    <div className={classes["wrapper"]} data-theme={theme}>
      <Header
        variant={headerVariant}
        withBreadcrumbs={withBreadcrumbs}
        breadcrumbSkips={breadcrumbSkips}
        isFullWidth
      />
      <div className={classes["container"]}>{children}</div>
      <ThemeSwitcher className={classes["switcher"]} />
    </div>
  );
};

FormPage.defaultProps = {
  withBreadcrumbs: false
};

export default FormPage;
