import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import PopupTitleAndMessage from "components/popups/common/PopupTitleAndMessage";
import { InputField } from "components/input/Input";
import Button from "components/buttons/Button";
import { useFormData } from "hooks/useFormData";
import { hasErrors } from "utils/form";
import classes from "./RejectPopup.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "utils/requests";
import GenericPopup from "components/popups/GenericPopup";
import { popupContext } from "context/popupContext";

interface IRejectPopup {
  message: string;
}

const defaultFormData: IRejectPopup = {
  message: ""
};

const validationSchema: Yup.SchemaOf<IRejectPopup> = Yup.object().shape({
  message: Yup.string()
});

interface IRejectPopupProps {
  schoolId: string;
  requestId?: string;
}

const RejectPopup = ({ schoolId, requestId }: IRejectPopupProps) => {
  const { theme } = useContext(themeContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setPopup } = useContext(popupContext);

  const { formData, handleChange, formErrors } = useFormData(
    defaultFormData,
    validationSchema
  );

  const {
    isLoading: rejectRegistrationRequestIsLoading,
    mutate: rejectRegistrationRequest
  } = useMutation({
    mutationKey: ["reject-registration-request"],
    mutationFn: async () => {
      const response = await fetchApi(
        "courses",
        "/admin/registration-request/reject",
        {
          method: "PUT",
          auth: true,
          data: {
            request_ids: [requestId],
            note: formData.message
          }
        }
      );
      return response;
    },
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: ["schools-data", { id: schoolId }]
      });
      navigate("/admin-registration-requests");
      setPopup(<GenericPopup type="success" msg={message} />);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  const { isLoading: changeStatusIsLoading, mutate: changeStatusMutation } =
    useMutation({
      mutationKey: ["change-school-status"],
      mutationFn: async () => {
        const resp = await fetchApi(
          "courses",
          `/admin/schools/${schoolId}/deactivate`,
          {
            method: "PUT",
            auth: true,
            data: {
              deactivate_note: formData.message
            }
          }
        );
        return resp;
      },
      onSuccess: ({ message }) => {
        queryClient.invalidateQueries({
          queryKey: ["schools-data"]
        });
        queryClient.invalidateQueries({
          queryKey: ["team-data", { id: schoolId, limit: "3", page: "1" }]
        });
        setPopup(<GenericPopup type="success" msg={message} />);
      },
      onError: (e: Error) => {
        setPopup(<GenericPopup type="error" msg={e.message} />);
      }
    });

  return (
    <ModalWrapper size="md">
      <div data-theme={theme}>
        <div className={classes["reject-popup"]}>
          <PopupTitleAndMessage
            title={
              requestId ? "Reject Registration Request" : "Deactivate School"
            }
            msg={`You are about to ${
              requestId ? "reject" : "deactivate"
            } a school ${
              requestId ? "registration request" : ""
            }. Please include a reason for your action below.`}
          />
          <div className={classes["message-container"]}>
            <InputField
              type="textarea"
              title={`${requestId ? "Reject" : "Deactivation"} Reason`}
              name="message"
              placeholder={`Enter ${
                requestId ? "Reject" : "Deactivation"
              } Reason`}
              onChange={handleChange}
              value={formData.message}
              error={formErrors.message}
            />
            <Button
              minWidth={"sm"}
              variant={"outline"}
              onClick={
                requestId ? rejectRegistrationRequest : changeStatusMutation
              }
              isFetching={
                requestId
                  ? rejectRegistrationRequestIsLoading
                  : changeStatusIsLoading
              }
              isDanger
              isDisabled={hasErrors(formErrors)}
            >
              {requestId ? "Reject Registration Request" : "Deactivate School"}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RejectPopup;
