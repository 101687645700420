import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { useMutationQuery } from "hooks/useMutationQuery";
import { connectWalletMutation, disconnectWalletMutation } from "query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { CHAIN_ID } from "utils/constants";
import { getExchangeRate } from "utils/requests";
import { popupContext } from "./popupContext";
import { userContext } from "./userContext";
import { formatAddress } from "utils/format";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import WalletAddrBtn from "components/wallet/Wallet";
import classes from "App.module.scss";
import {
  useAccount,
  useNetwork,
  useConnect,
  useDisconnect,
  useBalance
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import Wallet from "components/wallet/Wallet";

interface IWeb3Context {
  web3UserData: {
    wallet_address: string;
    balanceInMatic: number;
    balanceInUsd: number;
  };
  setWeb3User: (web3UserData: IWeb3Context["web3UserData"]) => void;
  clearWeb3User: () => void;
  usdPrice?: number;
}

export const Web3Context = createContext<IWeb3Context>(null);

export const Web3Provider = (props: any) => {
  const [web3UserData, setWeb3UserData] =
    useState<IWeb3Context["web3UserData"]>();
  const [usdPrice, setUsdPrice] = useState(0);
  const { userData, setUser } = useContext(userContext);
  const { setPopup, clearPopup } = useContext(popupContext);
  const queryClient = useQueryClient();

  const { address, connector: activeConnector } = useAccount();
  const { chain } = useNetwork();
  const { connectAsync } = useConnect({
    connector: new InjectedConnector()
  });
  const { disconnect } = useDisconnect();
  const { data: balance } = useBalance({ address: address });

  const setWeb3User = useCallback(
    (web3User: IWeb3Context["web3UserData"]) => {
      setWeb3UserData(web3User);
    },
    [setWeb3UserData]
  );

  const clearWeb3User = useCallback(() => {
    setWeb3UserData(null);
  }, [setWeb3UserData]);

  const { mutate: connectWallet } = useMutation<any, any, any, any>(
    connectWalletMutation(userData?.is_student, (resp) => {
      queryClient.invalidateQueries({ queryKey: ["me-data"] });
      setUser((prevState) => ({ ...prevState, wallet: resp.wallet }));
    })
  );
  const { mutate: disconnectWallet } = useMutationQuery(
    disconnectWalletMutation(userData?.is_student, () => {
      queryClient.invalidateQueries({ queryKey: ["me-data"] });
      setUser((prevState) => ({ ...prevState, wallet: "" }));
    })
  );

  const verifyChain = () => {
    let isChainValid = false;
    if (Number(chain?.id) === Number(CHAIN_ID)) {
      isChainValid = true;
    }
    return isChainValid;
  };

  /* Update user context with wallet info */
  const updateWalletInfo = async (account: string) => {
    if (verifyChain()) {
      setWeb3UserData({
        wallet_address: account,
        balanceInMatic: +(+balance?.formatted).toFixed(4),
        balanceInUsd: +(+balance?.formatted * usdPrice).toFixed(2)
      });
    }
  };

  const renderWrongNetworkPopup = async () => {
    setPopup(
      <GenericPopup
        type="error"
        title="Check your network"
        msg="Please switch your network to continue"
        buttonName="Switch network in wallet"
        buttonVariant="contrast"
        clearPopupOnBtnAction={false}
        isClosable={false}
        buttonAction={async () => {
          try {
            let walletProvider;
            await activeConnector.getProvider().then((provider) => {
              walletProvider = provider;
            });
            await walletProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: CHAIN_ID }]
            });
            setWeb3UserData({
              wallet_address: address,
              balanceInMatic: +(+balance?.formatted).toFixed(4),
              balanceInUsd: +(+balance?.formatted * usdPrice).toFixed(2)
            });
            clearPopup();
          } catch (e) {
            console.log(e);
          }
        }}
        bellowBtnComp={
          <Button
            variant="link"
            onClick={() => {
              clearWeb3User();
              clearPopup();
              disconnect();
              localStorage.setItem("isWalletConnected", "false");
            }}
          >
            Disconnect Wallet
          </Button>
        }
      />
    );
  };

  /* Handle wallet accounts switch and disconnect */
  useEffect(() => {
    const handleSwitch = () => {
      if (!!userData?.email) {
        // Wallet connected
        if (address) {
          updateWalletInfo(address);
          // if the user is a student, their wallet from the database was fetched and it is different from
          // the connected wallet, then throw a popup if they want to change the address associated with their profile.
          if (
            !!userData?.is_student &&
            address !== userData?.wallet &&
            userData?.wallet !== "none" &&
            !userData?.is_super_admin
          ) {
            setPopup(
              <GenericPopup
                type="info"
                title="Change Wallet Address?"
                msg={
                  <span
                    className={`${classes["u-flex-col--centered"]} ${classes["u-gap-8"]}`}
                  >
                    <div>
                      Do you want to link this wallet address to your profile?
                      Course completion certificates will be minted to this address:
                    </div>
                    <div>
                      Current Profile Address:
                      <WalletAddrBtn
                        wallet={userData?.wallet}
                        isShort
                        className={`${classes["u-text--large"]} ${classes["u-semibold"]} ${classes["u-text--primary"]}`}
                      />
                    </div>
                    <div>
                      New Address:
                      <WalletAddrBtn
                        wallet={address}
                        isShort
                        className={`${classes["u-text--large"]} ${classes["u-semibold"]} ${classes["u-text--primary"]}`}
                      />
                    </div>
                  </span>
                }
                buttonName="Confirm"
                buttonAction={() => {
                  connectWallet(address);
                  setPopup(
                    <GenericPopup
                      type="success"
                      title="Connected!"
                      msg={
                        <span>
                          You have successfully connected your wallet. Your
                          profile wallet address is now <br />
                          <b>{formatAddress(address)}</b>
                        </span>
                      }
                    />
                  );
                }}
                buttonVariant="contrast"
                bellowBtnComp={
                  <Button
                    variant="link"
                    size="medium"
                    onClick={() => clearPopup()}
                  >
                    Cancel
                  </Button>
                }
              />
            );
          }
          // If the user is a member and their connected wallet address is NOT the same
          // the one associated with their profile, update it.
          if (
            !userData?.is_student &&
            userData.wallet !== address &&
            !userData?.is_super_admin
          ) {
            connectWallet(address);
          }
        }
        // Wallet disconnected
        else {
          // If user is a team member and they have an address (meaning they have not been disconnected yet)
          // then disconnect their wallet. Students do not get their wallet disconnected.
          if (
            !userData?.is_student &&
            userData?.wallet &&
            // Wallet is "none" after successful login AND before userData is set from /me
            userData?.wallet !== "none" &&
            !userData?.is_super_admin
          )
            disconnectWallet();
          clearWeb3User();
          localStorage.setItem("isWalletConnected", "false");
        }
      }
    };
    // Do NOT handle wallet logic on homepage and if there is not active connector
    if (window.location.pathname !== "/") {
      handleSwitch();
    }
  }, [address, userData?.email, userData?.wallet]);

  useEffect(() => {
    if (!!userData?.is_student && !!userData?.wallet && !activeConnector)
      setPopup(
        <GenericPopup
          type="info"
          title="Disconnected!"
          msg={
            <span className={classes["u-flex-col--centered"]}>
              {userData?.wallet ? (
                <>
                  <span>
                    Please note that a wallet is still associated with your
                    profile. Your certificates will be minted to this address.
                  </span>
                  <br />
                  <Wallet
                    className={`${classes["u-justify--center"]}  ${classes["u-text--large"]} ${classes["u-semibold"]} ${classes["u-text--primary"]}`}
                    wallet={formatAddress(userData?.wallet)}
                  />
                </>
              ) : (
                "there is no wallet associated with your profile. You cannot receive certificates without a specified wallet address."
              )}
            </span>
          }
        />
      );
  }, [activeConnector]);

  /* 1. Automatically connect wallet 
     2. Fetch and set the usd price */
  useEffect(() => {
    getExchangeRate().then((res) => {
      setUsdPrice(res["matic-network"].usd);
    });
    const connectWallet = async () => {
      try {
        await connectAsync();
      } catch (e) {
        console.log(e);
      }
    };
    if (
      localStorage.getItem("isWalletConnected") === "true" &&
      window.location.pathname !== "/"
    ) {
      connectWallet();
    }
  }, []);

  /* Detecting wrong chains */
  useEffect(() => {
    if (
      !!userData?.email &&
      !!chain?.id &&
      Number(CHAIN_ID) !== Number(chain?.id) &&
      !!activeConnector
    ) {
      renderWrongNetworkPopup();
    }
  }, [chain?.id, userData?.email, activeConnector]);

  return (
    <Web3Context.Provider
      value={{ web3UserData, setWeb3User, clearWeb3User, usdPrice }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
