import { IAGD, IInstructor } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./renderComponent.module.scss";

export const renderAGD = (AGD: IAGD[]) => {
  const hasAGD = AGD && AGD.length > 0;

  if (!hasAGD) return <div className={classes["detail-desc"]}>-</div>;

  return AGD.map((d, i) => (
    <div key={`${d.code}-${i}`} className={classes["detail-desc"]}>{`${
      d.code
    } ${d.hours ? ` - ${d.hours}` : ""} ${
      d.description ? ` - ${d.description}` : ""
    }  ${d.type ? ` - ${d.type}` : ""}`}</div>
  ));
};

export const renderInstructors = (
  isPrimary: boolean,
  instructors: IInstructor[]
) => {
  const instructorsArr = instructors.filter((d) => d.primary === isPrimary);
  const hasInstructors = instructorsArr.length > 0;

  if (!hasInstructors) return <div className={classes["detail-desc"]}>-</div>;

  return instructorsArr.map((d, i) => (
    <div
      key={`${d.first_name} ${d.last_name}-${i}`}
      className={classes["detail-desc"]}
    >
      {/* Hide all `Instructor Degree` related logic */}
      {`${d.title} ${d.first_name} ${d.last_name}`}
    </div>
  ));
};
