import React, { FormEvent, useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormData } from "hooks/useFormData";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { popupContext } from "context/popupContext";
import { ReactComponent as CheckIconCircle } from "assets/icons/check-circle.svg";
import GenericPopup from "components/popups/GenericPopup";
import { InputField } from "components/input/Input";
import ImageInput from "components/image-input/ImageInput";
import Button from "components/buttons/Button";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import classes from "../EditSchool.module.scss";
import { fetchApi } from "utils/requests";
import { citiesDataQuery, countriesDataQuery, schoolDataQuery } from "query";
import {
  ICity,
  ICountry,
  ISchoolInfo,
  ISchoolInfoForm
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { yupSchemas } from "utils/yupSchemas";
import { hasErrors } from "utils/form";
import { fileInstructions } from "utils/staticOptions";
import { getFileName, getFileNameFromUrl } from "utils/format";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";

const validationSchema: Yup.SchemaOf<
  Omit<
    ISchoolInfoForm,
    "tenant" | "is_blacklisted" | "deactivate_note" | "reg_date"
  >
> = Yup.object().shape({
  name: yupSchemas.requiredStr,
  country: yupSchemas.labelAndValue.required(),
  state: yupSchemas.labelAndValue.required(),
  city: yupSchemas.labelAndValue.required(),
  zip: Yup.string(),
  addr1: yupSchemas.requiredStr,
  addr2: Yup.string(),
  phone: yupSchemas.phone,
  fax: yupSchemas.fax,
  website: yupSchemas.website,
  description: Yup.string(),
  CERP: Yup.string(),
  CERP_provider_number: Yup.string(),
  PACE: Yup.string(),
  PACE_provider_number: Yup.string(),
  pic: Yup.string(),
  banner: Yup.string()
});

const defaultFormData: ISchoolInfoForm = {
  name: "",
  country: null,
  state: null,
  city: null,
  zip: "",
  addr1: "",
  addr2: "",
  phone: "",
  fax: "",
  website: "",
  description: "",
  CERP: "",
  CERP_provider_number: "",
  PACE: "",
  PACE_provider_number: "",
  pic: "",
  banner: ""
};

const EditSchool = () => {
  const queryClient = useQueryClient();

  const fetchedSchoolData = useGetFetchQuery(schoolDataQuery()) as ISchoolInfo;

  const {
    formData,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    formErrors,
    setFormErrors
  } = useFormData(defaultFormData, validationSchema);
  const { setPopup } = useContext(popupContext);

  const { isLoading, error } = useQuery<boolean, Error>({
    ...schoolDataQuery(),
    enabled: !fetchedSchoolData
  });

  const fetchedCountriesData = useGetFetchQuery(
    countriesDataQuery()
  ) as ICountry[];

  const { isLoading: isLoadingCountries, error: errorCountries } = useQuery<
    boolean,
    Error,
    ICountry[]
  >({
    ...countriesDataQuery(),
    enabled: !fetchedCountriesData
  });

  const selectedState = formData.state ? formData.state.value : null;

  const fetchedCitiesData = useGetFetchQuery(
    citiesDataQuery(selectedState)
  ) as ICity[];

  const { isLoading: isLoadingCities, error: errorCities } = useQuery<
    boolean,
    Error,
    ICity[]
  >({
    ...citiesDataQuery(selectedState),
    enabled: !!selectedState && !fetchedCitiesData
  });

  const getSelectedCountryStates = () => {
    const country =
      fetchedCountriesData && formData.country
        ? fetchedCountriesData.find((c) => c._id === formData.country.value)
        : { states: [] };
    return (
      country &&
      country.states.map((s) => ({
        label: `${s.state_code} - ${s.name}`,
        value: s._id,
        code: s.state_code,
        name: s.name
      }))
    );
  };

  const buildFileValue = (val) => (val ? `${FILES_PUBLIC_DOMAIN}${val}` : "");

  // Populate form values from cache
  useEffect(() => {
    if (fetchedSchoolData) {
      const schoolCountry =
        fetchedCountriesData &&
        fetchedCountriesData.find((c) => c.name === fetchedSchoolData.country);
      const schoolState =
        schoolCountry &&
        fetchedSchoolData.state &&
        schoolCountry.states.find(
          (s) => s.name === fetchedSchoolData.state.name
        );
      setFormData({
        name: fetchedSchoolData.name,
        country: {
          ...(schoolCountry
            ? {
                label: schoolCountry.name,
                value: schoolCountry._id
              }
            : null)
        },
        state: {
          ...(schoolState
            ? {
                label: `${schoolState.state_code} - ${schoolState.name}`,
                value: schoolState._id
              }
            : null)
        },
        city:
          {
            label: fetchedSchoolData.city,
            value: fetchedSchoolData.city
          } || null,
        zip: fetchedSchoolData?.zip || "",
        addr1: fetchedSchoolData.addr1,
        addr2: fetchedSchoolData?.addr2 || "",
        phone: fetchedSchoolData.phone,
        fax: fetchedSchoolData.fax || "",
        website: fetchedSchoolData.website,
        description: fetchedSchoolData?.description,
        CERP: buildFileValue(fetchedSchoolData?.CERP),
        CERP_provider_number: fetchedSchoolData?.CERP_provider_number,
        PACE: buildFileValue(fetchedSchoolData?.PACE),
        PACE_provider_number: fetchedSchoolData?.PACE_provider_number,
        pic: buildFileValue(fetchedSchoolData?.pic),
        banner: buildFileValue(fetchedSchoolData?.banner)
      });
    }
  }, [fetchedSchoolData, fetchedCountriesData, setFormData]);

  // Edit data
  const { isLoading: isEditLoading, mutate: handleEdit } = useMutation({
    mutationKey: ["edit-school"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      let data: any = { ...formData };
      data.zip = +data.zip;
      data.country = data.country.label;
      data.state = {
        name: data.state.name,
        code: data.state.code
      };
      data.city = data.city.label;

      if (!data.state) delete data.state;

      // Pic
      if (data.pic.includes(FILES_PUBLIC_DOMAIN)) {
        delete data.pic;
      }

      // Banner
      if (data.banner.includes(FILES_PUBLIC_DOMAIN)) {
        delete data.banner;
      }

      // CERP
      if (
        data.CERP.value &&
        data.CERP.value.includes("data:application/pdf;base64,")
      ) {
        if (typeof formData.CERP !== "string") {
          data.CERP = {
            file: data.CERP.value,
            name: getFileName(formData.CERP.name)
          };
        }
      } else if (data.CERP === "") {
        data.CERP = {
          file: "",
          name: ""
        };
      } else {
        delete data.CERP;
      }

      // PACE
      if (
        data.PACE.value &&
        data.PACE.value.includes("data:application/pdf;base64,")
      ) {
        if (typeof formData.PACE !== "string") {
          data.PACE = {
            file: data.PACE.value,
            name: getFileName(formData.PACE.name)
          };
        }
      } else if (data.PACE === "") {
        data.PACE = {
          file: "",
          name: ""
        };
      } else {
        delete data.PACE;
      }
      await fetchApi("courses", "/school", {
        method: "PUT",
        auth: true,
        data: data
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["school-data"] });
      setPopup(
        <GenericPopup
          type="success"
          msg="You have successfully edited the school details!"
          redirectPath="/school"
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <form className={classes["form-layout"]}>
      <InputField
        title="School Name"
        isRequired
        name="name"
        placeholder="Enter School Name"
        onChange={handleChange}
        value={formData.name}
        error={formErrors.name}
      />
      <ReactSelectWrapper
        title="School Country"
        isRequired
        name="country"
        placeholder="Enter School Country"
        options={
          fetchedCountriesData &&
          fetchedCountriesData.map((country) => {
            return { label: country.name, value: country._id };
          })
        }
        isSearchable
        value={formData.country}
        isLoading={isLoadingCountries}
        error={
          errorCountries ? errorCountries.message : formErrors.country.value
        }
        onChange={(e) =>
          handleChange({
            target: {
              name: "country",
              value: e
            }
          })
        }
      />
      <ReactSelectWrapper
        title="School State"
        isRequired
        name="state"
        placeholder="Enter School State"
        options={getSelectedCountryStates()}
        isSearchable
        value={formData.state}
        isLoading={isLoadingCountries}
        isDisabled={!formData.country}
        error={errorCountries ? errorCountries.message : formErrors.state.value}
        onChange={(e) =>
          handleChange({
            target: {
              name: "state",
              value: e
            }
          })
        }
      />
      <ReactSelectWrapper
        title="School City"
        isRequired
        name="city"
        placeholder="Enter School City"
        options={
          fetchedCitiesData &&
          fetchedCitiesData.map((city) => {
            return { label: city.name, value: city.name };
          })
        }
        isSearchable
        value={formData.city}
        isDisabled={!formData.state}
        error={errorCities ? errorCities.message : formErrors.city.value}
        onChange={(e) =>
          handleChange({
            target: {
              name: "city",
              value: e
            }
          })
        }
      />
      <InputField
        type="number"
        title="School Zip Code"
        name="zip"
        placeholder="Enter School Zip Code"
        onChange={handleChange}
        value={formData.zip}
        error={formErrors.zip}
      />
      <InputField
        title="School Street Address 1"
        isRequired
        name="addr1"
        placeholder="Enter School Street Address 1"
        onChange={handleChange}
        value={formData.addr1}
        error={formErrors.addr1}
      />
      <InputField
        title="School Street Address 2"
        name="addr2"
        placeholder="Enter School Street Address 2"
        onChange={handleChange}
        value={formData.addr2}
        error={formErrors.addr2}
      />
      <InputField
        title="School Phone Number"
        isRequired
        name="phone"
        placeholder="Enter School Phone Number"
        onChange={handleChange}
        value={formData.phone}
        error={formErrors.phone}
        showTooltipIcon="10-digit phone number without special symbols (e.g. 0647909921)"
      />
      <InputField
        title="School Fax Number"
        name="fax"
        placeholder="Enter School Fax Number"
        onChange={handleChange}
        value={formData.fax}
        error={formErrors.fax}
        showTooltipIcon="10-digit fax number without special symbols (e.g. 0647909921)"
      />
      <InputField
        type="url"
        title="School Website"
        isRequired
        name="website"
        placeholder="Enter School Website"
        onChange={handleChange}
        value={formData.website}
        error={formErrors.website}
      />
      <InputField
        type="textarea"
        title="School Description"
        name="description"
        placeholder="Enter School Description"
        onChange={handleChange}
        value={formData.description}
        error={formErrors.description}
      />
      <InputField
        type="file"
        fileType=".pdf"
        fileName={
          typeof formData.CERP !== "string" && formData.CERP.name
            ? formData.CERP.name
            : formData?.CERP
            ? getFileNameFromUrl(formData?.CERP as string)
            : ""
        }
        onClear={() => {
          setFormData({ ...formData, CERP: "", CERP_provider_number: "" });
          setFormErrors({ ...formErrors, CERP: "" });
        }}
        title="CERP Certification"
        subtitle={fileInstructions.pdf}
        name="CERP"
        onChange={(e: FormEvent) => handleUploadedFileBase64("CERP", e)}
        maxWidth={200}
        error={formErrors.CERP.toString()}
      />
      {formData.CERP && (
        <InputField
          title="CERP Provider Number"
          name="CERP_provider_number"
          placeholder="Enter CERP provider number"
          onChange={handleChange}
          value={formData.CERP_provider_number}
          error={formErrors.CERP_provider_number}
        />
      )}
      <InputField
        type="file"
        fileType=".pdf"
        fileName={
          typeof formData.PACE !== "string" && formData.PACE.name
            ? formData.PACE.name
            : formData?.PACE
            ? getFileNameFromUrl(formData?.PACE as string)
            : ""
        }
        onClear={() => {
          setFormData({ ...formData, PACE: "", PACE_provider_number: "" });
          setFormErrors({ ...formErrors, PACE: "" });
        }}
        title="PACE Certification"
        subtitle={fileInstructions.pdf}
        name="PACE"
        onChange={(e: FormEvent) => handleUploadedFileBase64("PACE", e)}
        maxWidth={200}
        error={formErrors.PACE.toString()}
      />
      {formData.PACE && (
        <InputField
          title="PACE Provider Number"
          name="PACE_provider_number"
          placeholder="Enter PACE provider number"
          onChange={handleChange}
          value={formData.PACE_provider_number}
          error={formErrors.PACE_provider_number}
        />
      )}
      <ImageInput
        title="School Profile Picture"
        subtitle={fileInstructions.pfp}
        fileType="image/png, image/jpeg, image/jpg"
        pic={formData.pic}
        onChange={(e: FormEvent) => handleUploadedFileBase64("pic", e)}
        onClear={() => {
          setFormData({
            ...formData,
            pic: ""
          });
          setFormErrors({ ...formErrors, pic: "" });
        }}
        error={formErrors.pic}
      />
      <ImageInput
        title="School Banner"
        subtitle={fileInstructions.banner}
        fileType="image/png, image/jpeg, image/jpg"
        pic={formData.banner}
        onChange={(e: FormEvent) => handleUploadedFileBase64("banner", e)}
        onClear={() => {
          setFormData({
            ...formData,
            banner: ""
          });
          setFormErrors({ ...formErrors, banner: "" });
        }}
        error={formErrors.banner}
        isFullWidth
      />
      <Button
        type="submit"
        minWidth="md"
        onClick={handleEdit}
        icon={CheckIconCircle}
        iconPosition="right"
        isDisabled={
          hasErrors(formErrors) ||
          !formData.name ||
          !formData.country ||
          !formData.state ||
          !formData.city ||
          !formData.addr1 ||
          !formData.phone ||
          !formData.website
        }
        isFetching={isEditLoading}
      >
        Update School Details
      </Button>
    </form>
  );
};
export default EditSchool;
