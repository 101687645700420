import RegisterSchoolForm from "./components/RegisterSchoolForm";
import FormPage from "layouts/form-page";

const RegisterSchool = () => {
  return (
    <FormPage headerVariant="only-logo">
      <RegisterSchoolForm />
    </FormPage>
  );
};

export default RegisterSchool;
