import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import NoRoles from "assets/images/dashboard/no-roles.png";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import EmptyPage from "components/empty-page/EmptyPage";
import NoResults from "components/no-results/NoResults";
import Button from "components/buttons/Button";
import Table from "components/table/Table";
import { StudentsCols } from "components/table/StudentCols";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import classes from "./StudentsTable.module.scss";
import PermissionsGate from "roles/PermissionsGate";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";
import { IEntityTable } from "utils/commonTypes";
import { IStudentsData } from "utils/apiDataTypes/CourseModuleDataTypes";

interface IStudentsTable extends IEntityTable {
  showConfirmPopup: (
    obj: { id: string; is_blacklisted: boolean }[],
    activate: boolean
  ) => void;
  setSelected: (
    obj: { index: number; id: string; is_blacklisted: boolean }[]
  ) => void;
  data: { data: IStudentsData; isLoading: boolean; error: Error };
  schoolId?: string;
  type: "students-admin" | "students";
}

const StudentsTable = ({
  searchValue,
  filterValue,
  showConfirmPopup,
  page,
  setPage,
  itemsAmountMsg,
  setSelected,
  data,
  isPaginationHidden,
  hasNoActions,
  hasViewAll,
  schoolId,
  type
}: IStudentsTable) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const { userData } = useContext(userContext);
  const { role_flag } = userData || {};
  const isNotManagerAndReg =
    role_flag !== ROLE_FLAG["MANAGER"] && role_flag !== ROLE_FLAG["REGISTRAR"];

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const studentsCols = useMemo(
    () =>
      StudentsCols(showConfirmPopup, hasNoActions, type, isNotManagerAndReg),
    []
  );

  const { data: studentsData, isLoading, error } = data || {};
  const hasData = studentsData?.result && studentsData?.result.length > 0;

  if (isLoading) return <Loader size="lg" hasText />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <>
      {hasData && (
        <>
          <Table
            type={type}
            tableData={studentsData}
            columns={studentsCols}
            hiddenColumns={
              isMobile
                ? ["names", "email", "registered", "student_school_status"]
                : ["mobile"]
            }
            activePage={page}
            passActivePageUp={activePageHandler}
            itemsAmountMsg={itemsAmountMsg}
            passSelectedRowsUp={setSelected}
            isPaginationHidden={isPaginationHidden}
            schoolId={schoolId}
          />
          {hasViewAll && (
            <Button
              onClick={() => navigate(`/admin-schools/${schoolId}/students`)}
              icon={ArrowIcon}
              iconPosition={"right"}
              variant={"link"}
            >
              View All
            </Button>
          )}
        </>
      )}
      {((!hasData && searchValue && !isLoading) ||
        (!hasData && filterValue.id !== 0 && !isLoading)) && <NoResults />}
      {!hasData && !searchValue && filterValue.id === 0 && !isLoading && (
        <EmptyPage
          title="No Students."
          icon={NoRoles}
          message={
            <div className={classes["msg-wrapper"]}>
              <PermissionsGate permissions={["COURSE.CREATE"]}>
                <div className={classes["msg"]}>
                  Students will be displayed here after they have been added to
                  a course.
                </div>
              </PermissionsGate>
              <PermissionsGate permissions={["COURSE.CREATE"]}>
                <Button
                  onClick={() => navigate("/courses/create")}
                  icon={PlusIcon}
                >
                  Create Course
                </Button>
              </PermissionsGate>
            </div>
          }
        />
      )}
    </>
  );
};

export default StudentsTable;
