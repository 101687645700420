import React from "react";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as CrossIcon } from "assets/icons/clear.svg";
import classes from "./Badge.module.scss";

interface IBadge {
  text: string;
  isOpen?: boolean;
  isDanger?: boolean;
  isSmall?: boolean;
}

const Badge = ({ text, isOpen, isDanger, isSmall }: IBadge) => {
  const OpenIcon = StarIcon;
  const DangerIcon = CrossIcon;

  return (
    <div
      className={`${classes["c-badge"]} ${
        isOpen ? classes["c-badge--open"] : ""
      } ${isDanger ? classes["c-badge--danger"] : ""} ${
        isSmall ? classes["c-badge--small"] : ""
      }`}
    >
      {isOpen && <OpenIcon width={18} height={18} />}
      {isDanger && <DangerIcon width={18} height={18} />}
      {text}
    </div>
  );
};

export default Badge;
