import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CoursesCols } from "components/table/CoursesCols";
import { windowContext } from "context/windowsContext";
import Table from "components/table/Table";
import { IEntityTable } from "utils/commonTypes";
import { ICourseData } from "utils/apiDataTypes/CourseModuleDataTypes";
import Loader from "components/loader/Loader";
import Error from "components/error/Error";
import Button from "components/buttons/Button";
import EmptyPage from "components/empty-page/EmptyPage";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import NoCourses from "assets/images/dashboard/no-courses.png";

interface ICoursesTable extends IEntityTable {
  itemsAmountMsg: React.ReactNode;
  data: { data: ICourseData; isLoading: boolean; error: Error };
  setPage: (page: number) => void;
  page: number;
  schoolId?: string;
}

const CoursesTable = ({
  itemsAmountMsg,
  data,
  setPage,
  page,
  hasViewAll,
  isPaginationHidden,
  schoolId
}: ICoursesTable) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const coursesCols = useMemo(() => CoursesCols(), []);

  const { data: coursesData, isLoading, error } = data || {};
  const hasData = coursesData?.result && coursesData?.result.length > 0;

  if (isLoading) return <Loader size="lg" hasText />;
  if (error) return <Error error={error} />;

  return (
    <>
      {hasData && (
        <>
          <Table
            type="courses"
            tableData={coursesData}
            columns={coursesCols}
            hiddenColumns={
              isMobile ? ["course", "dates"] : ["school_course_mobile"]
            }
            activePage={page}
            passActivePageUp={setPage}
            itemsAmountMsg={itemsAmountMsg}
            isPaginationHidden={isPaginationHidden}
            schoolId={schoolId}
          />
          {hasViewAll && (
            <Button
              onClick={() => navigate(`/admin-schools/${schoolId}/courses`)}
              icon={ArrowIcon}
              iconPosition={"right"}
              variant={"link"}
            >
              View All
            </Button>
          )}
        </>
      )}
      {!hasData && !isLoading && (
        <EmptyPage title="There are no active courses." icon={NoCourses} />
      )}
    </>
  );
};

export default CoursesTable;
