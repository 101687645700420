import Loader from "components/loader/Loader";
import { ITable } from "../Table";

interface IItemsAmount {
  type?: string;
  page: number;
  perPage: number;
  result: any[];
  total_results?: number;
  all_records: number;
  isShowAll?: boolean;
}

export const calcItemsAmount = ({
  type,
  page,
  perPage,
  result,
  total_results,
  all_records,
  isShowAll
}: IItemsAmount) => {
  let itemsAmount;

  if (result?.length > 0) {
    if (page === 1 || isShowAll) {
      itemsAmount = result?.length;
    }
    if (page > 1 && perPage * page < all_records) {
      itemsAmount = perPage * (page - 1) + result?.length;
    }
    if (page > 1 && perPage * page >= all_records) {
      itemsAmount = perPage * (page - 1) + result?.length;
    }
  } else {
    itemsAmount = 0;
  }

  const calcItems = (total_results) => {
    if (total_results) {
      return `${(page - 1) * perPage + 1} - ${
        (page - 1) * perPage + result.length
      }`;
    } else {
      return "0";
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {type && type}
      {itemsAmount === undefined || all_records === undefined ? (
        <Loader size="sm" />
      ) : all_records ? (
        total_results !== all_records ? (
          `${calcItems(
            total_results
          )} of ${total_results} (${all_records} total)`
        ) : (
          `${calcItems(total_results)} of ${all_records}`
        )
      ) : null}
    </div>
  );
};

export const getTableHeight = (type: ITable["type"]) => {
  let height;
  switch (type) {
    case "team":
      height = 760;
      break;
    case "diplomas":
      height = 1200;
      break;
    case "attendees":
      height = 760;
      break;
    case "managers":
      height = 240;
      break;
    case "mint-diplomas":
      height = 240;
      break;
    case "mint-requests":
      height = 240;
      break;
    case "students":
      height = 760;
      break;
    case "diploma-requests":
      height = 760;
      break;
  }
  return height;
};

export const tableColumnsWidths = {
  nameMin: 200,
  emailMin: 200,
  diplomaNameMin: 350,
  roleMin: 150,
  role: 150,
  roleMax: 150,
  regMin: 120,
  reg: 120,
  regMax: 120,
  walletMin: 160,
  wallet: 160,
  walletMax: 160,
  diplomaMin: 100,
  diploma: 100,
  diplomaMax: 100,
  statusMin: 180,
  status: 180,
  statusMax: 180,
  typeMin: 140,
  type: 140,
  typeMax: 140,
  dateMin: 140,
  date: 140,
  dateMax: 140,
  actionsMin: 72,
  actions: 72,
  actionsMax: 72,
  actionsExtMin: 100,
  actionsExt: 100,
  actionsExtMax: 100,
  actionsXlMin: 180,
  actionsXl: 180,
  actionsXlMax: 180,
  selectionMin: 48,
  selection: 48,
  selectionMax: 48
};
