import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Table from "components/table/Table";
import { SchoolRegistrationRequestsCols } from "components/table/SchoolRegistrationRequestsCols";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import classes from "./SchoolRegistrationRequestsTable.module.scss";
import { windowContext } from "context/windowsContext";
import { IEntityTable } from "utils/commonTypes";
import Divider from "components/divider/Divider";
import NoResults from "components/no-results/NoResults";

interface ISchoolRegistrationRequestsTable extends IEntityTable {
  perPage?: number;
  setPerPage?: (page: number) => void;
  data: { data: any; isLoading: boolean; error: Error };
}

const SchoolRegistrationRequestsTable = ({
  page,
  setPage,
  perPage,
  itemsAmountMsg,
  data,
  isPaginationHidden,
  searchValue
}: ISchoolRegistrationRequestsTable) => {
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const navigate = useNavigate();

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const schoolRegistrationRequestsCols = useMemo(
    () => SchoolRegistrationRequestsCols(navigate, isMobile),
    []
  );

  const { data: requestsData, isLoading, error } = data || {};
  const hasData = requestsData?.result && requestsData?.result.length > 0;

  if (isLoading) return <Loader size="lg" hasText />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <>
      {hasData && (
        <>
          <Table
            type="school-registration-requests"
            tableData={requestsData}
            columns={schoolRegistrationRequestsCols}
            hiddenColumns={
              isMobile
                ? ["school-name", "date_requested", "requested_by"]
                : ["school-reg-requests-mobile"]
            }
            activePage={page}
            passActivePageUp={activePageHandler}
            perPage={perPage}
            itemsAmountMsg={itemsAmountMsg}
            isPaginationHidden={isPaginationHidden}
          />
        </>
      )}
      {!hasData && !isLoading && searchValue && <NoResults />}
      {!hasData && !isLoading && !searchValue && (
        <div className={`${classes["empty-state"]}`}>
          <Divider orientation="horizontal" />
          <div
            className={`${classes["u-text--small"]} ${classes["u-text--content"]}`}
          >
            No new school registration requests
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolRegistrationRequestsTable;
