import React, { useContext } from "react";
import SchoolComp from "./components/School";
import SchoolList from "./components/SchoolList";
import { userContext } from "context/userContext";
import classes from "./School.module.scss";

const School = () => {
  const { userData } = useContext(userContext);
  return (
    <div className={classes["content-wrapper"]}>
      {userData && (userData.is_student ? <SchoolList /> : <SchoolComp />)}
    </div>
  );
};

export default School;
