import React, { FormEvent, useContext } from "react";
import * as Yup from "yup";
import { useFormData } from "hooks/useFormData";
import { InputField } from "components/input/Input";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import classes from "../Team.module.scss";
import { yupSchemas } from "utils/yupSchemas";
import { IMemberFormData } from "utils/apiDataTypes/AccountManagementDataTypes";
import { hasErrors } from "utils/form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllRolesQuery } from "query";
import { IFetchedRoles } from "utils/apiDataTypes/RolesManagementDataTypes";
import { fetchApi } from "utils/requests";
import { useNavigate } from "react-router-dom";
import ImageInput from "components/image-input/ImageInput";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { userContext } from "context/userContext";
import { fileInstructions, titleOptions } from "utils/staticOptions";
import { colors } from "styles";

const validationSchema: Yup.SchemaOf<IMemberFormData> = Yup.object().shape({
  role_type: yupSchemas.labelAndValue.required(),
  title: yupSchemas.labelAndValue.nullable(),
  first_name: yupSchemas.requiredStr,
  last_name: yupSchemas.requiredStr,
  email: yupSchemas.email,
  phone: yupSchemas.phone,
  pic: Yup.string()
});

interface IMemberForm {
  defaultFormData: IMemberFormData;
  isEdit?: boolean;
  memberId?: string;
}

const MemberForm = ({ defaultFormData, isEdit, memberId }: IMemberForm) => {
  const {
    formData,
    handleChange,
    setFormData,
    formErrors,
    handleUploadedFileBase64,
    setFormErrors
  } = useFormData(defaultFormData, validationSchema);
  const { setPopup } = useContext(popupContext);
  const { tenant, userData } = useContext(userContext);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingRoles,
    error: errorRoles,
    data: dataRoles
  } = useQuery<boolean, Error, IFetchedRoles[]>({
    ...getAllRolesQuery()
  });

  const roleType = dataRoles?.filter(
    (role) => role.name === formData?.role_type?.value
  );

  const buildFormData = async () => {
    const cleanFormData = {
      ...formData,
      role_id: roleType[0]._id,
      title: formData.title?.value || null
    };
    delete cleanFormData.role_type;
    if (isEdit && cleanFormData.pic.includes(FILES_PUBLIC_DOMAIN)) {
      delete cleanFormData.pic;
    }
    return cleanFormData;
  };

  const { isLoading: isLoadingCreate, mutate: handleCreate } = useMutation({
    mutationKey: ["create-member"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      await fetchApi("account", "/user/nft/register/member", {
        method: "POST",
        auth: true,
        data: await buildFormData()
      });
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          msg=" Invitation for the member successfully sent!"
          icon={<EmailIcon fill={`rgb(${colors.orange60})`} />}
          redirectPath="/team"
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) =>
      setPopup(
        <GenericPopup
          type="error"
          title="Invitation was not sent!"
          msg={err.message}
        />
      )
  });

  const { isLoading: isLoadingUpdate, mutate: handleUpdate } = useMutation({
    mutationKey: ["update-member", { id: memberId }],
    mutationFn: async (e: HTMLFormElement) => {
      e.preventDefault();
      await fetchApi("courses", `/school/members/${memberId}`, {
        method: "PUT",
        auth: true,
        data: await buildFormData()
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["team-data", { id: memberId }]
      });
      setPopup(
        <GenericPopup
          type="success"
          msg="Member's data successfully edited!"
          redirectPath={`/team/${memberId}`}
          isClosable={false}
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) =>
      setPopup(<GenericPopup type="error" msg={err.message} />)
  });

  if (memberId === tenant) return <>{navigate("/team")}</>;

  return (
    <form className={classes["form-layout"]}>
      <ReactSelectWrapper
        title="Choose Member Type*"
        name="role_type"
        value={formData.role_type}
        error={errorRoles ? errorRoles.message : formErrors.role_type.value}
        placeholder="Choose Member Type"
        // This string prop indicates that the tooltip should be visible.
        // `tooltipContent` introduces html type of tooltip content for the first time here.
        // Please use it this way for now. TBD - refactoring.
        showTooltipIcon="true"
        tooltipContent={
          <div>
            <ul>
              <li>
                - Course Managers can create and edit NFT certificate templates, add
                attendees to a course and send minting requests for approval.
              </li>
              <li>
                - School Registrars have the permissions of a Course Manager and
                also can approve or reject NFT certificate minting and create, edit
                and delete a course.
              </li>
              <li>
                - School Admins have the permissions of both a Course Manager
                and a School Registrar and also can manage the roles of the
                school members.
              </li>
            </ul>
          </div>
        }
        options={
          dataRoles &&
          dataRoles?.map((role) => {
            return { label: role.name, value: role.name };
          })
        }
        onChange={(e) =>
          handleChange({
            target: {
              name: "role_type",
              value: e
            }
          })
        }
        isLoading={isLoadingRoles}
        isDisabled={userData.id === memberId}
      />
      <ReactSelectWrapper
        title="Title"
        name="title"
        placeholder="Choose Title"
        value={formData.title}
        error={formErrors.title.value}
        options={titleOptions.map((name) => {
          return { label: name, value: name };
        })}
        onChange={(e) =>
          handleChange({
            target: {
              name: "title",
              value: e
            }
          })
        }
        isClearable
      />
      <InputField
        title="First Name*"
        name="first_name"
        value={formData.first_name}
        error={formErrors.first_name}
        onChange={handleChange}
        placeholder="Enter First Name"
      />
      <InputField
        title="Last Name*"
        name="last_name"
        value={formData.last_name}
        error={formErrors.last_name}
        onChange={handleChange}
        placeholder="Enter Last Name"
      />
      <InputField
        title="E-mail*"
        name="email"
        value={formData.email}
        error={formErrors.email}
        onChange={handleChange}
        placeholder="Enter Email"
        showTooltipIcon={`The member invitation ${
          isEdit ? "was sent" : "will be send"
        } to this E-mail`}
        isDisabled={isEdit}
      />
      <InputField
        title="Phone Number*"
        name="phone"
        value={formData.phone}
        error={formErrors.phone}
        onChange={handleChange}
        placeholder="Enter Phone Number"
        showTooltipIcon="10-digit phone number without special symbols (e.g. 0647909921)"
      />
      {isEdit && (
        <ImageInput
          title="Profile Picture"
          subtitle={fileInstructions.pfp}
          fileType="image/png, image/jpeg, image/jpg"
          pic={formData.pic}
          onChange={(e: FormEvent) => handleUploadedFileBase64("pic", e)}
          onClear={() => {
            setFormData({
              ...formData,
              pic: ""
            });
            setFormErrors({ ...formErrors, pic: "" });
          }}
          error={formErrors.pic}
        />
      )}
      <Button
        type="submit"
        minWidth="md"
        onClick={isEdit ? handleUpdate : handleCreate}
        icon={isEdit ? CheckIcon : ArrowIcon}
        iconPosition="right"
        isDisabled={
          hasErrors(formErrors) ||
          !formData.email ||
          !formData.first_name ||
          !formData.last_name ||
          !formData.phone ||
          !formData.role_type
        }
        isFetching={isEdit ? isLoadingUpdate : isLoadingCreate}
      >
        {isEdit ? "Save Changes" : "Send Invitation to E-mail"}
      </Button>
    </form>
  );
};

export default MemberForm;
