import { colors } from "styles";
import { ReactComponent as Image } from "assets/icons/image.svg";
import { FormEvent, useContext } from "react";
import { themeContext } from "context/themeContext";
import { fileInstructions } from "utils/staticOptions";
import classes from "./EmptyBackground.module.scss";

const EmptyBackground = ({ handleUpload, isDraggingFile }) => {
  const { theme } = useContext(themeContext);
  return (
    <div
      className={`${classes["empty"]} ${
        isDraggingFile ? classes["highlight"] : ""
      }`}
    >
      <Image
        fill={
          theme === "dark"
            ? `rgb(${colors.greyishBlue50})`
            : `rgb(${colors.greyishBlue30})`
        }
        width={32}
        height={32}
      />
      <div>Background design not attached.</div>
      <div>{fileInstructions.nftTemplate}</div>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        name="template_background"
        onChange={(e: FormEvent) => handleUpload("template_background", e)}
      />
    </div>
  );
};

export default EmptyBackground;
