import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import PageHeader from "components/page-header/PageHeader";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import NoDiplomas from "assets/images/dashboard/no-templates.png";
import { ReactComponent as ShareLink } from "assets/icons/share-link.svg";
import { ReactComponent as Slideshow } from "assets/icons/slideshow.svg";
import { popupContext } from "context/popupContext";
import { userContext } from "context/userContext";
import { themeContext } from "context/themeContext";
import { IStudentDiplomasData } from "utils/apiDataTypes/CourseModuleDataTypes";
import Button from "components/buttons/Button";
import DiplomasList from "./DiplomasList";
import DiplomasSlideshow from "./DiplomasSlideshow";
import EmptyPage from "components/empty-page/EmptyPage";
import Pagination from "components/pagination/Pagination";
import {
  studentDiplomasDataQuery,
  studentDiplomasPublicDataQuery
} from "query";
import { calcItemsAmount } from "components/table/utils/table-utils";
import SharePopup from "components/popups/SharePopup";
import Avatar from "components/avatar/Avatar";
import classes from "./StudentDiplomas.module.scss";

interface IStudentDiplomas {
  isPublicPage?: boolean;
  isSlideshow?: boolean;
}

const StudentDiplomas = ({ isPublicPage, isSlideshow }: IStudentDiplomas) => {
  const { search: urlParams } = useLocation();
  const { page: pageNumber } = queryString.parse(urlParams);

  const { theme } = useContext(themeContext);
  const { userData } = useContext(userContext);
  const { studentId } = useParams();
  const userId = studentId || userData.id;

  const { setPopup } = useContext(popupContext);

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const pageSize = isPublicPage ? 12 : 6;

  const searchParams = isSlideshow ? "" : `?&limit=${pageSize}&page=${page}`;

  // Fetch data
  const {
    isLoading,
    error,
    data: responseData
  } = useQuery<boolean, Error, any>(
    isPublicPage
      ? studentDiplomasPublicDataQuery(userId, searchParams)
      : studentDiplomasDataQuery(searchParams)
  );

  const data: IStudentDiplomasData =
    responseData && isPublicPage ? responseData.data : responseData;

  const user = responseData && isPublicPage ? responseData.user : {};
  const { first_name, last_name, pic } = user;

  const { result: diplomas, total_results, all_records } = data || {};
  const hasData = diplomas && diplomas.length > 0;

  if (isSlideshow) {
    return (
      <DiplomasSlideshow
        isLoading={isLoading}
        hasData={hasData}
        error={error}
        diplomas={diplomas}
      />
    );
  }

  return (
    <div
      data-theme={theme}
      className={`${classes["content-wrapper"]} ${
        isPublicPage ? classes["content-wrapper--public"] : ""
      }`}
    >
      {isPublicPage && !isLoading && (
        <div className={classes["headline-wrapper"]}>
          <div className={classes["headline-wrapper__user-info"]}>
            <Avatar
              className={classes["user-avatar"]}
              src={pic}
              size="lg"
              type="user"
              isActive
            />
            <div className={classes["title-wrapper"]}>
              <h4
                className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["m-0"]}`}
              >
                {`${first_name} ${last_name}`}
              </h4>

              <div className={classes["u-text--primary"]}>NFT Certificates</div>
            </div>
          </div>
          <div className={classes["headline-wrapper__items-info"]}>
            {calcItemsAmount({
              page: page,
              perPage: pageSize,
              result: diplomas,
              total_results,
              all_records: all_records
            })}
          </div>
        </div>
      )}

      {(hasData || page > 1) && !isPublicPage && (
        <PageHeader
          hasData={true}
          title="My NFT Certificates"
          itemsAmountMsg={calcItemsAmount({
            page: page,
            perPage: pageSize,
            result: diplomas,
            total_results,
            all_records: all_records
          })}
          hasSearch={false}
          hasSort={false}
        />
      )}
      {isLoading && <Loader size="lg" hasText withPadding />}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          {!isPublicPage && (
            <div className={classes["actions-wrapper"]}>
              <Button
                size="medium"
                icon={ShareLink}
                variant="link"
                onClick={() =>
                  setPopup(
                    <SharePopup
                      title="Share Preview"
                      desc="Copy preview link and share your public certificates."
                      fieldLabel="Preview Link"
                      link={`${window.location.host}/students/${userId}/diplomas/preview`}
                    />
                  )
                }
              >
                Share Preview
              </Button>
              <Button
                size="medium"
                icon={Slideshow}
                variant="link"
                onClick={() =>
                  setPopup(
                    <SharePopup
                      title="Share Slideshow"
                      desc="Copy slideshow link and share your public certificates."
                      fieldLabel="Slideshow Link"
                      link={`${window.location.host}/students/${userId}/diplomas/slideshow`}
                    />
                  )
                }
              >
                Slideshow Link
              </Button>
            </div>
          )}
          <DiplomasList diplomas={diplomas} isPublicPage={isPublicPage} />
          <Pagination
            currentPage={page}
            totalCount={total_results}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            itemsAmountMsg={calcItemsAmount({
              type: "Diplomas ",
              page: page,
              perPage: pageSize,
              result: diplomas,
              total_results,
              all_records: all_records
            })}
          />
        </>
      )}
      {!hasData && !isLoading && !error && (
        <EmptyPage
          title={
            isPublicPage
              ? "No certificates yet."
              : "You don’t have any certificates yet."
          }
          icon={NoDiplomas}
        />
      )}
    </div>
  );
};

export default StudentDiplomas;
