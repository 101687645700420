import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import { calcItemsAmount } from "components/table/utils/table-utils";
import SchoolsTable from "./SchoolsTable";
import { useQuery } from "@tanstack/react-query";
import { ISchoolsData } from "utils/apiDataTypes/AccountManagementDataTypes";
import Loader from "components/loader/Loader";
import Error from "components/error/Error";
import { adminGetAllSchools } from "query";
import { sortDirEnum } from "utils/staticOptions";

export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "Status",
    value: "activation_status",
    dir: "asc"
  },
  {
    id: 2,
    label: "Register Date",
    value: "reg_date",
    dir: "asc"
  }
  // TO DO: Discuss whether to implement this sort option
  // {
  //   id: 3,
  //   label: "Payment Due",
  //   value: "payment_due_date",
  //   dir: "asc"
  // }
];

const Schools = () => {
  const { pathname, search: urlParams } = useLocation();
  const {
    page: pageNumber,
    sort,
    sortDir,
    search
  } = queryString.parse(urlParams);

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [sortFilter, setSortFilter] = useState(defaultSortFilter);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ""
  );
  const perPage = 8;

  // TO DO: Adjust when BE is ready with search
  const params = `?&limit=${perPage}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ""
  }${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ISchoolsData>(
    adminGetAllSchools(params)
  );

  const { result, total_results } = data || {};

  if (isLoading) return <Loader size="md" />;

  if (error) return <Error error={error} />;

  return (
    <>
      <PageHeader
        hasData={true}
        title="All Schools"
        itemsAmountMsg={calcItemsAmount({
          page,
          perPage,
          result,
          total_results,
          all_records: data.all_records
        })}
        hasSearch
        searchPlaceholder="Search by School Name"
        searchValue={searchValue}
        passSearchValueUp={(value: string) => {
          setSearchValue(value);
        }}
        hasSort
        sortOptions={sortOptions}
        sortValue={sortFilter}
        setSortValue={setSortFilter}
        passSortValueUp={(value: ISortOption) => {
          setSortFilter(value);
        }}
        setPage={setPage}
      />
      <SchoolsTable
        searchValue={searchValue}
        page={page}
        setPage={setPage}
        itemsAmountMsg={calcItemsAmount({
          type: "All Schools ",
          page,
          perPage,
          result,
          total_results,
          all_records: data.all_records
        })}
        data={{
          data,
          isLoading,
          error
        }}
      />
    </>
  );
};

export default Schools;
