import React, { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { popupContext } from "context/popupContext";
import { userContext } from "context/userContext";
import Button from "components/buttons/Button";
import TooltipComp from "components/tooltip/TooltipComp";
import GenericPopup from "components/popups/GenericPopup";
import { fetchApi } from "utils/requests";
import { IStudentCourse } from "utils/apiDataTypes/CourseModuleDataTypes";

interface IRequestNFTDiploma
  extends Pick<
    IStudentCourse,
    "course_id" | "has_pending_request" | "student_wallet" | "is_blacklisted"
  > {}

const RequestNFTDiploma = ({
  course_id,
  has_pending_request,
  student_wallet,
  is_blacklisted
}: IRequestNFTDiploma) => {
  const { userData } = useContext(userContext);
  const { setPopup } = useContext(popupContext);
  const queryClient = useQueryClient();

  // Request mint
  const verify = async () =>
    await fetchApi("courses", `/course-student/request-mint/${course_id}`, {
      method: "POST",
      auth: true
    });

  // Request mint mutation
  const { isLoading: isLoadingUpdate, mutate: handleVerify } = useMutation({
    mutationKey: ["request-mint", { courseId: course_id, userId: userData.id }],
    mutationFn: async () => await verify(),
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: [`student-courses-data`, { id: course_id }]
      });
      return setPopup(
        <GenericPopup
          title="Mint Requested"
          msg={`You have requested a new NFT Certificate for course "${data.course_name}". The school registrar has been notified.`}
          size={"md"}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <TooltipComp
      id="diploma-request"
      text={
        !student_wallet && !has_pending_request
          ? "Please set your wallet address first."
          : is_blacklisted
          ? "This school has deactivated your account."
          : null
      }
    >
      <Button
        onClick={handleVerify}
        isFetching={isLoadingUpdate}
        isDisabled={has_pending_request || !student_wallet || is_blacklisted}
        minWidth="full"
      >
        {has_pending_request ? "NFT Certificate Requested" : "Request NFT Certificate"}
      </Button>
    </TooltipComp>
  );
};

export default RequestNFTDiploma;
