import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { themeContext } from "context/themeContext";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classes from "../Landing.module.scss";
import {
  schoolAdminOnboardingItems,
  schoolAdminOnboardingImagesLight,
  schoolAdminOnboardingImagesDark,
  schoolTeamOnboardingItems,
  schoolTeamOnboardingImagesLight,
  schoolTeamOnboardingImagesDark,
  studentOnboardingItems,
  studentOnboardingImagesLight,
  studentOnboardingImagesDark
} from "../content";
import OnboardingItem from "./OnboardingItem";
import Button from "components/buttons/Button";

const tabs = ["School Admin", "School Team", "Student"];

interface IOnboardingTabsProps {
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const OnboardingTabs = ({ activeTab, setActiveTab }: IOnboardingTabsProps) => {
  const { theme } = useContext(themeContext);
  const isDark = theme === "dark";

  const schoolAdminOnboardingImages = isDark
    ? schoolAdminOnboardingImagesDark
    : schoolAdminOnboardingImagesLight;
  const schoolTeamOnboardingImages = isDark
    ? schoolTeamOnboardingImagesDark
    : schoolTeamOnboardingImagesLight;
  const studentOnboardingImages = isDark
    ? studentOnboardingImagesDark
    : studentOnboardingImagesLight;

  const [openItem, setOpenItem] = useState<number | null>(null);

  const isSchoolAdminTab = activeTab === 0;
  const isSchoolTeamTab = activeTab === 1;
  const isStudentTab = activeTab === 2;
  return (
    <div>
      <div className={classes["tabs"]}>
        {tabs.map((tab, i) => {
          return (
            <div
              key={i}
              className={`${classes["tab"]} ${
                classes[activeTab === i ? "u-text--primary" : ""]
              }
              ${classes[activeTab === i ? "tab--active" : ""]}
              `}
              onClick={() => {
                setActiveTab(i);
                setOpenItem(null);
              }}
            >
              <span>{tab}</span>
            </div>
          );
        })}
      </div>
      {isSchoolAdminTab && (
        <div className={classes["tab-items"]}>
          <div className={classes["accordion-wrapper"]}>
            <Fade direction="up" triggerOnce cascade>
              <div className={classes["accordion-section"]}>
                {schoolAdminOnboardingItems.map((f, i) => (
                  <div
                    key={i}
                    className={classes["accordion__el"]}
                    onClick={() => setOpenItem(openItem === i ? null : i)}
                  >
                    <OnboardingItem
                      question={f.q}
                      answer={f.a}
                      isOpen={openItem === i}
                      i={i}
                    />
                  </div>
                ))}
                <Button
                  onClick={() => {
                    setActiveTab(1);
                    setOpenItem(null);
                  }}
                  variant={"link"}
                  icon={ArrowIcon}
                  iconPosition={"right"}
                >
                  School Team Onboarding
                </Button>
              </div>
            </Fade>
            <Fade direction="right" triggerOnce cascade>
              <div className={classes["accordion-item__img"]}>
                <img
                  src={schoolAdminOnboardingImages[openItem || 0]}
                  alt="School"
                />
              </div>
            </Fade>
          </div>
        </div>
      )}
      {isSchoolTeamTab && (
        <div className={classes["tab-items"]}>
          <div className={classes["accordion-wrapper"]}>
            <Fade direction="up" triggerOnce cascade>
              <div className={classes["accordion-section"]}>
                {schoolTeamOnboardingItems.map((f, i) => (
                  <div
                    key={i}
                    className={classes["accordion__el"]}
                    onClick={() => setOpenItem(openItem === i ? null : i)}
                  >
                    <OnboardingItem
                      question={f.q}
                      answer={f.a}
                      isOpen={openItem === i}
                      i={i}
                    />
                  </div>
                ))}
                <Button
                  onClick={() => {
                    setActiveTab(2);
                    setOpenItem(null);
                  }}
                  variant={"link"}
                  icon={ArrowIcon}
                  iconPosition={"right"}
                >
                  Student Onboarding
                </Button>
              </div>
            </Fade>
            <Fade direction="right" triggerOnce cascade>
              <div className={classes["accordion-item__img"]}>
                <img
                  src={schoolTeamOnboardingImages[openItem || 0]}
                  alt="School"
                />
              </div>
            </Fade>
          </div>
        </div>
      )}
      {isStudentTab && (
        <div className={classes["tab-items"]}>
          <div className={classes["accordion-wrapper"]}>
            <Fade direction="up" triggerOnce cascade>
              <div className={classes["accordion-section"]}>
                {studentOnboardingItems.map((f, i) => (
                  <div
                    key={i}
                    className={classes["accordion__el"]}
                    onClick={() => setOpenItem(openItem === i ? null : i)}
                  >
                    <OnboardingItem
                      question={f.q}
                      answer={f.a}
                      isOpen={openItem === i}
                      i={i}
                    />
                  </div>
                ))}
                <Button
                  onClick={() => {
                    setActiveTab(0);
                    setOpenItem(null);
                  }}
                  variant={"link"}
                  icon={ArrowIcon}
                  iconPosition={"right"}
                >
                  School Admin Onboarding
                </Button>
              </div>
            </Fade>
            <Fade direction="right" triggerOnce cascade>
              <div className={classes["accordion-item__img"]}>
                <img
                  src={studentOnboardingImages[openItem || 0]}
                  alt="School"
                />
              </div>
            </Fade>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingTabs;
