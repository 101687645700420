import React from "react";
import PopupTitleAndMessage from "components/popups/common/PopupTitleAndMessage";
import classes from "./AddAttendeesPopup.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

interface IAddAttendeesPopupStaticContent {
  isOpen: boolean;
}

const AddAttendeesPopupStaticContent = ({
  isOpen
}: IAddAttendeesPopupStaticContent) => {
  return (
    <PopupTitleAndMessage
      title="Add Attendees"
      msg="In order to add new attendees to the course, you must attach a .csv or .xlsx file that contains at least the E-mail address of the attendant. If the attendant doesn’t have a registration in the platform, an invitation will be sent via the provided E-mail."
      msgSecondary={
        <div className={classes["template-msg-wrapper"]}>
          <div className={classes["template-msg"]}>
            Please use one of the following templates:{" "}
            <Link
              to={isOpen ? "/files/example-open.csv" : "/files/example.csv"}
              target="_blank"
              download
            >
              <DownloadIcon /> CSV template
            </Link>{" "}
            or{" "}
            <Link
              to={isOpen ? "/files/example-open.xlsx" : "/files/example.xlsx"}
              target="_blank"
              download
            >
              <DownloadIcon /> XLSX template
            </Link>
            .
          </div>
        </div>
      }
    />
  );
};

export default AddAttendeesPopupStaticContent;
