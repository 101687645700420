import React from "react";
import NoResultsImg from "assets/images/dashboard/no-results.png";
import classes from "./NoResults.module.scss";

interface INoResults {
  className?: string;
}

const NoResults = ({ className }: INoResults) => {
  return (
    <div className={`${classes["wrapper"]} ${className}`}>
      <img src={NoResultsImg} alt="No results" />
      <div className={classes["u-text--center"]}>
        <h4
          className={`${classes["u-title"]} ${classes["u-bold"]} ${classes["u-text--primary"]} ${classes["m-0"]}`}
        >
          No results found
        </h4>
      </div>
    </div>
  );
};

export default NoResults;
