// ************************** I C O N S ******************************* //
import logoDark from "assets/images/logo-dark.svg";
import logoLight from "assets/images/logo-light.svg";
import logoDarkAdmin from "assets/images/logo-admin-dark.svg";
import logoLightAdmin from "assets/images/logo-admin-light.svg";
import logo from "assets/images/logo.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as TeamIcon } from "assets/icons/team.svg";
import { ReactComponent as StudentsIcon } from "assets/icons/students.svg";
import { ReactComponent as DiplomaIcon } from "assets/icons/diploma.svg";
import { ReactComponent as SunIcon } from "assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "assets/icons/moon.svg";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
// ******************************************************************* //
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { themeContext } from "context/themeContext";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Sidebar.module.scss";
import Button from "components/buttons/Button";
import { windowSizes } from "utils/windowSizes";
import Tabs from "./Tabs";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";

interface IItems {
  id: number;
  name: string;
  icon: FC;
  link: string;
}

export const superAdminItems: IItems[] = [
  {
    id: 0,
    name: "Main Dashboard",
    icon: DashboardIcon,
    link: "/main-dashboard"
  },
  {
    id: 1,
    name: "Schools",
    icon: SchoolIcon,
    link: "/admin-schools"
  },
  {
    id: 2,
    name: "Students",
    icon: StudentsIcon,
    link: "/admin-students"
  }
];

export const primaryItems: IItems[] = [
  {
    id: 0,
    name: "School",
    icon: SchoolIcon,
    link: "/school"
  },
  {
    id: 1,
    name: "Courses",
    icon: BookIcon,
    link: "/courses"
  },
  {
    id: 2,
    name: "Team",
    icon: TeamIcon,
    link: "/team"
  },
  {
    id: 3,
    name: "Students",
    icon: StudentsIcon,
    link: "/students"
  },
  {
    id: 4,
    name: "NFT Certificates",
    icon: DiplomaIcon,
    link: "/diplomas"
  }
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, switchTheme, isCollapsed, setIsCollapsed } =
    useContext(themeContext);
  const [activeItem, setActiveItem] = useState<number>(0);
  const { windowSize } = useContext(windowContext);
  const { width } = windowSize;
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};

  const renderCollapseButton = useCallback(() => {
    if (width > windowSizes.screenXlMax) {
      return (
        <Button
          className={classes["c-btn--collapse"]}
          onClick={() => collapseHandler(!isCollapsed)}
          variant={"neutral"}
          size="small"
          isIconBtn
          icon={ChevronIcon}
          iconRotate={isCollapsed ? 270 : 90}
          isDisabled={width < windowSizes.screenXlMax}
        />
      );
    }
  }, [width, isCollapsed]);

  const collapseHandler = (collapse: boolean) => {
    localStorage.setItem("sidebar_collapsed", collapse.toString());
    setIsCollapsed(collapse);
  };

  useEffect(() => {
    if (!localStorage.getItem("sidebar_collapsed")) {
      localStorage.setItem("sidebar_collapsed", "false");
    }
  }, []);

  useEffect(() => {
    if (width <= windowSizes.screenXlMax) {
      collapseHandler(true);
    }
  }, [width]);

  useEffect(() => {
    if (!!userData) {
      const currentItems = is_super_admin ? superAdminItems : primaryItems;

      const activeItem = currentItems.find((item) =>
        location.pathname.includes(item.link) ? item.link : ""
      );

      // Note: custom check for `/requests` route
      if (
        !activeItem &&
        (location.pathname === "/requests" ||
          location.pathname === "/ready-to-mint")
      )
        return setActiveItem(currentItems[4].id);

      // Note: custom check for `/admin-registration-requests` route in super admin
      if (!activeItem && location.pathname === "/admin-registration-requests")
        return setActiveItem(currentItems[1].id);

      if (activeItem) {
        return setActiveItem(activeItem.id);
      }
      return setActiveItem(null);
    }
  }, [location.pathname, userData]);

  return (
    <div id="c-sidebar" className={classes["c-sidebar"]}>
      {renderCollapseButton()}
      <div className={classes["tabs-container"]}>
        <img
          className={classes["c-logo"]}
          src={
            isCollapsed
              ? logo
              : theme === "dark"
              ? is_super_admin
                ? logoLightAdmin
                : logoLight
              : is_super_admin
              ? logoDarkAdmin
              : logoDark
          }
          height={56}
          onClick={() =>
            navigate(is_super_admin ? "/main-dashboard" : "/school")
          }
          alt="Logo"
        />
        {/* Main Nav */}
        <div className={classes["c-items-wrapper"]}>
          <Tabs
            isCollapsed={isCollapsed}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        </div>
      </div>
      <div
        className={`${classes["c-items-wrapper"]} ${classes["c-items-wrapper--fixed"]}`}
      >
        {/* Theme Switcher */}
        <div className={classes["c-item"]} onClick={switchTheme}>
          <div className={classes["icon"]}>
            {theme === "dark" ? <SunIcon /> : <MoonIcon />}
          </div>
          {!isCollapsed && (
            <div className={classes["title"]}>{`${
              theme === "dark" ? "Light" : "Dark"
            } Theme`}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
