import React, { useContext, useMemo } from "react";
import Table from "components/table/Table";
import { SchoolsCols } from "components/table/SchoolsCols";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import classes from "./SchoolsTable.module.scss";
import { windowContext } from "context/windowsContext";
import { IEntityTable } from "utils/commonTypes";
import Divider from "components/divider/Divider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import NoResults from "components/no-results/NoResults";
import { fetchApi } from "utils/requests";
import RejectPopup from "routes/dashboard/school/components/RejectPopup";

interface ISchoolsTable extends IEntityTable {
  data: { isLoading: boolean; error: Error; data: any };
}

const SchoolsTable = ({
  itemsAmountMsg,
  data,
  setPage,
  page,
  searchValue
}: ISchoolsTable) => {
  const queryClient = useQueryClient();

  const { setPopup } = useContext(popupContext);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const { isLoading: changeStatusIsLoading, mutate: changeStatusMutation } =
    useMutation({
      mutationKey: ["change-school-status"],
      mutationFn: async (obj: {
        action: "activate" | "deactivate";
        id: string;
      }) => {
        const { id, action } = obj;
        const resp = await fetchApi(
          "courses",
          `/admin/schools/${id}/${action}`,
          {
            method: "PUT",
            auth: true
          }
        );
        return resp;
      },
      onSuccess: ({ message }) => {
        queryClient.invalidateQueries({
          queryKey: ["schools-data"]
        });
        setPopup(<GenericPopup title="Success!" msg={message} />);
      },
      onError: (e: Error) => {
        setPopup(<GenericPopup type="error" msg={e.message} />);
      }
    });

  const changeStatusHandler = (obj: {
    action: "activate" | "deactivate";
    id: string;
  }) => {
    const { action, id } = obj;
    if (action === "deactivate") {
      return setPopup(<RejectPopup schoolId={id} />);
    }
    setPopup(
      <GenericPopup
        type="loading"
        title="Activating school"
        msg="Please wait..."
      />
    );
    changeStatusMutation(obj);
  };

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const schoolsCols = useMemo(
    () => SchoolsCols(isMobile, changeStatusHandler),
    []
  );

  const { data: requestsData, isLoading, error } = data || {};
  const hasData = requestsData?.result && requestsData?.result.length > 0;

  if (isLoading) return <Loader size="lg" hasText />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <>
      {hasData && (
        <>
          <Table
            type="schools"
            tableData={requestsData}
            columns={schoolsCols}
            hiddenColumns={
              isMobile
                ? ["school-name", "reg_date", "school_status", "payment_due"]
                : ["schools-mobile"]
            }
            activePage={page}
            passActivePageUp={activePageHandler}
            itemsAmountMsg={itemsAmountMsg}
          />
        </>
      )}
      {!hasData && !isLoading && searchValue && <NoResults />}
      {!hasData && !isLoading && !searchValue && (
        <div className={`${classes["empty-state"]}`}>
          <Divider orientation="horizontal" />
          <div
            className={`${classes["u-text--small"]} ${classes["u-text--content"]}`}
          >
            No schools
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolsTable;
