import classes from "./SwitchInput.module.scss";

interface ISwitchInput {
  label: string;
  onClick: (isShow: boolean) => void;
  value: boolean;
}

const SwitchInput = ({ label, onClick, value }: ISwitchInput) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(!value);
  };
  return (
    <div className={classes["switch-container"]} onClick={handleClick}>
      <label className={classes["switch"]}>
        <input
          type="checkbox"
          checked={value}
          className={classes[value ? "active" : ""]}
          readOnly
        />
        <span className={classes["slider"]}></span>
      </label>
      <div className={classes["label"]}>{label}</div>
    </div>
  );
};

export default SwitchInput;
