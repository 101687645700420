import { Fragment, useContext } from "react";
import { Portal } from "react-portal";
import { Column } from "react-table";
import { ReactComponent as Dots } from "assets/icons/menu-dots.svg";
import Button from "components/buttons/Button";
import { Link } from "react-router-dom";
import classes from "./TableCols.module.scss";
import PermissionsGate from "roles/PermissionsGate";
import { userContext } from "context/userContext";
import { IndeterminateCheckbox } from "./InterdeterminateCheckbox";
import RenderCell from "./RenderCell";
import useComponentVisible from "hooks/useComponentVisible";
import { tableColumnsWidths } from "./utils/table-utils";

const dropdownItems = (rowProps, tenant) => {
  return [
    {
      link: (
        <Link
          className={classes["c-menu-item"]}
          to={`/team/${rowProps.id}`}
          key={rowProps.id}
        >
          View
        </Link>
      )
    },
    {
      link: (
        <PermissionsGate permissions={["SCHOOL.UPDATE"]}>
          {rowProps.id !== tenant && (
            <Link
              key={rowProps.id}
              className={classes["c-menu-item"]}
              to={`/team/${rowProps.id}/edit`}
            >
              Edit
            </Link>
          )}
        </PermissionsGate>
      )
    }
  ];
};

interface ITeamCols {
  showConfirmPopup: (
    obj: { index: number; id: string; active: boolean }[],
    activate: boolean
  ) => void;
  userId: string;
  tenant: string;
  showSendEmailPopup: (userId: string) => void;
  hasNoActions: boolean;
  isNotManagerAndReg: boolean;
}

export const TeamCols = (
  showConfirmPopup: ITeamCols["showConfirmPopup"],
  userId: ITeamCols["userId"],
  tenant: ITeamCols["tenant"],
  showSendEmailPopup: ITeamCols["showSendEmailPopup"],
  hasNoActions: ITeamCols["hasNoActions"],
  isNotManagerAndReg: ITeamCols["isNotManagerAndReg"]
) => {
  const cols: Column = [
    {
      Header: "Team Member",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="member_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "First/Last Name",
      accessor: "names",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => <RenderCell type="names" rowProps={row.original} />
    },
    {
      Header: "Role",
      accessor: "role_name",
      maxWidth: tableColumnsWidths.roleMax,
      minWidth: tableColumnsWidths.roleMin,
      width: tableColumnsWidths.role,
      Cell: ({ row }) => (
        <RenderCell
          type="role"
          rowProps={{
            role_name:
              row.original.id === tenant ? "Owner" : row.original.role_name
          }}
        />
      )
    },
    {
      Header: "Email",
      accessor: "email",
      minWidth: tableColumnsWidths.emailMin,
      Cell: ({ row }) => <RenderCell type="email" rowProps={row.original} />
    },
    {
      Header: "Registration",
      accessor: "registered",
      maxWidth: tableColumnsWidths.regMax,
      minWidth: tableColumnsWidths.regMin,
      width: tableColumnsWidths.reg,
      Cell: ({ row }) => (
        <RenderCell type="registration" rowProps={row.original} />
      )
    }
  ];

  if (!hasNoActions && isNotManagerAndReg) {
    // Add an element to the beginning of columns
    cols.unshift({
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.getToggleAllRowsSelectedProps()}
            />
          </PermissionsGate>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.row.getToggleRowSelectedProps()}
            />
          </PermissionsGate>
        );
      }
    });

    // Add an element to the end of columns
    cols.push({
      Header: "",
      accessor: " ",
      maxWidth: tableColumnsWidths.actionsMax,
      minWidth: tableColumnsWidths.actionsMin,
      width: tableColumnsWidths.actions,
      Cell: (tableProps) => {
        const { ref, isComponentVisible, setIsComponentVisible } =
          useComponentVisible(false);
        const { userData } = useContext(userContext);

        return (
          <div
            className={classes["actions-container"]}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              size="medium"
              variant="link"
              icon={Dots}
              isDisabled={userData.role_flag !== 4}
              isIconBtn
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            {isComponentVisible && (
              <Portal node={document && document.getElementById("table-body")}>
                <div
                  className={classes["c-menu-wrapper"]}
                  ref={ref}
                  style={{ top: `${tableProps.row.distance_from_top}px` }}
                >
                  <div className={classes["c-menu"]}>
                    {dropdownItems(tableProps.row.original, tenant).map(
                      (item, i) => (
                        <Fragment key={i}>{item.link}</Fragment>
                      )
                    )}
                    <PermissionsGate permissions={["SCHOOL.UPDATE"]}>
                      {tableProps.row.original.id !== tenant &&
                        tableProps.row.original.id !== userId &&
                        tableProps.row.original.registered && (
                          <div
                            key={tableProps.row.original.id}
                            className={`${classes["c-menu-item"]} ${
                              classes[
                                tableProps.row.original.active
                                  ? "c-menu-item--danger"
                                  : ""
                              ]
                            }`}
                            onClick={() => {
                              tableProps.toggleAllRowsSelected(false);
                              tableProps.row.toggleRowSelected(true);
                              showConfirmPopup(
                                [
                                  {
                                    index: tableProps.row.id,
                                    id: tableProps.row.original.id,
                                    active: !tableProps.row.original.active
                                  }
                                ],
                                !tableProps.row.original.active
                              );
                            }}
                          >
                            {tableProps.row.original.active
                              ? "Deactivate"
                              : "Activate"}
                          </div>
                        )}
                      {!tableProps.row.original.registered && (
                        <div
                          key={tableProps.row.original.id}
                          className={classes["c-menu-item"]}
                          onClick={() => {
                            showSendEmailPopup(tableProps.row.original.id);
                          }}
                        >
                          Resend Activation Email
                        </div>
                      )}
                    </PermissionsGate>
                  </div>
                </div>
              </Portal>
            )}
          </div>
        );
      }
    });
  }

  return cols;
};
