import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import styled from "styled-components";
import clearSearchIcon from "assets/icons/clear.svg";
import "react-datepicker/dist/react-datepicker.css";
import { colors } from "styles";

interface ITextareaProps {
  value?: any;
  onChange: any;
  isDisabled?: boolean;
  placeholder?: string;
  name?: string;
  error?: string;
  themeIs: string;
}

interface IInputWrapperProps {
  themeIs: string;
  isDisabled: boolean;
  error: boolean;
}

const STextareaWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ themeIs, error }) =>
    themeIs === "dark"
      ? !error
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.error100})`
      : !error
      ? `rgb(${colors.lightBlue30})`
      : `rgb(${colors.error10})`};
  border-radius: 16px;
  font-family: Poppins;
  transition: background-color 350ms;
  width: 100%;
  height: auto;
  resize: vertical;
  outline: ${({ error }) => (error ? `1px solid rgb(${colors.error60})` : "")};
  outline-offset: ${({ error }) => (error ? "-1px" : "unset")};
  &:disabled: {
    border: unset;
  }
`;

const STextarea = styled.textarea<ITextareaProps>`
  font-family: Poppins;
  font-size: 14px;
  padding: 13.5px 20px;
  border-radius: 16px;
  min-height: 120px;
  background-color: ${({ themeIs, error }) =>
    themeIs === "dark"
      ? !error
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.error100})`
      : !error
      ? `rgb(${colors.lightBlue30})`
      : `rgb(${colors.error10})`};
  border: none;
  resize: vertical;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: background-color 350ms;
  color: ${({ themeIs, disabled }) =>
    themeIs === "dark" && !disabled
      ? `rgb(${colors.lightBlue10})`
      : themeIs === "dark" && disabled
      ? `rgb(${colors.greyishBlue70})`
      : themeIs !== "dark" && !disabled
      ? `rgb(${colors.darkBlue100})`
      : `rgb(${colors.greyishBlue20})`};
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: url(${clearSearchIcon});
    cursor: pointer;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.lightBlue10})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.greyishBlue20})`};
    caret-color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.lightBlue10})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.greyishBlue20})`};
  }

  ::placeholder {
    color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.greyishBlue50})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.greyishBlue30})`
        : `rgb(${colors.greyishBlue10})`};
    opacity: 1;
  }
  &:focus {
    background-color: ${({ themeIs }) =>
      themeIs === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
    transition: none 0s;
  }
  &:hover {
    transition: none 0s;
    box-shadow: ${({ themeIs, isDisabled, error }) =>
      themeIs === "dark"
        ? isDisabled
          ? "unset"
          : error
          ? `inset 0 0 0 1px rgb(${colors.error60})`
          : `inset 0 0 0 1px rgb(${colors.greyishBlue60})`
        : isDisabled
        ? "unset"
        : error
        ? `inset 0 0 0 1px rgb(${colors.error60})`
        : `inset 0 0 0 1px rgb(${colors.lightBlue90})`};
  }
  &:focus-within {
    transition: none 0s;
    box-shadow: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? error
          ? `inset 0 0 0 1px rgb(${colors.error60})`
          : `inset 0 0 0 2px rgb(${colors.greyishBlue60})`
        : error
        ? `inset 0 0 0 1px rgb(${colors.error60})`
        : `inset 0 0 0 2px rgb(${colors.lightBlue90})`};
    background-color: ${({ themeIs }) =>
      themeIs === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
  }
`;

export const Textarea = (props: ITextareaProps) => {
  const { onChange, isDisabled, value, placeholder, error, name } = props;
  const { theme } = useContext(themeContext);

  return (
    <STextareaWrapper
      themeIs={theme}
      isDisabled={isDisabled}
      data-theme={theme}
      error={!!error}
    >
      <STextarea
        data-theme={theme}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        themeIs={theme}
        value={value}
      />
    </STextareaWrapper>
  );
};

Textarea.defaultProps = {
  themeIs: "light",
  isDisabled: false
};
