import React, { Dispatch, SetStateAction, useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { themeContext } from "context/themeContext";
import School1Dark from "assets/landing/school1-dark.png";
import School1Light from "assets/landing/school1-light.png";
import School2Dark from "assets/landing/school2-dark.png";
import School2Light from "assets/landing/school2-light.png";
import classes from "../Landing.module.scss";

const tabs = ["School", "Student"];

interface IBenefitsTabsProps {
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const BenefitsTabs = ({ activeTab, setActiveTab }: IBenefitsTabsProps) => {
  const { theme } = useContext(themeContext);
  const isDark = theme === "dark";

  const isSchoolTab = activeTab === 0;
  const isStudentTab = activeTab === 1;
  return (
    <div>
      {/* Hide all `Student Benefits` logic for now */}
      {/*<div className={classes["tabs"]}>*/}
      {/*  {tabs.map((tab, i) => {*/}
      {/*    return (*/}
      {/*      <div*/}
      {/*        key={i}*/}
      {/*        className={`${classes["tab"]} ${*/}
      {/*          classes[activeTab === i ? "u-text--primary" : ""]*/}
      {/*        }*/}
      {/*        ${classes[activeTab === i ? "tab--active" : ""]}*/}
      {/*        `}*/}
      {/*        onClick={() => setActiveTab(i)}*/}
      {/*      >*/}
      {/*        <span>{tab}</span>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
      {isSchoolTab && (
        <div className={classes["tab-items"]}>
          <Fade direction="up" triggerOnce cascade>
            <div className={classes["item"]}>
              <div className={classes["item__img"]}>
                <img src={isDark ? School1Dark : School1Light} alt="School" />
              </div>
              <div className={classes["item__text"]}>
                <h2 className={classes["item__title"]}>
                  Streamlined certificate distribution process.
                </h2>
                <p>
                  Schools can use the platform to easily issue NFT certificates
                  to their students, eliminating the need for paper-based
                  certificate issuance and reducing administrative burden.
                </p>
              </div>
            </div>

            <div className={`${classes["item"]} ${classes["item--reverse"]}`}>
              <div className={classes["item__text"]}>
                <h2 className={classes["item__title"]}>
                  Enhanced security and verifiability
                </h2>
                <p>
                  By using blockchain technology, the platform ensures that
                  issued NFT certificates are secure and verifiable, reducing
                  the risk of lost or fake documents and streamlining the audit
                  process for everyone.
                </p>
              </div>
              <div className={classes["item__img"]}>
                <img src={isDark ? School2Dark : School2Light} alt="School" />
              </div>
            </div>
            <div className={classes["item"]}>
              <div className={classes["item__img"]}>
                <img src={isDark ? School1Dark : School1Light} alt="School" />
              </div>
              <div className={classes["item__text"]}>
                <h2 className={classes["item__title"]}>
                  Say goodbye to paper-based certificates.
                </h2>
                <p>
                  You can easily issue, verify, and showcase your NFT
                  certificates online, knowing that they are protected by
                  blockchain technology. No more worrying about lost or fake
                  documents - your NFT certificates are stored on the
                  blockchain.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      )}
      {isStudentTab && (
        <div className={classes["tab-items"]}>
          <Fade direction="up" triggerOnce cascade>
            <div className={`${classes["item"]} ${classes["item--reverse"]}`}>
              <div className={classes["item__text"]}>
                <h2 className={classes["item__title"]}>
                  Enhanced security and verifiability
                </h2>
                <p>
                  By using blockchain technology, the platform ensures that
                  issued NFT certificates are secure and verifiable, reducing
                  the risk of lost or fake documents and streamlining the audit
                  process for everyone.
                </p>
              </div>
              <div className={classes["item__img"]}>
                <img src={isDark ? School2Dark : School2Light} alt="School" />
              </div>
            </div>
            <div className={classes["item"]}>
              <div className={classes["item__img"]}>
                <img src={isDark ? School1Dark : School1Light} alt="School" />
              </div>
              <div className={classes["item__text"]}>
                <h2 className={classes["item__title"]}>
                  Say goodbye to paper-based certificates.
                </h2>
                <p>
                  You can easily issue, verify, and showcase your NFT
                  certificates online, knowing that they are protected by
                  blockchain technology. No more worrying about lost or fake
                  documents - your NFT certificates are stored on the
                  blockchain.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default BenefitsTabs;
