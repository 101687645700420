import React, { Fragment } from "react";
import classes from "./RegisterSchoolForm.module.scss";

interface ISummary {
  formData: any;
  fieldTitles: {
    [key: string]: string;
  };
}

const Summary = ({ formData, fieldTitles }: ISummary) => {
  return (
    <div className={classes["step-summary"]}>
      {Object.keys(formData).map((field, i) => {
        if (
          field !== "CERP" &&
          field !== "PACE" &&
          field !== "new_pass" &&
          field !== "confirm_new_pass" &&
          field !== "banner" &&
          field !== "pic" &&
          field !== "country" &&
          field !== "state" &&
          field !== "city"
        ) {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  {formData[field] && (
                    <>
                      <div className={classes["title"]}>
                        {fieldTitles[field]}
                      </div>
                      <div
                        className={`${classes["description"]} ${
                          classes[
                            field === "description"
                              ? "description--small"
                              : "description--normal"
                          ]
                        }`}
                      >
                        {formData[field]["value"]
                          ? formData[field]["value"]
                          : formData[field]}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Fragment>
          );
        }
        if (field === "country" || field === "state" || field === "city") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  {formData[field] && (
                    <>
                      <div className={classes["title"]}>
                        {fieldTitles[field]}
                      </div>
                      <div
                        className={`${classes["description"]} ${classes["description--normal"]}`}
                      >
                        {field === "state"
                          ? formData[field]["code"]
                          : formData[field]["label"]}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Fragment>
          );
        }
        if (field === "pic" || field === "banner") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title-image"]}>
                    {fieldTitles[field]}
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${formData[field]})`
                    }}
                    className={classes[field]}
                  />
                </div>
              )}
            </Fragment>
          );
        }
        if (field === "CERP" || field === "PACE") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title"]}>{fieldTitles[field]}</div>
                  <div
                    className={`${classes["description"]} ${classes["description--normal"]}`}
                  >
                    {formData[field]["name"]}
                  </div>
                </div>
              )}
            </Fragment>
          );
        }
        return <Fragment />;
      })}
    </div>
  );
};

export default Summary;
