import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import * as Yup from "yup";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { useFormData } from "hooks/useFormData";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import Button from "components/buttons/Button";
import { InputField } from "components/input/Input";
import FormSteps from "components/forms/form-steps/FormSteps";
import ImageInput from "components/image-input/ImageInput";
import GenericPopup from "components/popups/GenericPopup";
import { popupContext } from "context/popupContext";
import { fetchApi } from "utils/requests";
import { useMutation, useQuery } from "@tanstack/react-query";
import { yupSchemas } from "utils/yupSchemas";
import { hasErrors } from "utils/form";
import {
  ICity,
  ICountry,
  ISchoolInfoForm
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { getFileName } from "utils/format";
import { fileInstructions } from "utils/staticOptions";
import classes from "./RegisterSchoolForm.module.scss";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { citiesDataQuery, countriesDataQuery } from "query";
import Summary from "./Summary";

interface IExtendedSchoolInfo
  extends Omit<ISchoolInfoForm, "activation_status" | "deactivate_note"> {
  first_name: string;
  last_name: string;
  email: string;
  new_pass: string;
  confirm_new_pass: string;
}

const validationSchema: Yup.SchemaOf<
  Omit<
    IExtendedSchoolInfo,
    | "tenant"
    | "_id"
    | "is_blacklisted"
    | "activation_status"
    | "deactivate_note"
    | "reg_date"
  >
> = Yup.object().shape({
  first_name: yupSchemas.requiredStr,
  last_name: yupSchemas.requiredStr,
  email: yupSchemas.email,
  new_pass: yupSchemas.new_pass,
  confirm_new_pass: yupSchemas.confirm_new_pass,
  name: yupSchemas.requiredStr,
  country: yupSchemas.labelAndValue.required(),
  state: yupSchemas.labelAndValue.required(),
  city: yupSchemas.labelAndValue.required(),
  zip: Yup.string(),
  addr1: yupSchemas.requiredStr,
  addr2: Yup.string(),
  phone: yupSchemas.phone,
  fax: yupSchemas.fax,
  website: yupSchemas.website,
  description: Yup.string(),
  CERP: Yup.string(),
  CERP_provider_number: Yup.string(),
  PACE: Yup.string(),
  PACE_provider_number: Yup.string(),
  pic: Yup.string(),
  banner: Yup.string()
});

const defaultFormData: IExtendedSchoolInfo = {
  first_name: "",
  last_name: "",
  email: "",
  new_pass: "",
  confirm_new_pass: "",
  name: "",
  country: null,
  state: null,
  city: null,
  zip: "",
  addr1: "",
  addr2: "",
  phone: "",
  fax: "",
  website: "",
  description: "",
  CERP: "",
  CERP_provider_number: "",
  PACE: "",
  PACE_provider_number: "",
  pic: "",
  banner: ""
};

const fieldTitles = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "E-mail",
  password: "Password",
  confirmPassword: "Confirm Password",
  name: "School Name",
  country: "School Country",
  state: "School State",
  city: "School City",
  zip: "School Zip Code",
  addr1: "School Street Address 1",
  addr2: "School Street Address 2",
  phone: "School Phone Number",
  fax: "School Fax Number",
  website: "School Website",
  description: "School Description",
  CERP: "CERP Certification",
  CERP_provider_number: "CERP Provider Number",
  PACE: "PACE Certification",
  PACE_provider_number: "PACE Provider Number",
  pic: "School Profile Picture",
  banner: "School Banner"
};

const formSteps = ["School Details", "Confirm Application"];

const RegisterSchoolForm = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);
  const {
    formData,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    formErrors,
    setFormErrors
  } = useFormData(defaultFormData, validationSchema);
  const { setPopup } = useContext(popupContext);

  const pageRef = useRef(null);
  const executeScroll = () => pageRef.current.scrollIntoView();

  const fetchedCountriesData = useGetFetchQuery(
    countriesDataQuery()
  ) as ICountry[];

  const { isLoading: isLoadingCountries, error: errorCountries } = useQuery<
    boolean,
    Error
  >({
    ...countriesDataQuery(),
    enabled: !fetchedCountriesData
  });

  const selectedState = formData.state ? formData.state.value : null;

  const fetchedCitiesData = useGetFetchQuery(
    citiesDataQuery(selectedState)
  ) as ICity[];

  const { isLoading: isLoadingCities, error: errorCities } = useQuery<
    boolean,
    Error
  >({
    ...citiesDataQuery(selectedState),
    enabled: !!selectedState && !fetchedCitiesData
  });

  const getSelectedCountryStates = () => {
    const country =
      fetchedCountriesData && formData.country
        ? fetchedCountriesData.find((c) => c._id === formData.country.value)
        : { states: [] };
    return country.states.map((s) => ({
      label: `${s.state_code} - ${s.name}`,
      value: s._id,
      code: s.state_code,
      name: s.name
    }));
  };

  // Reset `city` on state change (Please do the same for `state` on country change, once we have more than one country)
  useEffect(() => {
    setFormData({
      ...formData,
      city: null
    });
  }, [formData.state]);

  const renderStep = (step: number) => {
    if (step === 0) {
      return (
        <div className={classes["step"]}>
          <InputField
            title={fieldTitles.first_name}
            isRequired
            name="first_name"
            placeholder="Enter First Name"
            onChange={handleChange}
            value={formData.first_name}
            error={formErrors.first_name}
          />
          <InputField
            title={fieldTitles.last_name}
            isRequired
            name="last_name"
            placeholder="Enter Last Name"
            onChange={handleChange}
            value={formData.last_name}
            error={formErrors.last_name}
          />
          <InputField
            title={fieldTitles.email}
            isRequired
            name="email"
            placeholder="Enter E-mail"
            onChange={handleChange}
            value={formData.email}
            error={formErrors.email}
          />
          <InputField
            type="password"
            title={fieldTitles.password}
            isRequired
            name="new_pass"
            placeholder="Enter Password"
            onChange={handleChange}
            value={formData.new_pass}
            error={formErrors.new_pass}
            showPasswordTooltip
          />
          <InputField
            type="password"
            title={fieldTitles.confirmPassword}
            isRequired
            name="confirm_new_pass"
            placeholder="Confirm Password"
            onChange={handleChange}
            value={formData.confirm_new_pass}
            error={formErrors.confirm_new_pass}
            showPasswordTooltip
          />
          <InputField
            title={fieldTitles.name}
            isRequired
            name="name"
            placeholder="Enter School Name"
            onChange={handleChange}
            value={formData.name}
            error={formErrors.name}
          />
          <ReactSelectWrapper
            title={fieldTitles.country}
            isRequired
            name="country"
            placeholder="Enter School Country"
            options={
              fetchedCountriesData &&
              fetchedCountriesData.map((country) => {
                return { label: country.name, value: country._id };
              })
            }
            isSearchable
            value={formData.country}
            isLoading={isLoadingCountries}
            error={
              errorCountries ? errorCountries.message : formErrors.country.value
            }
            onChange={(e) =>
              handleChange({
                target: {
                  name: "country",
                  value: e
                }
              })
            }
          />
          <ReactSelectWrapper
            title={fieldTitles.state}
            isRequired
            name="state"
            placeholder="Enter School State"
            options={getSelectedCountryStates()}
            isSearchable
            value={formData.state}
            isLoading={isLoadingCountries}
            isDisabled={!formData.country}
            error={
              errorCountries ? errorCountries.message : formErrors.state.value
            }
            onChange={(e) =>
              handleChange({
                target: {
                  name: "state",
                  value: e
                }
              })
            }
          />
          <ReactSelectWrapper
            title={fieldTitles.city}
            isRequired
            name="city"
            placeholder="Enter School City"
            options={
              fetchedCitiesData &&
              fetchedCitiesData.map((city) => {
                return { label: city.name, value: city.name };
              })
            }
            isSearchable
            value={formData.city}
            isDisabled={!formData.state}
            error={errorCities ? errorCities.message : formErrors.city.value}
            onChange={(e) =>
              handleChange({
                target: {
                  name: "city",
                  value: e
                }
              })
            }
          />
          <InputField
            type="number"
            title={fieldTitles.zip}
            name="zip"
            placeholder="Enter School Zip Code"
            onChange={handleChange}
            value={formData.zip}
            error={formErrors.zip}
          />
          <InputField
            title={fieldTitles.addr1}
            isRequired
            name="addr1"
            placeholder="Enter School Street Address 1"
            onChange={handleChange}
            value={formData.addr1}
            error={formErrors.addr1}
          />
          <InputField
            title={fieldTitles.addr2}
            name="addr2"
            placeholder="Enter School Street Address 2"
            onChange={handleChange}
            value={formData.addr2}
            error={formErrors.addr2}
          />
          <InputField
            title={fieldTitles.phone}
            isRequired
            name="phone"
            placeholder="Enter School Phone Number"
            onChange={handleChange}
            value={formData.phone}
            error={formErrors.phone}
            showTooltipIcon="10-digit phone number without special symbols (e.g. 0647909921)"
          />
          <InputField
            title={fieldTitles.fax}
            name="fax"
            placeholder="Enter School Fax Number"
            onChange={handleChange}
            value={formData.fax}
            error={formErrors.fax}
            showTooltipIcon="10-digit fax number without special symbols (e.g. 0647909921)"
          />
          <InputField
            type="url"
            title={fieldTitles.website}
            isRequired
            name="website"
            placeholder="Enter School Website"
            onChange={handleChange}
            value={formData.website}
            error={formErrors.website}
          />
          <InputField
            type="textarea"
            title={fieldTitles.description}
            name="description"
            placeholder="Enter School Description"
            onChange={handleChange}
            value={formData.description}
            error={formErrors.description}
          />
          <InputField
            type="file"
            fileType=".pdf"
            fileName={
              typeof formData.CERP !== "string" && formData.CERP.name
                ? formData.CERP.name
                : ""
            }
            onClear={() => {
              setFormData({
                ...formData,
                CERP: "",
                CERP_provider_number: ""
              });
              setFormErrors({ ...formErrors, CERP: "" });
            }}
            title={fieldTitles.CERP}
            subtitle={fileInstructions.pdf}
            name="CERP"
            onChange={(e: FormEvent) => handleUploadedFileBase64("CERP", e)}
            maxWidth={200}
            error={formErrors.CERP.toString()}
          />
          {formData.CERP && (
            <InputField
              title={fieldTitles.CERP_provider_number}
              name="CERP_provider_number"
              placeholder="Enter CERP provider number"
              onChange={handleChange}
              value={formData.CERP_provider_number}
              error={formErrors.CERP_provider_number}
            />
          )}
          <InputField
            type="file"
            fileType=".pdf"
            fileName={
              typeof formData.PACE !== "string" && formData.PACE.name
                ? formData.PACE.name
                : ""
            }
            onClear={() => {
              setFormData({
                ...formData,
                PACE: "",
                PACE_provider_number: ""
              });
              setFormErrors({ ...formErrors, PACE: "" });
            }}
            title={fieldTitles.PACE}
            subtitle={fileInstructions.pdf}
            name="PACE"
            onChange={(e: FormEvent) => handleUploadedFileBase64("PACE", e)}
            maxWidth={200}
            error={formErrors.PACE.toString()}
          />
          {formData.PACE && (
            <InputField
              title={fieldTitles.PACE_provider_number}
              name="PACE_provider_number"
              placeholder="Enter PACE provider number"
              onChange={handleChange}
              value={formData.PACE_provider_number}
              error={formErrors.PACE_provider_number}
            />
          )}
          <ImageInput
            title={fieldTitles.pic}
            subtitle={fileInstructions.pfp}
            fileType="image/png, image/jpeg, image/jpg"
            pic={formData.pic}
            onChange={(e: FormEvent) => handleUploadedFileBase64("pic", e)}
            onClear={() => {
              setFormData({
                ...formData,
                pic: ""
              });
              setFormErrors({ ...formErrors, pic: "" });
            }}
            error={formErrors.pic}
          />
          <ImageInput
            title={fieldTitles.banner}
            subtitle={fileInstructions.banner}
            fileType="image/png, image/jpeg, image/jpg"
            pic={formData.banner}
            onChange={(e: FormEvent) => handleUploadedFileBase64("banner", e)}
            onClear={() => {
              setFormData({
                ...formData,
                banner: ""
              });
              setFormErrors({ ...formErrors, banner: "" });
            }}
            error={formErrors.banner}
            isFullWidth
          />
          <div className={classes["buttons-container"]}>
            <Button
              className={classes["next-btn"]}
              onClick={() => buttonClickHandler("next")}
              icon={ArrowIcon}
              iconPosition="right"
              isDisabled={
                hasErrors(formErrors) ||
                !formData.first_name ||
                !formData.last_name ||
                !formData.email ||
                !formData.new_pass ||
                !formData.confirm_new_pass ||
                !formData.name ||
                !formData.country ||
                !formData.state ||
                !formData.city ||
                !formData.addr1 ||
                !formData.phone ||
                !formData.website
              }
            >
              Next
            </Button>
          </div>
        </div>
      );
    }
    if (step === 1) {
      return (
        <>
          <div className={classes["step"]}>
            <Summary formData={formData} fieldTitles={fieldTitles} />
            <div className={classes["buttons-container"]}>
              <Button
                variant="link"
                className={classes["prev-btn"]}
                onClick={() => buttonClickHandler("prev")}
                icon={ArrowIcon}
                iconRotate={180}
              >
                Back
              </Button>
              <Button
                className={classes["next-btn"]}
                onClick={handleRegister}
                icon={checkIcon}
                iconPosition="right"
                isDisabled={hasErrors(formErrors)}
                isFetching={isLoading}
              >
                Send Application
              </Button>
            </div>
          </div>
        </>
      );
    }
  };

  const buttonClickHandler = (dir: string) => {
    if (dir === "next") {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const register = async (formData: IExtendedSchoolInfo) => {
    const data = {
      userInfo: {
        email: formData.email,
        password: formData.new_pass,
        first_name: formData.first_name,
        last_name: formData.last_name
      },
      schoolInfo: {
        name: formData.name,
        addr1: formData.addr1,
        addr2: formData.addr2,
        city: formData.city.label,
        state: {
          name: formData.state.name,
          code: formData.state.code
        },
        zip: +formData.zip,
        country: formData.country.label,
        phone: formData.phone,
        fax: formData.fax,
        website: formData.website,
        description: formData.description,
        banner: formData.banner,
        pic: formData.pic,
        CERP: formData.CERP
          ? {
              file:
                typeof formData.CERP !== "string"
                  ? formData.CERP.value
                  : formData.CERP,
              name: getFileName(
                typeof formData.CERP !== "string"
                  ? formData.CERP.name
                  : formData.CERP
              )
            }
          : {
              file: "",
              name: ""
            },
        CERP_provider_number: formData.CERP_provider_number,
        PACE: formData.PACE
          ? {
              file:
                typeof formData.PACE !== "string"
                  ? formData.PACE.value
                  : formData.PACE,
              name: getFileName(
                typeof formData.PACE !== "string"
                  ? formData.PACE.name
                  : formData.PACE
              )
            }
          : {
              file: "",
              name: ""
            },
        PACE_provider_number: formData.PACE_provider_number
      }
    };
    await fetchApi("courses", "/school/register", {
      method: "POST",
      data
    });
  };

  // Register mutation
  const { isLoading, mutate: handleRegister } = useMutation({
    mutationKey: ["register"],
    mutationFn: async () => await register(formData),
    onSuccess: () => {
      setPopup(
        <GenericPopup
          msg="You successfully sent your school registration application! We will review your application and notify you within 5 business days."
          redirectPath="/login"
          buttonName="Back to Log in"
          buttonVariant="contrast"
          size="md"
          isClosable={false}
        />
      );
      setFormData(defaultFormData);
      setCurrentStep(0);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  useEffect(() => {
    switch (currentStep) {
      case 0:
        executeScroll();
        setFinishedSteps(0);
        break;
      case 1:
        setFinishedSteps(1);
        executeScroll();
        break;
      case 2:
        executeScroll();
        setFinishedSteps(2);
        break;
      default:
        break;
    }
  }, [currentStep]);

  return (
    <div
      ref={pageRef}
      className={`${classes["wrapper"]} ${classes["form-md"]}`}
    >
      <FormSteps
        title="Apply for Registration"
        steps={formSteps}
        currentStep={currentStep}
        finishedSteps={finishedSteps}
        setCurrentStep={(step: number) => setCurrentStep(step)}
      />
      <div className={classes["form-layout"]}>
        <div className={classes["title"]}>{formSteps[currentStep]}</div>
        <div className={classes["body"]}>{renderStep(currentStep)}</div>
      </div>
    </div>
  );
};

export default RegisterSchoolForm;
