import React from "react";
import Card from "./Card";
import classes from "./CoursesList.module.scss";
import { useNavigate } from "react-router-dom";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";

interface ICourseList {
  courses: ICourse[];
  isTemplate?: boolean;
  isDraft?: boolean;
}

const CoursesList = ({ courses, isTemplate, isDraft }: ICourseList) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={classes["cards-wrapper"]}>
        {courses.map((course) => (
          <Card
            key={course._id}
            name={isDraft && !course.name ? "(No name)" : course.name}
            _id={course._id}
            image={course.image}
            has_mints={course.has_mints}
            startDate={!isTemplate && !isDraft && course.start_date}
            onClick={() =>
              isDraft
                ? navigate(`/courses-drafts/${course._id}/edit`)
                : navigate(course._id)
            }
            hasMenu={!isTemplate}
            isTemplate={isTemplate}
            isOpen={course.is_open}
            isDraft={isDraft}
          />
        ))}
      </div>
    </>
  );
};

export default CoursesList;
