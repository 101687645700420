import * as React from "react";
import { useRoutes, Navigate, useParams } from "react-router-dom";

// Layouts
import { ProtectedLayout, UnprotectedLayout } from "layouts";

// Landing
import Landing from "./landing";

// Auth
import Login from "./auth/login";
import RegisterSchool from "./auth/register-school";
import RegisterStudent from "./auth/register-student";
import ActivateUser from "./auth/activate-user";
import VerifyUser from "./auth/verify-user";

// Dashboard
import Dashboard from "./dashboard";
import Profile from "./dashboard/profile";
import EditProfile from "./dashboard/profile/edit";
import MainDashboard from "./dashboard/mainDashboard";
import MainDashboardSchools from "./dashboard/mainDashboard/schools";
import School from "./dashboard/school";
import SingleSchool from "./dashboard/school/single";
import EditSchool from "./dashboard/school/edit";
import Courses from "./dashboard/courses";
import CreateCourse from "./dashboard/courses/create";
import EditCourse from "./dashboard/courses/edit";
import SingleCourse from "./dashboard/courses/single";
import CourseTemplates from "./dashboard/courses/templates";
import SingleTemplate from "./dashboard/courses/templates/single";
import Team from "./dashboard/team";
import Students from "./dashboard/students";
import EditStudent from "./dashboard/students/edit";
import LoginForm from "./auth/login/components/forms/LoginForm";
import ForgotPasswordForm from "./auth/login/components/forms/ForgotPasswordForm";
import ResetPassword from "./auth/reset-password";
import Page404 from "./404";
import PermissionsGate from "roles/PermissionsGate";
import DynamicBreadcrumb from "components/breadcrumbs/DynamicBreadcrumb";
import DiplomaBreadcrumb from "components/breadcrumbs/DiplomaBreadcrumb";
import CourseBreadcrumb from "../components/breadcrumbs/CourseBreadcrumb";
import MemberBreadcrumb from "../components/breadcrumbs/MemberBreadcrumb";
import StudentBreadcrumb from "../components/breadcrumbs/StudentBreadcrumb";
import EditMember from "./dashboard/team/edit";
import CreateMember from "./dashboard/team/create";
import Diplomas from "./dashboard/diplomas";
import SingleDiploma from "./dashboard/diplomas/single";
import CreateDiploma from "./dashboard/diplomas/admin/CreateDiploma";
import StudentDiplomas from "./dashboard/diplomas/student/StudentDiplomas";
import VerifyDiploma from "./dashboard/diplomas/verify-diploma/VerifyDiploma";
import { useContext } from "react";
import { IUserCtx, userContext } from "../context/userContext";
import SchoolCourses from "./dashboard/school/components/Courses";

const DeleteToken = () => {
  localStorage.removeItem("token");
  return <Navigate to="/login" />;
};

// Navigate `/diplomas/${diplomaId}` to `/diplomas/${diplomaId}/edit`
const DiplomaTemplateElement = () => {
  const { diplomaId } = useParams();
  const { userData } = useContext(userContext);
  const { is_student } = userData || {};

  // userContext not set yet
  if (is_student === undefined) return;

  if (is_student) return <SingleDiploma />;

  return <Navigate to={`/diplomas/${diplomaId}/edit`} replace={true} />;
};

// Navigate `/students/:studentId/diplomas` to `/students/:studentId`
const DiplomasElement = () => {
  const { studentId } = useParams();
  return <Navigate to={`/students/${studentId}`} replace={true} />;
};

// Navigate `/courses-drafts/:courseDraftId` to `/courses-drafts/:courseDraftId/edit`
const SingleCourseDraftElement = () => {
  const { courseDraftId } = useParams();
  return (
    <Navigate to={`/courses-drafts/${courseDraftId}/edit`} replace={true} />
  );
};

export const routes = (userData: IUserCtx["userData"]) => {
  return [
    // Does not require login
    {
      element: <UnprotectedLayout />,
      children: [
        {
          path: "/",
          element: <Landing />
        },
        {
          path: "/login",
          element: (
            <Login>
              <LoginForm />
            </Login>
          )
        },
        {
          path: "/forgot-password",
          element: (
            <Login>
              <ForgotPasswordForm />
            </Login>
          )
        },
        {
          path: "/reset-password",
          element: <ResetPassword />
        },
        {
          path: "/school-register",
          element: <RegisterSchool />,
          breadcrumb: "School Register"
        },
        {
          path: "/student-register",
          element: <RegisterStudent />,
          breadcrumb: "Student Register"
        },
        {
          path: "/user/activate",
          element: <ActivateUser />,
          breadcrumb: "Finish Registration"
        },
        {
          path: "/user/verify",
          element: <VerifyUser />,
          breadcrumb: "Verify Registration"
        },
        {
          path: "/students/:studentId/diplomas/preview",
          element: <StudentDiplomas isPublicPage />,
          breadcrumb: "Certificates"
        },
        {
          path: "/students/:studentId/diplomas/:diplomaId/preview",
          element: <SingleDiploma isPublicPage />,
          breadcrumb: "test"
        },
        {
          path: "/students/:studentId/diplomas/slideshow",
          element: <StudentDiplomas isPublicPage isSlideshow />,
          breadcrumb: "Certificates"
        },
        {
          path: "/verify-diploma",
          element: <VerifyDiploma />,
          breadcrumb: "Verify Certificate"
        }
      ]
    },
    // Dashboard views (Required access rights)
    {
      element: <ProtectedLayout />,
      children: [
        {
          element: <Dashboard />,
          children: [
            {
              path: "/profile",
              element: <Profile page="my-profile" />
            },
            {
              path: "/profile/edit",
              element: <EditProfile />,
              breadcrumb: "Edit My Details"
            },
            {
              path: "/main-dashboard",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <MainDashboard />
                </PermissionsGate>
              )
            },
            {
              path: "admin-schools",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <MainDashboardSchools />
                </PermissionsGate>
              ),
              breadcrumb: "Schools"
            },
            {
              path: "/admin-schools/:schoolId",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <SingleSchool />
                </PermissionsGate>
              ),
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/admin-schools/:schoolId/team",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <Team perPage={16} hasNoActions />
                </PermissionsGate>
              )
            },
            {
              path: "/admin-schools/:schoolId/students",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <Students perPage={16} hasNoActions type="students" />
                </PermissionsGate>
              )
            },
            {
              path: "/admin-schools/:schoolId/courses",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <SchoolCourses perPage={8} hasNoActions />
                </PermissionsGate>
              )
            },
            {
              path: "admin-registration-requests",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <MainDashboardSchools isRequests />
                </PermissionsGate>
              )
            },
            {
              path: "/admin-students",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <Students perPage={16} hasNoActions type="students-admin" />
                </PermissionsGate>
              )
            },
            {
              path: "/admin-students/:studentId",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <Profile page="student" />
                </PermissionsGate>
              ),
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/admin-schools/:schoolId/students/:studentId",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <Profile page="student" />
                </PermissionsGate>
              ),
              breadcrumb: StudentBreadcrumb
            },
            {
              path: "/admin-schools/:schoolId/team/:memberId",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <Profile page="member" />
                </PermissionsGate>
              ),
              breadcrumb: MemberBreadcrumb
            },
            {
              path: "/admin-schools/:schoolId/courses/:courseId",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <SingleCourse />
                </PermissionsGate>
              ),
              breadcrumb: CourseBreadcrumb
            },
            {
              path: "/school",
              element: <School />
            },
            {
              path: "/school/:schoolId",
              element: (
                <PermissionsGate permissions={["ROLE.STUDENT"]} redirect>
                  <SingleSchool />
                </PermissionsGate>
              ),
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/school/:schoolId/:requestId",
              element: (
                <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]} redirect>
                  <SingleSchool />
                </PermissionsGate>
              ),
              breadcrumb: "Resolve"
            },
            {
              path: "/school/edit",
              element: (
                <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
                  <EditSchool />
                </PermissionsGate>
              ),
              breadcrumb: "Edit School Info"
            },
            {
              path: "/courses",
              element: (
                <PermissionsGate
                  permissions={
                    userData?.is_student ? ["ROLE.STUDENT"] : ["COURSE.GET"]
                  }
                  redirect
                >
                  <Courses tab="active" />
                </PermissionsGate>
              )
            },
            {
              path: "/courses-drafts",
              element: (
                <PermissionsGate permissions={["COURSE.CREATE"]} redirect>
                  <Courses tab="drafts" />
                </PermissionsGate>
              )
            },
            {
              path: "/courses/create",
              element: (
                <PermissionsGate permissions={["COURSE.CREATE"]} redirect>
                  <CreateCourse />
                </PermissionsGate>
              ),
              breadcrumb: "Create Course"
            },
            {
              path: "/courses/:courseId",
              element: <SingleCourse />,
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/courses/:courseId/attend/:attendance_hash",
              element: (
                <PermissionsGate permissions={["ROLE.STUDENT"]} redirect>
                  <SingleCourse />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: "/courses/:courseId/edit",
              element: (
                <PermissionsGate permissions={["COURSE.UPDATE"]} redirect>
                  <EditCourse />
                </PermissionsGate>
              ),
              breadcrumb: "Edit Course Info"
            },
            {
              path: "/courses/templates",
              element: (
                <PermissionsGate permissions={["COURSE.UPDATE"]} redirect>
                  <CourseTemplates />
                </PermissionsGate>
              ),
              breadcrumb: "Course Templates"
            },
            {
              path: "/courses/templates/:courseId",
              element: (
                <PermissionsGate permissions={["COURSE.GET"]} redirect>
                  <SingleTemplate />
                </PermissionsGate>
              ),
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/courses/:courseId/createFromTemplate",
              element: (
                <PermissionsGate permissions={["COURSE.CREATE"]} redirect>
                  <EditCourse isCreateFromTemplate />
                </PermissionsGate>
              ),
              breadcrumb: "Create Course From Template"
            },
            {
              path: "/courses-drafts/:courseDraftId",
              element: <SingleCourseDraftElement />,
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/courses-drafts/:courseDraftId/edit",
              element: (
                <PermissionsGate permissions={["COURSE.CREATE"]} redirect>
                  <EditCourse isDraft />
                </PermissionsGate>
              ),
              breadcrumb: "Edit Course Draft"
            },
            {
              path: "/team",
              element: (
                <PermissionsGate permissions={["SCHOOL.GET"]} redirect>
                  <Team />
                </PermissionsGate>
              )
            },
            {
              path: "/team/:memberId",
              element: (
                <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
                  <Profile page="member" />
                </PermissionsGate>
              ),
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/team/:memberId/edit",
              element: (
                <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
                  <EditMember />
                </PermissionsGate>
              ),
              breadcrumb: "Edit Member Details"
            },
            {
              element: (
                <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
                  <CreateMember />
                </PermissionsGate>
              ),
              path: "/team/add-member",
              breadcrumb: "Add Member"
            },
            {
              path: "/students",
              element: (
                <PermissionsGate permissions={["STUDENT.GET"]} redirect>
                  <Students type="students" />
                </PermissionsGate>
              )
            },
            {
              path: "/students/:studentId",
              element: (
                <PermissionsGate permissions={["STUDENT.GET"]} redirect>
                  <Profile page="student" />
                </PermissionsGate>
              ),
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/students/:studentId/edit",
              element: (
                <PermissionsGate permissions={["STUDENT.UPDATE"]} redirect>
                  <EditStudent />
                </PermissionsGate>
              ),
              breadcrumb: "Edit Student Details"
            },
            {
              path: "/students/:studentId/diplomas/:diplomaId",
              element: (
                <PermissionsGate permissions={["STUDENT.GET"]} redirect>
                  <SingleDiploma />
                </PermissionsGate>
              ),
              breadcrumb: DiplomaBreadcrumb
            },
            {
              path: "/students/:studentId/diplomas",
              element: <DiplomasElement />,
              breadcrumb: "Certificates"
            },
            {
              path: "/diplomas",
              element: (
                <PermissionsGate
                  permissions={
                    userData?.is_student ? ["ROLE.STUDENT"] : ["TEMPLATE.GET"]
                  }
                  redirect
                >
                  <Diplomas tab="templates" />
                </PermissionsGate>
              )
            },
            {
              path: "/diplomas/:diplomaId",
              element: <DiplomaTemplateElement />,
              breadcrumb: DynamicBreadcrumb
            },
            {
              path: "/diplomas/:templateId/edit",
              element: (
                <PermissionsGate permissions={["TEMPLATE.UPDATE"]} redirect>
                  <CreateDiploma isEdit />
                </PermissionsGate>
              )
            },
            {
              path: "/diplomas/create",
              element: (
                <PermissionsGate permissions={["TEMPLATE.CREATE"]} redirect>
                  <CreateDiploma />
                </PermissionsGate>
              ),
              breadcrumb: "Create Template"
            },
            {
              path: "/requests",
              element: (
                <PermissionsGate permissions={["STUDENT.GET"]} redirect>
                  <Diplomas tab="requests" />
                </PermissionsGate>
              )
            },
            {
              path: "/ready-to-mint",
              element: (
                <PermissionsGate permissions={["STUDENT.GET"]} redirect>
                  <Diplomas tab="ready-to-mint" />
                </PermissionsGate>
              )
            }
          ]
        }
      ]
    },
    {
      path: "/404",
      element: <Page404 />,
      breadcrumb: null
    },
    {
      path: "*",
      element: <Navigate to="/404" replace={true} />
    },
    {
      path: "/token-delete",
      element: <DeleteToken />
    }
  ];
};

export function Router() {
  const { userData } = useContext(userContext);
  const routing = useRoutes(routes(userData));

  return routing;
}
