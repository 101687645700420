import { useContext, useEffect, useMemo, useRef } from "react";
import {
  useTable,
  usePagination,
  useRowSelect,
  Column,
  useFlexLayout
} from "react-table";
import classes from "./Table.module.scss";
import { userContext } from "context/userContext";
import { themeContext } from "context/themeContext";
import { useNavigate } from "react-router-dom";
import Pagination from "components/pagination/Pagination";
import {
  IMemberData,
  ISchoolInfo,
  ISchoolRegRequests
} from "utils/apiDataTypes/AccountManagementDataTypes";
import {
  ICourse,
  IMintRequest,
  IReadyToMint,
  IStudent,
  ITemplate
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { useVirtual } from "react-virtual";
import { windowContext } from "context/windowsContext";

export interface ITable {
  type:
    | "team"
    | "diplomas"
    | "attendees"
    | "managers"
    | "mint-diplomas"
    | "mint-requests"
    | "students"
    | "students-admin"
    | "diploma-requests"
    | "ready-to-mint"
    | "ready-to-mint-popup-table"
    | "school-registration-requests"
    | "schools"
    | "courses";
  tableData: any;
  columns: Column;
  activePage: number;
  passActivePageUp: (page: number) => void;
  itemsAmountMsg?: React.ReactNode;
  className?: string;
  helperData?: any;
  perPage?: number;
  setPerPage?: (amount: number) => void;
  showIs?: "Show All" | "Show Pages" | "none";
  passSelectedRowsUp?: (rows) => void;
  hiddenColumns?: string[];
  isPaginationHidden?: boolean;
  schoolId?: string;
}

const Table = ({
  type,
  tableData,
  columns,
  activePage,
  passActivePageUp,
  className,
  helperData,
  itemsAmountMsg,
  perPage,
  setPerPage,
  showIs,
  passSelectedRowsUp,
  hiddenColumns,
  isPaginationHidden,
  schoolId
}: ITable) => {
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};
  const { theme } = useContext(themeContext);
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const data = useMemo(() => {
    if (type === "team") {
      return tableData?.result.map((dataPiece: IMemberData) => {
        return {
          names: dataPiece.first_name
            ? `${dataPiece.first_name + " " + dataPiece.last_name}`
            : "Admin",
          // When role obj is missing, the returned user is a `Super admin`
          role_name: dataPiece.role ? dataPiece.role.name : "Super Admin",
          role_flag: dataPiece.role.role_flag,
          email: dataPiece.email,
          //registration: dataPiece.registration,
          //wallet_address: dataPiece.wallet_address,
          //diploma: dataPiece.diploma,
          pic: dataPiece.pic,
          title: dataPiece.title,
          id: dataPiece._id,
          registered: dataPiece.registered,
          active: dataPiece.active,
          status: dataPiece.registered
            ? dataPiece.active
              ? "Active"
              : "Disabled"
            : "Pending"
        };
      });
    }
    if (type === "diplomas") {
      return tableData.result.map((dataPiece: ITemplate) => {
        return {
          id: dataPiece._id,
          name: dataPiece.name,
          pic: dataPiece.thumbnail
        };
      });
    }
    if (type === "attendees") {
      return tableData.result.map((dataPiece: any) => {
        return {
          id: dataPiece._id,
          userId: dataPiece.user_id,
          names: dataPiece.first_name
            ? `${dataPiece.first_name + " " + dataPiece.last_name}`
            : "-",
          title: dataPiece.title,
          email: dataPiece.email,
          wallet: dataPiece.wallet,
          active: dataPiece.active,
          registered: dataPiece.registered,
          diploma_status: dataPiece.diploma_status,
          attendance_status: dataPiece.attendance_status,
          ipfs_diploma_id: dataPiece.ipfs_diploma_id,
          pic: dataPiece.pic,
          custom_diploma_date: dataPiece.custom_diploma_date,
          is_blacklisted: dataPiece.is_blacklisted,
          role_flag: dataPiece.role_flag
        };
      });
    }
    if (type === "managers") {
      return tableData?.map((dataPiece: IMemberData) => {
        const assignedManager = helperData
          ? helperData.filter((m) => m.user_id === dataPiece._id)
          : [];

        const isAssigned = assignedManager.length > 0;

        return {
          names: dataPiece.first_name
            ? `${dataPiece.first_name + " " + dataPiece.last_name}`
            : "Admin",
          title: dataPiece.title,
          id: dataPiece._id,
          userId: isAssigned ? assignedManager[0]._id : "",
          pic: dataPiece.pic
        };
      });
    }
    if (type === "students" || type === "students-admin") {
      return tableData?.result.map((dataPiece: IStudent, i) => {
        return {
          id: dataPiece._id,
          names: dataPiece.first_name
            ? `${dataPiece.first_name + " " + dataPiece.last_name}`
            : "-",
          email: dataPiece.email,
          registered: dataPiece.registered,
          active: dataPiece.active,
          pic: dataPiece.pic,
          title: dataPiece.title || "",
          is_blacklisted: dataPiece.is_blacklisted,
          role_flag: dataPiece.role_flag
        };
      });
    }
    if (type === "mint-diplomas") {
      return tableData.map((dataPiece: IStudent) => {
        return {
          id: dataPiece._id,
          names: `${dataPiece.first_name + " " + dataPiece.last_name}`,
          title: dataPiece.title,
          pic: dataPiece.pic,
          wallet: dataPiece.wallet
        };
      });
    }
    if (type === "diploma-requests") {
      return tableData?.result.map((dataPiece: IMintRequest) => {
        return {
          request_id: dataPiece._id,
          request_type: dataPiece.type,
          requested_by: dataPiece.requested_by,
          requested_for: dataPiece.requested_for,
          request_date: dataPiece.date_requested,
          status: dataPiece.status,
          course: dataPiece.course[0]
        };
      });
    }
    if (type === "mint-requests") {
      return tableData.map((dataPiece: IMintRequest) => {
        return {
          id: dataPiece.request_id,
          names: `${
            dataPiece.requested_by.first_name +
            " " +
            dataPiece.requested_by.last_name
          }`,
          title: dataPiece.requested_by.title,
          pic: dataPiece.requested_by.pic || "",
          wallet: dataPiece.requested_by.wallet,
          course: dataPiece.course
        };
      });
    }
    if (type === "ready-to-mint") {
      return tableData?.result.map((dataPiece: IReadyToMint) => {
        return {
          user: dataPiece.user,
          course: dataPiece.course,
          _id: dataPiece._id
        };
      });
    }
    if (type === "ready-to-mint-popup-table") {
      return tableData?.map((dataPiece: IReadyToMint) => {
        return {
          ...dataPiece,
          user: {
            ...dataPiece.user,
            names: dataPiece.user.first_name + " " + dataPiece.user.last_name
          }
        };
      });
    }
    if (type === "school-registration-requests") {
      return tableData.result.map((dataPiece: ISchoolRegRequests) => {
        return {
          id: dataPiece._id,
          school: dataPiece.school,
          schoolName: dataPiece.school.name,
          date_requested: dataPiece.date_requested,
          requested_by: dataPiece.requested_by.email,
          school_status: dataPiece.status
        };
      });
    }
    if (type === "schools") {
      return tableData.result.map((dataPiece: ISchoolInfo) => ({
        ...dataPiece,
        schoolName: dataPiece.name
      }));
    }
    if (type === "courses") {
      return tableData.result.map((dataPiece: ICourse) => ({
        course: { ...dataPiece, id: dataPiece._id }
      }));
    }
  }, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: tableData.total_pages,
      autoResetPageIndex: false,
      autoResetPage: false,
      initialState: {
        pageIndex: activePage,
        ...(hiddenColumns && hiddenColumns.length > 0 ? { hiddenColumns } : {})
      }
    },
    usePagination,
    useRowSelect,
    useFlexLayout
  ) as any;

  const gotoPageHandler = (id: number) => {
    gotoPage(id);
    passActivePageUp(id);
  };

  const navigateHandler = (row, cellIndex: number) => {
    if (type === "team" && userData.role_flag === 4) {
      navigate(`/team/${row.original.id}`);
    }

    if (type === "team" && is_super_admin) {
      navigate(`/admin-schools/${schoolId}/team/${row.original.id}`);
    }

    if (type === "courses" && is_super_admin) {
      navigate(`/admin-schools/${schoolId}/courses/${row.original.course.id}`);
    }

    if (type === "schools" && is_super_admin) {
      navigate(`/admin-schools/${row.original._id}`);
    }

    if (type === "students") {
      navigate(
        is_super_admin
          ? `/admin-schools/${schoolId}/students/${row.original.id}`
          : `/students/${row.original.id}`
      );
    }

    if (type === "students-admin") {
      navigate(`/admin-students/${row.original.id}`);
    }

    if (type === "diplomas" && !isMobile) {
      navigate(`/diplomas/${row.original.id}/edit`);
    }
  };

  useEffect(() => {
    if (passSelectedRowsUp) {
      const originalRows = selectedFlatRows.map((flatRow) => flatRow.original);
      passSelectedRowsUp(originalRows);
    }
  }, [selectedFlatRows]);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 20,
    paddingStart: document.getElementById("table-head")?.clientHeight
  });
  const { virtualItems: virtualRows } = rowVirtualizer;

  return (
    <>
      {/* We use the id to target element and render the dropdowns oustide of the overflow context */}
      <div
        id="table-wrapper"
        className={classes["table-wrapper"]}
        ref={tableContainerRef}
        style={{
          maxHeight: 3500,
          height: "100%",
          width: `100%`,
          overflow: "auto"
        }}
      >
        <table
          {...getTableProps()}
          id="table"
          className={`${classes["table"]} ${className}`}
          data-theme={theme}
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: "100%",
            position: "relative"
          }}
        >
          <thead
            id="table-head"
            style={{
              // Fix thead height in order to resolve huge empty space issue on Firefox
              height: "43px"
            }}
          >
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, i) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps({
                          style: {
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth
                          }
                        })}
                        className={
                          i === headerGroup.headers.length - 1 && !isMobile
                            ? classes["u-justify--end"]
                            : ""
                        }
                      >
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()} id="table-body">
            {
              // Loop over the table rows
              virtualRows.map((virtualRow, i) => {
                const row = rows[virtualRow.index];
                row.distance_from_top = virtualRow.start;
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    ref={virtualRow.measureRef}
                    onClick={() => navigateHandler(row, i)}
                    className={`${classes[`row`]} ${
                      selectedFlatRows?.some(
                        (row) => +row.index === virtualRow.index
                      )
                        ? classes["selected"]
                        : ""
                    }
                    ${type === "diplomas" ? classes["diplomas-border"] : ""}
                  `}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      width: "100%",
                      transform: `translateY(${virtualRow.start}px)`,
                      zIndex: 1
                    }}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, i) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                maxWidth: cell.column.maxWidth,
                                cursor: "pointer",
                                position: "relative"
                              }
                            })}
                            className={`${classes["u-text--primary"]} ${
                              i === row.cells.length - 1 &&
                              !isMobile &&
                              classes["u-justify--end"]
                            }`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      {/* PAGINATION */}
      {!isPaginationHidden && (
        <Pagination
          className={classes["pagination"]}
          currentPage={activePage}
          totalCount={pageCount || 1}
          pageSize={1}
          onPageChange={(page) => gotoPageHandler(page)}
          itemsAmountMsg={itemsAmountMsg}
          perPage={perPage}
          setPerPage={setPerPage}
          showIs={showIs}
        />
      )}
    </>
  );
};

export default Table;
