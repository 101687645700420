import Button from "components/buttons/Button";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import classes from "./TableCols.module.scss";
import { IStudent } from "utils/apiDataTypes/CourseModuleDataTypes";
import { tableColumnsWidths } from "./utils/table-utils";
import RenderCell from "./RenderCell";

interface IMintDiplomaCols {
  selectedItems: IStudent[];
  setSelectedItems: (items: IStudent[]) => void;
}

export const MintDiplomaCols = (
  selectedItems: IMintDiplomaCols["selectedItems"],
  setSelectedItems: IMintDiplomaCols["setSelectedItems"]
) => {
  const removeHandler = (id: string) => {
    const filteredItems = selectedItems.filter((item) => item._id !== id);
    setSelectedItems(filteredItems);
  };
  return [
    {
      Header: "Attendee",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="mint_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "First/Last Name",
      accessor: "names",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => <RenderCell type="names" rowProps={row.original} />
    },
    {
      Header: "Wallet Address",
      accessor: "wallet_address",
      maxWidth: tableColumnsWidths.walletMax,
      minWidth: tableColumnsWidths.walletMin,
      width: tableColumnsWidths.wallet,
      Cell: ({ row }) => <RenderCell type="wallet" rowProps={row.original} />
    },
    {
      Header: (
        <div className={classes["u-justify--end"]}>
          {selectedItems.length} Attendee{selectedItems.length > 1 ? "s" : ""}
        </div>
      ),
      accessor: "action",
      maxWidth: tableColumnsWidths.actionsExtMax,
      minWidth: tableColumnsWidths.actionsExtMin,
      width: tableColumnsWidths.actionsExt,
      Cell: ({ row }) => {
        return (
          <div className={classes["u-justify--end"]}>
            <Button
              variant="link"
              size="medium"
              icon={TrashIcon}
              isIconBtn
              onClick={() => removeHandler(row.original.id)}
            />
          </div>
        );
      }
    }
  ];
};
