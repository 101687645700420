import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Button from "components/buttons/Button";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import {
  IMintRequest,
  IReadyToMintData
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { windowContext } from "context/windowsContext";
import { readyToMintDataQuery } from "query";
import { useQuery } from "@tanstack/react-query";
import { sortDirEnum } from "utils/staticOptions";
import { popupContext } from "context/popupContext";
import { Web3Context } from "context/web3Context";
import TooltipComp from "components/tooltip/TooltipComp";
import ReadyToMintTable from "../table/ReadyToMintTable";
import ReadyToMintPopup from "../popups/ReadyToMintPopup";
import Loader from "components/loader/Loader";
import { calcItemsAmount } from "components/table/utils/table-utils";

export const readyToMintSortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "First Name",
    value: "user.first_name",
    dir: "asc"
  },
  {
    id: 2,
    label: "Last Name",
    value: "user.last_name",
    dir: "asc"
  },
  {
    id: 3,
    label: "Course Name",
    value: "course.name",
    dir: "asc"
  }
];

const ReadyToMintTab = () => {
  const { pathname, search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir
  } = queryString.parse(urlParams);

  const { windowSize } = useContext(windowContext);
  const { setPopup } = useContext(popupContext);
  const { web3UserData } = useContext(Web3Context);
  const { isMobile } = windowSize;

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...readyToMintSortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState((search as string) || "");
  const [perPage, setPerPage] = useState(30);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const [selected, setSelected] = useState([]);

  const params = `?${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }&limit=${perPage}&page=${page}${
    searchValue.length > 1
      ? `&search[first_name]=${searchValue}&search[last_name]=${searchValue}&search[email]=${searchValue}`
      : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, IReadyToMintData>(
    {
      ...readyToMintDataQuery(params)
    }
  );

  const { result, total_results, all_records } = data || {};

  const hasData = data && result?.length > 0;

  const showReadyToMintPopup = (singleRequest?: IMintRequest[]) => {
    setPopup(
      <ReadyToMintPopup
        invalidateParams={params}
        readyToMintItems={singleRequest ? [singleRequest] : selected}
      />
    );
  };

  const getDataStatus = useCallback(() => {
    let status: "loading" | "error" | "no-results" | "empty" | null;

    return isLoading
      ? (status = "loading")
      : error
      ? (status = "error")
      : !hasData && searchValue && !isLoading
      ? (status = "no-results")
      : !hasData && !searchValue && !isLoading
      ? (status = "empty")
      : (status = null);
  }, [isLoading, error, hasData, searchValue]);

  return (
    <>
      <PageHeader
        hasData={true}
        title="Ready To Mint"
        searchPlaceholder="Search by Email / Name"
        hasSearch
        searchValue={searchValue}
        passSearchValueUp={setSearchValue}
        setPage={setPage}
        hasSort
        sortValue={sortFilter}
        setSortValue={setSortFilter}
        sortOptions={readyToMintSortOptions}
        passSortValueUp={(value: ISortOption) => {
          setSortFilter(value);
        }}
        itemsAmountMsg={calcItemsAmount({
          type: "",
          page: page,
          perPage: perPage,
          result: result,
          total_results,
          // Intentionally have total_results set as all_records, because BE currently
          // returns all instances of a a student eligible for a mint for a course
          all_records: total_results
        })}
        withChildrenDivider
      >
        {!!selected.length && !isMobile && (
          <TooltipComp
            id="resolve-requests-btn"
            text={
              !web3UserData?.wallet_address
                ? "Connect your wallet first."
                : "You can resolve up to 30 requests at a time. "
            }
          >
            <Button
              size="medium"
              onClick={() => showReadyToMintPopup()}
              isDisabled={!web3UserData?.wallet_address || selected.length > 30}
            >
              {`Mint Selected (${selected.length})`}
            </Button>
          </TooltipComp>
        )}
      </PageHeader>
      {!!selected.length && isMobile && (
        <TooltipComp
          id="resolve-requests-btn"
          text={
            !web3UserData?.wallet_address
              ? "Connect your wallet first."
              : selected.length > 30
              ? "You can resolve up to 30 requests at a time. "
              : ""
          }
        >
          <Button
            size="medium"
            onClick={() => showReadyToMintPopup()}
            isDisabled={!web3UserData?.wallet_address || selected.length > 30}
            minWidth="full"
          >
            {`Mint Selected (${selected.length})`}
          </Button>
        </TooltipComp>
      )}
      <ReadyToMintTable
        page={page}
        setPage={setPage}
        perPage={30}
        setPerPage={setPerPage}
        data={data}
        status={getDataStatus()}
        params={params}
        selected={selected}
        setSelected={setSelected}
        showMintRequestPopup={showReadyToMintPopup}
        error={error}
      />
    </>
  );
};

export default ReadyToMintTab;
