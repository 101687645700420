import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import PageHeader from "components/page-header/PageHeader";
import Schools from "../components/Schools";
import RegistrationRequests from "../components/RegistrationRequests";
import { schoolsRegRequestsDataQuery } from "query";
import Loader from "components/loader/Loader";
import { ISchoolRegRequestsData } from "utils/apiDataTypes/AccountManagementDataTypes";
import classes from "./Schools.module.scss";

const tabs = [
  { id: 0, name: "All Schools", route: "/admin-schools" },
  {
    id: 1,
    name: "Registration Requests",
    route: "/admin-registration-requests"
  }
];

interface ISchoolsPage {
  isRequests?: boolean;
}

const SchoolsPage = ({ isRequests }: ISchoolsPage) => {
  const [activeTab, setActiveTab] = useState(isRequests ? 1 : 0);
  const navigate = useNavigate();

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ISchoolRegRequestsData
  >(schoolsRegRequestsDataQuery(`?limit=8&page=1&status=0`));

  return (
    <div className={classes["content-wrapper"]}>
      <PageHeader
        hasData={true}
        title="Schools"
        hasSearch={false}
        hasSort={false}
      />
      <div className={classes["tabs"]}>
        {tabs.map((tab, i) => {
          return (
            <div
              key={i}
              className={`${classes["tab"]} ${
                classes[activeTab === i ? "u-text--primary" : ""]
              }
              ${classes[activeTab === i ? "tab-active" : ""]}
              `}
              onClick={() => {
                setActiveTab(i);
                navigate(tabs[i].route);
              }}
            >
              {i === 1 && data?.result?.length > 0 && (
                <div className={classes["circle"]} />
              )}
              {i === 1 && isLoading && <Loader size="sm" />}
              <span>{tab.name}</span>
            </div>
          );
        })}
      </div>
      {activeTab === 0 && <Schools />}
      {activeTab === 1 && <RegistrationRequests />}
    </div>
  );
};

SchoolsPage.defaultProps = {
  isRequests: false
};

export default SchoolsPage;
