import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PageHeader from "components/page-header/PageHeader";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import PermissionsGate from "roles/PermissionsGate";
import AttendeesTable from "./AttendeesTable";
import {
  IPreviewLink,
  IStudentsData
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { fetchApi } from "utils/requests";
import classes from "./Single.module.scss";
import AddMoreAttendeesPopup from "./AddMoreAttendeesPopup";
import { calcItemsAmount } from "components/table/utils/table-utils";
import MintDiplomasPopup from "./MintDiplomasPopup";
import TooltipComp from "components/tooltip/TooltipComp";
import GenericPopup from "components/popups/GenericPopup";
import { IFilterOption } from "components/filter/Filter";
import { popupContext } from "context/popupContext";
import { Web3Context } from "context/web3Context";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";
import {
  adminSingleCourseStudentsDataQuery,
  getDiplomaPreviewLink,
  singleCourseStudentsDataQuery
} from "query";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";

interface ISingleAttendees {
  courseId: string;
  templateId: string;
  hasMints: boolean;
  isOpen: boolean;
  hasNoActions?: boolean;
}

const filterOptions: IFilterOption[] = [
  { id: 0, label: "None", value: "" },
  { id: 1, label: "School Verified", value: "school_verified" },
  { id: 2, label: "Student Verified", value: "student_verified" },
  { id: 3, label: "Not Verified", value: "not_verified" }
];

const filterValueMapping = {
  school_verified: "attendance_status=1",
  student_verified: "attendance_status=2",
  not_verified: "attendance_status=0"
};

const SingleAttendees = ({
  courseId,
  templateId,
  hasMints,
  isOpen,
  hasNoActions
}: ISingleAttendees) => {
  const { search: urlParams } = useLocation();
  const { page: pageNumber, filter } = queryString.parse(urlParams);
  const isShowAll = pageNumber === "all";

  const defaultFilterValue: IFilterOption = filter
    ? ({
        ...filterOptions.find((o) => o.value === filter)
      } as IFilterOption)
    : filterOptions[0];

  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};
  const { web3UserData } = useContext(Web3Context);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const queryClient = useQueryClient();
  const [perPage, setPerPage] = useState(isShowAll ? 10000 : 8);
  const [page, setPage] = useState<number | "all">(
    isShowAll ? "all" : parseInt(pageNumber as string) || 1
  );
  const [selected, setSelected] = useState<
    {
      index: number;
      id: string;
      attendance_status?: number;
      wallet?: string;
      diploma_status?: number;
    }[]
  >([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  const params: string = `?&limit=${perPage}&page=${page}&sort=first_name${
    filterValue?.value ? `&${filterValueMapping[filterValue.value]}` : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, IStudentsData>(
    is_super_admin
      ? adminSingleCourseStudentsDataQuery(courseId, params)
      : singleCourseStudentsDataQuery(courseId, params)
  );

  const showConfirmPopup = useCallback(
    (
      obj: { id: string }[],
      isChangeStatus?: boolean,
      attendance_status?: number,
      type?: "mint"
    ) => {
      if (type === "mint") {
        return mintDiplomasFlow(obj[0]);
      }
      setPopup(
        <GenericPopup
          type="info"
          title={`${isChangeStatus ? "Update" : "Remove"} Attendant`}
          msg={`Are you sure you  want to ${
            isChangeStatus ? "update" : "remove"
          } ${obj.length > 1 ? "these attendants" : "this attendant"}?`}
          buttonName={`${isChangeStatus ? "Update" : "Remove"} attendant${
            obj.length > 1 ? "s" : ""
          }`}
          buttonVariant="contrast"
          buttonAction={() =>
            isChangeStatus ? changeStatus(attendance_status) : removeStudent()
          }
          buttonMinWidth="md"
          bellowBtnComp={
            <Button
              variant="neutral"
              size="medium"
              onClick={() => clearPopup()}
              minWidth="md"
            >
              Cancel
            </Button>
          }
          clearPopupOnBtnAction={false}
        />
      );
    },
    [data]
  );

  // Preview certificate query disabled by default. Enabled on every preview btn click.
  useQuery<boolean, Error, IPreviewLink, any>({
    ...getDiplomaPreviewLink(selected[0]?.id),
    enabled: isPreviewOpen && !!selected[0]?.id,
    onSuccess: (data) => {
      setIsPreviewOpen(false);
      setPopup(
        <GenericPopup
          type="info"
          title="Preview Certificate"
          msg="Preview certificate link successfully generated!"
          buttonName="Open Preview"
          buttonVariant="contrast"
          buttonAction={() => {
            window.open(FILES_PUBLIC_DOMAIN + data.preview_link);
            clearPopup();
          }}
          buttonMinWidth="md"
          bellowBtnComp={
            <Button
              variant="link"
              onClick={() => {
                clearPopup();
              }}
            >
              Cancel
            </Button>
          }
          clearPopupOnBtnAction={false}
        />
      );
      setSelected([]);
    },
    onError: (err: Error) => {
      setIsPreviewOpen(false);
      setPopup(<GenericPopup type="error" msg={err.message} />);
      setSelected([]);
    }
  });

  const getPreviewDiplomaLink = useCallback(() => {
    setIsPreviewOpen(true);
    setPopup(
      <GenericPopup
        type="loading"
        msg="Please wait generating the preview certificate link."
        hideActionBtn
      />
    );
  }, [data]);

  const { isLoading: removeStudentIsLoading, mutate: removeStudent } =
    useMutation({
      mutationKey: ["remove-student"],
      mutationFn: async () => {
        setPopupLoading(true);
        const ids = selected.map((item) => item.id);
        const response = await fetchApi(
          "courses",
          `/course/${courseId}/students`,
          {
            method: "DELETE",
            auth: true,
            data: {
              students: ids
            }
          }
        );
        return response;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["course-students-data"] });
        setPopup(
          <GenericPopup
            type="success"
            title={"Successfully removed!"}
            msg={`The selected attendant(s) were successfully removed!`}
          />
        );
        setPopupLoading(false);
        setSelected([]);
      },
      onError: (err: Error) => {
        setPopup(<GenericPopup type="error" msg={err.message} />);
        setPopupLoading(false);
      }
    });

  const { isLoading: changeStatusIsLoading, mutate: changeStatus } =
    useMutation({
      mutationKey: ["course-student-change-status"],
      mutationFn: async (status: number) => {
        setPopupLoading(true);
        const ids = selected.map((item) => item.id);
        const response = await fetchApi(
          "courses",
          `/course/${courseId}/students`,
          {
            method: "PUT",
            auth: true,
            data: {
              status: status,
              students: ids
            }
          }
        );
        return response;
      },
      onSuccess: ({ status }) => {
        queryClient.invalidateQueries({ queryKey: ["course-students-data"] });
        setPopup(
          <GenericPopup
            type="success"
            title={"Successfully changed!"}
            msg={`The status was successfully changed to ${
              status === 0 ? "Not Verified" : "School Verified"
            }!`}
          />
        );
        setPopupLoading(false);
        setSelected([]);
      },
      onError: (err: Error) => {
        setPopup(<GenericPopup type="error" msg={err.message} />);
        setPopupLoading(false);
      }
    });

  const { isLoading: verifyAllIsLoading, mutate: verifyAllMutation } =
    useMutation({
      mutationKey: ["course-verify-all"],
      mutationFn: async () => {
        setPopupLoading(true);
        const response = await fetchApi(
          "courses",
          `/course/${courseId}/students/verify`,
          {
            method: "PUT",
            auth: true
          }
        );
        return response;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["course-students-data"] });
        setPopup(
          <GenericPopup
            type="success"
            title={"Verified!"}
            msg="All students are verified!"
          />
        );
        setPopupLoading(false);
        setSelected([]);
      },
      onError: (err: Error) => {
        setPopup(<GenericPopup type="error" msg={err.message} />);
        setPopupLoading(false);
      }
    });

  const mintDiplomasFlow = (singleObj: { id: string }) => {
    let students;
    // Use for single minting via dropdown
    if (singleObj.id) {
      students = data.result.filter((student) => {
        if (student._id === singleObj.id) {
          return {
            id: student._id,
            names: student.email,
            wallet: student.wallet
          };
        }
      });
      return setPopup(
        <MintDiplomasPopup
          students={students}
          courseId={courseId}
          templateId={templateId}
          hasMints={hasMints}
          clearSelection={() => setSelected([])}
        />
      );
    }
    // Used for batch minting
    students = data.result.filter((student) =>
      selected?.some((item) => {
        if (item.id === student._id) {
          return {
            id: student._id,
            names: student.email,
            wallet: student.wallet
          };
        }
      })
    );
    setPopup(
      <MintDiplomasPopup
        students={students}
        courseId={courseId}
        templateId={templateId}
        hasMints={hasMints}
        clearSelection={() => setSelected([])}
      />
    );
  };

  const setPerPageHandler = (perPage: number) => {
    setPerPage(perPage);
    setPage(1);
  };

  const { result, all_records, total_results } = data || {};
  const hasData = result && result.length > 0;

  const checkSelected = () => {
    if (
      selected.some(
        (student) =>
          student.diploma_status === 1 || student.diploma_status === 2
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes["students-wrapper"]}>
      {(hasData || (!hasData && filterValue)) && (
        <PageHeader
          hasData={true}
          title="Attendees"
          itemsAmountMsg={calcItemsAmount({
            page: isShowAll ? 1 : (page as number),
            perPage: perPage,
            result: result,
            total_results,
            all_records: all_records,
            isShowAll
          })}
          hasSearch={false}
          hasSort={false}
          hasFilter
          filterOptions={filterOptions}
          filterValue={filterValue}
          passFilterValueUp={setFilterValue}
          setPage={setPage}
        >
          {/* Table action buttons on desktop */}
          {!selected.length ? (
            hasData && (
              <PermissionsGate permissions={["STUDENT.CREATE"]}>
                <TooltipComp
                  id="header-add-more"
                  text={
                    hasMints && !isOpen
                      ? "Cannot add students once certificates were minted."
                      : ""
                  }
                >
                  <Button
                    variant={"neutral"}
                    size="medium"
                    icon={PlusIcon}
                    onClick={() =>
                      setPopup(
                        <AddMoreAttendeesPopup
                          courseId={courseId}
                          isOpen={isOpen}
                        />
                      )
                    }
                    isDisabled={hasMints && !isOpen}
                  >
                    Add More
                  </Button>
                </TooltipComp>
                <Button
                  variant={"neutral"}
                  size="medium"
                  onClick={() =>
                    setPopup(
                      <GenericPopup
                        type="info"
                        title="Verify Students?"
                        msg="Are you sure you want to verify all students in this course?"
                        buttonName="Verify All"
                        buttonMinWidth="md"
                        buttonVariant="contrast"
                        buttonAction={verifyAllMutation}
                        bellowBtnComp={
                          <Button
                            variant="neutral"
                            minWidth="md"
                            size="medium"
                            onClick={clearPopup}
                          >
                            Close
                          </Button>
                        }
                      />
                    )
                  }
                >
                  Verify All
                </Button>
              </PermissionsGate>
            )
          ) : (
            <>
              {!isMobile && (
                <>
                  <PermissionsGate permissions={["STUDENT.UPDATE"]}>
                    <TooltipComp
                      id="header-change-status"
                      text={
                        checkSelected()
                          ? "Some of the selected student(s) have a certificate and cannot be edited."
                          : ""
                      }
                    >
                      <Button
                        size="medium"
                        icon={CheckCircle}
                        variant="link"
                        isFetching={changeStatusIsLoading}
                        onClick={() => showConfirmPopup(selected, true, 1)}
                        isDisabled={checkSelected()}
                      >
                        Change Status to Verified
                      </Button>
                    </TooltipComp>
                  </PermissionsGate>
                  <TooltipComp
                    text={
                      !templateId
                        ? "Please select a certificate template first."
                        : ""
                    }
                    id="mint-btn"
                  >
                    <TooltipComp
                      id="header-mint-diploma"
                      text={
                        checkSelected()
                          ? "Some of the selected student(s) already have a certificate."
                          : !web3UserData?.wallet_address
                          ? "Please connect your wallet."
                          : selected.length > 30
                          ? `${selected.length} students are currently selected. You can mint up to 30 certificates at a time.`
                          : ""
                      }
                    >
                      <Button
                        size="medium"
                        variant="neutral"
                        onClick={mintDiplomasFlow}
                        isDisabled={
                          !selected.length ||
                          selected.length > 30 ||
                          !templateId ||
                          selected.some(
                            (student) =>
                              !student.attendance_status ||
                              !student.wallet ||
                              checkSelected()
                          ) ||
                          !web3UserData?.wallet_address
                        }
                      >
                        Mint NFT Certificate
                      </Button>
                    </TooltipComp>
                  </TooltipComp>
                  <Divider orientation="vertical" />
                  <PermissionsGate permissions={["STUDENT.DELETE"]}>
                    <TooltipComp
                      id="header-remove-attendee"
                      text={
                        checkSelected()
                          ? "Some of the selected student(s) already have a certificate and cannot be removed."
                          : ""
                      }
                    >
                      <Button
                        size="medium"
                        icon={TrashIcon}
                        variant="neutral"
                        isFetching={removeStudentIsLoading}
                        onClick={() => showConfirmPopup(selected)}
                        isDisabled={checkSelected()}
                      >
                        Remove attendees
                      </Button>
                    </TooltipComp>
                  </PermissionsGate>
                </>
              )}
            </>
          )}
        </PageHeader>
      )}
      {/* Table action buttons on mobile */}
      {isMobile && (
        <>
          {!!selected.length && (
            <div className={classes["mobile-btns"]}>
              <div className={classes["mobile-btns--main"]}>
                <PermissionsGate permissions={["STUDENT.DELETE"]}>
                  <TooltipComp
                    id="header-remove-attendee"
                    text={
                      checkSelected()
                        ? "Some of the selected student(s) already have a certificate and cannot be removed."
                        : ""
                    }
                  >
                    <Button
                      size="medium"
                      icon={TrashIcon}
                      variant="neutral"
                      isFetching={removeStudentIsLoading}
                      onClick={() => showConfirmPopup(selected)}
                      isDisabled={checkSelected()}
                    >
                      Remove
                    </Button>
                  </TooltipComp>
                </PermissionsGate>
                <TooltipComp
                  text={
                    !templateId
                      ? "Please select a certificate template first."
                      : ""
                  }
                  id="mint-btn"
                >
                  <TooltipComp
                    id="header-mint-diploma"
                    text={
                      checkSelected()
                        ? "Some of the selected student(s) already have a certificate."
                        : !web3UserData?.wallet_address
                        ? "Please connect your wallet."
                        : selected.length > 30
                        ? `${selected.length} students are currently selected. You can mint up to 30 certificates at a time.`
                        : ""
                    }
                  >
                    <Button
                      size="medium"
                      onClick={mintDiplomasFlow}
                      isDisabled={
                        !selected.length ||
                        selected.length > 30 ||
                        !templateId ||
                        selected.some(
                          (student) =>
                            !student.attendance_status ||
                            !student.wallet ||
                            checkSelected()
                        ) ||
                        !web3UserData?.wallet_address
                      }
                    >
                      Mint NFT Certificate
                    </Button>
                  </TooltipComp>
                </TooltipComp>
              </div>
              <PermissionsGate permissions={["STUDENT.UPDATE"]}>
                <TooltipComp
                  id="header-change-status"
                  text={
                    checkSelected()
                      ? "Some of the selected student(s) have a certificate and cannot be edited."
                      : ""
                  }
                >
                  <Button
                    size="medium"
                    icon={CheckCircle}
                    variant="link"
                    isFetching={changeStatusIsLoading}
                    onClick={() => showConfirmPopup(selected, true, 1)}
                    isDisabled={checkSelected()}
                    className={classes["u-align-self--end"]}
                  >
                    Change Status
                  </Button>
                </TooltipComp>
              </PermissionsGate>
            </div>
          )}
        </>
      )}
      <AttendeesTable
        filterValue={filterValue}
        showConfirmPopup={showConfirmPopup}
        getPreviewDiplomaLink={getPreviewDiplomaLink}
        setSelected={setSelected}
        page={isShowAll ? 1 : (page as number)}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPageHandler}
        itemsAmountMsg={calcItemsAmount({
          page: isShowAll ? 1 : (page as number),
          perPage: perPage,
          result: result,
          total_results,
          all_records: all_records,
          isShowAll
        })}
        showIs={perPage !== 10000 ? "Show All" : "Show Pages"}
        isTemplateSelected={!!templateId}
        isOpen={isOpen}
        data={{ data, isLoading, error }}
        hasNoActions={hasNoActions}
      />
    </div>
  );
};

export default SingleAttendees;
