import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Button from "components/buttons/Button";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import classes from "./Team.module.scss";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import GenericPopup from "components/popups/GenericPopup";
import { popupContext } from "context/popupContext";
import { fetchApi } from "utils/requests";
import PermissionsGate from "roles/PermissionsGate";
import MembersTable from "./components/MembersTable";
import { ITeamData } from "utils/apiDataTypes/AccountManagementDataTypes";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { windowContext } from "context/windowsContext";
import { IFilterOption } from "components/filter/Filter";
import { adminTeamDataQuery, teamDataQuery } from "query";
import { sortDirEnum } from "utils/staticOptions";
import { userContext } from "context/userContext";

export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "First Name",
    value: "first_name",
    dir: "asc"
  },
  {
    id: 2,
    label: "Email",
    value: "email",
    dir: "asc"
  },
  {
    id: 3,
    label: "Status",
    value: "active",
    dir: "asc"
  }
];

const filterOptions: IFilterOption[] = [
  { id: 0, label: "None", value: "" },
  { id: 1, label: "Active", value: "active" },
  { id: 2, label: "Disabled", value: "disabled" },
  { id: 3, label: "Pending", value: "pending" }
];

const filterValueMapping = {
  active: "registered=true&active=true",
  disabled: "registered=true&active=false",
  pending: "registered=false&active=false"
};

interface ITeamProps {
  perPage?: number;
  isPaginationHidden?: boolean;
  hasNoActions?: boolean;
  hasViewAll?: boolean;
  hideHeaderControls?: boolean;
}

const Team = ({
  perPage = 8,
  isPaginationHidden,
  hasNoActions,
  hasViewAll,
  hideHeaderControls
}: ITeamProps) => {
  const { userData } = useContext(userContext);
  const { windowSize } = useContext(windowContext);
  const { isLowestMobile } = windowSize;
  const { is_super_admin } = userData || {};

  let { schoolId } = useParams();
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir,
    filter
  } = queryString.parse(urlParams);

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;
  const defaultFilterValue: IFilterOption = filter
    ? ({
        ...filterOptions.find((o) => o.value === filter)
      } as IFilterOption)
    : filterOptions[0];

  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isMobile } = windowSize;

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ""
  );
  const [filterValue, setFilterValue] =
    useState<IFilterOption>(defaultFilterValue);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [selected, setSelected] = useState<
    { index: number; id: string; active: boolean }[]
  >([]);

  const params = `?${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }&limit=${perPage}&page=${page}${
    searchValue.length > 1
      ? `&search[first_name]=${searchValue}&search[last_name]=${searchValue}&search[email]=${searchValue}`
      : ""
  }${
    filterValue && filterValue.value
      ? `&${filterValueMapping[filterValue.value]}`
      : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ITeamData>(
    is_super_admin
      ? adminTeamDataQuery(schoolId, params)
      : teamDataQuery(params)
  );

  const showConfirmPopup = (
    obj: { id: string; active: boolean }[],
    activate: boolean
  ) => {
    setPopup(
      <GenericPopup
        type="info"
        title={`${!activate ? "Deactivate" : "Activate"} member${
          obj.length > 1 ? "s" : ""
        }?`}
        msg={`Are you sure you  want to ${
          !activate ? "deactivate" : "activate"
        } ${obj.length > 1 ? "these members" : "this member"}?`}
        buttonName={`${!activate ? "Deactivate" : "Activate"} member${
          obj.length > 1 ? "s" : ""
        }`}
        buttonVariant="outline"
        buttonAction={() => changeStatusHandler(activate)}
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            onClick={() => clearPopup()}
            minWidth="md"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const { isLoading: mutateIsLoading, mutate: changeStatusHandler } =
    useMutation({
      mutationKey: ["edit-member-status"],
      mutationFn: async (activate: boolean) => {
        setPopupLoading(true);
        const ids = selected.map((item) => item.id);
        const response = await fetchApi("courses", "/school/members", {
          method: "PUT",
          auth: true,
          data: {
            members: ids,
            active: activate
          }
        });
        return response;
      },
      onSuccess: (response: { msg: string; active: boolean }) => {
        queryClient.invalidateQueries({ queryKey: ["team-data"] });
        setPopupLoading(false);
        setPopup(
          <GenericPopup
            type="success"
            title={"Status changed!"}
            msg={`The selected member(s) were successfully ${
              response.active ? "activated" : "deactivated"
            }!`}
          />
        );
        setSelected([]);
      },
      onError: (err: Error) => {
        setPopupLoading(false);
        setPopup(<GenericPopup type="error" msg={err.message} />);
      }
    });

  const showSendEmailPopup = (userId: string) => {
    setPopup(
      <GenericPopup
        type="info"
        title="Resend Email"
        msg="Are you sure you want to send a new activation email to this user?"
        buttonName="Resend Email"
        buttonVariant="outline"
        buttonAction={() => sendEmailHandler(userId)}
        buttonMinWidth="md"
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            onClick={() => clearPopup()}
            minWidth="md"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const { mutate: sendEmailHandler } = useMutation({
    mutationKey: ["resend-email"],
    mutationFn: async (userId: string) => {
      await fetchApi("courses", "/school/members/resend-activate", {
        method: "POST",
        auth: true,
        data: {
          members: [userId]
        }
      });
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="A new activation email was sent to this member."
        />
      );
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          title="Something went wrong!"
          msg={err.message}
        />
      );
    }
  });

  const renderButtons = () => {
    const includesActives = selected.some(({ active }) => active) && (
      <Button
        size="medium"
        variant="neutral"
        isFetching={mutateIsLoading}
        onClick={() => showConfirmPopup(selected, false)}
        isDanger
      >
        {isMobile ? "Deactivate" : "Deactivate Members"}
      </Button>
    );
    const includesInactives = selected.some(({ active }) => !active) && (
      <Button
        size="medium"
        variant="neutral"
        isFetching={mutateIsLoading}
        onClick={() => showConfirmPopup(selected, true)}
      >
        {isMobile ? "Activate" : "Activate Members"}
      </Button>
    );

    return (
      <PermissionsGate permissions={["SCHOOL.UPDATE"]}>
        {includesActives}
        {includesInactives}
      </PermissionsGate>
    );
  };

  const { result, all_records, total_results } = data || {};
  const hasData = result && result.length > 0;

  return (
    <div className={classes["content-wrapper"]}>
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortFilter) ||
        (!hasData && filterValue) ||
        page > 1) && (
        <PageHeader
          hasData={true}
          title="Team"
          itemsAmountMsg={calcItemsAmount({
            page: page,
            perPage: perPage,
            result: result,
            total_results,
            all_records: all_records
          })}
          hasSearch={!selected.length}
          searchValue={searchValue}
          passSearchValueUp={(value: string) => {
            setSearchValue(value);
          }}
          setPage={setPage}
          hasSort={!selected.length}
          passSortValueUp={(value: ISortOption) => {
            setSortFilter(value);
          }}
          sortValue={sortFilter}
          setSortValue={setSortFilter}
          sortOptions={sortOptions}
          searchPlaceholder="Search by first and last name"
          className={"divider-hidden"}
          hasFilter
          filterOptions={filterOptions}
          filterValue={filterValue}
          passFilterValueUp={setFilterValue}
          hideHeaderControls={hideHeaderControls}
        >
          {!selected.length ? (
            <PermissionsGate permissions={["SCHOOL.UPDATE"]}>
              {isLowestMobile ? (
                <Button
                  onClick={() => navigate("/team/add-member/")}
                  variant={"neutral"}
                  size="medium"
                  icon={PlusIcon}
                  isIconBtn
                />
              ) : (
                <Button
                  onClick={() => navigate("/team/add-member/")}
                  variant={"neutral"}
                  size="medium"
                  icon={PlusIcon}
                  minWidth="sm"
                >
                  Add Member
                </Button>
              )}
            </PermissionsGate>
          ) : (
            <>{renderButtons()}</>
          )}
        </PageHeader>
      )}
      <MembersTable
        searchValue={searchValue}
        filterValue={filterValue}
        showConfirmPopup={showConfirmPopup}
        setSelected={setSelected}
        showSendEmailPopup={showSendEmailPopup}
        page={page}
        setPage={setPage}
        itemsAmountMsg={calcItemsAmount({
          type: "Team ",
          page: page,
          perPage: perPage,
          result: result,
          total_results,
          all_records: all_records
        })}
        data={{ data, isLoading, error }}
        isPaginationHidden={isPaginationHidden}
        hasNoActions={hasNoActions}
        hasViewAll={hasViewAll}
        schoolId={schoolId}
      />
    </div>
  );
};

export default Team;
