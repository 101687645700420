import React, { useContext, useEffect, useState } from "react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import MintDiplomasTable from "components/table/MintDiplomasTable";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import {
  ICourse,
  IReadyToMint
} from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./MintRequestsPopup.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import GenericPopup from "components/popups/GenericPopup";
import { fetchApi } from "utils/requests";
import { Web3Context } from "context/web3Context";
import { ethers } from "ethers";
import { diplomaContract } from "contracts/contracts";
import { BLOCK_EXPLORER_URL, MINT_FEE } from "utils/constants";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { buildQueryKey } from "query";
import MintFee from "components/popups/common/MintFee";
import { useAccount } from "wagmi";

interface IMintRequestsTable {
  invalidateParams: string;
  readyToMintItems?: IReadyToMint[];
}

interface ICustomError extends Error {
  cause: { id: string; courses: ICourse[] };
}

const ReadyToMintPopup = ({
  invalidateParams,
  readyToMintItems
}: IMintRequestsTable) => {
  const { theme } = useContext(themeContext);
  const { clearPopup, setPopup } = useContext(popupContext);
  const { web3UserData } = useContext(Web3Context);
  const [readyToMintItemsInTable, setReadyToMintItemsInTable] =
    useState(readyToMintItems);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { connector: activeConnector } = useAccount();

  const mintFee = (readyToMintItemsInTable.length * MINT_FEE).toFixed(4);
  const student_ids = readyToMintItemsInTable.map((item) => item._id);

  const { mutate: mintDiplomasHandler } = useMutation({
    mutationKey: ["mint-diplomas"],
    mutationFn: async () => {
      setIsLoading(true);
      let txReceipt;

      if (!web3UserData?.wallet_address)
        throw Error("Please connect your wallet.");

      setPopup(
        <GenericPopup
          type="loading"
          size="sm"
          title="Mint Certificates"
          msg="Approve this mint transaction from your wallet."
        />
      );

      // 1. Submit mint to BE
      const response = await fetchApi(
        "courses",
        `/course-student/diplomas/mint`,
        {
          method: "POST",
          auth: true,
          data: {
            student_ids
          }
        }
      )
        // 2. Use BE response for transaction
        .then(
          async (response: {
            ids: string[];
            signatures: string[];
            to: string[];
            tokensIds: number[];
          }) => {
            if (
              !response.ids.length ||
              !response.to.length ||
              !response.tokensIds.length ||
              !response.signatures.length
            )
              throw Error(
                "A certificate cannot be minted for the given student(s)."
              );
            let walletProvider;
            await activeConnector.getProvider().then((provider) => {
              walletProvider = provider;
            });

            let provider = new ethers.providers.Web3Provider(
              walletProvider,
              "any"
            );
            let signer = provider.getSigner(web3UserData.wallet_address);

            let contract = new ethers.Contract(
              diplomaContract.address,
              diplomaContract.abi,
              signer
            );

            const fee = ethers.utils.parseEther(mintFee.toString());

            try {
              const tx = await contract[
                "mintBatch(address[],uint256[],bytes[])"
              ](response.to, response.tokensIds, response.signatures, {
                value: fee
              });
              const receipt = await tx.wait();
              txReceipt = receipt;
              return receipt;
            } catch (e: any) {
              await fetchApi("courses", `/course-student/diplomas/reset-mint`, {
                method: "POST",
                auth: true,
                data: {
                  student_ids
                }
              });
              queryClient.invalidateQueries({
                queryKey: ["ready-to-mint", buildQueryKey(invalidateParams)]
              });
              if (e.code === "ACTION_REJECTED") {
                throw Error("Transaction cancelled.");
              }
              if (
                e?.data?.code === -32000 ||
                e?.code === "INSUFFICIENT_FUNDS"
              ) {
                throw Error("Insufficient funds.");
              }
              throw Error(e.message);
            }
          }
        );
      return { txReceipt };
    },
    onSuccess: (obj) => {
      setIsLoading(false);
      setPopup(
        <GenericPopup
          title="Success!"
          msg="The certificates are minted."
          bellowBtnComp={
            <Button
              onClick={() =>
                window.open(
                  `${BLOCK_EXPLORER_URL}/tx/${obj.txReceipt.transactionHash}`,
                  "_blank"
                )
              }
              icon={LinkIcon}
              variant={"link"}
              isIconBtn
              minWidth="full"
            >
              View in Explorer
            </Button>
          }
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["ready-to-mint", buildQueryKey(invalidateParams)]
      });
    },
    onError: (err: ICustomError) => {
      setIsLoading(false);
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  useEffect(() => {
    if (readyToMintItemsInTable?.length === 0) clearPopup();
  }, [readyToMintItemsInTable]);

  return (
    <ModalWrapper size="lg">
      <div data-theme={theme} className={classes["mint-request-popup"]}>
        <h4
          className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
        >
          Confirm Mint NFT Certificate
          {readyToMintItemsInTable.length > 1 ? "s" : ""}
        </h4>
        <div
          className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
        >
          Confirm that you want to send the NFT Certificate to the listed attendees.
          <br />
          <br />
          <MintFee fee={mintFee} />
        </div>
        <div className={classes["content"]}>
          <MintDiplomasTable
            type="ready-to-mint-popup-table"
            readyToMintItems={readyToMintItemsInTable}
            setReadyToMintItems={setReadyToMintItemsInTable}
          />
        </div>
        <div className={classes["buttons-container"]}>
          <Button
            className={classes["btn-reject"]}
            variant="outline"
            iconRotate={180}
            onClick={() => clearPopup()}
            iconPosition="right"
          >
            Close
          </Button>
          <Button
            className={classes["btn-approve"]}
            icon={CheckCircle}
            iconPosition="right"
            onClick={() => mintDiplomasHandler()}
            isFetching={isLoading}
          >
            Approve Mint
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ReadyToMintPopup;
