import React, { createContext, useEffect, useState } from "react";

interface IWindowCtx {
  windowSize: {
    width: number;
    height: number;
    isMobile: boolean;
    isLowestMobile: boolean;
  };
}

export const windowContext = createContext<IWindowCtx | undefined>(undefined);

export function WindowProvider(props: any) {
  const [windowSize, setWindowSize] = useState<IWindowCtx["windowSize"] | null>(
    {
      width: undefined,
      height: undefined,
      isMobile: undefined,
      isLowestMobile: undefined
    }
  );

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= 992,
        isLowestMobile: window.innerWidth <= 360
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <windowContext.Provider value={{ windowSize }}>
      {props.children}
    </windowContext.Provider>
  );
}
