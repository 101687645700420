import Table from "components/table/Table";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { useContext, useMemo } from "react";
import { IReadyToMintData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { windowContext } from "context/windowsContext";
import EmptyPage from "components/empty-page/EmptyPage";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import DiplomaIcon from "assets/images/dashboard/no-templates.png";
import NoResults from "components/no-results/NoResults";
import classes from "../CreateDiploma.module.scss";
import { ReadyToMintCols } from "components/table/ReadyToMintCols";

interface IRequestsTable {
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage?: (amount: number) => void;
  data?: IReadyToMintData;
  params?: string;
  selected: any;
  setSelected: (selected: any[]) => void;
  showMintRequestPopup: (request: any) => void;
  status: "loading" | "error" | "no-results" | "empty" | null;
  error: Error;
}

const ReadyToMintTable = ({
  page,
  setPage,
  perPage,
  data,
  params,
  selected,
  setSelected,
  showMintRequestPopup,
  status,
  error
}: IRequestsTable) => {
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const setSelectedHandler = (
    ids: { index: number; id: string; active: boolean }[]
  ) => {
    setSelected(ids);
  };

  const readyToMintCols = useMemo(
    () => ReadyToMintCols(showMintRequestPopup, isMobile),
    [data]
  );

  const { result, total_results, all_records } = data || {};
  const hasData = result && result.length > 0;

  if (status === "loading") return <Loader size="lg" hasText />;

  if (status === "error")
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );

  return (
    <>
      {hasData && (
        <Table
          type="ready-to-mint"
          tableData={data}
          columns={readyToMintCols}
          hiddenColumns={isMobile ? ["name", "email", "course"] : ["mobile"]}
          activePage={page}
          passActivePageUp={setPage}
          perPage={perPage}
          itemsAmountMsg={calcItemsAmount({
            type: "Ready To Mint ",
            page: page,
            perPage: perPage,
            result: result,
            total_results,
            all_records: all_records
          })}
          passSelectedRowsUp={setSelectedHandler}
        />
      )}
      {status === "no-results" && <NoResults />}
      {status === "empty" && (
        <EmptyPage
          title="No certificates ready to be minted Requests."
          message="There are currently no students ready to receive a certificate."
          icon={DiplomaIcon}
        />
      )}
    </>
  );
};

export default ReadyToMintTable;
