import React, { Fragment } from "react";
import Divider from "components/divider/Divider";
import Badge from "components/badge/Badge";
import classes from "./CourseForm.module.scss";
import { ITemplate } from "utils/apiDataTypes/CourseModuleDataTypes";
import { formatCourseLocation } from "utils/format";
import moment from "moment";
import { dateFormatDisplay } from "utils/staticOptions";

interface ISummary {
  formData: any;
  fieldTitles: {
    [key: string]: string;
  };
  templates: ITemplate[];
}

const Summary = ({ formData, fieldTitles, templates }: ISummary) => {
  return (
    <div className={classes["step-summary"]}>
      {Object.keys(formData).map((field, i) => {
        if (field === "start_date" || field === "end_date") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title"]}>{fieldTitles[field]}</div>
                  <div
                    className={`${classes["description"]} ${classes["description--normal"]}`}
                  >
                    {moment(formData[field]).format(dateFormatDisplay)}
                  </div>
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "lecture_dates") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field].length > 0 && (
                <div className={classes["row-container"]}>
                  {formData[field] && (
                    <>
                      <div className={classes["title"]}>
                        {fieldTitles[field]}
                      </div>
                      {formData[field].map((d, i) => {
                        return (
                          <div
                            key={`${d.start_date}-${i}`}
                            className={`${classes["description"]} ${classes["description--normal"]}`}
                          >
                            {`${moment(d.start_date).format(
                              dateFormatDisplay
                            )} ${moment(d.start_date).format(
                              "HH:mm"
                            )} - ${moment(d.end_date).format("HH:mm")} ${
                              d.title
                            }`}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "instructors") {
          const primary = formData[field].filter((d) => d.primary);
          const secondary = formData[field].filter((d) => !d.primary);

          return (
            <Fragment key={`${field}-${i}`}>
              {primary.length > 0 && (
                <>
                  <Divider orientation="horizontal" />
                  <div className={classes["row-container"]}>
                    {formData[field] && (
                      <>
                        <div className={classes["title"]}>
                          {fieldTitles["primary_instructor"]}
                        </div>
                        <div className={classes["group"]}>
                          {primary.map((d, i) => {
                            return (
                              <div key={`${d.first_name}-${d.last_name}-${i}`}>
                                <div
                                  className={`${classes["description"]} ${classes["description--normal"]}`}
                                >
                                  {/* Hide all `Instructor Degree` related logic */}
                                  {`${d.title || ""} ${d.first_name} ${
                                    d.last_name
                                  }`}
                                </div>
                                {d.signature && (
                                  <div
                                    style={{
                                      backgroundImage: `url(${d.signature})`,
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat"
                                    }}
                                    className={`${classes["pic"]} ${classes["pic-square"]}`}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {secondary.length > 0 && (
                <div className={classes["row-container"]}>
                  {formData[field] && (
                    <>
                      <div className={classes["title"]}>
                        {fieldTitles["secondary_instructor"]}
                      </div>
                      <div className={classes["group"]}>
                        {secondary.map((d, i) => {
                          return (
                            <div key={`${d.first_name}-${d.last_name}-${i}`}>
                              <div
                                className={`${classes["description"]} ${classes["description--normal"]}`}
                              >
                                {/* Hide all `Instructor Degree` related logic */}
                                {`${d.title} ${d.first_name} ${d.last_name}`}
                              </div>
                              {d.signature && (
                                <div
                                  style={{
                                    backgroundImage: `url(${d.signature})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat"
                                  }}
                                  className={`${classes["pic"]} ${classes["pic-square"]}`}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "AGD") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title"]}>{fieldTitles[field]}</div>

                  <div
                    className={`${classes["description"]} ${classes["description--normal"]}`}
                  >
                    {formData[field].map((d, i) => {
                      return (
                        <div
                          key={`${d.code}-${i}`}
                          className={classes["description"]}
                        >
                          {`${d.code} ${d.hours ? ` - ${d.hours}` : ""} ${
                            d.description ? ` - ${d.description}` : ""
                          }  ${d.type ? ` - ${d.type}` : ""}`}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "image") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title-image"]}>
                    {fieldTitles[field]}
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${formData[field]})`
                    }}
                    className={`${classes["pic"]} ${classes["pic-square"]}`}
                  />
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "attendees") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title"]}>{fieldTitles[field]}</div>
                  <div
                    className={`${classes["description"]} ${classes["description--normal"]}`}
                  >
                    {formData[field]["name"]}
                  </div>
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "course_managers") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  {formData[field] && (
                    <>
                      <div className={classes["title"]}>
                        {fieldTitles[field]}
                      </div>
                      <div
                        className={`${classes["description"]} ${classes["description--normal"]}`}
                      >
                        {formData[field].join(", ")}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "location") {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <div className={classes["title"]}>{fieldTitles[field]}</div>
                  <div
                    className={`${classes["description"]} ${classes["description--normal"]}`}
                  >
                    {formatCourseLocation(
                      formData[field].city ? formData[field].city.label : "",
                      formData[field].state ? formData[field].state.code : ""
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          );
        }

        if (field === "is_open" && formData[field]) {
          return (
            <Fragment key={`${field}-${i}`}>
              {formData[field] && (
                <div className={classes["row-container"]}>
                  <Badge text="Open Course" isOpen />
                </div>
              )}
            </Fragment>
          );
        }

        return (
          <Fragment key={`${field}-${i}`}>
            {formData[field] && (
              <div className={classes["row-container"]}>
                {formData[field] && (
                  <>
                    <div className={classes["title"]}>{fieldTitles[field]}</div>
                    <div
                      className={`${classes["description"]} ${
                        classes[
                          field === "description"
                            ? "description--small"
                            : "description--normal"
                        ]
                      }`}
                    >
                      {field === "template_id"
                        ? templates.find(
                            (template) => template._id === formData[field]
                          ).name
                        : formData[field]}
                    </div>
                  </>
                )}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Summary;
