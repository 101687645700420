import React, { useContext } from "react";
import Select, { components } from "react-select";
import { colors } from "styles";
import { themeContext } from "context/themeContext";
import Avatar from "../avatar/Avatar";
import classes from "./ReactSelect.module.scss";

interface IReactSelect {
  name: string;
  placeholder: string;
  value:
    | { value: number | string; label: string }
    | { value: number | string; label: string }[];
  options: { value: number | string; label: string }[];
  onChange: (e: any) => any;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isSort?: boolean;
  hasError?: boolean;
  hasAvatar?: boolean;
  onlyChevron: boolean;
  left?: string;
  top?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
}

const ReactSelect = ({
  name,
  isSort,
  placeholder,
  value,
  options,
  onChange,
  isDisabled,
  isLoading,
  isMulti,
  hasError,
  hasAvatar,
  onlyChevron,
  left,
  top,
  isClearable,
  isSearchable
}: IReactSelect) => {
  const { theme } = useContext(themeContext);
  const isDark = theme === "dark";

  const buildCustomStyles = () => ({
    control: (styles) => ({
      ...styles,
      backgroundColor: !onlyChevron
        ? isDark
          ? `rgb(${colors.greyishBlue90})`
          : `rgb(${colors.lightBlue30})`
        : "transparent",
      outline: hasError ? `1px solid rgb(${colors.error60})` : "",
      outlineOffset: hasError ? "-1px" : "unset",
      boxShadow: "none",
      border: "none",
      borderRadius: isSort ? "100px" : "16px",
      fontFamily: isSort ? "Poppins SemiBold" : "Poppins Regular",
      padding: !onlyChevron ? (isSort ? "2px" : "6px") : null,
      transition: "background-color 350ms",

      ":hover": {
        ...styles[":hover"],
        boxShadow: !onlyChevron
          ? isDark
            ? `inset 0 0 0 1px rgb(${colors.greyishBlue60})`
            : `inset 0 0 0 1px rgb(${colors.lightBlue90})`
          : null
      },

      ":focus-within": {
        ...styles[":focus-within"],
        backgroundColor: !onlyChevron
          ? isDark
            ? `rgb(${colors.greyishBlue80})`
            : `rgb(${colors.lightBlue20})`
          : null,
        boxShadow: !onlyChevron
          ? isDark
            ? `inset 0 0 0 2px rgb(${colors.greyishBlue60})`
            : `inset 0 0 0 2px rgb(${colors.lightBlue90})`
          : null
      },

      // Searchable text
      "[type='text']": {
        color: isDark
          ? `rgb(${colors.lightBlue10}) !important`
          : `rgb(${colors.darkBlue100}) !important`,
        fontFamily: isSort
          ? "Poppins SemiBold !important"
          : "Poppins Regular !important",
        fontSize: "14px !important"
      }
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
      margin: "0px",
      color:
        isDark && !isDisabled
          ? `rgb(${colors.greyishBlue50})`
          : isDark && isDisabled
          ? `rgb(${colors.greyishBlue70})`
          : !isDark && !isDisabled
          ? `rgb(${colors.greyishBlue30})`
          : `rgb(${colors.greyishBlue10})`
    }),
    valueContainer: (base) => ({
      ...base,
      display: onlyChevron ? "flex" : isMulti ? "flex" : "grid",
      position: onlyChevron ? "absolute" : "auto",
      marginLeft: "14px",
      width: onlyChevron ? "0px" : "auto",
      height: onlyChevron ? "0px" : "auto",
      padding: onlyChevron ? "0px" : "auto"
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: onlyChevron ? "0px" : "",
      div: {
        padding: onlyChevron ? "0px" : ""
      },
      svg: {
        fill: isDark
          ? `rgb(${colors.greyishBlue50})`
          : `rgb(${colors.greyishBlue30})`,
        ":hover": {
          fill: isDark
            ? `rgb(${colors.lightBlue10})`
            : `rgb(${colors.darkBlue100})`
        }
      }
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: isSort ? "Poppins SemiBold" : "Poppins",
      fontSize: "14px",
      padding: "0px",
      margin: "0px",
      color:
        isDark && !isDisabled
          ? `rgb(${colors.lightBlue10})`
          : isDark && isDisabled
          ? `rgb(${colors.greyishBlue70})`
          : !isDark && !isDisabled
          ? `rgb(${colors.darkBlue100})`
          : `rgb(${colors.greyishBlue20})`
    }),
    clearIndicator: (styles) => ({
      ...styles,
      color: isDark
        ? `rgb(${colors.lightBlue10})`
        : `rgb(${colors.greyishBlue70})`,
      transition: "color 350ms",
      cursor: "pointer",

      ":hover": {
        ...styles[":hover"],
        color: `rgb(${colors.greyishBlue30})`
      }
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: isMulti ? `rgb(${colors.greyishBlue50})` : "transparent"
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: `rgb(${colors.greyishBlue30})`,
      transition: "color 350ms, transform 150ms",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      cursor: "pointer",

      ":hover": {
        ...styles[":hover"],
        color: `rgb(${colors.greyishBlue30})`
      }
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: isDark
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.neutral10})`,
      border: "unset",
      color:
        isDark && !isDisabled
          ? `rgb(${colors.lightBlue10})`
          : isDark && isDisabled
          ? `rgb(${colors.greyishBlue70})`
          : !isDark && !isDisabled
          ? `rgb(${colors.darkBlue100})`
          : `rgb(${colors.greyishBlue20})`,
      borderRadius: "16px",
      boxShadow: "var(--main-shadow)",
      zIndex: 2,
      minWidth: onlyChevron ? "256px" : "",
      left: left ? left : "",
      top: top ? top : "",
      padding: "4px 0"
    }),
    menuList: (styles) => ({
      ...styles,
      margin: "2px 8px",
      padding: 0,
      maxHeight: "220px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? isDark
            ? `rgb(${colors.greyishBlue60})`
            : `rgb(${colors.lightBlue40})`
          : isFocused
          ? isDark
            ? `rgb(${colors.greyishBlue70})`
            : `rgb(${colors.lightBlue30})`
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? data.color : data.color,
        padding: "12px",
        margin: "4px 0",
        borderRadius: "8px",
        fontSize: "14px",
        fontFamily: isSort ? "Poppins SemiBold" : "Poppins Regular",
        cursor: isDisabled ? "not-allowed" : "pointer",
        height: "48px",
        display: "flex",
        alignItems: "center",
        transition: "all 350ms",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : isDark
              ? `rgb(${colors.greyishBlue60})`
              : `rgb(${colors.lightBlue40})`
            : undefined
        },

        ":hover": {
          ...styles[":hover"],
          backgroundColor: !isDisabled
            ? isSelected
              ? isDark
                ? `rgb(${colors.greyishBlue70})`
                : `rgb(${colors.lightBlue30})`
              : isDark
              ? `rgb(${colors.greyishBlue70})`
              : `rgb(${colors.lightBlue30})`
            : undefined
        }
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: isDark
          ? `rgb(${colors.greyishBlue70})`
          : `rgb(${colors.lightBlue50})`,
        borderRadius: "8px",
        padding: "0 6px"
      };
    },
    multiValueLabel: (styles, { isDisabled }) => ({
      ...styles,
      color: isDark
        ? isDisabled
          ? `rgb(${colors.greyishBlue60})`
          : `rgb(${colors.lightBlue10})`
        : isDisabled
        ? `rgb(${colors.greyishBlue20})`
        : `rgb(${colors.darkBlue100})`,
      fontSize: "14px"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: isDark
        ? isDisabled
          ? `rgb(${colors.greyishBlue60})`
          : `rgb(${colors.lightBlue10})`
        : isDisabled
        ? `rgb(${colors.greyishBlue20})`
        : `rgb(${colors.darkBlue100})`,
      cursor: "pointer",
      ":hover": {
        backgroundColor: data.color,
        color: isDark
          ? `rgb(${colors.lightBlue10})`
          : `rgb(${colors.greyishBlue70})`
      }
    })
  });

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div className={classes["custom-option"]}>
            <label className={classes["custom-checkbox"]}>
              <>
                <input type="checkbox" checked={props.isSelected} readOnly />
                <span className={classes["mark"]} />
              </>
            </label>
            {hasAvatar && <Avatar size="xs" src={props.data.pic} isActive />}
            {props.data.label}
          </div>
        </components.Option>
      </div>
    );
  };

  const NoOptionsMessage = (props) => {
    return (
      <div>
        <components.NoOptionsMessage {...props}>
          <div className={classes["simple-label"]}>{props.children}</div>
        </components.NoOptionsMessage>
      </div>
    );
  };

  const LoadingMessage = (props) => {
    return (
      <div>
        <components.LoadingMessage {...props}>
          <div className={classes["simple-label"]}>{props.children}</div>
        </components.LoadingMessage>
      </div>
    );
  };

  return (
    <Select
      className={onlyChevron ? classes["only-chev"] : ""}
      defaultValue={value}
      value={value}
      isMulti={isMulti}
      name={name}
      placeholder={placeholder}
      options={options}
      components={{
        NoOptionsMessage,
        LoadingMessage,
        ...(isMulti ? { Option } : {})
      }}
      onChange={onChange}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={isSearchable || false}
      hideSelectedOptions={false}
      closeMenuOnSelect={!isMulti}
      styles={buildCustomStyles()}
      isClearable={isClearable}
    />
  );
};

ReactSelect.defaultProp = {
  onlyChevron: false
};

export default ReactSelect;
