import React, { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { Fade } from "react-awesome-reveal";
import * as Yup from "yup";
import md5 from "md5";
import { yupSchemas } from "utils/yupSchemas";
import { InputField } from "components/input/Input";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { hasErrors } from "utils/form";
import { useFormData } from "hooks/useFormData";
import { fetchApi } from "utils/requests";
import { popupContext } from "context/popupContext";
import classes from "../Landing.module.scss";

interface IFormValues {
  first_name: string;
  last_name: string;
  school_name: string;
  email: string;
  phone: string;
  message: string;
}

const validationSchema: Yup.SchemaOf<IFormValues> = Yup.object().shape({
  first_name: yupSchemas.requiredStr,
  last_name: yupSchemas.requiredStr,
  school_name: Yup.string(),
  email: yupSchemas.email,
  phone: yupSchemas.phoneNonRequired,
  message: yupSchemas.requiredStr
});

const defaultFormData = {
  first_name: "",
  last_name: "",
  school_name: "",
  email: "",
  phone: "",
  message: ""
};

const ContactForm = () => {
  const { setPopup } = useContext(popupContext);

  const { formData, setFormData, formErrors, handleChange } = useFormData(
    defaultFormData,
    validationSchema
  );

  const submitForm = async (data) =>
    await fetchApi("courses", "/contact/admin", {
      method: "POST",
      data
    });

  // Submit mutation
  const { isLoading, mutate: handleSubmit } = useMutation({
    mutationKey: ["contact-form"],
    mutationFn: async () =>
      await submitForm({
        ...formData,
        signature: md5(formData.email)
      }),
    onSuccess: () => {
      setPopup(
        <GenericPopup
          type="success"
          title="Thank you for getting in touch!"
          msg="We appreciate you contacting us. Our team will get back to you shortly."
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <Fade direction="up" triggerOnce cascade>
      <form className={classes["form"]} onSubmit={(e) => e.preventDefault()}>
        <div className={classes["input-group-half"]}>
          <InputField
            isRequired
            name="first_name"
            placeholder="First Name*"
            onChange={handleChange}
            value={formData.first_name}
            error={formErrors.first_name}
          />
          <InputField
            isRequired
            name="last_name"
            placeholder="Last Name*"
            onChange={handleChange}
            value={formData.last_name}
            error={formErrors.last_name}
          />
        </div>
        <InputField
          name="school_name"
          placeholder="School Name"
          onChange={handleChange}
          value={formData.school_name}
          error={formErrors.school_name}
        />
        <div className={classes["input-group-half"]}>
          <InputField
            isRequired
            type="email"
            name="email"
            placeholder="E-mail*"
            onChange={handleChange}
            value={formData.email}
            error={formErrors.email}
          />
          <InputField
            name="phone"
            placeholder="Phone Number"
            onChange={handleChange}
            value={formData.phone}
            error={formErrors.phone}
          />
        </div>
        <InputField
          isRequired
          type="textarea"
          name="message"
          placeholder="Message*"
          onChange={handleChange}
          value={formData.message}
          error={formErrors.message}
        />
        <div className={classes["input-group-half"]}>
          <div>{/* TODO - Captcha */}</div>
          <Button
            type="submit"
            onClick={handleSubmit}
            isDisabled={
              hasErrors(formErrors) ||
              !formData.first_name.length ||
              !formData.last_name.length ||
              !formData.email.length ||
              !formData.message.length
            }
            isFetching={isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default ContactForm;
