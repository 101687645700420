import React from "react";
import Label from "./Label";
import classes from "./ColorInput.module.scss";

interface IColorInput {
  name: string;
  title: string;
  showTooltipIcon?: string;
  colors: string[];
  value: string;
  onChange: (color: string) => void;
}

const ColorInput = ({
  name,
  title,
  showTooltipIcon,
  colors,
  value,
  onChange
}: IColorInput) => {
  return (
    <div className={classes["main-wrapper"]}>
      <Label name={name} title={title} showTooltipIcon={showTooltipIcon} />
      <div className={classes["inputs-container"]}>
        {colors.map((color, i) => {
          return (
            <input
              key={i}
              style={{ background: color }}
              className={`${classes["color-input"]} ${
                value === color ? classes["selected"] : ""
              }`}
              readOnly
              onClick={() => onChange(color)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ColorInput;
