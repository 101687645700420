import { defineChain } from "viem";

declare global {
  interface Window {
    _env_: any;
  }
}

export enum PROJECT_ID {
  OPUS_NFT = 1,
  OPUS_EDU = 2
}

export const polygonAmoy = defineChain({
  id: 80_002,
  name: "Polygon Amoy",
  network: "maticamoy",
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpcUrls: {
    alchemy: {
      http: ["https://polygon-amoy.g.alchemy.com/v2"],
      webSocket: ["wss://polygon-amoy.g.alchemy.com/v2"]
    },
    infura: {
      http: ["https://polygon-amoy.infura.io/v3"],
      webSocket: ["wss://polygon-amoy.infura.io/ws/v3"]
    },
    default: {
      http: ["https://rpc.ankr.com/polygon_amoy"]
    },
    public: {
      http: ["https://rpc.ankr.com/polygon_amoy"]
    }
  },
  blockExplorers: {
    etherscan: {
      name: "PolygonScan",
      url: "https://www.oklink.com/amoy"
    },
    default: {
      name: "PolygonScan",
      url: "https://www.oklink.com/amoy"
    }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 25770160
    }
  },
  testnet: true
});

export const ACCOUNT_MANAGEMENT_API_ENDPOINT =
  window._env_.REACT_APP_ACCOUNT_MANAGEMENT_API_ENDPOINT;
export const COURSES_API_ENDPOINT = window._env_.REACT_APP_COURSES_API_ENDPOINT;
export const NOTIFICATIONS_API_ENDPOINT =
  window._env_.REACT_APP_NOTIFICATIONS_API_ENDPOINT;
export const ROLES_API_ENDPOINT = window._env_.REACT_APP_ROLES_API_ENDPOINT;

export const FILES_PUBLIC_DOMAIN = window._env_.REACT_APP_FILES_PUBLIC_DOMAIN;
export const IPFS_GATEWAY = window._env_.REACT_APP_IPFS_GATEWAY;

export const USA_ID = window._env_.REACT_APP_USA_ID;
export const PLANET_ID = window._env_.REACT_APP_PLANET_ID;
export const PLANET2_ID = window._env_.REACT_APP_PLANET2_ID;

// Note: configure this link based on your own ip and port
// Generate your link here: https://metamask.github.io/metamask-deeplinks/#
export const METAMASK_LOCAL_DEEPLINK =
  window._env_.REACT_APP_METAMASK_LOCAL_DEEPLINK;

export const CONTRACT_ADDRESS = window._env_.REACT_APP_CONTRACT_ADDRESS;
export const CHAIN_ID = window._env_.REACT_APP_CHAIN_ID;
export const BLOCK_EXPLORER_URL = window._env_.REACT_APP_BLOCK_EXPLORER_URL;

export const AUTH0_DOMAIN = window._env_.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = window._env_.REACT_APP_AUTH0_CLIENT_ID;

export const GOOGLE_ANALYTICS_ID = window._env_.REACT_APP_GOOGLE_ANALYTICS_ID;

export const MINT_FEE = window._env_.REACT_APP_MINT_FEE;
