import React from "react";
import { ReactComponent as CrossIcon } from "assets/icons/clear.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import classes from "./TableCols.module.scss";

enum requestStatus {
  "pending" = 0,
  "minted" = 1,
  "rejected" = 2,
  "minted-in-course" = 3
}

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, isDisabled, status, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || (defaultRef as any);

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <div
          className={classes["header-checkbox-wrapper"]}
          onClick={(e) => !isDisabled && e.stopPropagation()}
        >
          <label
            className={`${classes["custom-checkbox"]} ${classes["checkbox"]} ${
              classes[`status-${requestStatus[status]}`]
            }`}
          >
            {status === 2 && <CrossIcon />}
            {(status === 1 || status === 3) && <CheckIcon />}
            <input
              className={classes["checkbox"]}
              type="checkbox"
              ref={resolvedRef}
              {...rest}
              disabled={isDisabled}
            />
            <span className={classes["mark"]} />
          </label>
        </div>
      </>
    );
  }
);
