import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { userContext } from "context/userContext";
import { ISchoolInfo } from "utils/apiDataTypes/AccountManagementDataTypes";
import Avatar from "components/avatar/Avatar";
import Badge from "components/badge/Badge";
import classes from "./SchoolCard.module.scss";

interface ISchoolCard
  extends Pick<ISchoolInfo, "_id" | "name" | "pic" | "website"> {
  is_blacklisted: boolean;
}

const SchoolCard = ({
  _id,
  name,
  pic,
  website,
  is_blacklisted
}: ISchoolCard) => {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);

  return (
    <div
      className={`${classes["card"]} ${
        is_blacklisted ? classes["card--disabled"] : ""
      }`}
      onClick={() => !is_blacklisted && navigate(`/school/${_id}`)}
    >
      <Avatar
        src={pic}
        type={"school"}
        size="lg"
        isActive={!is_blacklisted}
        role_flag={userData.role_flag}
      />
      <div>
        <h6
          className={`${classes["u-title"]} ${classes["u-text--medium"]} ${classes["u-bold"]} ${classes["m-0"]}`}
        >
          {name}
        </h6>
        <a
          className={`${classes["u-text--small"]} ${classes["u-text--content"]} ${classes["u-website"]}`}
          href={website.includes("http") ? website : `//${website}`}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noopener noreferrer"
        >
          {website}
        </a>
      </div>
      {is_blacklisted && (
        <div className={classes["badge-wrapper"]}>
          <Badge text="Deactivated" isDanger />
        </div>
      )}
    </div>
  );
};

export default SchoolCard;
