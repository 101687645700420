import React, { Fragment, useContext, useState } from "react";
import StudentDiplomas from "./student/StudentDiplomas";
import { userContext } from "context/userContext";
import { themeContext } from "context/themeContext";
import Loader from "components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { mintRequestsDataQuery } from "query";
import { IMintRequestsData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { useQuery } from "@tanstack/react-query";
import classes from "./Diplomas.module.scss";
import RequestsTab from "./admin/tabs/RequestsTab";
import TemplatesTab from "./admin/tabs/TemplatesTab";
import ReadyToMintTab from "./admin/tabs/ReadyToMintTab";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";

const tabs = [
  { name: "Templates", route: "/diplomas" },
  { name: "Requests", route: "/requests" },
  { name: "Ready To Mint", route: "/ready-to-mint", isAdminOnly: true }
];

export interface IDiplomasPage {
  tab: "templates" | "requests" | "ready-to-mint";
}

const DiplomasPage = ({ tab }: IDiplomasPage) => {
  const { theme } = useContext(themeContext);
  const { userData } = useContext(userContext);
  const [activeTab, setActiveTab] = useState(
    tab === "templates" ? 0 : tab === "requests" ? 1 : 2
  );
  const navigate = useNavigate();

  const params = "?&status=0";
  // TODO: this query must not be visible to students
  const {
    isLoading,
    error,
    data: fetchedPendingRequestsData
  } = useQuery<boolean, Error, IMintRequestsData>({
    ...mintRequestsDataQuery(params),
    enabled: userData?.is_student !== undefined && !userData?.is_student
  });

  if (userData && userData.is_student) {
    return <StudentDiplomas />;
  }

  return (
    <div className={classes["content-wrapper"]} data-theme={theme}>
      <div className={classes["tabs"]}>
        {tabs.map((tab, i) => {
          return (
            <Fragment key={i}>
              {((tab.isAdminOnly &&
                userData?.role_flag === ROLE_FLAG["ADMIN"]) ||
                !tab.isAdminOnly) && (
                <div
                  className={`${classes["tab"]} ${
                    classes[activeTab === i ? "u-text--primary" : ""]
                  }
              ${classes[activeTab === i ? "tab-active" : ""]}
              `}
                  onClick={() => {
                    setActiveTab(i);
                    navigate(tabs[i].route);
                  }}
                >
                  {i === 1 &&
                    fetchedPendingRequestsData?.result?.length > 0 && (
                      <div className={classes["circle"]} />
                    )}
                  {i === 1 && isLoading && <Loader size="sm" />}
                  <span>{tab.name}</span>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      {tab === "templates" && <TemplatesTab />}
      {tab === "requests" && <RequestsTab />}
      {tab === "ready-to-mint" &&
        userData?.role_flag === ROLE_FLAG["ADMIN"] && <ReadyToMintTab />}
    </div>
  );
};

DiplomasPage.defaultProps = {
  tab: "templates"
};

export default DiplomasPage;
