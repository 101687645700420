import Error from "components/error/Error";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { permissionsDataQuery } from "query";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { IFetchedPermissionsData } from "utils/apiDataTypes/RolesManagementDataTypes";
import { useQuery } from "@tanstack/react-query";
import { userContext } from "./userContext";

interface IPermissionsData {
  permissionsData?: Object;
  setPermissions?: any;
  clearPermissions?: any;
}

export const permissionsContext = createContext<IPermissionsData>({});

export const PermissionsProvider = (props: any) => {
  const { userData } = useContext(userContext);
  const [permissionsData, setPermissionsData] = useState({});

  const setPermissions = useCallback(
    (permissions: IFetchedPermissionsData) => {
      setPermissionsData(permissions);
    },
    [setPermissionsData]
  );

  const clearPermissions = useCallback(() => {
    setPermissionsData(null);
  }, [setPermissionsData]);

  const fetchedPermissionsData = useGetFetchQuery(
    permissionsDataQuery()
  ) as IFetchedPermissionsData;

  // Fetch auth info in case cache data is missing
  // TODO - use `isLoading` and `error`
  const { isLoading: permissionsDataLoading, error: permissionsDataError } =
    useQuery<boolean, Error>({
      ...permissionsDataQuery(),
      enabled: !fetchedPermissionsData && !!userData
    });

  /* Set Permissions */
  useEffect(() => {
    if (fetchedPermissionsData) {
      setPermissions(fetchedPermissionsData);
    }
  }, [fetchedPermissionsData]);

  if (permissionsDataError) {
    return <Error error={permissionsDataError} />;
  }

  return (
    <permissionsContext.Provider
      value={{ permissionsData, setPermissions, clearPermissions }}
    >
      {props.children}
    </permissionsContext.Provider>
  );
};
