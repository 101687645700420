import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CourseForm from "../components/CourseForm";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import {
  countriesDataQuery,
  nftTemplatesDataQuery,
  singleCourseDataQuery,
  singleCourseDraftDataQuery,
  teamDataQuery
} from "query";
import {
  ICourse,
  ICourseFormData,
  ITemplatesData
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import {
  ICountry,
  ITeamData
} from "utils/apiDataTypes/AccountManagementDataTypes";
import classes from "../components/Single.module.scss";

interface IEditCourse {
  isCreateFromTemplate?: boolean;
  isDraft?: boolean;
}

const EditCourse = ({ isCreateFromTemplate, isDraft }: IEditCourse) => {
  let { courseId, courseDraftId } = useParams();
  const navigate = useNavigate();

  const fetchedCourseData = useGetFetchQuery(
    isDraft
      ? singleCourseDraftDataQuery(courseDraftId)
      : singleCourseDataQuery(courseId)
  ) as ICourse;

  // Fetch course data in case cache data is missing
  const { isLoading, error } = useQuery<boolean, Error, ICourse>({
    ...(isDraft
      ? singleCourseDraftDataQuery(courseDraftId)
      : singleCourseDataQuery(courseId)),
    enabled: !fetchedCourseData
  });

  const fetchedMembersData = useGetFetchQuery(teamDataQuery()) as ITeamData;

  // Fetch members in case cache data is missing
  const { isLoading: membersIsLoading, error: membersError } = useQuery<
    boolean,
    Error
  >({
    ...teamDataQuery(),
    enabled: !fetchedMembersData
  });

  // Fetch templates data in case cache data is missing
  const {
    isLoading: templatesIsLoading,
    error: templatesError,
    data: fetchedNftTemplatesData
  } = useQuery<boolean, Error, ITemplatesData>(nftTemplatesDataQuery());

  // Fetch templates data in case cache data is missing
  const fetchedCountriesData = useGetFetchQuery(
    countriesDataQuery()
  ) as ICountry[];

  const { isLoading: isLoadingCountries, error: errorCountries } = useQuery<
    boolean,
    Error
  >({
    ...countriesDataQuery(),
    enabled: !fetchedCountriesData
  });

  if (isLoading || templatesIsLoading || membersIsLoading)
    return <Loader size="lg" hasText />;

  if (error || templatesError || membersError) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error || templatesError || membersError} />
      </div>
    );
  }

  const defaultLectureDates = fetchedCourseData.lecture_dates
    ? fetchedCourseData.lecture_dates.reduce((acc, current, i) => {
        acc[`lecture_date_dates_${i + 1}`] = current.start_date;
        acc[`lecture_date_startHour_${i + 1}`] = current.start_date;
        acc[`lecture_date_endHour_${i + 1}`] = current.end_date;
        acc[`lecture_date_lecture_${i + 1}`] = current.title;
        return acc;
      }, {})
    : {};

  const defaultAGD = fetchedCourseData.AGD
    ? fetchedCourseData.AGD.reduce((acc, current, i) => {
        acc[`AGD_code_${i + 1}`] = current.code;
        acc[`AGD_hours_${i + 1}`] = current.hours;
        acc[`AGD_description_${i + 1}`] = current.description;
        acc[`AGD_type_${i + 1}`] = current.type
          ? {
              label: current.type,
              value: current.type
            }
          : null;
        return acc;
      }, {})
    : {};

  const defaultManagers = fetchedMembersData.result.filter((member) =>
    fetchedCourseData.managers
      ? fetchedCourseData.managers.some((manager) => {
          return member._id === manager._id;
        })
      : false
  );

  const defaultTemplate = fetchedNftTemplatesData.result.find(
    (template) => template._id === fetchedCourseData.template_id
  );

  const defaultPrimaryInstructors = fetchedCourseData.instructors
    .filter((i) => i.primary)
    .reduce((acc, current, i) => {
      acc[`primary_instructor_title_${i + 1}`] = {
        label: current.title,
        value: current.title
      };
      acc[`primary_instructor_firstName_${i + 1}`] = current.first_name;
      acc[`primary_instructor_lastName_${i + 1}`] = current.last_name;
      // Hide all `Instructor Degree` related logic
      // acc[`primary_instructor_degree_${i + 1}`] =
      //   current.degree && current.degree.length
      //     ? current.degree.map((deg) => ({
      //         label: deg,
      //         value: deg
      //       }))
      //     : [];
      acc[
        `primary_instructor_signature_${i + 1}`
      ] = `${FILES_PUBLIC_DOMAIN}${current.signature}`;
      return acc;
    }, {});

  const defaultSecondaryInstructors = fetchedCourseData.instructors
    .filter((i) => !i.primary)
    .reduce((acc, current, i) => {
      acc[`secondary_instructor_title_${i + 1}`] = {
        label: current.title,
        value: current.title
      };
      acc[`secondary_instructor_firstName_${i + 1}`] = current.first_name;
      acc[`secondary_instructor_lastName_${i + 1}`] = current.last_name;
      // Hide all `Instructor Degree` related logic
      // acc[`secondary_instructor_degree_${i + 1}`] =
      //   current.degree && current.degree.length
      //     ? current.degree.map((deg) => ({
      //         label: deg,
      //         value: deg
      //       }))
      //     : [];
      acc[
        `secondary_instructor_signature_${i + 1}`
      ] = `${FILES_PUBLIC_DOMAIN}${current.signature}`;
      return acc;
    }, {});

  const courseStateLabel =
    fetchedCourseData.location && fetchedCourseData.location.state
      ? fetchedCourseData.location.state.name
      : null;
  const courseStateObj =
    courseStateLabel &&
    fetchedCountriesData &&
    fetchedCountriesData[0].states.find((s) => s.name === courseStateLabel);

  const defaultFormData: ICourseFormData = {
    name: fetchedCourseData.name,
    start_date: !isCreateFromTemplate ? fetchedCourseData.start_date : "",
    end_date: !isCreateFromTemplate ? fetchedCourseData.end_date : "",
    ...defaultLectureDates,
    image: fetchedCourseData.image
      ? `${FILES_PUBLIC_DOMAIN}${fetchedCourseData.image}`
      : "",
    course_managers: defaultManagers.map((m) => ({
      value: m._id,
      label: `${m.first_name} ${m.last_name}`
    })),
    template_id: defaultTemplate
      ? {
          value: defaultTemplate._id,
          label: defaultTemplate.name
        }
      : null,
    attendees: "",
    ...defaultPrimaryInstructors,
    ...defaultSecondaryInstructors,
    state: courseStateObj
      ? {
          label: `${courseStateObj.state_code} - ${courseStateObj.name}`,
          value: courseStateObj._id,
          code: courseStateObj.state_code,
          name: courseStateObj.name
        }
      : null,
    city:
      fetchedCourseData.location && fetchedCourseData.location.city
        ? {
            label: fetchedCourseData.location.city,
            value: fetchedCourseData.location.city
          }
        : null,
    credit_hours: fetchedCourseData.credit_hours,
    ...defaultAGD,
    is_open: fetchedCourseData.is_open
  };

  if (fetchedCourseData.has_mints && !isCreateFromTemplate)
    navigate(`/courses/${courseId}`);

  return (
    <>
      <CourseForm
        title={
          isCreateFromTemplate
            ? "Create Course From Template"
            : isDraft
            ? "Edit Course Draft"
            : "Edit Course Info"
        }
        defaultFormData={defaultFormData}
        isEdit={!isCreateFromTemplate}
        isCreateFromTemplate={isCreateFromTemplate}
        isDraft={isDraft}
        courseId={courseId}
        courseDraftId={courseDraftId}
        // Hide all `Lecture Dates` related logic
        // lectureDatesEditCount={
        //   fetchedCourseData.lecture_dates
        //     ? fetchedCourseData.lecture_dates.length
        //     : 0
        // }
        primaryInstructorEditCount={
          fetchedCourseData.instructors.filter((i) => i.primary).length
        }
        secondaryInstructorEditCount={
          fetchedCourseData.instructors.filter((i) => !i.primary).length
        }
        AGDEditCount={fetchedCourseData.AGD ? fetchedCourseData.AGD.length : 0}
      />
    </>
  );
};

export default EditCourse;
