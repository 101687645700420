import React from "react";
import { NavigateFunction } from "react-router-dom";
import Button from "components/buttons/Button";
import RenderCell from "./RenderCell";
import moment from "moment";
import { tableColumnsWidths } from "./utils/table-utils";
import { dateFormatDisplay } from "utils/staticOptions";
import classes from "./TableCols.module.scss";

interface ISchoolRegistrationRequestsCols {
  navigate: NavigateFunction;
  isMobile: boolean;
}

export const SchoolRegistrationRequestsCols = (
  navigate: ISchoolRegistrationRequestsCols["navigate"],
  isMobile: ISchoolRegistrationRequestsCols["isMobile"]
) => {
  return [
    {
      Header: "School",
      accessor: "school-reg-requests-mobile",
      Cell: ({ row }) => (
        <RenderCell type="school_requests_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "School Name",
      accessor: "school-name",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => (
        <RenderCell type="school-name" rowProps={row.original} />
      )
    },
    {
      Header: "Request Date",
      accessor: "date_requested",
      maxWidth: tableColumnsWidths.dateMax,
      minWidth: tableColumnsWidths.dateMin,
      width: tableColumnsWidths.date,
      Cell: ({ row }) => {
        const date = moment(row.original.date_requested)
          .format(dateFormatDisplay)
          .toString();
        return <div>{date}</div>;
      }
    },
    {
      Header: "Requested by",
      accessor: "requested_by",
      minWidth: tableColumnsWidths.emailMin,
      Cell: ({ row }) => (
        <div className={classes["email-container"]}>
          {row.original.requested_by}
        </div>
      )
    },
    {
      Header: "",
      accessor: "action",
      maxWidth: !isMobile && tableColumnsWidths.actionsXlMax,
      minWidth: !isMobile && tableColumnsWidths.actionsXlMin,
      width: !isMobile && tableColumnsWidths.actionsXl,
      Cell: ({ row }) => {
        return (
          <div
            className={`${classes["u-justify--end"]}   ${
              classes[
                row.original.school_status === 1
                  ? "approved"
                  : row.original.school_status === 2
                  ? "rejected"
                  : ""
              ]
            }`}
          >
            {row.original.school_status === 0 && (
              <Button
                variant="contrast"
                size="medium"
                onClick={() =>
                  navigate(
                    `/school/${row.original.school._id}/${row.original.id}`
                  )
                }
              >
                Resolve
              </Button>
            )}
            {row.original.school_status === 1 && "Approved"}
            {row.original.school_status === 2 && "Rejected"}
          </div>
        );
      }
    }
  ];
};
