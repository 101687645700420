import React, { createContext, useCallback, useEffect, useState } from "react";
import { userData } from "utils/storage";
import { tokenExpired } from "utils/requests";
import { meDataQuery, schoolDataQuery } from "query";
import {
  IFetchedMeData,
  ISchoolInfo,
  ROLE_FLAG
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { useQuery } from "@tanstack/react-query";

export interface IUserCtx {
  userData?: {
    email: string;
    is_student: boolean;
    is_super_admin: boolean;
    role_flag: number;
    role_access: number;
    id: string;
    wallet: string;
  } | null;
  setUser?: any;
  clearUser?: any;
  tenant?: string;
}

export const userContext = createContext<IUserCtx>({});

export function UserProvider(props: any) {
  const [userData, setUserData] = useState<IUserCtx["userData"] | null>();
  const [tenant, setTenant] = useState("");

  const setUser = useCallback(
    (user: userData) => {
      setUserData(user);
    },
    [setUserData]
  );

  const clearUser = useCallback(() => {
    setUserData(null);
  }, [setUserData]);

  const {
    isLoading,
    error,
    data: fetchedMeData
  } = useQuery<boolean, Error, IFetchedMeData>({
    ...meDataQuery(),
    enabled: !tokenExpired()
  });

  /* Set mandatory data on login */
  useEffect(() => {
    if (fetchedMeData) {
      setUserData({
        id: fetchedMeData?._id,
        email: fetchedMeData?.email,
        is_student: fetchedMeData?.role_flag === ROLE_FLAG["STUDENT"],
        is_super_admin: fetchedMeData?.role_flag === ROLE_FLAG["SUPER_ADMIN"],
        role_flag: fetchedMeData?.role_flag,
        role_access: fetchedMeData?.role?.role_access,
        wallet: fetchedMeData?.wallet
      });
    }
  }, [fetchedMeData]);

  // Fetch school data when a user is logged in
  const {
    isLoading: schoolDataIsLoading,
    error: schoolDataError,
    data: schoolData
  } = useQuery<boolean, Error, ISchoolInfo>({
    ...schoolDataQuery(),
    enabled:
      !!userData?.email && !userData?.is_student && !userData?.is_super_admin
  });

  useEffect(() => {
    if (schoolData) {
      setTenant(schoolData.tenant);
    }
  }, [schoolData]);

  return (
    <userContext.Provider value={{ userData, setUser, clearUser, tenant }}>
      {props.children}
    </userContext.Provider>
  );
}
