import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ReactComponent as Dots } from "assets/icons/menu-dots.svg";
import classes from "./TableCols.module.scss";
import Button from "components/buttons/Button";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import useComponentVisible from "hooks/useComponentVisible";
import { Portal } from "react-portal";
import { tableColumnsWidths } from "./utils/table-utils";
import RenderCell from "./RenderCell";
import PermissionsGate from "roles/PermissionsGate";

const dropdownItems = (rowProps) => {
  return [
    {
      link: (
        <PermissionsGate permissions={["TEMPLATE.UPDATE"]}>
          <Link
            className={classes["c-menu-item"]}
            to={`/diplomas/${rowProps.id}`}
            key={rowProps.id}
          >
            Open
          </Link>
        </PermissionsGate>
      )
    }
  ];
};

interface IDiplomaCols {
  showConfirmPopup: (id: string, action: "delete" | "duplicate") => void;
  isMobile: boolean;
}

export const DiplomaCols = (
  showConfirmPopup: IDiplomaCols["showConfirmPopup"],
  isMobile: IDiplomaCols["isMobile"]
) => {
  return [
    {
      Header: "Template",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="diploma_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "Template name",
      accessor: "template_name",
      minWidth: tableColumnsWidths.diplomaNameMin,
      Cell: ({ row }) => (
        <RenderCell type="diploma_template" rowProps={row.original} />
      )
    },
    {
      Header: "",
      accessor: "dropdown",
      maxWidth: tableColumnsWidths.actionsMax,
      minWidth: tableColumnsWidths.actionsMin,
      width: tableColumnsWidths.actions,
      Cell: (tableProps) => {
        const { ref, isComponentVisible, setIsComponentVisible } =
          useComponentVisible(false);

        return (
          <div
            className={classes["actions-container"]}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              variant="link"
              size="medium"
              icon={Dots}
              isIconBtn
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            {isComponentVisible && (
              <Portal node={document && document.getElementById("table-body")}>
                <div
                  className={classes["c-menu-wrapper"]}
                  ref={ref}
                  style={{
                    top: `${tableProps.row.distance_from_top + 48}px`
                  }}
                >
                  <div className={classes["c-menu"]}>
                    <div
                      key={tableProps.row.original.id}
                      className={classes["c-menu-item"]}
                      onClick={() =>
                        window.open(
                          FILES_PUBLIC_DOMAIN + tableProps.row.original.pic
                        )
                      }
                    >
                      Preview
                    </div>
                    {!isMobile &&
                      dropdownItems(tableProps.row.original).map((item, i) => (
                        <Fragment key={i}>{item.link}</Fragment>
                      ))}
                    <PermissionsGate permissions={["TEMPLATE.CREATE"]}>
                      <div
                        className={classes["c-menu-item"]}
                        onClick={() => {
                          showConfirmPopup(
                            tableProps.row.original.id,
                            "duplicate"
                          );
                        }}
                      >
                        Duplicate
                      </div>
                    </PermissionsGate>
                    <PermissionsGate permissions={["TEMPLATE.DELETE"]}>
                      <div
                        className={`${classes["c-menu-item"]}  ${classes["c-menu-item--danger"]}`}
                        onClick={() => {
                          showConfirmPopup(
                            tableProps.row.original.id,
                            "delete"
                          );
                        }}
                      >
                        Delete
                      </div>
                    </PermissionsGate>
                  </div>
                </div>
              </Portal>
            )}
          </div>
        );
      }
    }
  ];
};
