import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./Datepicker.module.scss";
import InputText from "./InputText";
import Error from "./Error";
import Button from "components/buttons/Button";

interface IDatepickerProps {
  onChange: (value: number) => void;
  isDisabled?: boolean;
  placeholder?: string;
  error?: string;
  name?: string;
  value?: number;
  displayValue?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  hasTime?: boolean;
  onConfirm?: (value: number) => void;
  children?: React.ReactNode;
}

const Datepicker = (props: IDatepickerProps) => {
  const {
    onChange,
    isDisabled,
    placeholder,
    error,
    name,
    value,
    displayValue,
    minDate,
    maxDate,
    hasTime,
    onConfirm,
    children
  } = props;

  let datepickerRef = useRef(null);

  const closeDatepicker = () => datepickerRef.current.setOpen(false);

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <>
      <InputText
        onClick={onClick}
        ref={ref}
        type={"datepicker"}
        onChange={onChange}
        isDisabled={isDisabled}
        value={displayValue}
        placeholder={placeholder}
        error={error}
        name={name}
        readOnly
      />
      <Error text={error} />
    </>
  ));

  return (
    <div className={classes["c-datepicker"]}>
      <DatePicker
        ref={datepickerRef}
        selected={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={"MM/dd/yyyy"}
        shouldCloseOnSelect={!hasTime}
        customInput={<CustomInput />}
      >
        {hasTime && (
          <div className={classes["children-wrapper"]}>
            <div className={classes["time-wrapper"]}>{children}</div>
            <Button
              onClick={() => {
                closeDatepicker();
                onConfirm(value);
              }}
              variant={"contrast"}
              minWidth="full"
            >
              Done
            </Button>
          </div>
        )}
      </DatePicker>
    </div>
  );
};

export default Datepicker;
