import React, { useContext, useState } from "react";
import { themeContext } from "context/themeContext";
import { ReactComponent as copyIcon } from "assets/icons/copy.svg";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import Button from "components/buttons/Button";
import PopupTitleAndMessage from "components/popups/common/PopupTitleAndMessage";
import { shortenUrl } from "utils/format";
import classes from "./SharePopup.module.scss";
import { windowContext } from "context/windowsContext";

interface ISharePopup {
  title: string;
  desc: string;
  fieldLabel: string;
  link: string;
}

const SharePopup = ({ title, desc, fieldLabel, link }: ISharePopup) => {
  const [copied, setCopied] = useState<boolean>(false);
  const { theme } = useContext(themeContext);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  return (
    <ModalWrapper size="md">
      <div data-theme={theme} className={classes["share-popup"]}>
        <PopupTitleAndMessage title={title} msg={desc} />
        <div className={classes["link-container"]}>
          <div className={`${classes["u-text--primary"]} ${classes["title"]}`}>
            <span className={classes["u-text--small"]}>{fieldLabel}</span>
          </div>
          <div className={classes["link"]}>
            <div>{shortenUrl(link, isMobile ? 10 : 25)}</div>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(link);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 3000);
              }}
              iconPosition="right"
              icon={!copied ? copyIcon : checkIcon}
            >
              {!copied ? "Copy" : "Copied"}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SharePopup;
