import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import CoursesTable from "./CoursesTable";
import { ICourseData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { adminCoursesDataQuery } from "query";
import { sortDirEnum } from "utils/staticOptions";
import classes from "../School.module.scss";

export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "Name",
    value: "name",
    dir: "asc"
  },
  {
    id: 2,
    label: "Start Date",
    value: "start_date",
    dir: "asc"
  }
];

interface ICoursesProps {
  perPage?: number;
  isPaginationHidden?: boolean;
  hasNoActions?: boolean;
  hasViewAll?: boolean;
  hideHeaderControls?: boolean;
}

const SchoolCourses = ({
  perPage = 8,
  isPaginationHidden,
  hasNoActions,
  hasViewAll,
  hideHeaderControls
}: ICoursesProps) => {
  let { schoolId } = useParams();
  const { search: urlParams } = useLocation();

  const {
    page: pageNumber,
    search,
    sort,
    sortDir
  } = queryString.parse(urlParams);

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ""
  );

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const params = `?&limit=${perPage}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ""
  }${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    adminCoursesDataQuery(schoolId, params)
  );

  const { result, all_records, total_results } = data || {};
  const hasData = result && result.length > 0;

  return (
    <div className={classes["content-wrapper"]}>
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortFilter) ||
        page > 1) && (
        <PageHeader
          hasData={true}
          title="Courses"
          itemsAmountMsg={calcItemsAmount({
            page: page,
            perPage: perPage,
            result: result,
            total_results,
            all_records: all_records
          })}
          hasSearch
          searchValue={searchValue}
          passSearchValueUp={(value: string) => {
            setSearchValue(value);
          }}
          setPage={setPage}
          hasSort
          passSortValueUp={(value: ISortOption) => {
            setSortFilter(value);
          }}
          sortValue={sortFilter}
          setSortValue={setSortFilter}
          sortOptions={sortOptions}
          searchPlaceholder="Search by course name"
          hideHeaderControls={hideHeaderControls}
        />
      )}
      <CoursesTable
        searchValue={searchValue}
        page={page}
        setPage={setPage}
        itemsAmountMsg={calcItemsAmount({
          type: "Courses ",
          page: page,
          perPage: perPage,
          result: result,
          total_results,
          all_records: all_records
        })}
        data={{ data, isLoading, error }}
        isPaginationHidden={isPaginationHidden}
        hasNoActions={hasNoActions}
        hasViewAll={hasViewAll}
        schoolId={schoolId}
      />
    </div>
  );
};

export default SchoolCourses;
