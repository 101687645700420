import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loader from "../loader/Loader";
import { adminSchoolMemberDataQuery } from "query";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { IMemberData } from "utils/apiDataTypes/AccountManagementDataTypes";
import classes from "./Breadcrumbs.module.scss";

// Dynamic breadcrumbs component displaying entity name by param id.
// Specific `Member` breadcrumb component to prevent issue with more than 1 params in url.
//
// Currently used only on admin route. Please have in mind to update query in any other case.
const MemberBreadcrumb = () => {
  let { id, memberId } = useParams();

  const fetchedMemberData = useGetFetchQuery(
    adminSchoolMemberDataQuery(memberId)
  ) as IMemberData;

  const { isLoading: memberIsLoading, error: memberError } = useQuery<
    boolean,
    Error
  >({
    ...adminSchoolMemberDataQuery(memberId),
    enabled: !!memberId && !fetchedMemberData
  });

  if (memberId && memberIsLoading) {
    return (
      <span>
        <Loader size="sm" />
      </span>
    );
  }

  if (memberId && memberError) {
    return <div>{memberError.message}</div>;
  }

  if (memberId && fetchedMemberData)
    return (
      <div className={classes["last"]}>
        {fetchedMemberData.first_name + " " + fetchedMemberData.last_name}
      </div>
    );

  return <div className={classes["last"]}>{id}</div>;
};

export default MemberBreadcrumb;
