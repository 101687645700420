import { useContext } from "react";
import { themeContext } from "context/themeContext";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import { colors } from "styles";

interface IChevron {
  variant: "contrast" | "neutral";
  isOpen: boolean;
  onClick: () => void;
  rotate?: number;
  isDisabled?: boolean;
}

export const Chevron = ({
  variant,
  isOpen,
  onClick,
  rotate,
  isDisabled
}: IChevron) => {
  const { theme } = useContext(themeContext);
  const Icon = ChevronIcon;
  return (
    <Icon
      width={20}
      height={20}
      fill={
        theme === "dark"
          ? !isDisabled
            ? variant === "contrast"
              ? `rgb(${colors.neutral10})`
              : `rgb(${colors.orange60})`
            : `rgb(${colors.greyishBlue90})`
          : !isDisabled
          ? variant === "contrast"
            ? `rgb(${colors.neutral90})`
            : `rgb(${colors.orange60})`
          : `rgb(${colors.greyishBlue10})`
      }
      style={{
        rotate: `${rotate}deg`,
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        cursor: isDisabled ? "default" : "pointer"
      }}
      onClick={onClick}
    />
  );
};
