import Button from "components/buttons/Button";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { InputField } from "components/input/Input";
import { FormEvent, useCallback, useEffect } from "react";
import {
  ICreateTemplateQueryData,
  defaultFieldHeight,
  diplomaTemplateWidth,
  dndAreaPadding,
  fullWidthFieldWidth
} from "../CreateDiploma";
import { IField } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "../CreateDiploma.module.scss";
import ColorInput from "components/input/ColorInput";
import { colors, fonts } from "styles";
import { rgbToHex } from "utils/format";
import {
  dateFormatDisplay,
  dateFormatDisplayDiploma,
  dateFormatDisplayDiplomaNoYear,
  dateFormatDisplayNoYear,
  fileInstructions
} from "utils/staticOptions";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { schoolDataQuery } from "query";
import {
  ISelectOption,
  ISchoolInfo
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { useQuery } from "@tanstack/react-query";
import Loader from "components/loader/Loader";
import Error from "components/error/Error";
import moment from "moment";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import PermissionsGate from "roles/PermissionsGate";

export interface ICreateDiplomaFormData {
  name: string;
  template_background: {
    name: string;
    value: string;
    originalDimensions: { width: number; height: number };
    bgDimensions: { width: number; height: number };
  };
  template_fields: ITemplateFieldOption[];
  preview_school: { label: ""; value: "" };
  preview_logo: { label: ""; value: "" };
  preview_course: { label: ""; value: "" };
  preview_student: { label: ""; value: "" };
  preview_teacher_1: { label: ""; value: "" };
  preview_teacher_1_sig: { label: ""; value: "" };
  preview_teacher_2: { label: ""; value: "" };
  preview_teacher_2_sig: { label: ""; value: "" };
  preview_start_end_date: { label: ""; value: "" };
  preview_custom_data: { label: ""; value: "" };
  preview_cert_date: { label: ""; value: "" };
}

const fontOptions = [
  { label: fonts.family.diplomasDefault, value: fonts.family.diplomasDefault },
  {
    label: fonts.family.diplomasSecondary,
    value: fonts.family.diplomasSecondary
  }
];

export const fontSizes = {
  tiny: 36,
  small: 48,
  regular: 66,
  medium: 78,
  large: 96
};

const fontSizeOptions = [
  { label: "Tiny (36px)", value: fontSizes.tiny },
  { label: "Small (48px)", value: fontSizes.small },
  { label: "Regular (66px)", value: fontSizes.regular },
  { label: "Medium (78px)", value: fontSizes.medium },
  { label: "Large (96px)", value: fontSizes.large }
];

const lineBreakOptions = [
  { label: "No Line Break", value: "nowrap" },
  { label: "Break Line", value: "break-spaces" }
];

export enum fieldLabels {
  "school-name" = "School name",
  "student-name" = "Student name",
  "course-name" = "Course name",
  "teacher-1" = "Prim. Instructor",
  "teacher-1-sig" = "Prim. Instructor Sig",
  "teacher-2" = "Sec. Instructor",
  "teacher-2-sig" = "Sec. Instructor Sig",
  "cert-date" = "Cert. date",
  "start-end-date" = "Start - End Date",
  "logo" = "Logo",
  "custom-data" = "Custom Data"
}

export enum fieldPreviewPair {
  "student-name" = "preview_student",
  "course-name" = "preview_course",
  "teacher-1" = "preview_teacher_1",
  "teacher-1-sig" = "preview_teacher_1_sig",
  "teacher-2" = "preview_teacher_2",
  "teacher-2-sig" = "preview_teacher_2_sig",
  "cert-date" = "preview_cert_date",
  "start-end-date" = "preview_start_end_date",
  "custom-data" = "preview_custom_data"
}

export const defaultStartEndDate = {
  label: `${moment(new Date())
    .format(dateFormatDisplayDiplomaNoYear)
    .toString()} - ${moment(new Date())
    .add(30, "days")
    .format(dateFormatDisplayDiploma)
    .toString()}`,
  value: `${moment(new Date())
    .format(dateFormatDisplayDiplomaNoYear)
    .toString()} - ${moment(new Date())
    .add(30, "days")
    .format(dateFormatDisplayDiploma)
    .toString()}`
};

export const defaultCertDate = {
  label: moment(new Date()).format(dateFormatDisplayDiploma).toString(),
  value: moment(new Date()).format(dateFormatDisplayDiploma).toString()
};

export const defaultCustomData = {
  label: moment(new Date()).format("YYYY"),
  value: moment(new Date()).format("YYYY")
};

export interface ITemplateFieldOption extends IField, ISelectOption {}

interface ICreateDiplomaForm {
  onSubmit: (data: ICreateTemplateQueryData) => void;
  isLoading: boolean;
  formData: ICreateDiplomaFormData;
  formErrors: any;
  setFormData: any;
  setFormErrors: any;
  handleChange: any;
  handleUploadedFileBase64: any;
  selectedField: ITemplateFieldOption;
  setSelectedField: (field: any) => void;
  isEdit: boolean;
  onEdit: (data: ICreateTemplateQueryData) => void;
  onDelete: () => void;
}

const CreateDiplomaForm = ({
  onSubmit,
  isLoading,
  formData,
  formErrors,
  setFormData,
  setFormErrors,
  handleChange,
  handleUploadedFileBase64,
  selectedField,
  setSelectedField,
  isEdit,
  onEdit,
  onDelete
}: ICreateDiplomaForm) => {
  const fetchedSchoolData = useGetFetchQuery(schoolDataQuery()) as ISchoolInfo;
  const { isLoading: schoolIsLoading, error: schoolError } = useQuery<
    boolean,
    Error
  >({
    ...schoolDataQuery(),
    enabled: !fetchedSchoolData
  });

  const buildData = () => {
    const fields = formData.template_fields.map((field) => {
      return {
        label: fieldLabels[field.name],
        name: field.name,
        fontSize: +field.fontSize,
        fontWeight: 600,
        fontColor: field.fontColor,
        fontStyle: field.fontStyle.replace(" Semibold", ""),
        textAlign: field.textAlign,
        whiteSpace: field.whiteSpace,
        top:
          +field.top *
          (formData.template_background.originalDimensions.width /
            diplomaTemplateWidth),
        left:
          +field.left *
          (formData.template_background.originalDimensions.width /
            diplomaTemplateWidth),
        width:
          +field.width *
          (formData.template_background.originalDimensions.width /
            diplomaTemplateWidth),
        height:
          +field.height *
          (formData.template_background.originalDimensions.width /
            diplomaTemplateWidth),
        visible: field.visible
      };
    });
    const data = {
      name: formData.name,
      background: formData.template_background.value,
      background_name: formData.template_background.name,
      fields: fields
    };
    if (isEdit) {
      return onEdit(data);
    }
    onSubmit(data);
  };

  const changeFontStyleHandler = (
    fontSetting: string,
    option: "fontColor" | "fontStyle" | "fontSize" | "whiteSpace"
  ) => {
    const fields = [...formData.template_fields];
    if (!selectedField) {
      for (let i = 0; i < fields.length; i++) {
        fields[i][option] = fontSetting;
      }
      setFormData((prevState) => ({ ...prevState, template_fields: fields }));
    }
    if (selectedField) {
      const [targetFieldIndex] = fields
        .map((field, i) => {
          if (field.name === selectedField.name) {
            return i;
          }
        })
        .filter((field) => field !== undefined);
      fields[targetFieldIndex][option] = fontSetting;
      setSelectedField(fields[targetFieldIndex]);
      setFormData((prevState) => ({ ...prevState, template_fields: fields }));
    }
  };

  const renderFontStyle = useCallback(() => {
    // Render value of selected field
    if (selectedField) {
      return { label: selectedField.fontStyle, value: selectedField.fontStyle };
    }
    // Render value when a field is not selected
    // and all fields have the same fontStyle
    if (
      !selectedField &&
      (formData.template_fields.every(
        (field) => field.fontStyle === fonts.family.diplomasDefault
      ) ||
        formData.template_fields.every(
          (field) => field.fontStyle === fonts.family.diplomasSecondary
        ))
    ) {
      return {
        label: formData.template_fields[0]?.fontStyle,
        value: formData.template_fields[0]?.fontStyle
      };
    }
    // Render value when a field is NOT selected
    // and fields have varying fontStyles
    return { label: "Mixed", value: "Mixed" };
  }, [selectedField, formData.template_fields]);

  const renderFontColor = useCallback(() => {
    if (selectedField) return selectedField.fontColor;
    if (
      formData.template_fields.every(
        (field) => field.fontColor === rgbToHex(colors.black)
      )
    ) {
      return rgbToHex(colors.black);
    }
    if (
      formData.template_fields.every(
        (field) => field.fontColor === rgbToHex(colors.white)
      )
    ) {
      return rgbToHex(colors.white);
    }
  }, [selectedField, formData.template_fields]);

  const renderFontSize = useCallback(() => {
    // If there are no fields, return the default font size
    if (formData.template_fields.length === 0) {
      return fontSizeOptions[4];
    }

    // If a field is selected, render its font size
    if (selectedField)
      return fontSizeOptions.filter(
        (option) => option.value === selectedField.fontSize
      )[0];

    // If there are fields, but none are selected either:
    // 1. Return the font size if all fields have the same font size
    // 2. Return "Mixed" if not all fields have the same font size
    const firstValue = formData.template_fields[0].fontSize;

    for (let i = 1; i < formData.template_fields.length; i++) {
      if (formData.template_fields[i].fontSize !== firstValue) {
        return { label: "Mixed", value: "Mixed" };
      }
    }

    return fontSizeOptions.filter((option) => option.value === firstValue)[0];
  }, [selectedField, formData.template_fields]);

  const renderLineBreakRule = useCallback(() => {
    if (selectedField)
      return selectedField.whiteSpace === "nowrap"
        ? lineBreakOptions[0]
        : lineBreakOptions[1];
    if (
      formData.template_fields.every((field) => field.whiteSpace === "nowrap")
    ) {
      return lineBreakOptions[0];
    }
    if (
      formData.template_fields.every(
        (field) => field.whiteSpace === "break-spaces"
      )
    ) {
      return lineBreakOptions[1];
    }
    return { label: "Mixed", value: "Mixed" };
  }, [selectedField, formData.template_fields]);

  const templateFieldOptions = useCallback(() => {
    return [
      {
        preview: fetchedSchoolData
          ? {
              label: fetchedSchoolData?.name,
              value: fetchedSchoolData?._id
            }
          : null,
        label: fieldLabels["school-name"],
        value: fieldLabels["school-name"],
        name: "school-name",
        fontSize: fontSizes.large,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.1,
        left: dndAreaPadding,
        width: fullWidthFieldWidth,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: formData.preview_course || null,
        label: fieldLabels["course-name"],
        value: fieldLabels["course-name"],
        name: "course-name",
        fontSize: fontSizes.large,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.5,
        left: dndAreaPadding,
        width: fullWidthFieldWidth,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: formData.preview_student || null,
        label: fieldLabels["student-name"],
        value: fieldLabels["student-name"],
        name: "student-name",
        fontSize: fontSizes.large,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.6,
        left: dndAreaPadding,
        width: fullWidthFieldWidth,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: fetchedSchoolData
          ? { label: "Logo", value: fetchedSchoolData?.pic }
          : null,
        label: fieldLabels["logo"],
        value: fieldLabels["logo"],
        fontSize: fontSizes.small,
        name: "logo",
        fontWeight: 400,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.25,
        left: 280,
        visible: true,
        isLogo: true
      },
      {
        preview: formData.preview_teacher_1 || null,
        label: fieldLabels["teacher-1"],
        value: fieldLabels["teacher-1"],
        name: "teacher-1",
        fontSize: fontSizes.small,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.9,
        left: 40,
        width: 180,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: formData.preview_teacher_1_sig || null,
        label: fieldLabels["teacher-1-sig"],
        value: fieldLabels["teacher-1-sig"],
        name: "teacher-1-sig",
        fontSize: fontSizes.small,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.75,
        left: 40,
        visible: true,
        isSignature: true
      },
      {
        preview: formData.preview_teacher_2 || null,
        label: fieldLabels["teacher-2"],
        value: fieldLabels["teacher-2"],
        name: "teacher-2",
        fontSize: fontSizes.small,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.9,
        left: 236,
        width: 180,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: formData.preview_teacher_2_sig || null,
        label: fieldLabels["teacher-2-sig"],
        value: fieldLabels["teacher-2-sig"],
        name: "teacher-2-sig",
        fontSize: fontSizes.small,
        fontWeight: 600,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.75,
        left: 260,
        visible: true,
        isSignature: true
      },
      {
        preview: formData.preview_cert_date || defaultCertDate,
        label: fieldLabels["cert-date"],
        value: fieldLabels["cert-date"],
        name: "cert-date",
        fontSize: fontSizes.small,
        fontWeight: 400,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.9,
        left: 470,
        width: 180,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: formData.preview_start_end_date || defaultStartEndDate,
        label: fieldLabels["start-end-date"],
        value: fieldLabels["start-end-date"],
        name: "start-end-date",
        fontSize: fontSizes.medium,
        fontWeight: 400,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.7,
        left: 202,
        width: fullWidthFieldWidth / 2,
        height: defaultFieldHeight,
        visible: true
      },
      {
        preview: formData.preview_custom_data || defaultCustomData,
        label: fieldLabels["custom-data"],
        value: fieldLabels["custom-data"],
        name: "custom-data",
        fontSize: fontSizes.small,
        fontWeight: 400,
        fontColor: rgbToHex(colors.black),
        fontStyle: fonts.family.diplomasDefault,
        textAlign: "center",
        whiteSpace: "nowrap",
        top: formData.template_background.bgDimensions.height * 0.8,
        left: 470,
        width: 180,
        height: 32,
        visible: true
      }
    ];
  }, [formData.template_fields, fetchedSchoolData]);

  // Clear all preview fields when preview course changes
  useEffect(() => {
    setFormData((prevState) => {
      const fields = [...prevState.template_fields];
      if (fields.some((field, i) => field.name === "student-name")) {
        fields.filter((field, i) => field.name === "student-name")[0].preview =
          null;
      }
      if (fields.some((field, i) => field.name === "teacher-1")) {
        fields.filter((field, i) => field.name === "teacher-1")[0].preview =
          null;
      }
      if (fields.some((field, i) => field.name === "teacher-2")) {
        fields.filter((field, i) => field.name === "teacher-2")[0].preview =
          null;
      }
      if (
        fields.some(
          (field, i) =>
            field.name === "start-end-date" && !formData.preview_course
        )
      ) {
        fields.filter(
          (field, i) => field.name === "start-end-date"
        )[0].preview = defaultStartEndDate;
      }
      if (
        fields.some(
          (field, i) => field.name === "cert-date" && !formData.preview_course
        )
      ) {
        fields.filter((field, i) => field.name === "cert-date")[0].preview =
          defaultCertDate;
      }

      if (fields.some((field, i) => field.name === "custom-data")) {
        fields.filter((field, i) => field.name === "custom-data")[0].preview =
          defaultCustomData;
      }

      return {
        ...prevState,
        preview_student: null,
        preview_teacher_1: null,
        preview_teacher_1_sig: null,
        preview_teacher_2: null,
        preview_teacher_2_sig: null,
        preview_start_end_date: formData.preview_course
          ? formData.preview_start_end_date
          : null,
        preview_custom_data: null,
        template_fields: fields
      };
    });
  }, [formData.preview_course]);

  if (!fetchedSchoolData && schoolIsLoading)
    return <Loader size="lg" hasText withPadding />;

  if (schoolError) return <Error error={schoolError} />;

  return (
    <div className={classes["fields-area"]}>
      <div
        className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--h4"]}`}
      >
        {isEdit ? "Edit" : "Create"} NFT Certificate Template
        {isEdit && (
          <PermissionsGate permissions={["TEMPLATE.DELETE"]}>
            <Button variant="link" icon={TrashIcon} onClick={onDelete} isDanger>
              Delete Template
            </Button>
          </PermissionsGate>
        )}
      </div>
      <InputField
        title="NFT Certificate Template Name"
        name="name"
        onChange={handleChange}
        value={formData.name}
        placeholder="Enter NFT Certificate Template Name"
        isRequired
        error={formErrors.name}
      />
      <InputField
        type="file"
        fileType="image/png, image/jpeg, image/jpg"
        name="template_background"
        title="Background Design"
        subtitle={fileInstructions.nftTemplate}
        showTooltipIcon="Attach an image used for the background of the certificate template."
        isRequired
        onChange={(e: FormEvent) =>
          handleUploadedFileBase64("template_background", e)
        }
        fileName={formData.template_background?.name || ""}
        onClear={() => {
          setFormData({
            ...formData,
            template_background: null
          });
          setFormErrors({ ...formErrors, template_background: "" });
          setSelectedField(null);
        }}
        error={formErrors.template_background}
      />
      <ReactSelectWrapper
        title="Template fields"
        placeholder="Choose fields"
        name="template_fields"
        options={templateFieldOptions()}
        value={formData.template_fields}
        onChange={(e) => {
          handleChange({
            target: {
              name: "template_fields",
              value: e
            }
          });
          setSelectedField(null);
        }}
        isMulti
        isDisabled={!formData.template_background?.value}
        error={formErrors.template_fields}
      />
      <ReactSelectWrapper
        title="Font Style"
        showTooltipIcon="Double-click on the text box in order to change the font style just for that field."
        placeholder="Choose font"
        name="font_style"
        options={fontOptions}
        value={renderFontStyle()}
        isRequired
        onChange={(fontStyleObj) =>
          changeFontStyleHandler(fontStyleObj.value, "fontStyle")
        }
        isDisabled={!formData.template_fields.length}
      />
      <ReactSelectWrapper
        title="Font Sizе"
        showTooltipIcon="Double-click on the text box in order to change the font size just for that field."
        placeholder="Choose size"
        name="font_size"
        options={fontSizeOptions}
        value={renderFontSize()}
        isRequired
        onChange={(fontStyleObj) =>
          changeFontStyleHandler(fontStyleObj.value, "fontSize")
        }
        isDisabled={!formData.template_fields.length}
      />
      <ReactSelectWrapper
        title="Break Line"
        showTooltipIcon="Double-click on the text box in order to change the line break rule just for that field."
        placeholder="Choose Line Break Rule"
        name="white_space"
        options={lineBreakOptions}
        value={renderLineBreakRule()}
        isRequired
        onChange={(fontStyleObj) =>
          changeFontStyleHandler(fontStyleObj.value, "whiteSpace")
        }
        isDisabled={!formData.template_fields.length}
      />
      <ColorInput
        title="Font Color"
        showTooltipIcon="Double-click on the text box in order to change the font color just for that field."
        name="font_color"
        colors={[rgbToHex(colors.black), rgbToHex(colors.white)]}
        value={renderFontColor()}
        onChange={(color) => changeFontStyleHandler(color, "fontColor")}
      />
      <Button
        onClick={buildData}
        minWidth="md"
        isFetching={isLoading}
        isDisabled={
          !formData.template_background?.value ||
          !formData.template_background?.name ||
          !formData.name ||
          !formData.template_fields.some(
            (field) => field.name === "student-name"
          ) ||
          !!formErrors.template_background
        }
      >
        {isEdit ? "Update NFT Certificate Template" : "Save NFT Certificate Template"}
      </Button>
    </div>
  );
};
export default CreateDiplomaForm;
