import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { schoolMemberDataQuery } from "query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import FormWrapper from "components/forms/form-wrapper/FormWrapper";
import MemberForm from "../components/MemberForm";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import {
  IMemberData,
  IMemberFormData
} from "utils/apiDataTypes/AccountManagementDataTypes";

const EditMember = () => {
  const { memberId } = useParams();

  const {
    isLoading: memberLoading,
    error: memberError,
    data: memberData
  } = useQuery<boolean, Error, IMemberData>({
    ...schoolMemberDataQuery(memberId)
  });

  if (memberLoading) return <Loader size="lg" hasText withPadding />;

  if (memberError) {
    return <ErrorComponent error={memberError} />;
  }

  const buildFileValue = (val) => (val ? `${FILES_PUBLIC_DOMAIN}${val}` : "");

  const defaultFormData: IMemberFormData = {
    role_type: { label: memberData.role.name, value: memberData.role.name },
    title: memberData.title
      ? { label: memberData.title, value: memberData.title }
      : null,
    first_name: memberData.first_name,
    last_name: memberData.last_name,
    email: memberData.email,
    phone: memberData.phone,
    pic: buildFileValue(memberData?.pic)
  };

  return (
    <FormWrapper title="Edit Member Details">
      <MemberForm
        defaultFormData={defaultFormData}
        memberId={memberId}
        isEdit
      />
    </FormWrapper>
  );
};

export default EditMember;
