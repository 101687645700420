import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { themeContext } from "context/themeContext";
import Button from "components/buttons/Button";
import logoLight from "assets/images/logo-light.svg";
import logoDark from "assets/images/logo-dark.svg";
import Divider from "components/divider/Divider";
import { ReactComponent as SunIcon } from "assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "assets/icons/moon.svg";
import { ReactComponent as Ig } from "assets/icons/ig.svg";
import { ReactComponent as Twitter } from "assets/icons/twitter.svg";
import { ReactComponent as Linkedin } from "assets/icons/linkedin.svg";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import HeroDark from "assets/landing/hero-dark.svg";
import HeroLight from "assets/landing/hero-light.svg";
import LimeChainLogoBlack from "assets/landing/limechain-black.svg";
import LimeChainLogoWhite from "assets/landing/limechain-white.svg";
import PolygonLogoBlack from "assets/landing/polygon-black.svg";
import PolygonLogoWhite from "assets/landing/polygon-white.svg";
import KoisLogoBlack from "assets/landing/kois-black.svg";
import KoisLogoWhite from "assets/landing/kois-white.svg";
import ApogeeLogoBlack from "assets/landing/apogeelabs-black.svg";
import ApogeeLogoWhite from "assets/landing/apogeelabs-white.svg";
import EmailIcon from "assets/landing/email.svg";
import PhoneIcon from "assets/landing/phone.svg";
import BenefitsTabs from "./components/BenefitsTabs";
import OnboardingTabs from "./components/OnboardingTabs";
import AccordionItem from "./components/AccordionItem";
import Feature from "./components/Feature";
import ContactForm from "./components/ContactForm";
import { featuresLeft, featuresRight, faqs } from "./content";
import classes from "./Landing.module.scss";
import useComponentVisible from "hooks/useComponentVisible";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";
import ThemeSwitcher from "components/theme-switcher/ThemeSwitcher";

const menuItems = [
  { link: "#benefits", name: "OpusNFT Benefits" },
  { link: "#onboarding", name: "Onboarding" },
  { link: "#features", name: "Features" },
  { link: "#faq", name: "FAQ" },
  { link: "#contacts", name: "Contacts" }
];

const renderMenuItems = (setIsVisible?: (visible: boolean) => void) => {
  return menuItems.map((item) => (
    <li key={item.name}>
      <a href={item.link} onClick={() => setIsVisible && setIsVisible(false)}>
        {item.name}
      </a>
    </li>
  ));
};

const Landing = () => {
  const navigate = useNavigate();
  const { theme, switchTheme } = useContext(themeContext);
  const isDark = theme === "dark";
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};

  // Check if it is scrolled
  const [scrollTop, setScrollTop] = useState(0);
  // `Benefits` section tabs control
  const [benefitsActiveTab, setBenefitsActiveTab] = useState<number>(0);
  // `Onboarding Process` section tabs control
  const [onboardingActiveTab, setOnboardingActiveTab] = useState<number>(0);

  // Lock background scroll on mobile menu
  useEffect(() => {
    if (isComponentVisible) {
      document.getElementById("landing-page-wrapper").style.overflowY =
        "hidden";

      return;
    }
    document.getElementById("landing-page-wrapper").style.overflowY = "auto";
  }, [isComponentVisible]);

  const handleScroll = (event) => {
    setScrollTop(event.currentTarget.scrollTop);
  };
  const isScrolled = scrollTop > 0;

  const handleVisitApp = () => {
    if (userData && is_super_admin) return "/main-dashboard";
    if (userData && !is_super_admin) return "/school";
    return "/login";
  };

  return (
    <div
      id="landing-page-wrapper"
      className={classes["wrapper"]}
      data-theme={theme}
      onScroll={handleScroll}
    >
      <header
        className={`${classes["header"]} ${
          isComponentVisible && isMobile ? classes["rounded-bottom-border"] : ""
        } ${
          isScrolled || isComponentVisible ? classes["header--scrolled"] : ""
        }`}
        ref={ref}
      >
        <div className={classes["logo-and-menu"]}>
          <img
            className={classes["logo"]}
            src={isDark ? logoLight : logoDark}
            height={isMobile ? 40 : 56}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          {isMobile && (
            <Button
              className={classes["mobile-menu-btn"]}
              variant="link"
              isIconBtn
              icon={isComponentVisible ? CrossIcon : HamburgerIcon}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
          )}
        </div>
        {!isMobile && (
          <div className={classes["actions"]}>
            <ul className={classes["main-menu"]}>{renderMenuItems()}</ul>
            <Divider orientation={"vertical"} />
            <Button
              variant="link"
              isIconBtn
              icon={isDark ? SunIcon : MoonIcon}
              onClick={switchTheme}
            />
            <Link to={handleVisitApp()} target={"_blank"}>
              <Button variant={"outline"} size={"medium"} iconPosition="right">
                Visit App
              </Button>
            </Link>
          </div>
        )}
        {isMobile && (
          <div
            className={`${classes["mobile-menu-wrapper"]} ${
              isComponentVisible ? classes["open"] : ""
            } `}
          >
            <div className={classes["actions"]}>
              <Divider orientation={"horizontal"} />
              <ul className={classes["main-menu"]}>
                {renderMenuItems(setIsComponentVisible)}
              </ul>
              <Divider orientation={"horizontal"} />
              <Link
                className={classes["link-to-app"]}
                to={handleVisitApp()}
                target={"_blank"}
              >
                <Button
                  variant={"outline"}
                  size={"medium"}
                  iconPosition="right"
                  minWidth="full"
                >
                  Visit App
                </Button>
              </Link>

              <ThemeSwitcher className={classes["theme-btn"]} />
            </div>
          </div>
        )}
      </header>

      <section className={classes["hero"]}>
        <div className={classes["container--sm"]}>
          <Fade direction="up" triggerOnce cascade>
            <h1 className={`${classes["u-text--primary"]} ${classes["title"]}`}>
              Transform your credentials into unique digital assets with{" "}
              <span className={classes["accent"]}>Opus</span>
            </h1>
            <p
              className={`${classes["u-text--content"]} ${classes["u-text--center"]}`}
            >
              With Opus, you can easily share your NFT certificates with
              potential employers, clients, or educational institutions, knowing
              that they can be trusted as legitimate records of your
              achievements and skills. <br /> No more lost or fake documents!
            </p>
            <div className={classes["btn-container"]}>
              <Link to={handleVisitApp()} target={"_blank"}>
                <Button variant={"contrast"} minWidth="md" iconPosition="right">
                  {userData ? "Visit App" : "Login"}
                </Button>
              </Link>
              <Link to="/verify-diploma" target={"_blank"}>
                <Button variant={"outline"} minWidth="md" iconPosition="right">
                  Verify NFT Certificate
                </Button>
              </Link>
            </div>
          </Fade>
        </div>
      </section>
      <Fade direction="up" triggerOnce delay={1000}>
        <section className={classes["screenshot"]}>
          <div className={classes["container--sm"]}>
            <img
              src={isDark ? HeroDark : HeroLight}
              className={classes["screenshot__img"]}
              alt="OpusNFT"
            />
          </div>
        </section>
      </Fade>

      <section className={classes["partners-section"]}>
        <div className={classes["container"]}>
          <div className={classes["partners"]}>
            <div className={classes["partners__group"]}>
              <Fade direction="up" triggerOnce>
                <div
                  className={`${classes["u-text--primary"]} ${classes["u-text--small"]}`}
                >
                  Technical Partners
                </div>
                <div className={classes["partners__logos"]}>
                  <a
                    href="https://limechain.tech/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={isDark ? LimeChainLogoWhite : LimeChainLogoBlack}
                      alt="Lime Chain"
                      height={48}
                    />
                  </a>
                  <a
                    href="https://polygon.technology/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={isDark ? PolygonLogoWhite : PolygonLogoBlack}
                      alt="Polygon"
                      height={48}
                    />
                  </a>
                </div>
              </Fade>
            </div>
            <div className={classes["divider"]} />
            <div className={classes["partners__group"]}>
              <Fade direction="up" triggerOnce>
                <div
                  className={`${classes["u-text--primary"]} ${classes["u-text--small"]}`}
                >
                  Strategic Partners
                </div>
                <div className={classes["partners__logos"]}>
                  <a
                    href="https://www.koiscenter.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={isDark ? KoisLogoWhite : KoisLogoBlack}
                      alt="Kois Center"
                      height={34}
                    />
                  </a>
                  <a
                    href="https://apogeelabs.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={isDark ? ApogeeLogoWhite : ApogeeLogoBlack}
                      alt="Apogee Labs"
                      height={32}
                    />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <Fade direction="up" triggerOnce>
        <section className={classes["futureproof-section"]}>
          <div className={classes["container--sm"]}>
            <div
              className={`${classes["u-text--primary"]} ${classes["title"]}`}
            >
              <span>
                Futureproof Your{" "}
                <span className={classes["accent"]}>Accomplishments</span>
              </span>
            </div>
          </div>
        </section>
      </Fade>

      <section id="benefits" className={classes["benefits-section"]}>
        <div className={classes["container"]}>
          <Fade direction="up" triggerOnce>
            <h2 className={`${classes["u-text--primary"]} ${classes["title"]}`}>
              OpusNFT Benefits
            </h2>
            <BenefitsTabs
              activeTab={benefitsActiveTab}
              setActiveTab={setBenefitsActiveTab}
            />
          </Fade>
        </div>
      </section>
      <section id="onboarding" className={classes["onboarding-section"]}>
        <div className={classes["container"]}>
          <Fade direction="up" triggerOnce>
            <h2 className={`${classes["u-text--primary"]} ${classes["title"]}`}>
              Onboarding Process
            </h2>
            <OnboardingTabs
              activeTab={onboardingActiveTab}
              setActiveTab={setOnboardingActiveTab}
            />
          </Fade>
        </div>
      </section>
      <section className={classes["ready-section"]}>
        <div className={classes["container"]}>
          <Fade direction="up" triggerOnce>
            <div className={classes["box"]}>
              <h3
                className={`${classes["u-text--primary"]} ${classes["title"]}`}
              >
                Ready to start?
              </h3>
              <p
                className={`${classes["u-text--primary"]} ${classes["u-text--center"]}`}
              >
                If you are new to Opus, You can join our ecosystem by clicking
                the registration button. <br /> If you already have an account
                with us, you can log in by clicking the Log In button.
              </p>
              <div className={classes["btn-container"]}>
                <Link to={"/school-register"} target={"_blank"}>
                  <Button
                    variant={"outline"}
                    minWidth="md"
                    iconPosition="right"
                  >
                    Apply as School
                  </Button>
                </Link>
                <Link to={"/student-register"} target={"_blank"}>
                  <Button
                    variant={"outline"}
                    minWidth="md"
                    iconPosition="right"
                  >
                    Register as Student
                  </Button>
                </Link>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section id="features" className={classes["features-section"]}>
        <div className={classes["container"]}>
          <h2 className={`${classes["u-text--primary"]} ${classes["title"]}`}>
            Features
          </h2>
          <div className={classes["features-group"]}>
            <div className={classes["features-column"]}>
              <Fade direction="up" triggerOnce cascade>
                {featuresLeft.map((f, i) => (
                  <Feature
                    key={i}
                    icon={f.icon}
                    title={f.title}
                    desc={f.desc}
                  />
                ))}
              </Fade>
            </div>
            <div className={classes["features-column"]}>
              <Fade direction="up" triggerOnce cascade>
                {featuresRight.map((f, i) => (
                  <Feature
                    key={i}
                    icon={f.icon}
                    title={f.title}
                    desc={f.desc}
                  />
                ))}
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" className={classes["accordion-section"]}>
        <div className={classes["container--sm"]}>
          <h2 className={`${classes["u-text--primary"]} ${classes["title"]}`}>
            Frequently Asked Questions
          </h2>
          <div className={classes["accordions"]}>
            <Fade direction="up" triggerOnce cascade>
              {faqs.map((f, i) => (
                <div key={i} className={classes["accordion__el"]}>
                  <AccordionItem question={f.q} answer={f.a} />
                  <Divider />
                </div>
              ))}
            </Fade>
          </div>
        </div>
      </section>
      <section id="contacts" className={classes["contacts-section"]}>
        <div className={classes["container"]}>
          <Fade direction="up" triggerOnce cascade>
            <h2
              className={`${classes["u-text--primary"]} ${classes["title"]} ${classes["title--mobile"]}`}
            >
              Get in touch!
            </h2>

            <div className={classes["contacts-wrapper"]}>
              <div className={classes["contacts"]}>
                <h2
                  className={`${classes["u-text--primary"]} ${classes["title"]}`}
                >
                  Get in touch!
                </h2>
                <div className={classes["contact-group"]}>
                  <img src={EmailIcon} height={64} alt="Email" />
                  <div>
                    <h2 className={classes["contact-group__title"]}>
                      Other Inquiries
                    </h2>
                    <p>
                      You have other Inquiries? Want to discuss possible
                      partnerships? Please email us below:
                    </p>
                  </div>
                  <a href="mailto:support@opusnft.io">support@opusnft.io</a>
                </div>
                {/* Temporary hide phone section */}
                {/*<div className={classes["contact-group"]}>*/}
                {/*  <img src={PhoneIcon} height={64} alt="Phone" />*/}
                {/*  <div>*/}
                {/*    <h2 className={classes["contact-group__title"]}>*/}
                {/*      Give us a call*/}
                {/*    </h2>*/}
                {/*    <p>*/}
                {/*      We’d love to hear from you! Give us a call, leave a*/}
                {/*      message and we’ll get back to you:*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*  <a href="tel:+12 345 678 910">+12 345 678 910</a>*/}
                {/*</div>*/}
              </div>
              <div className={classes["form-wrapper"]}>
                <h2 className={classes["form-wrapper__title"]}>Lets talk!</h2>
                <p>
                  We are always on the look out for partnerships and new users.
                  If you have questions or ideas we would love to hear from you.
                </p>
                <ContactForm />
              </div>
            </div>
          </Fade>
        </div>
      </section>
      <section className={classes["divider-section"]}>
        <div className={classes["container"]}>
          <Divider />
        </div>
      </section>
      <footer className={classes["footer"]}>
        <div className={classes["footer--left"]}>
          <img
            className={classes["logo"]}
            src={isDark ? logoLight : logoDark}
            height={56}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <ul className={classes["footer__menu"]}>
            {renderMenuItems()}
            <li>
              <Link to={"/verify-diploma"} target="_blank">
                Verify Certificate
              </Link>
            </li>
          </ul>
          <div className={classes["footer__social"]}>
            <a
              href={"https://www.instagram.com/opusnft/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="link" icon={Ig} isIconBtn />
            </a>
            <a
              href={"https://www.linkedin.com/company/opusnft/"}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="link" icon={Linkedin} isIconBtn />
            </a>
            <a
              href={"https://twitter.com/opusnftio"}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="link" icon={Twitter} isIconBtn />
            </a>
          </div>
        </div>
        <div className={classes["footer--right"]}>
          {/* TODO - Newsletter */}
        </div>
      </footer>
    </div>
  );
};

export default Landing;
