import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useCookies } from "react-cookie";
import classes from "./App.module.scss";
import { Toast } from "./components/toast/Toast";
import { toastContext } from "./context/toastContext";
import { Router } from "./routes/Routes";
import { popupContext } from "./context/popupContext";
import { createGlobalStyle } from "styled-components";
import { themeContext } from "context/themeContext";
import { globalStyle } from "./styles";
import { GOOGLE_ANALYTICS_ID } from "utils/constants";
import Button from "./components/buttons/Button";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

// Fixes the followig bug:
// If a user click on connect with coinbase wallet, but does not have
// the extension, an error is thrown and the QR popup link to
// the coinbase wallet website is not displayed.
window.Buffer = window.Buffer || require("buffer").Buffer;

// Initialize analytics if the env var is set
GOOGLE_ANALYTICS_ID && ReactGA.initialize(GOOGLE_ANALYTICS_ID);

// Handle global styles through `styled-components`
const GlobalStyle = createGlobalStyle`
  ${globalStyle} 
`;

function App() {
  const { toastData } = useContext(toastContext);
  const { popupData } = useContext(popupContext);
  const { theme, isCollapsed } = useContext(themeContext);
  const location = useLocation();

  const [cookies, setCookie] = useCookies(["cookiesAccepted"]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search
    });
  }, [location]);

  const onChange = (accepted) =>
    setCookie("cookiesAccepted", accepted, { path: "/" });

  return (
    <div
      className={classes["AppWrapper"]}
      data-theme={"dark"}
      data-collapsed={isCollapsed}
    >
      {!cookies.cookiesAccepted && (
        <Toast
          msg={
            "We use cookies to analyze site performance and deliver personalized content. By clicking “Agree”, you consent to our Privacy Policy."
          }
          btn={
            <Button
              size={"small"}
              variant={"neutral"}
              onClick={() => onChange("true")}
            >
              Agree
            </Button>
          }
          className={"with-btns"}
          type={"info"}
          isSelfClosing={false}
          icon={InfoIcon}
        />
      )}
      {toastData ? (
        <Toast
          withTitle={toastData.withTitle}
          title={toastData.title}
          msg={toastData.msg}
          type={toastData.type}
          icon={toastData.icon}
        />
      ) : (
        ""
      )}
      {popupData}
      <Router />
      <GlobalStyle theme={theme} />
    </div>
  );
}

export default App;
