import React, { useContext } from "react";
import styled from "styled-components";
import Loader from "components/loader/Loader";
import { colors, fonts, transitions } from "styles";
import { themeContext } from "context/themeContext";

export interface IButtonStyleProps {
  isFetching?: boolean;
  type: "button" | "submit" | "reset";
  isDisabled?: boolean;
  isIconBtn?: boolean;
  icon?: any;
  iconPosition?: "left" | "right";
  iconRotate?: number;
  minWidth: "xs" | "sm" | "md" | "full";
  variant?: "neutral" | "contrast" | "outline" | "link" | "outline-neutral";
  themeIs?: string;
  size?: "large" | "medium" | "small";
  isText?: boolean;
  isDanger?: boolean;
  isSuccess?: boolean;
}

interface IButtonProps extends IButtonStyleProps {
  children?: React.ReactNode;
  onClick?: any;
  className?: string;
}

const SButton = styled.button<IButtonStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size, variant }) =>
    variant === "link"
      ? "auto"
      : size === "large"
      ? "48px"
      : size === "medium"
      ? "40px"
      : "32px"};
  width: ${({ size, isIconBtn }) =>
    isIconBtn
      ? size === "large"
        ? "48px"
        : size === "medium"
        ? "40px"
        : "32px"
      : "auto"};
  transition: ${transitions.button};
  position: relative;
  border-style: none;
  border-radius: 100px;
  box-sizing: border-box;
  background-color: ${({ variant, themeIs, isSuccess }) =>
    themeIs === "dark"
      ? variant === "contrast"
        ? `rgb(${isSuccess ? colors.success50 : colors.orange60})`
        : variant === "neutral"
        ? `rgb(${colors.greyishBlue90})`
        : `transparent`
      : variant === "contrast"
      ? `rgb(${isSuccess ? colors.success50 : colors.orange60})`
      : variant === "neutral"
      ? `rgb(${colors.lightBlue30})`
      : `transparent`};
  border: ${({ variant, themeIs, isDanger }) =>
    variant === "outline"
      ? isDanger
        ? `1px solid rgb(${colors.error50})`
        : `1px solid rgb(${colors.orange60})`
      : variant === "outline-neutral"
      ? themeIs === "dark"
        ? `1px solid rgb(${colors.greyishBlue50})`
        : `1px solid rgb(${colors.greyishBlue40})`
      : "unset"};
  color: ${({ variant, themeIs, isDanger }) =>
    isDanger
      ? `rgb(${colors.error40})`
      : themeIs === "dark"
      ? `rgb(${colors.lightBlue10})`
      : variant === "contrast"
      ? `rgb(${colors.lightBlue10})`
      : variant === "neutral"
      ? `rgb(${colors.darkBlue100})`
      : `rgb(${colors.darkBlue100})`};
  font-family: "Poppins SemiBold";
  font-size: ${({ size }) =>
    size === "small" ? `${fonts.size.xsmall}` : `${fonts.size.small}`};
  font-weight: normal;
  padding: ${({ size, icon, isText, variant }) =>
    variant === "link"
      ? "0"
      : size === "large"
      ? icon && !isText
        ? "16px"
        : "16px 24px"
      : size === "medium"
      ? icon && !isText
        ? "10px"
        : "10px 16px"
      : icon && !isText
      ? "10px"
      : "10px 12px"};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  will-change: transform;
  min-width: ${({ minWidth }) => btnWidths[minWidth]};

  svg {
    flex-shrink: 0;
    transition: ${transitions.button};
    fill: ${({ themeIs, variant, isDanger }) =>
      isDanger
        ? `rgb(${colors.error40})`
        : themeIs === "dark"
        ? `rgb(${colors.lightBlue10})`
        : variant === "contrast"
        ? `rgb(${colors.lightBlue10})`
        : variant === "neutral"
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.darkBlue100})`};
  }

  @media (hover: hover) {
    &:hover {
      opacity: 1;
      visibility: visible;
      background-color: ${({ variant, themeIs, isSuccess }) =>
        themeIs === "dark"
          ? variant === "contrast"
            ? `rgb(${isSuccess ? colors.success40 : colors.orange50})`
            : variant === "neutral"
            ? `rgb(${colors.greyishBlue80})`
            : variant === "link"
            ? "transparent"
            : variant === "outline-neutral"
            ? `rgba(229, 232, 255, 0.1)`
            : `rgba(255, 119, 21, 0.06)`
          : variant === "contrast"
          ? `rgb(${isSuccess ? colors.success40 : colors.orange50})`
          : variant === "neutral"
          ? `rgb(${colors.lightBlue10})`
          : variant === "link"
          ? "transparent"
          : variant === "outline-neutral"
          ? `rgb(36, 38, 58, 0.03)`
          : `rgba(255, 119, 21, 0.06)`};
      border-color: ${({ variant, themeIs }) =>
        variant === "outline-neutral"
          ? themeIs === "dark"
            ? `rgb(${colors.greyishBlue70})`
            : `rgb(${colors.greyishBlue20})`
          : ""};
      color: ${({ variant, themeIs, isDanger }) =>
        isDanger
          ? `rgb(${colors.error50})`
          : themeIs === "dark"
          ? variant === "link"
            ? `rgb(${colors.greyishBlue30})`
            : `rgb(${colors.lightBlue10})`
          : variant === "contrast"
          ? `rgb(${colors.lightBlue10})`
          : variant === "neutral"
          ? `rgb(${colors.darkBlue100})`
          : variant === "link"
          ? `rgb(${colors.greyishBlue80})`
          : `rgb(${colors.darkBlue100})`};

      svg {
        fill: ${({ themeIs, variant, isDanger }) =>
          isDanger
            ? `rgb(${colors.error50})`
            : themeIs === "dark"
            ? variant === "link"
              ? `rgb(${colors.greyishBlue30})`
              : `rgb(${colors.lightBlue10})`
            : variant === "contrast"
            ? `rgb(${colors.lightBlue10})`
            : variant === "neutral"
            ? `rgb(${colors.darkBlue100})`
            : variant === "link"
            ? `rgb(${colors.greyishBlue80})`
            : `rgb(${colors.darkBlue100})`};
      }
    }
  }

  &:active {
    background-color: ${({ variant, themeIs, isSuccess }) =>
      themeIs === "dark"
        ? variant === "contrast"
          ? `rgb(${isSuccess ? colors.success30 : colors.orange40})`
          : variant === "neutral"
          ? `rgb(${colors.greyishBlue70})`
          : variant === "link"
          ? "transparent"
          : variant === "outline-neutral"
          ? `rgba(229, 232, 255, 0.15)`
          : `rgba(255, 119, 21, 0.15)`
        : variant === "contrast"
        ? `rgb(${isSuccess ? colors.success30 : colors.orange40})`
        : variant === "neutral"
        ? `rgb(${colors.lightBlue30})`
        : variant === "link"
        ? "transparent"
        : variant === "outline-neutral"
        ? `rgb(36, 38, 58, 0.06)`
        : `rgba(255, 119, 21, 0.15)`};
    border-color: ${({ variant, themeIs }) =>
      variant === "outline-neutral"
        ? themeIs === "dark"
          ? `rgb(${colors.greyishBlue70})`
          : `rgb(${colors.greyishBlue10})`
        : ""};
    color: ${({ variant, themeIs, isDanger }) =>
      isDanger
        ? `rgb(${colors.error60})`
        : themeIs === "dark"
        ? variant === "contrast"
          ? `rgb(${colors.neutral10})`
          : variant === "neutral"
          ? `rgb(${colors.white})`
          : variant === "link"
          ? `rgb(${colors.greyishBlue40})`
          : `rgb(${colors.lightBlue10})`
        : variant === "contrast"
        ? `rgb(${colors.white})`
        : variant === "neutral"
        ? `rgb(${colors.greyishBlue100})`
        : variant === "link"
        ? `rgb(${colors.greyishBlue60})`
        : `rgb(${colors.greyishBlue100})`};

    svg {
      fill: ${({ themeIs, variant, isDanger }) =>
        isDanger
          ? `rgb(${colors.error60})`
          : themeIs === "dark"
          ? variant === "contrast"
            ? `rgb(${colors.neutral10})`
            : variant === "neutral"
            ? `rgb(${colors.white})`
            : variant === "link"
            ? `rgb(${colors.greyishBlue40})`
            : `rgb(${colors.lightBlue10})`
          : variant === "contrast"
          ? `rgb(${colors.white})`
          : variant === "neutral"
          ? `rgb(${colors.greyishBlue100})`
          : variant === "link"
          ? `rgb(${colors.greyishBlue60})`
          : `rgb(${colors.greyishBlue100})`};
    }
  }

  &:disabled {
    box-shadow: none;
    border: ${({ variant, themeIs }) =>
      themeIs === "dark"
        ? variant === "contrast"
          ? "unset"
          : variant === "neutral"
          ? "unset"
          : variant === "link"
          ? "unset"
          : variant === "outline"
          ? `1px solid rgb(${colors.orange60})`
          : `1px solid rgb(${colors.greyishBlue80})`
        : variant === "contrast"
        ? "red"
        : variant === "neutral"
        ? "red"
        : variant === "link"
        ? "unset"
        : `1px solid rgb(${colors.greyishBlue10})`};
    svg {
      fill: ${({ themeIs, variant }) =>
        themeIs === "dark"
          ? variant === "contrast"
            ? `rgb(${colors.greyishBlue70})`
            : variant === "neutral"
            ? `rgb(${colors.greyishBlue70})`
            : variant === "link"
            ? `rgb(${colors.greyishBlue60})`
            : `rgb(${colors.greyishBlue70})`
          : variant === "contrast"
          ? `rgb(${colors.greyishBlue10})`
          : variant === "neutral"
          ? `rgb(${colors.greyishBlue10})`
          : variant === "link"
          ? `rgb(${colors.greyishBlue30})`
          : `rgb(${colors.greyishBlue10})`};
    }
    background: ${({ variant, themeIs }) =>
      themeIs === "dark"
        ? variant === "contrast"
          ? `rgb(${colors.greyishBlue90})`
          : variant === "neutral"
          ? `rgb(${colors.greyishBlue90})`
          : `transparent`
        : variant === "contrast"
        ? `rgb(${colors.lightBlue30})`
        : variant === "neutral"
        ? `rgb(${colors.lightBlue10})`
        : `transparent`};
    color: ${({ variant, themeIs }) =>
      themeIs === "dark"
        ? variant === "contrast"
          ? `rgb(${colors.greyishBlue70})`
          : variant === "neutral"
          ? `rgb(${colors.greyishBlue70})`
          : variant === "link"
          ? `rgb(${colors.greyishBlue60})`
          : `rgb(${colors.greyishBlue70})`
        : variant === "contrast"
        ? `rgb(${colors.greyishBlue10})`
        : variant === "neutral"
        ? `rgb(${colors.greyishBlue10})`
        : variant === "link"
        ? `rgb(${colors.greyishBlue30})`
        : `rgb(${colors.greyishBlue10})`};
  }
`;

interface ITextWrapper {
  icon?: string;
  isText?: boolean;
}

const STextWrapper = styled.div<ITextWrapper>`
  display: flex;
  align-items: center;
  gap: ${({ icon, isText }) => (icon && isText ? "8px" : "0px")};
`;

export const btnWidths = {
  xs: "140px",
  sm: "160px",
  md: "256px",
  full: "100%"
};

const Button = (props: IButtonProps) => {
  const { theme } = useContext(themeContext);
  const { iconRotate } = props;

  const Icon = props.icon;
  const renderIcon = () => (
    <Icon
      width={props.size === "large" ? 24 : props.size === "medium" ? 20 : 16}
      height={props.size === "large" ? 24 : props.size === "medium" ? 20 : 16}
      style={{ transform: iconRotate ? `rotate(${iconRotate}deg)` : "" }}
    />
  );

  return (
    <SButton
      type={props.type}
      disabled={props.isDisabled}
      minWidth={props.minWidth}
      themeIs={theme}
      variant={props.variant || "contrast"}
      size={props.size || "large"}
      isText={!!props.children}
      {...props}
    >
      {props.isFetching ? (
        <Loader
          size="sm"
          loaderColor={props.variant === "contrast" ? "white" : "orange"}
        />
      ) : (
        <STextWrapper icon={props.icon || ""} isText={!!props.children}>
          {props.iconPosition === "left" && props.icon && renderIcon()}
          <div>{props?.children}</div>
          {props.iconPosition === "right" && props.icon && renderIcon()}
        </STextWrapper>
      )}
    </SButton>
  );
};

Button.defaultProps = {
  isFetching: false,
  type: "button",
  isDisabled: false,
  icon: null,
  iconPosition: "left",
  minWidth: "",
  variant: "contrast",
  size: "large"
};

export default Button;
