import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loader from "../loader/Loader";
import { adminStudentDataQuery } from "query";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { IStudent } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./Breadcrumbs.module.scss";

// Dynamic breadcrumbs component displaying entity name by param id.
// Specific `Student` breadcrumb component to prevent issue with more than 1 params in url.
//
// Currently used only on admin route. Please have in mind to update query in any other case.
const StudentBreadcrumb = () => {
  let { id, studentId } = useParams();

  const fetchedStudentData = useGetFetchQuery(
    adminStudentDataQuery(studentId)
  ) as IStudent;

  const { isLoading: studentIsLoading, error: studentError } = useQuery<
    boolean,
    Error
  >({
    ...adminStudentDataQuery(studentId),
    enabled: !!studentId && !fetchedStudentData
  });

  if (studentId && studentIsLoading) {
    return (
      <span>
        <Loader size="sm" />
      </span>
    );
  }

  if (studentId && studentError) {
    return <div>{studentError.message}</div>;
  }

  if (studentId && fetchedStudentData) {
    if (fetchedStudentData.first_name && fetchedStudentData.last_name)
      return (
        <div className={classes["last"]}>
          {fetchedStudentData.first_name + " " + fetchedStudentData.last_name}
        </div>
      );
    return <div className={classes["last"]}>{fetchedStudentData.email}</div>;
  }

  return <div className={classes["last"]}>{id}</div>;
};

export default StudentBreadcrumb;
