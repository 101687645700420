import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { windowContext } from "context/windowsContext";
import { InputFieldDebounced } from "components/input/DebouncedSearchInput";
import ReactSelectWrapper from "../react-select/ReactSelectWrapper";
import Divider from "../divider/Divider";
import { ReactComponent as SortArrow } from "assets/icons/arrow-sort.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ReactComponent as SortMenu } from "assets/icons/sort.svg";
import Button from "components/buttons/Button";
import TooltipComp from "components/tooltip/TooltipComp";
import useComponentVisible from "hooks/useComponentVisible";
import { navigateWithParams } from "utils/format";
import classes from "./PageHeader.module.scss";
import Filter, { IFilterOption } from "components/filter/Filter";
import { InputField } from "../input/Input";
import { dateFormatField } from "utils/staticOptions";

interface IPageHeader {
  hasData: boolean;
  title: string;
  titleDetails?: string;
  children?: React.ReactNode;
  className?: string;

  // Search props
  hasSearch: boolean;
  searchValue?: string;
  passSearchValueUp?: any;
  searchPlaceholder?: string;
  withChildrenDivider: boolean;

  // Sort props
  hasSort: boolean;
  sortValue?: ISortOption;
  sortOptions?: ISortOption[];
  setSortValue?: (obj: ISortOption) => void;
  passSortValueUp?: any;

  // Page props
  setPage?: (i: number) => void;
  itemsAmountMsg?: React.ReactNode;

  // Date filter props
  hasDateFilter?: boolean;
  filterStartDateRange?: Date;
  filterEndDateRange?: Date;
  setDateRange?: any;

  // Filter props
  hasFilter?: boolean;
  filterOptions?: IFilterOption[];
  filterValue?: IFilterOption;
  passFilterValueUp?: (value: IFilterOption) => void;
  filterLabel?: string;

  // Hide header controls and display only title and count
  hideHeaderControls?: boolean;
}

export interface ISortOption {
  id: number;
  label: string;
  value: string;
  dir: "asc" | "desc" | "";
}

const PageHeader = (props: IPageHeader) => {
  const {
    hasData,
    title,
    hasSearch,
    searchValue,
    passSearchValueUp,
    searchPlaceholder,
    hasSort,
    children,
    withChildrenDivider,
    passSortValueUp,
    sortValue,
    sortOptions,
    itemsAmountMsg,
    className,
    setSortValue,
    setPage,
    hasFilter,
    passFilterValueUp,
    filterOptions,
    filterValue,
    hasDateFilter,
    filterStartDateRange,
    filterEndDateRange,
    filterLabel,
    setDateRange,
    hideHeaderControls
  } = props;
  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const [inputIsOpen, setInputIsOpen] = useState(Boolean(searchValue));
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const sortDirectionHandler = (dir?: "asc" | "desc") => {
    setPage(1);
    if (sortValue) {
      if (dir && dir === "asc") {
        navigateWithParams(
          urlParams,
          { sortDir: "asc", page: 1 },
          navigate,
          pathname
        );
        return setSortValue({ ...sortValue, dir: "asc" });
      }
      if (dir && dir === "desc") {
        navigateWithParams(
          urlParams,
          { sortDir: "desc", page: 1 },
          navigate,
          pathname
        );
        return setSortValue({ ...sortValue, dir: "desc" });
      }
      if (sortValue.dir === "desc") {
        navigateWithParams(
          urlParams,
          { sortDir: "asc", page: 1 },
          navigate,
          pathname
        );
        return setSortValue({ ...sortValue, dir: "asc" });
      }
      navigateWithParams(
        urlParams,
        { sortDir: "desc", page: 1 },
        navigate,
        pathname
      );
      return setSortValue({ ...sortValue, dir: "desc" });
    }
  };

  useEffect(() => {
    if (passSearchValueUp) {
      passSearchValueUp(searchValue);
    }
  }, [searchValue]);

  const isSortArrowDisabled = !sortValue || sortValue.id === null;

  return (
    <div className={`${classes["page-header-wrapper"]} ${className}`}>
      <div className={classes["page-title"]}>
        <h4
          className={`${classes["u-title"]} ${classes["u-bold"]} ${classes["m-0"]}`}
        >
          {title}
        </h4>
        {itemsAmountMsg && hasData && (
          <div className={`${classes["u-title-details"]}`}>
            {itemsAmountMsg}
          </div>
        )}
      </div>
      {hasData && !hideHeaderControls && (
        <div className={classes["page-actions"]}>
          {hasSearch && (
            <>
              <InputFieldDebounced
                value={searchValue}
                onChange={(e) => {
                  passSearchValueUp(e.target.value);
                  setPage(1);
                  navigateWithParams(
                    urlParams,
                    { search: e.target.value, page: 1 },
                    navigate,
                    pathname
                  );
                }}
                debounceTimeout={300}
                placeholder={searchPlaceholder}
                passIsOpenUp={setInputIsOpen}
                isMobile={isMobile}
              />
            </>
          )}
          <div
            className={`${classes["sort-and-children"]} ${
              inputIsOpen ? classes["hide-sort-and-children"] : ""
            }`}
          >
            {hasSort && !isMobile && (
              <>
                <ReactSelectWrapper
                  name="sort"
                  isSort
                  placeholder="Sort by"
                  options={sortOptions}
                  value={sortValue}
                  onChange={(option: ISortOption) => {
                    passSortValueUp(option);
                    setPage(1);
                    navigateWithParams(
                      urlParams,
                      { sort: option.value, sortDir: option.dir, page: 1 },
                      navigate,
                      pathname
                    );
                  }}
                  isWidthFixed
                />
                <TooltipComp
                  id="page-header-sort-dir"
                  text={
                    isSortArrowDisabled
                      ? "None"
                      : sortValue?.dir === "asc"
                      ? "Ascending"
                      : sortValue?.dir === "desc"
                      ? "Descending"
                      : ""
                  }
                  position="bottom"
                >
                  <Button
                    variant="neutral"
                    size="medium"
                    isIconBtn
                    icon={SortArrow}
                    iconRotate={sortValue?.dir === "desc" ? 0 : 180}
                    onClick={sortDirectionHandler}
                    isDisabled={isSortArrowDisabled}
                  />
                </TooltipComp>
              </>
            )}
            {hasFilter && (
              <Filter
                options={filterOptions}
                value={filterValue}
                setValue={(option: IFilterOption) => {
                  passFilterValueUp(option);
                  setPage(1);
                  navigateWithParams(
                    urlParams,
                    { filter: option.value, page: 1 },
                    navigate,
                    pathname
                  );
                }}
                filterLabel={filterLabel}
              />
            )}
            {hasDateFilter && !inputIsOpen && (
              <InputField
                type="datepicker-range"
                name="date_filter"
                onChange={setDateRange}
                startDateRange={filterStartDateRange}
                endDateRange={filterEndDateRange}
                className={"date-filter"}
                isFilter
                filterTooltip={
                  filterStartDateRange || filterEndDateRange
                    ? `Filtered by: ${
                        filterStartDateRange
                          ? moment(filterStartDateRange).format(dateFormatField)
                          : ""
                      } ${filterStartDateRange ? "-" : ""} ${
                        filterEndDateRange
                          ? moment(filterEndDateRange).format(dateFormatField)
                          : filterStartDateRange
                          ? "--/--/----"
                          : ""
                      }`
                    : "Filter by date range"
                }
                clearFilter={() => setDateRange(["", ""])}
              />
            )}
            {/* MOBILE */}
            {hasSort && isMobile && (
              <>
                {!inputIsOpen && (
                  <Button
                    variant="neutral"
                    size="medium"
                    isIconBtn
                    icon={SortMenu}
                    iconRotate={90}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                  />
                )}
                <div
                  ref={ref}
                  className={`${classes["mobile-sort-menu-wrapper"]} ${
                    isComponentVisible ? classes["open"] : ""
                  }`}
                >
                  <div className={classes["mobile-sort-menu-container"]}>
                    <div className={classes["heading"]}>
                      <div
                        className={`${classes["u-text--medium"]} ${classes["u-text--primary"]} ${classes["u-bold"]}`}
                      >
                        Sort By
                      </div>
                      <div className={classes["buttons-wrapper"]}>
                        <Button
                          className={
                            classes[sortValue?.dir === "asc" ? "active" : ""]
                          }
                          variant="outline"
                          isIconBtn
                          icon={SortArrow}
                          iconRotate={180}
                          size="medium"
                          onClick={() => sortDirectionHandler("asc")}
                          isDisabled={isSortArrowDisabled}
                        />
                        <Button
                          className={
                            classes[sortValue?.dir === "desc" ? "active" : ""]
                          }
                          variant="outline"
                          isIconBtn
                          icon={SortArrow}
                          size="medium"
                          onClick={() => sortDirectionHandler("desc")}
                          isDisabled={isSortArrowDisabled}
                        />
                      </div>
                      <Button
                        className={classes["close-btn"]}
                        isIconBtn
                        icon={CrossIcon}
                        onClick={() => setIsComponentVisible(false)}
                        variant="link"
                        size="medium"
                      />
                    </div>
                    <Divider />
                    <div className={classes["options-container"]}>
                      {sortOptions.map((option) => {
                        return (
                          <Button
                            key={option.id}
                            className={
                              classes[
                                sortValue?.id === option.id ? "selected" : ""
                              ]
                            }
                            minWidth="full"
                            size="large"
                            onClick={() =>
                              setSortValue({
                                ...option,
                                dir: option.id === null ? sortValue?.dir : "asc"
                              })
                            }
                          >
                            {option.label}
                          </Button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
            {(hasSearch || hasSort) && children && withChildrenDivider && (
              <Divider orientation="vertical" />
            )}
            {((!inputIsOpen && isMobile) || !isMobile) && children}
          </div>
        </div>
      )}
    </div>
  );
};

PageHeader.defaultProps = {
  hasData: false,
  withChildrenDivider: true
};

export default PageHeader;
