import { themeContext } from "context/themeContext";
import React, { useContext, useEffect, useState } from "react";
import classes from "./CreateDiploma.module.scss";
import { colors, fonts } from "styles";
import CreateDiplomaForm, {
  ICreateDiplomaFormData,
  ITemplateFieldOption,
  defaultCertDate,
  defaultCustomData,
  defaultStartEndDate,
  fieldLabels,
  fieldPreviewPair,
  fontSizes
} from "./form/CreateDiplomaForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "utils/requests";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import DragContainer from "./dnd/DragContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFormData } from "hooks/useFormData";
import { ISelectOption } from "utils/apiDataTypes/AccountManagementDataTypes";
import {
  ICourse,
  ICourseData,
  IField,
  IStudent,
  IStudentsData
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { ReactComponent as AlignLeft } from "assets/icons/align-left.svg";
import { ReactComponent as AlignCenter } from "assets/icons/align-center.svg";
import { ReactComponent as AlignRight } from "assets/icons/align-right.svg";
import Button from "components/buttons/Button";
import Label from "components/input/Label";
import {
  singleCourseStudentsDataQuery,
  singleDiplomaTemplateQuery
} from "query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ITemplate } from "utils/apiDataTypes/CourseModuleDataTypes";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { ISchoolInfo } from "utils/apiDataTypes/AccountManagementDataTypes";
import { schoolDataQuery } from "query";
import { rgbToHex } from "utils/format";
import { ReactComponent as AlignTextLeft } from "assets/icons/text-align-left.svg";
import { ReactComponent as AlignTextCenter } from "assets/icons/text-align-center.svg";
import { ReactComponent as AlignTextRight } from "assets/icons/text-align-right.svg";
import Divider from "components/divider/Divider";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { coursesDataQuery } from "query";
import * as Yup from "yup";
import { yupSchemas } from "utils/yupSchemas";
import EmptyBackground from "./EmptyBackground";
import moment from "moment";
import {
  dateFormatDisplay,
  dateFormatDisplayDiploma,
  dateFormatDisplayDiplomaNoYear,
  dateFormatDisplayNoYear
} from "utils/staticOptions";
import { windowContext } from "context/windowsContext";

export const diplomaTemplateWidth = 640;
export const dndAreaPadding = 20;
export const fullWidthFieldWidth = diplomaTemplateWidth - 2 * dndAreaPadding; // 20px left-right padding
export const defaultFieldHeight = 32;

interface ISelectOptionExtended extends ISelectOption {
  sig?: string;
  courseData?: ICourse;
  studentData?: IStudent;
}

export interface ICreateTemplateQueryData {
  name: string;
  background: string;
  fields: IField[];
}

const defaultFormData: ICreateDiplomaFormData = {
  name: "",
  template_background: {
    name: "",
    value: "",
    originalDimensions: { height: 0, width: 0 },
    bgDimensions: { height: 0, width: 0 }
  },
  template_fields: [
    {
      preview: null,
      label: fieldLabels["student-name"],
      value: fieldLabels["student-name"],
      name: "student-name",
      fontSize: fontSizes.large,
      fontWeight: 600,
      fontColor: rgbToHex(colors.black),
      fontStyle: fonts.family.diplomasDefault,
      textAlign: "center",
      whiteSpace: "nowrap",
      top: 288,
      left: dndAreaPadding,
      width: fullWidthFieldWidth,
      height: defaultFieldHeight,
      visible: true
    }
  ],
  preview_school: null,
  preview_logo: null,
  preview_course: null,
  preview_student: null,
  preview_teacher_1: null,
  preview_teacher_1_sig: null,
  preview_teacher_2: null,
  preview_teacher_2_sig: null,
  preview_start_end_date: null,
  preview_custom_data: null,
  preview_cert_date: null
};

const validationSchema: Yup.SchemaOf<
  Pick<
    ICreateDiplomaFormData,
    "name" | "template_background" | "template_fields"
  >
> = Yup.object().shape({
  name: yupSchemas.requiredStr,
  // This does not work...
  template_background: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required("Required"),
  template_fields: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        name: Yup.string(),
        fontSize: Yup.string(),
        fontWeight: Yup.string(),
        fontColor: Yup.string(),
        fontStyle: Yup.string(),
        textAlign: Yup.string().oneOf(["left", "right", "center"]),
        width: Yup.string(),
        height: Yup.string(),
        left: Yup.string(),
        top: Yup.string(),
        visible: Yup.boolean(),
        isLogo: Yup.boolean(),
        isSignature: Yup.boolean(),
        whiteSpace: Yup.string(),
        preview: Yup.object()
          .shape({
            label: Yup.string(),
            value: Yup.string()
          })
          .nullable()
      })
    )
    .test(
      "student-name-required",
      "Student name is a mandatory field.",
      function (fields) {
        return fields.some((field) => field.name === "student-name");
      }
    )
    .required("Required") as Yup.SchemaOf<ITemplateFieldOption[]>
});

interface ICreateDiploma {
  isEdit: boolean;
}

const CreateDiploma = ({ isEdit }: ICreateDiploma) => {
  const { theme } = useContext(themeContext);
  const { setPopup, clearPopup } = useContext(popupContext);
  const navigate = useNavigate();
  const {
    formData,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    formErrors,
    setFormErrors
  } = useFormData<ICreateDiplomaFormData>(defaultFormData, validationSchema);
  const [selectedField, setSelectedField] =
    useState<ITemplateFieldOption>(null);
  const { templateId } = useParams();
  const { state } = useLocation();
  const queryClient = useQueryClient();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const [isDraggingFile, setIsDraggingFile] = useState(false);

  const {
    isLoading: templateIsLoading,
    error: templateError,
    data: templateData
  } = useQuery<boolean, Error, ITemplate>({
    ...singleDiplomaTemplateQuery(templateId),
    enabled: isEdit
  });

  const fetchedSchoolData = useGetFetchQuery(schoolDataQuery()) as ISchoolInfo;
  const { isLoading: schoolIsLoading, error: schoolError } = useQuery<
    boolean,
    Error
  >({
    ...schoolDataQuery(),
    enabled: !fetchedSchoolData
  });

  const {
    isLoading: coursesLoading,
    error: coursesError,
    data: coursesData
  } = useQuery<boolean, Error, ICourseData>(coursesDataQuery());

  const { result: allCourses } = coursesData || {};

  /* GET: Students data */
  const {
    isLoading: studentsLoading,
    error: studentsError,
    data: studentsData
  } = useQuery<boolean, Error, IStudentsData>({
    ...singleCourseStudentsDataQuery(formData.preview_course?.value),
    enabled: !!formData.preview_course?.value
  });

  const { result: allStudents } = studentsData || {};

  /* MUTATION: Create template */
  const { isLoading, mutate: createTemplateHandler } = useMutation({
    mutationKey: ["create-template"],
    mutationFn: async (data: ICreateTemplateQueryData) => {
      await fetchApi("courses", "/template", {
        method: "POST",
        auth: true,
        data: data
      });
    },
    onSuccess: () => {
      if (state.schoolHasTemplates) {
        queryClient.invalidateQueries({
          queryKey: ["nft-templates-data"]
        });
      }
      // Handles case where the invalidation where a brand new school without nft templates
      // creates an nft template, naviates to course creation form and gets the message, that
      // the school still has no forms. An invalidation does not work, so we use resetQueries().
      if (!state.schoolHasTemplates) {
        queryClient.resetQueries({
          queryKey: ["nft-templates-data"]
        });
      }
      setPopup(
        <GenericPopup
          title="Success!"
          msg="A certificate template was successfully created!"
          buttonAction={() => navigate("/diplomas")}
          buttonName="Go to certificates"
        />
      );
      setFormData(defaultFormData);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });
  /* MUTATION: Edit template */
  const { isLoading: editIsLoading, mutate: editTemplateHandler } = useMutation(
    {
      mutationKey: ["edit-template", { id: templateId }],
      mutationFn: async (data: ICreateTemplateQueryData) => {
        const checkedData = { ...data };
        if (checkedData.background.includes("https://")) {
          delete checkedData.background;
        }
        setPopup(
          <GenericPopup
            type="loading"
            msg={"Updating template..."}
            hideActionBtn
          />
        );
        await fetchApi("courses", `/template/${templateId}`, {
          method: "PUT",
          auth: true,
          data: checkedData
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["diploma-template", { id: templateId }]
        });
        queryClient.invalidateQueries({ queryKey: ["nft-templates-data"] });
        setPopup(
          <GenericPopup
            title="Success!"
            msg="A certificate template was successfully edited!"
            buttonAction={() => navigate("/diplomas")}
            buttonName="Go to certificates"
          />
        );
        setFormData(defaultFormData);
      },
      onError: (err: Error) => {
        setPopup(<GenericPopup type="error" msg={err.message} />);
      }
    }
  );
  // MUTATION: DELETE TEMPLATE
  const {
    isLoading: deleteIsLoading,
    error: deleteError,
    mutate: deleteMutation
  } = useMutation({
    mutationKey: ["delete-template"],
    mutationFn: async (id: string) => {
      setPopup(<GenericPopup type="loading" msg="Please wait..." />);
      await fetchApi("courses", `/template/${id}`, {
        method: "DELETE",
        auth: true
      });
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          title="Success"
          msg="The certificate template was successfully deleted."
          buttonAction={() => navigate("/diplomas")}
          isClosable={false}
        />
      );
    },
    onError: (e: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          title="Something went wrong!"
          msg={e.message}
        />
      );
    }
  });

  const showConfirmDeletePopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Delete template?"
        msg="Are you sure that you want to delete this certificate template?"
        buttonName="Confirm"
        buttonMinWidth="md"
        buttonAction={() => deleteMutation(templateData._id)}
        bellowBtnComp={
          <Button variant="link" onClick={() => clearPopup()}>
            Cancel
          </Button>
        }
      />
    );
  };

  const showEditTemplatePopup = (data: ICreateTemplateQueryData) => {
    setPopup(
      <GenericPopup
        type="info"
        title="Update NFT Certificate?"
        msg="Do you wish to update the NFT Certificate Template. NFTs that are already minted won’t be affected."
        buttonName="Update NFT Certificate Template"
        buttonVariant="contrast"
        buttonAction={() => editTemplateHandler(data)}
        clearPopupOnBtnAction={false}
        bellowBtnComp={
          <Button
            variant="link"
            size="medium"
            minWidth="md"
            onClick={() => clearPopup()}
          >
            Close
          </Button>
        }
      />
    );
  };

  const alignHandler = (
    align: "field" | "text",
    pos: "left" | "center" | "right"
  ) => {
    const fields = [...formData.template_fields];
    const [targetFieldIndex] = fields
      .map((field, i) => {
        if (field.name === selectedField.name) {
          return i;
        }
      })
      .filter((field) => field !== undefined);
    const fieldWidth = fields[targetFieldIndex].width;

    if (align === "field") {
      switch (pos) {
        case "left":
          fields[targetFieldIndex].left = 40;
          break;
        case "center":
          fields[targetFieldIndex].left =
            diplomaTemplateWidth / 2 - +fieldWidth / 2;
          break;
        case "right":
          fields[targetFieldIndex].left =
            diplomaTemplateWidth - +fieldWidth - 40;
          break;
      }
    }
    if (align === "text") {
      fields[targetFieldIndex].textAlign = pos;
    }
    setFormData((prevState) => ({ ...prevState, template_fields: fields }));
  };

  const previewLabelHandler = (
    fieldIs: "course-name" | "student-name" | "teacher-1" | "teacher-2",
    obj: ISelectOptionExtended
  ) => {
    const fields = [...formData.template_fields];

    // Find the index of the field with name === fieldIs
    const [targetFieldIndex] = fields
      .map((field, i) => {
        if (field.name === fieldIs) {
          return i;
        }
      })
      .filter((field) => field !== undefined);

    // If the index is found, set the preview of the field
    if (targetFieldIndex !== undefined) {
      fields[targetFieldIndex].preview = obj;
    }

    // If !obj, then the select input was cleared
    if (!obj) {
      // This check is required when trying to clear a the instructor preview input,
      // while only the signature field is present in the formData.templatefields
      if (targetFieldIndex) {
        fields[targetFieldIndex].preview = null;
      }
      // If a preview of teacher is cleared, also clear their signature
      if (fieldIs === "teacher-1" || fieldIs === "teacher-2") {
        return setFormData((prevState) => ({
          ...prevState,
          [fieldPreviewPair[fieldIs]]: null,
          [fieldPreviewPair[`${fieldIs}-sig`]]: null
        }));
      }
      // If a preview of student is cleared, also clear their custom data
      if (fieldIs === "student-name") {
        return setFormData((prevState) => {
          const fields = [...prevState.template_fields];
          if (fields.some((field) => field.name === "custom-data")) {
            fields.filter((field) => field.name === "custom-data")[0].preview =
              defaultCustomData;
          }
          return {
            ...prevState,
            [fieldPreviewPair[fieldIs]]: null,
            [fieldPreviewPair["custom-data"]]: null,
            template_fields: fields
          };
        });
      }
      return setFormData((prevState) => ({
        ...prevState,
        [fieldPreviewPair[fieldIs]]: null
      }));
    }

    // If there is an obj, then a value was selected in the select input
    // CASE #1: is a teacher field, set preview of both name AND signature
    if (fieldIs === "teacher-1" || fieldIs === "teacher-2") {
      return setFormData((prevState) => ({
        ...prevState,
        [fieldPreviewPair[fieldIs]]: {
          label: obj.label,
          value: fieldLabels[fieldIs]
        },
        [fieldPreviewPair[`${fieldIs}-sig`]]: {
          label: obj.label,
          value: obj.sig
        },
        template_fields: fields
      }));
    }
    if (fieldIs === "course-name") {
      const startDate = {
        label: obj.courseData.start_date
          ? `${moment(obj.courseData.start_date)
              .format(dateFormatDisplayDiplomaNoYear)
              .toString()}`
          : "No Start",
        value: obj.courseData.start_date
          ? `${moment(obj.courseData.start_date)
              .format(dateFormatDisplayDiplomaNoYear)
              .toString()}`
          : "No Start"
      };
      const endDate = {
        label: obj.courseData.end_date
          ? `${moment(obj.courseData.end_date)
              .format(dateFormatDisplayDiploma)
              .toString()}`
          : "End Date",
        value: obj.courseData.end_date
          ? `${moment(obj.courseData.end_date)
              .format(dateFormatDisplayDiploma)
              .toString()}`
          : "End Date"
      };

      const startEndDate = {
        label: `${startDate.label} - ${endDate.label}`,
        value: `${startDate.value} - ${endDate.value}`
      };

      if (fields.some((field) => field.name === "start-end-date")) {
        fields.filter((field) => field.name === "start-end-date")[0].preview =
          startEndDate;
      }
      if (fields.some((field) => field.name === "cert-date")) {
        fields.filter((field) => field.name === "cert-date")[0].preview =
          endDate;
      }

      return setFormData((prevState) => ({
        ...prevState,
        [fieldPreviewPair[fieldIs]]: obj,
        [fieldPreviewPair["start-end-date"]]: startEndDate,
        [fieldPreviewPair["cert-date"]]: endDate,
        template_fields: fields
      }));
    }

    if (fieldIs === "student-name") {
      if (fields.some((field) => field.name === "custom-data")) {
        fields.filter((field) => field.name === "custom-data")[0].preview = {
          label: obj.studentData.custom_diploma_date,
          value: obj.studentData.custom_diploma_date
        };
      }
      setFormData((prevState) => ({
        ...prevState,
        [fieldPreviewPair["custom-data"]]: {
          label: obj.studentData.custom_diploma_date,
          value: obj.studentData.custom_diploma_date
        },
        [fieldPreviewPair[fieldIs]]: obj,
        template_fields: fields
      }));
    }
  };

  /* Func: return all primary/secondary instructors for preview course */
  const previewInstructors = (isPrimary: boolean) => {
    const instructors = allCourses
      ?.filter((course) => course._id === formData.preview_course?.value)[0]
      ?.instructors.map((instructor) =>
        instructor.primary === isPrimary
          ? {
              label: `${instructor.title} ${instructor.first_name} ${instructor.last_name}`,
              value: `${instructor.title} ${instructor.first_name} ${instructor.last_name}`,
              sig: instructor.signature
            }
          : null
      )
      ?.filter((item) => item !== null);
    return instructors;
  };

  const handleImageLoad = (event) => {
    const img = event.target;
    setFormData((prevState) => ({
      ...prevState,
      template_background: {
        ...prevState.template_background,
        bgDimensions: {
          width: img.offsetWidth,
          height: img.offsetHeight
        }
      }
    }));
  };

  /* Populate formData on edit template view */
  useEffect(() => {
    if (templateData && fetchedSchoolData) {
      const previewFieldValue = {
        "school-name": {
          label: fetchedSchoolData.name,
          value: fetchedSchoolData._id
        },
        logo: { label: "Logo", value: fetchedSchoolData.pic },
        "cert-date": defaultCertDate,
        "start-end-date": defaultStartEndDate,
        "custom-data": defaultCustomData
      };
      const img = new Image();
      img.src = FILES_PUBLIC_DOMAIN + templateData.background;
      const loadImage = async () => {
        img.onload = () => {
          setFormData((prevState) => {
            const multiplier = img.width / diplomaTemplateWidth;
            return {
              ...prevState,
              template_background: {
                name: templateData.background_name,
                value: FILES_PUBLIC_DOMAIN + templateData.background,
                originalDimensions: {
                  height: img.height,
                  width: img.width
                },
                bgDimensions: {
                  height: img.height / (img.width / diplomaTemplateWidth),
                  width: diplomaTemplateWidth
                }
              },
              name: templateData.name,
              template_fields: templateData.fields.map((field) => {
                return {
                  ...field,
                  label: field.label,
                  value: field.label,
                  top: +field.top / multiplier,
                  left: +field.left / multiplier,
                  height: +field.height / multiplier,
                  width: +field.width / multiplier,
                  textAlign: field.textAlign,
                  fontSize: +field.fontSize,
                  fontStyle: field.fontStyle + " Semibold",
                  isLogo: field.name === "logo",
                  isSignature:
                    field.name === "teacher-1-sig" ||
                    field.name === "teacher-2-sig",
                  whiteSpace: field.whiteSpace || "nowrap",
                  preview: previewFieldValue[field.name] || null
                };
              }),
              preview_school: {
                label: fetchedSchoolData.name,
                value: fetchedSchoolData.name
              },
              preview_logo: { label: "Logo", value: fetchedSchoolData.pic }
            };
          });
        };
      };
      loadImage();
    }
  }, [templateData, fetchedSchoolData]);

  let isLogoField = formData.template_fields.some(
    (field) => field.name === "logo"
  );
  let isSchoolNameField = formData.template_fields.some(
    (field) => field.name === "school-name"
  );

  /* Populate formData preview of with the school's name and logo */
  useEffect(() => {
    if (isLogoField) {
      setFormData((prevState) => ({
        ...prevState,
        preview_logo: { label: "Logo", value: fetchedSchoolData.pic }
      }));
    }
    if (isSchoolNameField) {
      setFormData((prevState) => ({
        ...prevState,
        preview_school: {
          label: fetchedSchoolData.name,
          value: fetchedSchoolData._id
        }
      }));
    }
  }, [isLogoField, isSchoolNameField]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingFile(true);
  };

  if (isEdit && templateIsLoading)
    return <Loader size="lg" hasText withPadding />;

  if (isEdit && templateError) {
    return <ErrorComponent error={templateError} />;
  }

  if (isMobile) {
    return <Navigate to="/diplomas" />;
  }

  return (
    <div
      className={classes["content-wrapper"]}
      data-theme={theme}
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDraggingFile(false)}
      onDrop={() => setIsDraggingFile(false)}
    >
      <div className={classes["dnd-container"]}>
        {formData?.template_background?.value ? (
          <div className={classes["dnd-active"]}>
            <DndProvider backend={HTML5Backend}>
              <div
                className={classes["dnd"]}
                style={{
                  height: formData.template_background.bgDimensions.height
                }}
              >
                <img
                  className={classes["bg-img"]}
                  src={formData.template_background.value}
                  alt="Certificate background"
                  onLoad={handleImageLoad}
                />
                <DragContainer
                  formData={formData}
                  setFormData={(formData) => setFormData(formData)}
                  selectedField={selectedField}
                  setSelectedField={(field) => setSelectedField(field)}
                />
              </div>
            </DndProvider>
            <div className={classes["button-groups"]}>
              <div className={classes["align-buttons"]}>
                <Label
                  name="obj_align"
                  title="Align Object"
                  showTooltipIcon="Double-click on the text box in order to activate the alignment options."
                  hasNoMargin
                />
                <Button
                  variant="link"
                  isIconBtn
                  icon={AlignLeft}
                  onClick={() => alignHandler("field", "left")}
                  isDisabled={!selectedField}
                />
                <Button
                  variant="link"
                  isIconBtn
                  icon={AlignCenter}
                  onClick={() => alignHandler("field", "center")}
                  isDisabled={!selectedField}
                />
                <Button
                  variant="link"
                  isIconBtn
                  icon={AlignRight}
                  onClick={() => alignHandler("field", "right")}
                  isDisabled={!selectedField}
                />
              </div>
              <div className={classes["align-buttons"]}>
                <Label
                  name="text_align"
                  title="Text Alignment"
                  showTooltipIcon="Double-click on the text box in order to activate the text alignment options."
                  hasNoMargin
                />
                <Button
                  variant="link"
                  isIconBtn
                  icon={AlignTextLeft}
                  onClick={() => alignHandler("text", "left")}
                  isDisabled={!selectedField}
                />
                <Button
                  variant="link"
                  isIconBtn
                  icon={AlignTextCenter}
                  onClick={() => alignHandler("text", "center")}
                  isDisabled={!selectedField}
                />
                <Button
                  variant="link"
                  isIconBtn
                  icon={AlignTextRight}
                  onClick={() => alignHandler("text", "right")}
                  isDisabled={!selectedField}
                />
              </div>
            </div>
            <Divider />
            <div className={classes["preview-container"]}>
              <div
                className={`${classes["u-text--large"]} ${classes["u-bold"]} ${classes["u-text--primary"]}`}
              >
                Preview Options
              </div>
              <div className={classes["inputs-container"]}>
                <ReactSelectWrapper
                  title="Choose Course"
                  name="choose-course"
                  placeholder="Choose Course"
                  value={formData.preview_course}
                  options={allCourses?.map((course) => ({
                    label: course.start_date
                      ? course.name +
                        " - " +
                        moment(course.start_date).format("MMM DD, YYYY")
                      : course.name,
                    value: course._id,
                    courseData: course
                  }))}
                  onChange={(obj: ISelectOption) =>
                    previewLabelHandler("course-name", obj)
                  }
                  isLoading={coursesLoading}
                  error={coursesError?.message}
                  isClearable
                  isSearchable
                />
                <ReactSelectWrapper
                  title="Choose Student"
                  name="choose-student"
                  placeholder="Choose Student"
                  value={formData?.preview_student}
                  options={allStudents?.map((student) => ({
                    label: `${student.first_name} ${student.last_name}`,
                    value: student._id,
                    studentData: student
                  }))}
                  onChange={(obj: ISelectOption) =>
                    previewLabelHandler("student-name", obj)
                  }
                  isLoading={
                    !!formData.preview_course?.value && studentsLoading
                  }
                  error={studentsError?.message}
                  isClearable
                  isSearchable
                />
              </div>
              <div className={classes["inputs-container"]}>
                <ReactSelectWrapper
                  title="Choose Primary Instructor"
                  name="choose-teacher-1"
                  placeholder="Choose Primary Instructor"
                  value={formData.preview_teacher_1}
                  options={previewInstructors(true)}
                  onChange={(obj: ISelectOption) =>
                    previewLabelHandler("teacher-1", obj)
                  }
                  isLoading={coursesLoading}
                  isClearable
                  isSearchable
                />
                <ReactSelectWrapper
                  title="Choose Secondary Instructor"
                  name="choose-teacher-2"
                  placeholder="Choose Secondary Instructor"
                  value={formData.preview_teacher_2}
                  options={previewInstructors(false)}
                  onChange={(obj: ISelectOption) =>
                    previewLabelHandler("teacher-2", obj)
                  }
                  isLoading={coursesLoading}
                  isClearable
                  isSearchable
                />
              </div>
            </div>
          </div>
        ) : (
          <EmptyBackground
            handleUpload={handleUploadedFileBase64}
            isDraggingFile={isDraggingFile}
          />
        )}
      </div>
      <CreateDiplomaForm
        onSubmit={createTemplateHandler}
        isLoading={isLoading}
        formData={formData}
        formErrors={formErrors}
        setFormData={setFormData}
        setFormErrors={setFormErrors}
        handleChange={handleChange}
        handleUploadedFileBase64={handleUploadedFileBase64}
        selectedField={selectedField}
        setSelectedField={setSelectedField}
        isEdit={isEdit}
        onEdit={showEditTemplatePopup}
        onDelete={showConfirmDeletePopup}
      />
    </div>
  );
};

CreateDiploma.defaultProps = {
  isEdit: false
};
export default CreateDiploma;
