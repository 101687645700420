import {
  IMemberData,
  ISchoolInfo,
  ISelectOption,
  IExtendedStateSelectOption
} from "./AccountManagementDataTypes";

export interface IData {
  total_pages: number;
  total_results: number;
  all_records: number;
}

interface ILectureDate {
  start_date: string;
  end_date: string;
  title: string;
}

export interface IInstructor {
  title: string;
  first_name: string;
  last_name: string;
  degree: string[];
  signature: string;
  primary: boolean;
}

export interface IAGD {
  code: string;
  hours: number;
  description: string;
  type: string;
}

export interface ICourseData extends IData {
  result: ICourse[];
}

export interface ICourseLocation {
  state: {
    name: string;
    code: string;
  };
  city: string;
}

export interface ICourse {
  _id: string;
  name: string;
  start_date: string;
  end_date: string;
  lecture_dates: ILectureDate[];
  location: ICourseLocation;
  credit_hours: number;
  AGD: IAGD[];
  template_id: string;
  template_name?: string;
  is_template: boolean;
  instructors: IInstructor[];
  date_created: number;
  school: ISchoolInfo;
  school_tenant: string;
  image: string;
  date_updated: number;
  updated_by: string;
  managers: IMemberData[];
  attendance_hash: string;
  has_mints: boolean;
  is_open: boolean;
}

export interface IStudentCourse {
  user_id: string;
  school_id: string;
  course_id: string;
  attendance_status: ATTENDANCE_STATUS;
  diploma_status: DIPLOMA_STATUS;
  course: ICourse;
  has_pending_request: boolean;
  student_wallet: string;
  is_blacklisted: boolean;
}

export interface ICourseFormData {
  name: string;
  start_date: string;
  end_date: string;
  // Hide all `Lecture Dates` related logic
  // lecture_date_dates_1?: string;
  // lecture_date_startHour_1?: string;
  // lecture_date_endHour_1?: string;
  // lecture_date_lecture_1?: string;
  image: string;
  course_managers: ISelectOption[];
  template_id: ISelectOption;
  attendees: string | { name?: string; value?: string };
  primary_instructor_title_1?: ISelectOption;
  // Hide all `Instructor Degree` related logic
  // primary_instructor_degree_1?: ISelectOption[];
  primary_instructor_firstName_1?: string;
  primary_instructor_lastName_1?: string;
  primary_instructor_signature_1?: string;
  city: ISelectOption;
  state: IExtendedStateSelectOption;
  credit_hours: number;
  AGD_code_1?: string;
  AGD_hours_1?: number;
  AGD_description_1?: string;
  is_open: boolean;
}

export interface IField {
  label: string;
  name: string;
  fontSize: string | number;
  fontWeight: string | number;
  fontColor: string;
  fontStyle: string;
  textAlign: "left" | "center" | "right";
  width: string | number;
  height: string | number;
  left: string | number;
  top: string | number;
  visible: string | boolean;
  isLogo?: boolean;
  isSignature?: boolean;
  whiteSpace?: string;
  preview?: ISelectOption;
}

export interface ITemplatesData extends IData {
  result: ITemplate[];
}

export interface ITemplate {
  name: string;
  background: string;
  background_name: string;
  fields: IField[];
  date_created: Date;
  created_by: string;
  school: string;
  thumbnail: string;
  pic: string;
  _id: string;
}

export interface IStudentCourse {
  id: string;
  name: string;
}

export interface IStudent {
  _id: string;
  email: string;
  active: boolean;
  registered: boolean;
  first_name: string;
  last_name: string;
  pic: string;
  wallet: string;
  courses: IStudentCourse[];
  user_id?: string;
  title?: string;
  custom_diploma_date?: string;
  is_blacklisted?: boolean;
  role_flag?: number;
}

export interface IStudentFormData
  extends Pick<IStudent, "email" | "first_name" | "last_name"> {
  title: ISelectOption;
}

export interface IStudentsData extends IData {
  result: IStudent[];
}

export enum ATTENDANCE_STATUS {
  NOT_VERIFIED = 0,
  SCHOOL_VERIFIED = 1,
  STUDENT_VERIFIED = 2
}

export enum DIPLOMA_STATUS {
  NOT_MINTED = 0,
  MINTED = 1,
  VERIFIED = 2,
  RETURNED = 3
}

export interface IStudentDiplomasData extends IData {
  result: IStudentDiploma[];
}

export interface IStudentDiploma {
  _id: string;
  user_id: string;
  school_id: string;
  course_id: string;
  attendance_status: ATTENDANCE_STATUS;
  diploma_status: DIPLOMA_STATUS;
  minter_address: string;
  student_address: string;
  block_no: number;
  date_received: number;
  diploma_name: string;
  ipfs_diploma_id: string;
  token_id: number;
  is_public: boolean;
  course?: ICourse;
}

export interface IReadyToMint extends IData {
  course: Pick<ICourse, "_id" | "name">;
  user: Pick<IStudent, "email" | "first_name" | "last_name" | "_id">;
  _id: string;
}

export interface IReadyToMintData extends IData {
  result: IReadyToMint[];
}

export interface IMintRequestsData extends IData {
  result: IMintRequest[];
}

export interface IMintRequest {
  request_id?: string;
  course: ICourse;
  date_requested: number;
  date_resolved: number;
  requested_by: IStudent;
  requested_for: string[];
  resolved_by: string;
  school_id: string;
  status: RequestStatus;
  type: RequestType;
  _id: string;
}

export interface IPreviewLink {
  preview_link: string;
}

export enum RequestType {
  Student = 1,
  Manager = 2,
  Returned = 3
}

export enum RequestStatus {
  Initial = 0,
  Approved = 1,
  Rejected = 2,
  Minted = 3
}
