import classes from "./TableCols.module.scss";
import { Link } from "react-router-dom";
import Button from "components/buttons/Button";

import { IMintRequest } from "utils/apiDataTypes/CourseModuleDataTypes";
import { tableColumnsWidths } from "./utils/table-utils";
import { useContext } from "react";
import { Web3Context } from "context/web3Context";
import Tooltip from "components/tooltip/TooltipComp";
import RenderCell from "./RenderCell";
import PermissionsGate from "roles/PermissionsGate";
import { IndeterminateCheckbox } from "./InterdeterminateCheckbox";

interface IRequestCols {
  showMintRequestPopup: (request: IMintRequest[]) => void;
  isMobile: boolean;
}

export const ReadyToMintCols = (
  showMintRequestPopup: IRequestCols["showMintRequestPopup"],
  isMobile: IRequestCols["isMobile"]
) => {
  return [
    {
      id: "selection",
      Header: ({ page, toggleRowSelected, isAllPageRowsSelected }) => {
        const modifiedOnChange = (event) => {
          page.forEach((row) => {
            toggleRowSelected(row.id, event.currentTarget.checked);
          });
        };
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox onChange={modifiedOnChange} />
          </PermissionsGate>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.row.getToggleRowSelectedProps()}
              isDisabled={!!tableProps.row.original.status}
              status={tableProps.row.original.status}
            />
          </PermissionsGate>
        );
      }
    },
    {
      Header: "Ready To Mint",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="ready_to_mint_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "Student Name",
      accessor: "name",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => {
        const { title, first_name, last_name, _id } = row.original.user;
        return (
          <div className={classes["names-container"]}>
            <Link
              style={{ textDecoration: "underline" }}
              to={`/students/${_id}`}
            >
              {`${title || ""} ${first_name} ${last_name}`}
            </Link>
          </div>
        );
      }
    },
    {
      Header: "Email",
      accessor: "email",
      minWidth: tableColumnsWidths.emailMin,
      Cell: ({ row }) => (
        <RenderCell type="email" rowProps={row.original.user} />
      )
    },
    {
      Header: "Course Name",
      accessor: "course",
      Cell: ({ row }) => (
        <Link
          to={`/courses/${row.original.course._id}`}
          style={{ textDecoration: "underline" }}
        >
          {row.original?.course?.name}
        </Link>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: !isMobile && tableColumnsWidths.actionsXlMax,
      minWidth: !isMobile && tableColumnsWidths.actionsXlMin,
      width: isMobile ? "unset" : tableColumnsWidths.actionsXl,
      Cell: ({ row }) => {
        const { web3UserData } = useContext(Web3Context);
        return (
          <div className={classes["status-container"]}>
            <Tooltip
              id={`ready-to-mint-id-${row.original.request_id}`}
              text={
                !web3UserData?.wallet_address
                  ? "Please connect your wallet."
                  : ""
              }
            >
              <Button
                variant="neutral"
                size="medium"
                onClick={() => {
                  showMintRequestPopup(row.original);
                }}
                isDisabled={!web3UserData?.wallet_address}
              >
                {isMobile ? "Mint" : "Mint Certificate"}
              </Button>
            </Tooltip>
          </div>
        );
      }
    }
  ];
};
