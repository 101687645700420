import Button from "components/buttons/Button";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import classes from "./EmptyPage.module.scss";

interface IEmptyTab {
  icon: string | React.ReactNode;
  title: string;
  message?: string | React.ReactNode;
  buttonName?: string;
  onClick?: () => void;
  className?: string;
}

const EmptyPage = (props: IEmptyTab) => {
  const { icon, title, message, buttonName, onClick, className } = props;

  return (
    <div className={`${classes["empty-wrapper"]} ${className}`}>
      {typeof icon === "string" ? <img src={icon} alt="No data" /> : icon}
      <div className={`${classes["u-text--center"]} ${classes["container"]}`}>
        <h4
          className={`${classes["u-title"]} ${classes["u-bold"]} ${classes["u-text--primary"]} ${classes["m-0"]}`}
        >
          {title}
        </h4>
        {message && (
          <div
            className={`${classes["empty-desc"]} ${classes["u-text--content"]}`}
          >
            {message}
          </div>
        )}
      </div>
      {buttonName && (
        <Button onClick={onClick} icon={PlusIcon}>
          {buttonName}
        </Button>
      )}
    </div>
  );
};

export default EmptyPage;
