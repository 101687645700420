import React, { useContext, useState } from "react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import classes from "./ManagersPopup.module.scss";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";
import ManagersTable from "./ManagersTable";
import { IFetchedMeData } from "utils/apiDataTypes/AccountManagementDataTypes";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "utils/requests";
import GenericPopup from "components/popups/GenericPopup";
import PermissionsGate from "roles/PermissionsGate";
import { windowContext } from "context/windowsContext";

interface IManagersPopup extends Pick<ICourse, "managers" | "_id"> {}

const ManagersPopup = ({ managers, _id: id }: IManagersPopup) => {
  const { theme } = useContext(themeContext);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<
    { index: number; id: string; userId: string }[]
  >([]);
  const [data, setData] = useState<IFetchedMeData[]>(null);

  const showConfirmPopup = (
    obj: { index: number; id: string; userId: string }[],
    assign: boolean
  ) => {
    const objIs = Object.create(obj);

    setPopup(
      <GenericPopup
        type="info"
        title={`${!assign ? "Remove" : "Assign"} member${
          obj.length > 1 ? "s" : ""
        }?`}
        msg={`Are you sure you  want to ${!assign ? "remove" : "assign"} ${
          obj.length > 1 ? "these members" : "this member"
        }?`}
        buttonName={`${!assign ? "Remove" : "Assign"} member${
          obj.length > 1 ? "s" : ""
        }`}
        buttonVariant="outline"
        buttonAction={() =>
          updateCourseManagersHandler({ managers: objIs, assign })
        }
        buttonMinWidth="md"
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            onClick={() => clearPopup()}
            minWidth="md"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const { isLoading: mutateIsLoading, mutate: updateCourseManagersHandler } =
    useMutation({
      mutationKey: ["update-course-managers", { id }],
      mutationFn: async (obj: { managers: any[]; assign: boolean }) => {
        setPopupLoading(true);
        const idsToAssign = obj.managers.map((item) => item.id);
        const idsToRemove = obj.managers
          .filter((item) => item.userId !== "")
          .map((item) => item.userId);

        const response = await fetchApi("courses", `/course/${id}/managers`, {
          method: obj.assign ? "POST" : "DELETE",
          auth: true,
          data: {
            course_managers: obj.assign ? idsToAssign : idsToRemove
          }
        });
        return response;
      },
      // TODO - use response when BE done
      onSuccess: (response: { msg: string; active: boolean }) => {
        setPopupLoading(false);
        queryClient.invalidateQueries({ queryKey: ["courses-data", { id }] });
        setPopup(
          <GenericPopup
            type="success"
            title="Managers changed!"
            msg="The course manager(s) were successfully updated!"
          />
        );
        setSelected([]);
      },
      onError: (err: Error) => {
        setPopupLoading(false);
        setPopup(<GenericPopup type="error" msg={err.message} />);
      }
    });

  const hasData = data && data.length > 0;

  const hasAssigned = selected.some(({ userId }) => userId);
  const hasToAssign = selected.some(({ userId }) => !userId);

  return (
    <ModalWrapper size={hasData ? "lg" : "sm"}>
      <div data-theme={theme} className={classes["managers-popup"]}>
        {hasData && (
          <>
            <h4
              className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
            >
              Change Course Managers
            </h4>
            <div
              className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
            >
              If a Course Manager is not specified the Admin and the Registrar
              will be able to administer the course
            </div>
          </>
        )}
        <div className={classes["content"]}>
          {hasData && (
            <div className={classes["actions-container"]}>
              <PermissionsGate permissions={["COURSE.UPDATE"]}>
                <Button
                  size="medium"
                  icon={PlusIcon}
                  variant="link"
                  isFetching={mutateIsLoading}
                  onClick={() => showConfirmPopup(selected, true)}
                  isDisabled={selected.length === 0 || !hasToAssign}
                >
                  {isMobile ? "Assign" : "Assign Selected"}
                </Button>
              </PermissionsGate>
              <PermissionsGate permissions={["COURSE.UPDATE"]}>
                <Button
                  size="medium"
                  icon={TrashIcon}
                  variant="link"
                  isFetching={mutateIsLoading}
                  onClick={() => showConfirmPopup(selected, false)}
                  isDisabled={selected.length === 0 || !hasAssigned}
                >
                  {isMobile ? "Remove" : "Remove Selected"}
                </Button>
              </PermissionsGate>
            </div>
          )}
          <ManagersTable
            showConfirmPopup={showConfirmPopup}
            selected={selected}
            setSelected={setSelected}
            passDataUp={setData}
            managers={managers}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ManagersPopup;
