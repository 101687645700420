import React from "react";
import classes from "../Landing.module.scss";

const Feature = ({
  icon,
  title,
  desc
}: {
  icon: string;
  title: string;
  desc: string;
}) => {
  return (
    <div className={classes["feature"]}>
      <img src={icon} height={72} alt="Feature" />
      <div className={classes["feature__text"]}>
        <h2 className={classes["feature__title"]}>{title}</h2>
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default Feature;
