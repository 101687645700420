import { statusIconMapping } from "components/icons/IconMappings";
import classes from "./Status.module.scss";

interface IStudentSchoolStatus {
  is_blacklisted: boolean;
  isIconOnly?: boolean;
}

const StudentSchoolStatus = ({ is_blacklisted }: IStudentSchoolStatus) => {
  return (
    <div className={classes["status-container"]}>
      <div className={classes["icon-and-text"]}>
        {is_blacklisted
          ? statusIconMapping["Disabled"]
          : statusIconMapping["Active"]}
        {is_blacklisted ? "Disabled" : "Active"}
      </div>
    </div>
  );
};

StudentSchoolStatus.defaultProps = {
  isAttendees: false
};

export default StudentSchoolStatus;
