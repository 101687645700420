import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import moment from "moment";
import * as Yup from "yup";
import { themeContext } from "context/themeContext";
import { popupContext } from "context/popupContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import Button from "components/buttons/Button";
import { InputField } from "components/input/Input";
import GenericPopup from "components/popups/GenericPopup";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import classes from "./AddAttendeesPopup.module.scss";
import AddAttendeesPopupStaticContent from "./AddAttendeesPopupStaticContent";
import { useFormData } from "hooks/useFormData";
import { ICourseFormData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { fetchApi } from "utils/requests";

interface IAddMoreAttendeesPopup {
  courseId: string;
  isOpen: boolean;
}

const validationSchema: Yup.SchemaOf<IAttendeesFormData> = Yup.object().shape({
  attendees: Yup.string()
});

interface IAttendeesFormData extends Pick<ICourseFormData, "attendees"> {}

const defaultFormData: IAttendeesFormData = {
  attendees: ""
};

const AddMoreAttendeesPopup = ({
  courseId,
  isOpen
}: IAddMoreAttendeesPopup) => {
  const { theme } = useContext(themeContext);
  const { setPopup } = useContext(popupContext);
  const queryClient = useQueryClient();

  const [uploadedFile, setUploadedFile] = useState<Blob>();
  const [attendeesData, setAttendeesData] = useState<string[][]>();

  const { formData, handleUploadedFileBase64, setFormData, formErrors } =
    useFormData(defaultFormData, validationSchema);

  useEffect(() => {
    if (uploadedFile) {
      processFile();
    }
  }, [uploadedFile]);

  const setFile = async (e: React.FormEvent<HTMLFormElement>) => {
    const file = e.currentTarget.files[0];
    if (file) setUploadedFile(file);
  };

  // Process `.csv/.xlsx` upload
  // Ref. https://stackblitz.com/edit/react-excel-to-json-parser?file=ExcelReader.js
  const processFile = async () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;

      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        raw: true,
        cellDates: true
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      if (data.length > 0) {
        /* Update state */
        setAttendeesData(
          data
            .filter((d: any) => d.email)
            .map((d: any) => [
              d.email,
              d.first_name || "",
              d.last_name || "",
              d.wallet || "",
              d.custom_diploma_date
                ? typeof d.custom_diploma_date === "string"
                  ? d.custom_diploma_date
                  : d.custom_diploma_date.toString()
                : ""
            ])
        );
      } else {
        /* Show error */
        setPopup(
          <GenericPopup
            type="error"
            msg={"Failed processing. Add at least 1 Row."}
            buttonName="Close"
            buttonVariant="neutral"
          />
        );
      }
    };

    if (rABS) {
      reader.readAsBinaryString(uploadedFile);
    } else {
      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  const addAttendees = async () =>
    await fetchApi("courses", `/course/${courseId}/students`, {
      method: "POST",
      auth: true,
      data: {
        students: attendeesData
      }
    });

  const { isLoading, mutate: handleAddAttendees } = useMutation({
    mutationKey: ["add-attendees-course"],
    mutationFn: async () => await addAttendees(),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["course-students-data", { id: courseId }]
      });
      setPopup(
        <GenericPopup
          type="success"
          title={"Successfully added!"}
          msg={`Attendant(s) were successfully added!`}
        />
      );
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <ModalWrapper size="lg">
      <div data-theme={theme}>
        <AddAttendeesPopupStaticContent isOpen={isOpen} />
        <div className={classes["form-container"]}>
          <InputField
            type="file"
            fileType=".csv, .xlsx"
            fileName={
              typeof formData.attendees !== "string" && formData.attendees.name
                ? formData.attendees.name
                : ""
            }
            onClear={() => {
              setFormData({
                ...formData,
                attendees: ""
              });
              setAttendeesData(undefined);
              setUploadedFile(undefined);
            }}
            error={formErrors["attendees"] as string}
            name="attendees"
            onChange={(e: React.FormEvent<HTMLFormElement>) => {
              handleUploadedFileBase64("attendees", e);
              setFile(e);
            }}
            maxWidth={160}
            className={
              attendeesData && attendeesData.length > 0 ? "centered" : ""
            }
          />
          {attendeesData && attendeesData.length > 0 && (
            <Button
              onClick={handleAddAttendees}
              variant={"contrast"}
              minWidth="md"
              isFetching={isLoading}
              icon={PlusIcon}
              iconPosition="right"
            >
              {!!attendeesData
                ? `Add ${attendeesData.length} Attendee${
                    attendeesData.length > 1 ? "s" : ""
                  }`
                : "Add"}
            </Button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddMoreAttendeesPopup;
