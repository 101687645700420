import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "@tanstack/react-query";
import { popupContext } from "context/popupContext";
import Button from "components/buttons/Button";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import NoCourses from "assets/images/dashboard/no-courses.png";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import PermissionsGate from "roles/PermissionsGate";
import classes from "../Courses.module.scss";
import CreateCoursePopup from "./CreateCoursePopup";
import CoursesList from "./CoursesList";
import EmptyPage from "components/empty-page/EmptyPage";
import NoResults from "components/no-results/NoResults";
import Pagination from "components/pagination/Pagination";
import { coursesDraftsDataQuery } from "query";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { ICourseData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { sortDirEnum } from "utils/staticOptions";

export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "Name",
    value: "name",
    dir: "asc"
  },
  {
    id: 2,
    label: "Start Date",
    value: "start_date",
    dir: "asc"
  }
];

const DraftCourses = () => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir,
    startDate: startDateParam,
    endDate: endDateParam
  } = queryString.parse(urlParams);

  const { setPopup } = useContext(popupContext);

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;
  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState((search as string) || "");

  const [dateRange, setDateRange] = useState<any[]>([
    startDateParam ? new Date(startDateParam as string) : "",
    endDateParam ? new Date(endDateParam as string) : ""
  ]);
  const [startDateRange, endDateRange] = dateRange;

  const pageSize = 12;

  const searchParams = `?&limit=${pageSize}&page=${page}${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }${searchValue.length > 1 ? `&search[name]=${searchValue}` : ""}${
    startDateRange && endDateRange
      ? `&start_date=${new Date(startDateRange).getTime()}&end_date=${new Date(
          endDateRange
        ).getTime()}`
      : ""
  }`;

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    coursesDraftsDataQuery(searchParams)
  );

  const { result: courses, total_results, all_records } = data || {};
  const hasData = courses && courses.length > 0;

  return (
    <>
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortFilter) ||
        (!hasData && dateRange[0]) ||
        page > 1) && (
        <PageHeader
          hasData={true}
          title="Drafts"
          itemsAmountMsg={calcItemsAmount({
            page: page,
            perPage: pageSize,
            result: courses,
            total_results,
            all_records: all_records
          })}
          hasSearch
          searchPlaceholder="Search by Course Name"
          searchValue={searchValue}
          passSearchValueUp={(value: string) => {
            setSearchValue(value);
          }}
          setPage={setPage}
          hasSort
          passSortValueUp={(value: ISortOption) => {
            setSortFilter(value);
          }}
          sortValue={sortFilter}
          setSortValue={setSortFilter}
          sortOptions={sortOptions}
          hasDateFilter
          filterStartDateRange={startDateRange}
          filterEndDateRange={endDateRange}
          setDateRange={setDateRange}
        />
      )}
      {isLoading && <Loader size="lg" hasText withPadding />}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          <CoursesList courses={courses} isDraft />
          <Pagination
            currentPage={page}
            totalCount={total_results}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            itemsAmountMsg={calcItemsAmount({
              type: "Draft Courses ",
              page: page,
              perPage: pageSize,
              result: courses,
              total_results,
              all_records: all_records
            })}
          />
        </>
      )}
      {((!hasData && searchValue && !isLoading) ||
        (!hasData && dateRange[0] && dateRange[1] && !isLoading)) && (
        <NoResults />
      )}
      {!hasData &&
        !searchValue &&
        !isLoading &&
        !dateRange[0] &&
        !dateRange[1] &&
        !error && (
          <EmptyPage
            title="There are no draft courses."
            icon={NoCourses}
            message={
              <div className={classes["msg-wrapper"]}>
                <PermissionsGate permissions={["COURSE.CREATE"]}>
                  <div className={classes["msg"]}>
                    Create your first draft course! Saved draft courses will be
                    visualised here.
                  </div>
                </PermissionsGate>
                <PermissionsGate permissions={["COURSE.CREATE"]}>
                  <Button
                    onClick={() => setPopup(<CreateCoursePopup />)}
                    icon={PlusIcon}
                  >
                    Create Draft Course
                  </Button>
                </PermissionsGate>
              </div>
            }
          />
        )}
    </>
  );
};

export default DraftCourses;
