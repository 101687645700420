import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import BlueCheckmark from "assets/images/dashboard/blue-checkmark.png";
import Button from "components/buttons/Button";
import TitleAndMsg from "components/forms/form-title-and-msg/TitleAndMsg";
import { InputField } from "components/input/Input";
import GenericPopup from "components/popups/GenericPopup";
import { popupContext } from "context/popupContext";
import { useFormData } from "hooks/useFormData";
import FormPage from "layouts/form-page";
import { hasErrors } from "utils/form";
import { fetchApi } from "utils/requests";
import { yupSchemas } from "utils/yupSchemas";
import classes from "./VerifyDiploma.module.scss";

interface IDefaultFormData {
  token_id: string;
  email: string;
}

const defaultFormData = {
  token_id: "",
  email: ""
};

const validationSchema: Yup.SchemaOf<IDefaultFormData> = Yup.object().shape({
  token_id: yupSchemas.requiredStr,
  email: yupSchemas.email
});

const VerifyDiploma = () => {
  const navigate = useNavigate();
  const { setPopup, clearPopup } = useContext(popupContext);
  const { formData, handleChange, formErrors, setFormData } = useFormData(
    defaultFormData,
    validationSchema
  );

  const {
    isLoading,
    mutate: submitHandler,
    error
  } = useMutation({
    mutationKey: ["verify-diploma"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      setPopup(
        <GenericPopup
          type="loading"
          title="Verifying NFT Certificate"
          msg="Please wait, we are verifying the NFT Certificate."
          isClosable={false}
        />
      );

      const response = await fetchApi(
        "courses",
        "/course-student/public/verify-diploma",
        {
          method: "POST",
          data: {
            token_id: formData.token_id,
            email: formData.email
          }
        }
      );
      return response;
    },
    onSuccess: (data) => {
      setPopup(
        <GenericPopup
          type="success"
          msg="NFT Certificate successfully verified!"
          buttonName={data.is_public ? "Go to certificate" : "Go to homepage"}
          buttonVariant={"contrast"}
          buttonAction={() =>
            data.is_public
              ? navigate(
                  `/students/${data.user_id}/diplomas/${data.token_id}/preview`
                )
              : navigate("/")
          }
          bellowBtnComp={
            data.is_public ? (
              <Button
                variant="neutral"
                onClick={() => clearPopup()}
                minWidth="md"
                size="medium"
              >
                Close
              </Button>
            ) : null
          }
        />
      );
      setFormData(defaultFormData);
    },
    onError: (e: Error) => {
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  return (
    <FormPage headerVariant="only-logo">
      <form>
        <div className={`${classes["container"]} ${classes["form-lg"]}`}>
          <div className={classes["flex-subcontainer"]}>
            <img
              src={BlueCheckmark}
              width={256}
              height={256}
              alt="Blue checkmark"
            />
            <div>
              <TitleAndMsg
                title="Verify NFT Certificate"
                msg="Enter the Token ID to check if the NFT Certificate is minted by Opus"
              />
              <InputField
                title="Token ID"
                isRequired
                name="token_id"
                placeholder="Enter Token ID"
                onChange={handleChange}
                value={formData.token_id}
                error={formErrors.token_id}
              />
              <InputField
                title="Email"
                isRequired
                name="email"
                placeholder="Enter Email Address"
                onChange={handleChange}
                value={formData.email}
                error={formErrors.email}
              />
              <Button
                type="submit"
                minWidth="full"
                isDisabled={
                  hasErrors(formErrors) ||
                  !formData.token_id.length ||
                  !formData.email.length
                }
                onClick={submitHandler}
                isFetching={isLoading}
              >
                Check NFT Certificate
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormPage>
  );
};
export default VerifyDiploma;
