import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DiplomaCard from "./DiplomaCard";
import { IStudentDiploma } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./DiplomasList.module.scss";

interface IDiplomasList {
  diplomas: IStudentDiploma[];
  isPublicPage: boolean;
}

const DiplomasList = ({ diplomas, isPublicPage }: IDiplomasList) => {
  const navigate = useNavigate();

  const location = useLocation();
  const splitLocation = location.pathname.split("preview");

  return (
    <>
      <div className={classes["cards-wrapper"]}>
        {diplomas.map((diploma) => (
          <DiplomaCard
            key={diploma._id}
            name={diploma.diploma_name}
            _id={diploma._id}
            token_id={diploma.token_id}
            image={diploma.ipfs_diploma_id}
            is_public={diploma.is_public}
            isPublicPage={isPublicPage}
            onClick={() =>
              navigate(
                isPublicPage
                  ? `${splitLocation[0]}${diploma.token_id.toString()}/preview`
                  : diploma.token_id.toString()
              )
            }
          />
        ))}
      </div>
    </>
  );
};

export default DiplomasList;
