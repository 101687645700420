import React, { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Button from "components/buttons/Button";
import PermissionsGate from "roles/PermissionsGate";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/templates.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import TooltipComp from "components/tooltip/TooltipComp";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "utils/requests";
import GenericPopup from "components/popups/GenericPopup";
import { popupContext } from "context/popupContext";
import classes from "../../components/Single.module.scss";

interface ICourseActions {
  courseId: string;
  isTemplate: boolean;
  hasMints: boolean;
}

const CourseActions = ({ courseId, isTemplate, hasMints }: ICourseActions) => {
  const navigate = useNavigate();
  const { setPopup, clearPopup, popupIsLoading, setPopupLoading } =
    useContext(popupContext);
  const queryClient = useQueryClient();

  const saveAsTemplate = async (id) =>
    await fetchApi("courses", `/course/${id}`, {
      method: "PUT",
      auth: true,
      data: {
        is_template: true
      }
    });

  const deleteCourse = async (id) =>
    await fetchApi("courses", `/course/${id}`, {
      method: "DELETE",
      auth: true
    });

  // Update course mutation
  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["update-course", { id: courseId }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await saveAsTemplate(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["courses-data", { id: courseId }]
      });
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully saved this course as a template!"
          buttonName={"Go to templates"}
          buttonVariant={"contrast"}
          buttonAction={() => navigate("/courses/templates")}
        />
      );
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  // Delete course mutation
  const { mutate: handleDelete } = useMutation({
    mutationKey: ["delete-course", { id: courseId }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await deleteCourse(id);
    },
    onSuccess: async () => {
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully deleted this course!"
        />
      );
      setPopupLoading(false);
      navigate("/courses");
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
      setPopupLoading(false);
    }
  });

  const showConfirmDeletePopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Delete Course?"
        msg="Are you sure that you want to delete this course?"
        buttonAction={() => handleDelete(courseId)}
        buttonName="Delete"
        buttonVariant="contrast"
        bellowBtnComp={
          <Button
            variant="neutral"
            onClick={() => clearPopup()}
            minWidth="md"
            size="medium"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  return (
    <div className={classes["actions-wrapper"]}>
      <div className={classes["btn-group"]}>
        <PermissionsGate permissions={["COURSE.UPDATE"]}>
          <Button
            onClick={() => navigate(`/courses/${courseId}/edit`)}
            icon={EditIcon}
            variant={"link"}
            isDisabled={hasMints}
          >
            <TooltipComp
              text={
                hasMints
                  ? "A course cannot be edited after the first mint has occurred."
                  : ""
              }
              id="edit-course"
            >
              Edit Course Info
            </TooltipComp>
          </Button>
        </PermissionsGate>
        <PermissionsGate permissions={["COURSE.UPDATE"]}>
          <Button
            variant="link"
            minWidth="sm"
            isDisabled={hasMints || isTemplate}
            onClick={() => {
              handleUpdate(courseId);
            }}
            icon={TemplateIcon}
            isFetching={popupIsLoading}
          >
            <TooltipComp
              text={
                hasMints
                  ? "A course cannot be saved as template after the first mint has occurred."
                  : isTemplate
                  ? "Already saved as template"
                  : ""
              }
              id="save-as-template"
            >
              {isTemplate ? "Saved as Template" : "Save as Template"}
            </TooltipComp>
          </Button>
        </PermissionsGate>
      </div>
      <div className={classes["btn-group"]}>
        <PermissionsGate permissions={["COURSE.UPDATE"]}>
          <Button
            onClick={() => showConfirmDeletePopup()}
            icon={TrashIcon}
            variant={"link"}
            isDisabled={hasMints}
            isDanger
          >
            <TooltipComp
              text={
                hasMints
                  ? "A course cannot be deleted after the first mint has occurred."
                  : ""
              }
              id="delete-course"
            >
              Delete Course
            </TooltipComp>
          </Button>
        </PermissionsGate>
      </div>
    </div>
  );
};

export default CourseActions;
