import RegisterStudentForm from "./components/RegisterStudentForm";
import FormPage from "layouts/form-page";

const RegisterStudent = () => {
  return (
    <FormPage headerVariant="only-logo">
      <RegisterStudentForm />
    </FormPage>
  );
};

export default RegisterStudent;
