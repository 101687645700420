import React, { useContext } from "react";
import { QRCodeSVG } from "qrcode.react";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import classes from "./QrPopup.module.scss";

interface IQrPopup {
  link: string;
}

const QrPopup = ({ link }: IQrPopup) => {
  const { theme } = useContext(themeContext);
  const { clearPopup } = useContext(popupContext);

  return (
    <ModalWrapper size="md">
      <div data-theme={theme} className={classes["qr-popup"]}>
        <h4
          className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
        >
          Course QR
        </h4>
        <div
          className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
        >
          Scan this code in order to check attendance
        </div>
        <div className={classes["content"]}>
          <QRCodeSVG value={link} size={350} className={classes["qr-svg"]} />
        </div>
        <div className={classes["buttons-container"]}>
          <Button
            onClick={() => clearPopup()}
            variant={"neutral"}
            minWidth="sm"
          >
            Close
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default QrPopup;
