import { useContext, useState } from "react";
import { toastContext } from "context/toastContext";
import { imagesToBase64 } from "utils/processing";
import * as Yup from "yup";
import { trimInput } from "utils/format";
import { diplomaTemplateWidth } from "routes/dashboard/diplomas/admin/CreateDiploma";

interface useFormDataResponse<T> {
  formData: T;
  formErrors: T;
  handleChange: any;
  handleUploadedFileBase64: any;
  setFormData: any;
  setFormErrors: any;
}

export function useFormData<T>(
  defaultState: T,
  validationSchema?: any
): useFormDataResponse<T> {
  const { setToast } = useContext(toastContext);
  const [formData, setFormData] = useState<T>(defaultState);
  const formErrorsDefault = {} as T | (() => T);
  Object.keys(defaultState).forEach((key) => (formErrorsDefault[key] = ""));
  const [formErrors, setFormErrors] = useState<T>(formErrorsDefault);

  // Updates data based on fields name
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value: number | string | object | Date;
    const field = e.target.name;
    // Define value type based input type
    switch (e.target.type) {
      case "number":
        value = e.target.valueAsNumber;
        if (Number.isNaN(value)) {
          value = "";
        }
        break;
      case "date":
        value = e.target.valueAsDate;
        break;
      case "datepicker":
        value = e;
        break;
      case "text":
        value = trimInput(e.target.value);
        break;
      case "textarea":
        value = trimInput(e.target.value);
        break;
      case "password":
        value = trimInput(e.target.value, "no-spaces");
        break;
      default:
        value = e.target.value;
        break;
    }

    if (validationSchema) {
      const fieldSchema = Yup.object().shape({
        [field]: validationSchema.fields[field]
      });

      fieldSchema
        .validate(
          { [field]: value },
          {
            context: formData
          }
        )
        .then(
          () => {
            setFormErrors({ ...formErrors, [field]: "" });
          },
          (err) => {
            setFormErrors({ ...formErrors, [field]: err.message });
          }
        );
    }
    setFormData({ ...formData, [field]: value });
  }

  async function handleUploadedFileBase64(
    name: string,
    e: React.FormEvent<HTMLInputElement>
  ): Promise<any> {
    const files = [];
    for (let image of e.currentTarget.files) {
      files.push(image);
    }

    function onLoadHandler(e: ProgressEvent<FileReader>) {
      const field = name;
      const value = e.target.result as string;
      setFormData({
        ...formData,
        [field]:
          field === "CERP" ||
          field === "PACE" ||
          field === "attendees" ||
          field === "background"
            ? { name: files[0].name, value: value }
            : value
      });

      // Check resolution of the uploaded template background image.
      if (field === "template_background") {
        async function addImageProcess(src) {
          const promiseHeight = new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(img.height);
            img.onerror = reject;
            img.src = src;
          });
          const promiseWidth = new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(img.width);
            img.onerror = reject;
            img.src = src;
          });
          const promises = [promiseHeight, promiseWidth];
          const resolved = Promise.all(promises);
          return resolved;
        }
        addImageProcess(value).then((dimensions: any) => {
          // Allow only landscape images
          if (dimensions[1] < diplomaTemplateWidth) {
            setFormErrors({
              ...formErrors,
              template_background: `Please upload an image width minimum width of ${diplomaTemplateWidth}px.`
            });
          }
          setFormData((prevState) => {
            return {
              ...prevState,
              template_background: {
                name: files[0].name,
                value: value,
                originalDimensions: {
                  height: dimensions[0],
                  width: dimensions[1]
                },
                bgDimensions: {
                  height:
                    dimensions[0] / (dimensions[1] / diplomaTemplateWidth),
                  width: diplomaTemplateWidth
                }
              }
            };
          });
        });
      }
    }

    let sizeInMb: number;
    let sizeError: string = "";
    let isImageTooBig =
      files[0].type.includes("image/") && files[0].size > 10 * Math.pow(10, 6);
    let isPdfTooBig =
      files[0].type.includes("application/pdf") &&
      files[0].size > 4 * Math.pow(10, 6);

    if (isImageTooBig || isPdfTooBig) {
      sizeInMb = +(files[0].size / Math.pow(10, 6)).toFixed(2);
      sizeError = `${
        isImageTooBig ? "Image" : "File"
      } too big (${sizeInMb}MB). Please upload ${
        isImageTooBig ? "an image" : "a file"
      } up to ${isImageTooBig ? "10" : "4"}MB.`;
    }

    try {
      // Handles images in batch - if one fails, all fail
      await imagesToBase64(files, onLoadHandler);
      setFormErrors({ ...formErrors, [name]: sizeError });
    } catch (e) {
      setToast({
        type: "error",
        msg: "File upload failed",
        isSelfClosing: false
      });
    }
  }

  return {
    formData,
    formErrors,
    handleChange,
    handleUploadedFileBase64,
    setFormData,
    setFormErrors
  };
}
