import maticIcon from "assets/icons/matic.svg";
import classes from "./MintFee.module.scss";

interface IMintFee {
  fee: string;
}

const MintFee = ({ fee }: IMintFee) => {
  return (
    <div className={classes["mint-fee-container"]}>
      <div>Minting fee</div>
      <div className={classes["fee"]}>
        <img src={maticIcon} alt="matic-symbol" />
        {fee} MATIC
      </div>
    </div>
  );
};

export default MintFee;
