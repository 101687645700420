import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QRCodeSVG } from "qrcode.react";
import { popupContext } from "context/popupContext";
import { userContext } from "context/userContext";
import PermissionsGate from "roles/PermissionsGate";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as EnlargeIcon } from "assets/icons/enlarge.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import QrPopup from "../components/QrPopup";
import SharePopup from "components/popups/SharePopup";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import Status from "components/status/Status";
import Loader from "components/loader/Loader";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "../components/Single.module.scss";
import CourseActions from "./components/CourseActions";
import VerifyAttendance from "./components/VerifyAttendance";
import RequestNFTDiploma from "./components/RequestNFTDiploma";
import TemplateForm from "./components/TemplateForm";
import {
  adminSingleCourseDataQuery,
  singleCourseDataQuery,
  singleStudentCourseDataQuery
} from "query";
import SinglePrimaryInfo from "../components/SinglePrimaryInfo";
import SingleAttendees from "../components/SingleAttendees";
import { getWithExpiry, setWithExpiry } from "utils/storage";
import moment from "moment";
import GenericPopup from "components/popups/GenericPopup";
import Badge from "components/badge/Badge";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import { dateFormatDisplay } from "utils/staticOptions";
import { fetchApi } from "utils/requests";

const SingleCourse = () => {
  let { courseId: id, attendance_hash: attendance_hash_param } = useParams();

  const navigate = useNavigate();
  const { setPopup, clearPopup } = useContext(popupContext);
  const { userData } = useContext(userContext);
  const { is_student, is_super_admin, role_flag } = userData || {};

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, any>({
    ...(is_student
      ? singleStudentCourseDataQuery(id)
      : is_super_admin
      ? adminSingleCourseDataQuery(id)
      : singleCourseDataQuery(id)),
    enabled: !!userData
  });

  const { mutate: sendEmails } = useMutation({
    mutationKey: ["qr-code-emails"],
    mutationFn: async () => {
      setPopup(<GenericPopup type="loading" msg="Sending out emails..." />);
      await fetchApi("courses", `/course/${id}/students/send-attendance-link`, {
        method: "POST",
        auth: true
      });
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          type="success"
          msg="Emails successfully sent out to all non-verified attendees."
        />
      );
    },
    onError: (e: Error) => {
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  const showSendEmailPopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Send Emails?"
        msg="Are you sure that you want to send out an email with the attendance verification link to all non-verified students?"
        buttonName="Send Emails"
        buttonAction={sendEmails}
        buttonVariant="contrast"
        bellowBtnComp={
          <Button variant="link" onClick={clearPopup}>
            Cancel
          </Button>
        }
      />
    );
  };

  useEffect(() => {
    if (is_student && attendance_hash_param) {
      // Set `attendance_hash` to local storage with expiration time of 8 hours
      setWithExpiry(
        "attendance_hash",
        attendance_hash_param,
        8 * 60 * 60 * 1000,
        id
      );
    }
  }, [data]);

  if (isLoading) return <Loader size="lg" hasText />;

  if (error) {
    return (
      <div className={classes["content-wrapper"]}>
        {/* <ErrorComponent error={error} /> */}
        <GenericPopup
          type="error"
          title="Not Assigned To Course"
          msg="Your are not a part of this course. Please contact the Course Manager"
          buttonName="Go To Courses"
          buttonAction={() => navigate("/courses")}
          isClosable={false}
        />
      </div>
    );
  }

  const course: ICourse = is_student ? data.course : data;
  const {
    attendance_hash: course_attendance_hash,
    image,
    name,
    start_date,
    end_date,
    managers,
    instructors,
    lecture_dates,
    AGD,
    location,
    credit_hours,
    school,
    template_id,
    template_name,
    has_mints,
    is_template,
    is_open
  } = course;

  const qrLink = `${window.location.host}/courses/${id}/attend/${course_attendance_hash}`;

  const attendanceHash = getWithExpiry("attendance_hash");

  const renderQRWrapper = () => (
    <div className={classes["qr-wrapper"]}>
      <QRCodeSVG value={qrLink} size={88} className={classes["qr-svg"]} />
      <div className={classes["qr-details"]}>
        <div>
          <div className={classes["detail-desc"]}>Course QR</div>
          <div className={classes["detail-title"]}>
            Scan this code in order to check attendance
          </div>
        </div>
        <div className={classes["qr-btns-wrapper"]}>
          <Button
            onClick={() => setPopup(<QrPopup link={qrLink} />)}
            icon={EnlargeIcon}
            variant={"link"}
            size={"medium"}
          >
            Enlarge
          </Button>
          <PermissionsGate permissions={["COURSE.UPDATE"]}>
            <Button
              onClick={() =>
                setPopup(
                  <SharePopup
                    title="Share Course Link"
                    desc="Copy course link and send it to all attendees."
                    fieldLabel="Course Link"
                    link={qrLink}
                  />
                )
              }
              icon={LinkIcon}
              variant={"link"}
              size={"medium"}
            >
              Share
            </Button>
          </PermissionsGate>
          <Button
            onClick={() => window.print()}
            icon={PrintIcon}
            variant={"link"}
            size={"medium"}
          >
            Print
          </Button>
          <PermissionsGate permissions={["COURSE.UPDATE"]}>
            <Button
              onClick={showSendEmailPopup}
              icon={EmailIcon}
              variant={"link"}
              size={"medium"}
            >
              Send Email
            </Button>
          </PermissionsGate>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes["content-wrapper"]}>
      <div className={classes["print-wrapper"]}>
        <div>
          <h3
            className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["m-0"]}`}
          >
            {name}
          </h3>
          {start_date && (
            <div>
              Start Date: {moment(start_date).format(dateFormatDisplay)}
            </div>
          )}
        </div>
        <QRCodeSVG value={qrLink} size={500} className={classes["qr-svg"]} />
      </div>
      <div className={classes["course-wrapper"]}>
        {image ? (
          <div className={classes["card-pic"]}>
            <img src={`${FILES_PUBLIC_DOMAIN}${image}`} alt="Course" />
          </div>
        ) : (
          <div
            className={`${classes["card-pic"]} ${classes["card-pic--empty"]}`}
          >
            <BookIcon />
          </div>
        )}
        <div>
          <div>
            {is_open && !is_student && <Badge text="Open Course" isOpen />}
            <h3
              className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["m-0"]}`}
            >
              {name}
            </h3>
            <PermissionsGate permissions={["ROLE.STUDENT"]}>
              <span
                className={`${classes["u-text--small"]} ${classes["flex-container"]}`}
              >
                <span className={classes["u-text--content"]}>Status: </span>
                <Status status={data.attendance_status} isBold />
              </span>
            </PermissionsGate>
            {(role_flag === 3 || role_flag === 4) && (
              <CourseActions
                courseId={id}
                hasMints={has_mints}
                isTemplate={is_template}
              />
            )}
          </div>
          <PermissionsGate permissions={["ROLE.STUDENT"]}>
            {data.attendance_status === 0 &&
              attendanceHash &&
              attendanceHash.value &&
              attendanceHash.courseId === id && (
                <VerifyAttendance
                  courseId={id}
                  attendance_hash={attendanceHash.value}
                  attendance_status={data.attendance_status}
                  is_blacklisted={data.is_blacklisted}
                />
              )}
            <Divider orientation="horizontal" />
          </PermissionsGate>
          <SinglePrimaryInfo
            start_date={start_date}
            end_date={end_date}
            managers={managers}
            instructors={instructors}
            lecture_dates={lecture_dates}
            AGD={AGD}
            location={location}
            credit_hours={credit_hours}
            school={school}
            _id={id}
            has_mints={has_mints}
          />
          <PermissionsGate permissions={["COURSE.UPDATE"]}>
            {renderQRWrapper()}
          </PermissionsGate>
          <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
            {renderQRWrapper()}
          </PermissionsGate>
          <PermissionsGate permissions={["STUDENT.CREATE"]}>
            <>
              <Divider orientation="horizontal" />
              <TemplateForm
                courseId={id}
                templateId={template_id}
                hasMints={has_mints}
              />
            </>
          </PermissionsGate>
          <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
            <>
              <Divider orientation="horizontal" />
              <ReactSelectWrapper
                title={"NFT Certificate Template"}
                name="template_id"
                placeholder="Select NFT Certificate Template"
                options={[
                  {
                    value: template_id,
                    label: template_name
                  }
                ]}
                value={
                  template_id
                    ? {
                        value: template_id,
                        label: template_name
                      }
                    : null
                }
                isDisabled
                onChange={() => {}}
              />
            </>
          </PermissionsGate>
          {/*
            `RequestNFTDiploma` visibility checks:
              - Verified student
              - No certificate
              - Has no pending requests
          */}
          {is_student &&
            data.attendance_status > 0 &&
            (data.diploma_status === 0 || data.diploma_status === 3) && (
              <>
                <Divider orientation="horizontal" />
                <RequestNFTDiploma
                  course_id={id}
                  has_pending_request={data.has_pending_request}
                  student_wallet={data.student_wallet}
                  is_blacklisted={data.is_blacklisted}
                />
              </>
            )}
          {/* Display certificate link if exists */}
          {is_student && data.diploma_status === 2 && (
            <>
              <Divider orientation="horizontal" />
              <div className={classes["detail-block-wrapper"]}>
                <div className={classes["detail-block"]}>
                  <div className={classes["detail-title"]}>Certificate</div>
                  <div
                    className={`${classes["detail-desc"]} ${classes["detail-desc--flex"]}`}
                  >
                    <span>{data.diploma_name}</span>
                    <Button
                      variant={"link"}
                      size="medium"
                      isIconBtn
                      icon={LinkIcon}
                      onClick={() => navigate(`/diplomas/${data.token_id}`)}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <PermissionsGate permissions={["COURSE.GET"]}>
        <SingleAttendees
          courseId={id}
          templateId={data.template_id}
          hasMints={has_mints}
          isOpen={is_open}
        />
      </PermissionsGate>
      <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
        <SingleAttendees
          courseId={id}
          templateId={data.template_id}
          hasMints={has_mints}
          isOpen={is_open}
          hasNoActions
        />
      </PermissionsGate>
    </div>
  );
};

export default SingleCourse;
