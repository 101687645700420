import React, { createContext, useCallback, useEffect, useState } from "react";

interface IPopupCtx {
  popupData?: any;
  popupIsLoading?: boolean;
  setPopupLoading?: (isLoading: boolean) => void;
  setPopup?: any;
  clearPopup?: any;
}

export const popupContext = createContext<IPopupCtx>({});

export function PopupProvider(props: any) {
  const [popupData, setPopupData] = useState<any>();
  const [popupIsLoading, setPopupIsLoading] = useState(false);

  const setPopup = useCallback(
    (popup) => {
      setPopupData(popup);
    },
    [setPopupData]
  );

  const clearPopup = useCallback(() => {
    setPopupData(null);
  }, [setPopupData]);

  const setPopupLoading = useCallback(
    (isLoading: boolean) => {
      setPopupIsLoading(isLoading);
    },
    [setPopupIsLoading]
  );

  // Lock background scroll on popups
  useEffect(() => {
    if (popupData) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "initial";
  }, [popupData]);

  return (
    <popupContext.Provider
      value={{
        popupData,
        popupIsLoading,
        setPopupLoading,
        setPopup,
        clearPopup
      }}
    >
      {props.children}
    </popupContext.Provider>
  );
}
