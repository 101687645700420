import React, { useCallback, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Button from "components/buttons/Button";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import {
  IMintRequest,
  IMintRequestsData
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { calcItemsAmount } from "components/table/utils/table-utils";
import SwitchInput from "components/input/SwitchInput";
import RequestsTable from "../table/RequestsTable";
import { windowContext } from "context/windowsContext";
import { mintRequestsDataQuery } from "query";
import { useQuery } from "@tanstack/react-query";
import { sortDirEnum } from "utils/staticOptions";
import { navigateWithParams } from "utils/format";
import MintRequestsPopup from "../popups/MintRequestsPopup";
import { popupContext } from "context/popupContext";
import { Web3Context } from "context/web3Context";
import TooltipComp from "components/tooltip/TooltipComp";

export const mintRequestsSortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "Status",
    value: "status",
    dir: "asc"
  }
];

const RequestsTab = () => {
  const { pathname, search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir,
    showResolved: showResolvedParam
  } = queryString.parse(urlParams);

  const navigate = useNavigate();

  const { windowSize } = useContext(windowContext);
  const { setPopup } = useContext(popupContext);
  const { web3UserData } = useContext(Web3Context);
  const { isMobile } = windowSize;

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...mintRequestsSortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState((search as string) || "");
  const [perPage, setPerPage] = useState(8);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [showResolved, setShowResolved] = useState(
    showResolvedParam === "true"
  );

  const [selected, setSelected] = useState([]);

  // Mint requests status
  let statusString = "&status=0";
  if (showResolved) {
    statusString = "&status=0&status=1&status=2&status=3";
  }

  const params = `?${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }&limit=${perPage}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ""
  }${statusString}`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    IMintRequestsData
  >({
    ...mintRequestsDataQuery(params)
  });

  const { result, total_results, all_records } = data || {};
  const hasData = data && result?.length > 0;

  const showMintRequestPopup = (singleRequest?: IMintRequest[]) => {
    setPopup(
      <MintRequestsPopup
        invalidateParams={params}
        requests={singleRequest ? [singleRequest] : selected}
      />
    );
  };

  const getDataStatus = useCallback(() => {
    let status: "loading" | "error" | "no-results" | "empty" | null;

    return isLoading
      ? (status = "loading")
      : error
      ? (status = "error")
      : !hasData && searchValue && !isLoading
      ? (status = "no-results")
      : !hasData && !searchValue && !isLoading
      ? (status = "empty")
      : (status = null);
  }, [isLoading, error, hasData, searchValue]);

  return (
    <>
      <PageHeader
        hasData={true}
        title="Mint Requests"
        searchPlaceholder="Search by Email / Name"
        hasSearch={false}
        searchValue={searchValue}
        passSearchValueUp={setSearchValue}
        setPage={setPage}
        hasSort
        sortValue={sortFilter}
        setSortValue={setSortFilter}
        sortOptions={mintRequestsSortOptions}
        passSortValueUp={(value: ISortOption) => {
          setSortFilter(value);
        }}
        itemsAmountMsg={calcItemsAmount({
          page: page,
          perPage: perPage,
          result: result,
          total_results,
          all_records: all_records
        })}
      >
        <SwitchInput
          label="Show Resolved"
          onClick={() => {
            setShowResolved(!showResolved);
            navigateWithParams(
              urlParams,
              { page: 1, showResolved: !showResolved },
              navigate,
              pathname
            );
            setPage(1);
          }}
          value={showResolved}
        />

        {!!selected.length && !isMobile && (
          <TooltipComp
            id="resolve-requests-btn"
            text={
              !web3UserData?.wallet_address
                ? "Connect your wallet first."
                : "You can resolve up to 30 requests at a time. "
            }
          >
            <Button
              size="medium"
              onClick={() => showMintRequestPopup()}
              isDisabled={!web3UserData?.wallet_address || selected.length > 30}
            >
              {`Resolve Selected (${selected.length})`}
            </Button>
          </TooltipComp>
        )}
      </PageHeader>
      {!!selected.length && isMobile && (
        <TooltipComp
          id="resolve-requests-btn"
          text={
            !web3UserData?.wallet_address
              ? "Connect your wallet first."
              : selected.length > 30
              ? "You can resolve up to 30 requests at a time. "
              : ""
          }
        >
          <Button
            size="medium"
            onClick={() => showMintRequestPopup()}
            isDisabled={!web3UserData?.wallet_address || selected.length > 30}
            minWidth="full"
          >
            {`Resolve Selected (${selected.length})`}
          </Button>
        </TooltipComp>
      )}

      <RequestsTable
        page={page}
        setPage={setPage}
        perPage={8}
        setPerPage={setPerPage}
        data={data}
        status={getDataStatus()}
        params={params}
        selected={selected}
        setSelected={setSelected}
        showMintRequestPopup={showMintRequestPopup}
        error={error}
      />
    </>
  );
};

export default RequestsTab;
