import React from "react";
import { useQuery } from "@tanstack/react-query";
import CourseForm from "../components/CourseForm";
import { ICourseFormData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { countriesDataQuery, schoolDataQuery } from "query";
import {
  ICountry,
  ISchoolInfo
} from "utils/apiDataTypes/AccountManagementDataTypes";
import Loader from "components/loader/Loader";
import classes from "../components/Single.module.scss";
import ErrorComponent from "components/error/Error";

const CreateCourse = () => {
  const fetchedSchoolData = useGetFetchQuery(schoolDataQuery()) as ISchoolInfo;

  const { isLoading, error } = useQuery<boolean, Error>({
    ...schoolDataQuery(),
    enabled: !fetchedSchoolData
  });

  const fetchedCountriesData = useGetFetchQuery(
    countriesDataQuery()
  ) as ICountry[];

  const { isLoading: isLoadingCountries, error: errorCountries } = useQuery<
    boolean,
    Error,
    ICountry[]
  >({
    ...countriesDataQuery(),
    enabled: !fetchedCountriesData
  });

  if (isLoading || isLoadingCountries) return <Loader size="lg" hasText />;

  if (error || errorCountries) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error || errorCountries} />
      </div>
    );
  }

  const courseStateLabel = fetchedSchoolData.state
    ? fetchedSchoolData.state.name
    : null;
  const courseStateObj =
    courseStateLabel &&
    fetchedCountriesData &&
    fetchedCountriesData[0].states.find((s) => s.name === courseStateLabel);

  const defaultFormData: ICourseFormData = {
    name: "",
    start_date: "",
    end_date: "",
    // Hide all `Lecture Dates` related logic
    // lecture_date_dates_1: "",
    // lecture_date_startHour_1: "",
    // lecture_date_endHour_1: "",
    // lecture_date_lecture_1: "",
    image: "",
    course_managers: [],
    template_id: null,
    attendees: "",
    primary_instructor_title_1: null,
    primary_instructor_firstName_1: "",
    primary_instructor_lastName_1: "",
    // Hide all `Instructor Degree` related logic
    // primary_instructor_degree_1: [],
    primary_instructor_signature_1: "",
    state: courseStateObj
      ? {
          label: `${courseStateObj.state_code} - ${courseStateObj.name}`,
          value: courseStateObj._id,
          code: courseStateObj.state_code,
          name: courseStateObj.name
        }
      : null,
    city: fetchedSchoolData.city
      ? {
          label: fetchedSchoolData.city,
          value: fetchedSchoolData.city
        }
      : null,
    credit_hours: null,
    AGD_code_1: "",
    AGD_hours_1: null,
    AGD_description_1: "",
    is_open: false
  };

  return (
    <>
      <CourseForm title="Create Course" defaultFormData={defaultFormData} />
    </>
  );
};

export default CreateCourse;
