import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/page-header/PageHeader";
import ActiveCourses from "./components/ActiveCourses";
import DraftCourses from "./components/DraftCourses";
import { userContext } from "context/userContext";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";
import classes from "./Courses.module.scss";

const tabs = [
  { name: "Active", route: "/courses" },
  { name: "Drafts", route: "/courses-drafts" }
];

export interface ICoursesPage {
  tab: "active" | "drafts";
}

const CoursesPage = ({ tab }: ICoursesPage) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tab === "active" ? 0 : 1);

  const { userData } = useContext(userContext);
  const { is_student, role_flag } = userData || {};

  return (
    <div className={classes["content-wrapper"]}>
      {is_student || role_flag === ROLE_FLAG["MANAGER"] ? (
        <ActiveCourses />
      ) : (
        <>
          <PageHeader
            hasData={true}
            title="Courses"
            hasSearch={false}
            hasSort={false}
          />
          <div className={classes["tabs"]}>
            {tabs.map((tab, i) => {
              return (
                <div
                  key={i}
                  className={`${classes["tab"]} ${
                    classes[activeTab === i ? "u-text--primary" : ""]
                  }
              ${classes[activeTab === i ? "tab-active" : ""]}
              `}
                  onClick={() => {
                    setActiveTab(i);
                    navigate(tabs[i].route);
                  }}
                >
                  <span>{tab.name}</span>
                </div>
              );
            })}
          </div>
          {tab === "active" && <ActiveCourses />}
          {tab === "drafts" && <DraftCourses />}
        </>
      )}
    </div>
  );
};

export default CoursesPage;
