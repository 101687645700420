import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import styled from "styled-components";
import { ReactComponent as plusIcon } from "assets/icons/plus.svg";
import { ReactComponent as trashIcon } from "assets/icons/trash.svg";
import clearSearchIcon from "assets/icons/clear.svg";
import "react-datepicker/dist/react-datepicker.css";
import { colors } from "styles";
import classes from "./Input.module.scss";
import { Textarea } from "components/textarea/Textarea";
import Label from "./Label";
import Error from "./Error";
import Datepicker from "./Datepicker";
import DatepickerRange from "./DatepickerRange";
import Timepicker from "./Timepicker";
import InputText from "./InputText";

interface IInputProps {
  // Functional
  type?:
    | "text"
    | "number"
    | "password"
    | "file"
    | "datepicker"
    | "datepicker-range"
    | "timepicker"
    | "checkbox"
    | "search"
    | "textarea"
    | "email"
    | "url";
  value?: any;
  startDateRange?: any;
  endDateRange?: any;
  customHeader?: React.ReactNode;
  displayValue?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  isRequired?: boolean;
  isClearable?: boolean;
  onChange: any;
  onConfirm?: any;
  isDisabled?: boolean;
  error?: string;
  fileType?:
    | ".pdf"
    | ".csv, .xlsx"
    | "image/png, image/jpeg, image/jpg"
    | "image/png";
  fileName?: string;
  onClear?: () => void;
  autoFocus?: boolean;

  // Customization of component
  title?: string;
  subtitle?: string;
  placeholder?: string;
  showTooltipIcon?: string;
  icon?: any;
  showPasswordTooltip?: boolean;
  showTempPasswordTooltip?: boolean;

  // Used by useForm hook
  name?: string;

  // Styling
  className?: string;
  themeIs?: string;
  maxWidth?: number;
  isHidden?: boolean;

  // Time
  hasTime?: boolean;

  // Children
  children?: React.ReactNode;
  refIs?: any;
  filterPassedTime?: any;

  // Filter
  isFilter?: boolean;
  filterTooltip?: string;
  clearFilter?: () => void;
}

interface IInputWrapperProps {
  themeIs: string;
  isDisabled: boolean;
  error: boolean;
}

const SInputWrapper = styled.div<IInputWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ themeIs, error }) =>
    themeIs === "dark"
      ? !error
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.error100})`
      : !error
      ? `rgb(${colors.lightBlue30})`
      : `rgb(${colors.error10})`};
  border-radius: 16px;
  font-family: Poppins;
  transition: background-color 350ms;
  width: 100%;
  height: 48px;
  outline: ${({ error }) => (error ? `1px solid rgb(${colors.error60})` : "")};
  outline-offset: ${({ error }) => (error ? "-1px" : "unset")};
  &:hover {
    transition: none 0s;
    background-color: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? !error
          ? `rgb(${colors.greyishBlue80})`
          : `rgb(${colors.error100})`
        : !error
        ? `rgb(${colors.lightBlue40})`
        : `rgb(${colors.error10})`};
  }
  &:active {
    transition: none 0s;
    background-color: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? !error
          ? `rgb(${colors.greyishBlue70})`
          : `rgb(${colors.error100})`
        : !error
        ? `rgb(${colors.lightBlue50})`
        : `rgb(${colors.error10})`};
  }
  &:focus-within {
    transition: none 0s;
    outline: ${({ themeIs, error }) =>
      themeIs === "dark"
        ? error
          ? `1px solid rgb(${colors.error60})`
          : `2px solid rgb(${colors.greyishBlue60})`
        : error
        ? `1px solid rgb(${colors.error60})`
        : `2px solid rgb(${colors.lightBlue90})`};
    outline-offset: -1px;
    background-color: ${({ themeIs }) =>
      themeIs === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
  }
  &:disabled: {
    border: unset;
  }
`;

const SInput = styled.input<IInputProps>`
  font-family: Poppins;
  font-size: 14px;
  padding: 13.5px 20px;
  border-radius: 16px;
  background-color: ${({ themeIs, error }) =>
    themeIs === "dark"
      ? !error
        ? `rgb(${colors.greyishBlue90})`
        : `rgb(${colors.error100})`
      : !error
      ? `rgb(${colors.lightBlue30})`
      : `rgb(${colors.error10})`};
  border: none;
  width: 100%;
  height: 100%;
  transition: background-color 350ms;
  color: ${({ themeIs, disabled }) =>
    themeIs === "dark" && !disabled
      ? `rgb(${colors.lightBlue10})`
      : themeIs === "dark" && disabled
      ? `rgb(${colors.greyishBlue70})`
      : themeIs !== "dark" && !disabled
      ? `rgb(${colors.darkBlue100})`
      : `rgb(${colors.greyishBlue20})`};
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: url(${clearSearchIcon});
    cursor: pointer;
  }
  :-webkit-autofill {
    -webkit-text-fill-color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.lightBlue10})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.greyishBlue20})`};
    caret-color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.lightBlue10})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.darkBlue100})`
        : `rgb(${colors.greyishBlue20})`};
  }

  ::placeholder {
    color: ${({ themeIs, disabled }) =>
      themeIs === "dark" && !disabled
        ? `rgb(${colors.greyishBlue50})`
        : themeIs === "dark" && disabled
        ? `rgb(${colors.greyishBlue70})`
        : themeIs !== "dark" && !disabled
        ? `rgb(${colors.greyishBlue30})`
        : `rgb(${colors.greyishBlue10})`};
    opacity: 1;
  }
  &:focus {
    background-color: ${({ themeIs }) =>
      themeIs === "dark"
        ? `rgb(${colors.greyishBlue80})`
        : `rgb(${colors.lightBlue20})`};
    transition: none 0s;
  }
`;

export const InputField = (props: IInputProps) => {
  const {
    type,
    onChange,
    isRequired,
    isClearable,
    title,
    subtitle,
    icon,
    isDisabled,
    value,
    startDateRange,
    endDateRange,
    customHeader,
    displayValue,
    minDate,
    maxDate,
    placeholder,
    error,
    showTooltipIcon,
    name,
    maxWidth,
    isHidden,
    fileType,
    fileName,
    onClear,
    showPasswordTooltip,
    showTempPasswordTooltip,
    hasTime,
    onConfirm,
    children,
    filterPassedTime,
    className,
    isFilter,
    filterTooltip,
    clearFilter,
    refIs,
    autoFocus,
    ...other
  } = props;
  const { theme } = useContext(themeContext);

  const clearFileInput = (e) => {
    e.target.value = null;
  };

  if (type && type === "datepicker") {
    return (
      <div
        className={`${classes["input-with-label"]} ${className}`}
        style={{
          maxWidth: maxWidth || "unset"
        }}
        data-theme={theme}
      >
        <Label
          name={name}
          title={title}
          isRequired={isRequired}
          showTooltipIcon={showTooltipIcon}
        />
        <Datepicker
          onChange={onChange}
          onConfirm={onConfirm}
          isDisabled={isDisabled}
          placeholder={placeholder}
          error={error}
          name={name}
          value={value}
          displayValue={displayValue}
          minDate={minDate}
          maxDate={maxDate}
          hasTime={hasTime}
        >
          {children}
        </Datepicker>
      </div>
    );
  }

  if (type && type === "datepicker-range") {
    return (
      <div
        className={`${classes["input-with-label"]} ${className}`}
        style={{
          maxWidth: maxWidth || "unset"
        }}
        data-theme={theme}
      >
        <Label
          name={name}
          title={title}
          isRequired={isRequired}
          showTooltipIcon={showTooltipIcon}
        />
        <DatepickerRange
          onChange={onChange}
          isDisabled={isDisabled}
          isClearable={isClearable}
          placeholder={placeholder}
          error={error}
          name={name}
          startDate={startDateRange}
          endDate={endDateRange}
          customHeader={customHeader}
          displayValue={displayValue}
          minDate={minDate}
          maxDate={maxDate}
          isFilter={isFilter}
          filterTooltip={filterTooltip}
          clearFilter={clearFilter}
        >
          {children}
        </DatepickerRange>
      </div>
    );
  }

  if (type && type === "timepicker") {
    return (
      <div
        className={classes["input-with-label"]}
        style={{
          maxWidth: maxWidth || "unset"
        }}
        data-theme={theme}
      >
        <Label
          name={name}
          title={title}
          isRequired={isRequired}
          showTooltipIcon={showTooltipIcon}
        />
        <Timepicker
          onChange={onChange}
          isDisabled={isDisabled}
          placeholder={placeholder}
          error={error}
          name={name}
          value={value}
          filterPassedTime={filterPassedTime}
        />
      </div>
    );
  }

  if (type === "file") {
    const PlusIcon = plusIcon;
    const TrashIcon = trashIcon;
    return (
      <div
        className={`${classes["input-with-label"]} ${className}`}
        style={{
          maxWidth: maxWidth && !fileName ? maxWidth : "unset",
          display: isHidden ? "none" : "",
          width: className === "centered" ? "auto" : "100%"
        }}
      >
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          showTooltipIcon={showTooltipIcon}
        />
        {fileName ? (
          <div className={classes["uploaded-file-field"]}>
            <div>{fileName}</div>
            <TrashIcon
              onClick={onClear}
              fill={`rgb(${colors.greyishBlue50})`}
              width={20}
              height={20}
            />
          </div>
        ) : (
          <SInputWrapper
            themeIs={theme}
            isDisabled={isDisabled}
            error={!!error}
            style={{ borderRadius: "100px" }}
          >
            <label
              className={classes["custom-file-upload"]}
              style={{ maxWidth: maxWidth }}
            >
              <div>Attach File</div>
              <PlusIcon
                fill={
                  theme === "dark"
                    ? `rgb(${colors.lightBlue10})`
                    : `rgb(${colors.darkBlue100})`
                }
              />
              <SInput
                type={type || "text"}
                accept={fileType}
                onChange={onChange}
                {...other}
                placeholder={placeholder}
                themeIs={theme}
                onClick={clearFileInput}
                name={name}
                value={value}
              />
            </label>
          </SInputWrapper>
        )}
        <Error text={error} />
      </div>
    );
  }

  if (type === "textarea") {
    return (
      <div
        className={classes["input-with-label"]}
        style={{
          maxWidth: maxWidth || "unset"
        }}
        data-theme={theme}
      >
        <Label
          name={name}
          title={title}
          isRequired={isRequired}
          showTooltipIcon={showTooltipIcon}
        />
        <Textarea
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          isDisabled={isDisabled}
          error={error}
        />
        <Error text={error} />
      </div>
    );
  }

  return (
    <div
      className={classes["input-with-label"]}
      style={{
        maxWidth: maxWidth || "unset"
      }}
      data-theme={theme}
      ref={refIs}
    >
      <Label
        name={name}
        title={title}
        isRequired={isRequired}
        showTooltipIcon={showTooltipIcon}
      />
      <InputText
        type={type}
        onChange={onChange}
        icon={icon}
        isDisabled={isDisabled}
        value={value}
        placeholder={placeholder}
        error={error}
        name={name}
        showPasswordTooltip={showPasswordTooltip}
        showTempPasswordTooltip={showTempPasswordTooltip}
        autoFocus={autoFocus}
      />
      <Error text={error} />
    </div>
  );
};

InputField.defaultProps = {
  themeIs: "light",
  isDisabled: false,
  showPasswordTooltip: false
};
