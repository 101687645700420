import React from "react";
import SchoolComp from "../components/School";
import { useParams } from "react-router-dom";
import Team from "../../team";
import Students from "../../students";
import PermissionsGate from "roles/PermissionsGate";
import classes from "./SingleSchool.module.scss";
import Courses from "../components/Courses";

const SingleSchool = () => {
  let { schoolId, requestId } = useParams();

  return (
    <>
      <div className={classes["content-wrapper"]}>
        <SchoolComp schoolId={schoolId} requestId={requestId} />
      </div>
      {!requestId && (
        <>
          <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
            <Courses
              perPage={3}
              isPaginationHidden
              hasNoActions
              hasViewAll
              hideHeaderControls
            />
          </PermissionsGate>
          <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
            <Team
              perPage={3}
              isPaginationHidden
              hasNoActions
              hasViewAll
              hideHeaderControls
            />
          </PermissionsGate>
          <PermissionsGate permissions={["ROLE.SUPER_ADMIN"]}>
            <Students
              type="students"
              perPage={3}
              isPaginationHidden
              hasNoActions
              hasViewAll
              hideHeaderControls
            />
          </PermissionsGate>
        </>
      )}
    </>
  );
};

export default SingleSchool;
