import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import queryString from "query-string";
import { fetchApi } from "utils/requests";
import jwt_decode from "jwt-decode";
import * as Yup from "yup";
import { InputField } from "components/input/Input";
import { themeContext } from "context/themeContext";
import { userContext } from "context/userContext";
import { toastContext } from "context/toastContext";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import classes from "./LoginForm.module.scss";
import { useFormData } from "hooks/useFormData";
import {
  IDecodedToken,
  ROLE_FLAG
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { setTokenInStorage } from "utils/storage";
import { hasErrors } from "utils/form";
import { yupSchemas } from "utils/yupSchemas";
import { User, useAuth0 } from "@auth0/auth0-react";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import { MD5 } from "crypto-js";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, PROJECT_ID } from "utils/constants";
import { ReactComponent as Auth0Icon } from "assets/icons/auth0.svg";

interface IAuth0LoginData extends User {
  access_token: string;
  checksum: string;
}

interface IFormValues {
  email: string;
  password: string;
  remember: boolean;
}

const validationSchema: Yup.SchemaOf<IFormValues> = Yup.object().shape({
  email: yupSchemas.email,
  password: yupSchemas.requiredStr,
  remember: Yup.boolean()
});

const defaultFormData = {
  email: "",
  password: "",
  remember: true
};

const LoginForm = () => {
  const navigate = useNavigate();
  const { search: redirectPathParam } = useLocation();
  const [_, redirectPath] = redirectPathParam.split("=");
  const { diploma_id, opusedu_redirect_token } =
    queryString.parse(redirectPathParam);

  const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const { formData, formErrors, handleChange, setFormData } = useFormData(
    defaultFormData,
    validationSchema
  );

  const { setUser } = useContext(userContext);
  const { theme } = useContext(themeContext);
  const { setToast, clearToast } = useContext(toastContext);
  const { setPopup, clearPopup } = useContext(popupContext);
  const [isRedirectedWithAuth0, setIsRedirectedWithAuth0] = useState(
    redirectPathParam.includes("?auth0=true")
  );

  const login = async (data) =>
    await fetchApi("account", "/user/login", {
      method: "POST",
      data: { ...data, project_id: PROJECT_ID.OPUS_NFT }
    });

  const loginWithTokenHandler = async () => {
    return await fetchApi("account", "/user/login", {
      method: "POST",
      data: { token: opusedu_redirect_token, project_id: PROJECT_ID.OPUS_NFT }
    });
  };

  // Login mutation
  const { isLoading, mutate: handleLogin } = useMutation({
    mutationKey: ["login"],
    mutationFn: async () => await login(formData),
    onSuccess: (data) => {
      const decoded: IDecodedToken = jwt_decode(data.accessToken);
      const { email, id, role_access, is_student, role_flag, exp } = decoded;
      const is_super_admin = role_flag === ROLE_FLAG["SUPER_ADMIN"];
      setTokenInStorage({
        token: data.accessToken,
        stayLoggedIn: formData.remember,
        exp
      });
      setUser({
        email,
        id,
        role_access,
        role_flag,
        is_student,
        is_super_admin,
        // Wallet not returned in jwt. Using this state in web3 context
        // for connect / disconnect wallet queries.
        wallet: "none"
      });
      navigate(is_super_admin ? "/main-dashboard" : redirectPath || "/school");
      clearToast();
    },
    onError: (err: Error) =>
      setToast({
        type: "error",
        msg: err.message,
        isSelfClosing: false
      })
  });

  // Login with auth token from opus edu mutation
  const { isLoading: isLoadingHandleWithToken, mutate: handleLoginWithToken } =
    useMutation({
      mutationKey: ["login"],
      mutationFn: async () => await loginWithTokenHandler(),
      onSuccess: (data) => {
        const decoded: IDecodedToken = jwt_decode(data.accessToken);
        const { email, id, role_access, is_student, role_flag, exp } = decoded;
        setTokenInStorage({
          token: data.accessToken,
          stayLoggedIn: formData.remember,
          exp
        });
        setUser({
          email,
          id,
          role_access,
          role_flag,
          is_student,
          // Wallet not returned in jwt. Using this state in web3 context
          // for connect / disconnect wallet queries.
          wallet: "none"
        });
        navigate(`/diplomas/${diploma_id}`);
        clearToast();
      },
      onError: (err: Error) =>
        setToast({
          type: "error",
          msg: err.message,
          isSelfClosing: false
        })
    });

  const loginWithAuth0 = () => {
    setPopup(
      <GenericPopup
        type="loading"
        title="Logging in with Auth0"
        msg={
          <>
            Please wait...
            <br /> You will be redirected on successful log in.
          </>
        }
        isClosable={false}
      />
    );
    loginWithRedirect();
  };

  // Sending prepared auth0 data to BE
  const {
    isLoading: auth0LoginByBackedIsLoading,
    mutate: handleAuth0LoginByBackend
  } = useMutation({
    mutationKey: ["login-with-auth0"],
    mutationFn: async (data: IAuth0LoginData) => {
      setPopup(
        <GenericPopup
          type="loading"
          title="Logging in with Auth0"
          msg={
            <>
              Please wait...
              <br /> You will be redirected on successful log in.
            </>
          }
          isClosable={false}
        />
      );
      const response = await fetchApi("account", `/user/auth0`, {
        method: "POST",
        data: { ...data, project_id: PROJECT_ID.OPUS_NFT }
      });
      return response;
    },
    onSuccess: (data) => {
      const decoded: IDecodedToken = jwt_decode(data.accessToken);
      const { email, id, role_access, is_student, role_flag, exp } = decoded;
      const is_super_admin = role_flag === ROLE_FLAG["SUPER_ADMIN"];
      setTokenInStorage({
        token: data.accessToken,
        stayLoggedIn: formData.remember,
        exp
      });
      setUser({
        email,
        id,
        role_access,
        role_flag,
        is_student,
        is_super_admin,
        // Wallet not returned in jwt. Using this state in web3 context
        // for connect / disconnect wallet queries.
        wallet: "none"
      });
      clearPopup();
      setIsRedirectedWithAuth0(false);
      navigate(is_super_admin ? "/main-dashboard" : "/school");
      clearToast();
    },
    onError: (err: Error) => {
      setToast({
        type: "error",
        msg: err.message,
        isSelfClosing: false
      });
      clearPopup();
      setIsRedirectedWithAuth0(false);
    }
  });

  // Scenario: A user lands on https://domainName.tld/login?auth0=true and they ARE auth0 authenticated.
  // Case 1: They do NOT have an account on the platform. BE creates an account and they are logged in.
  // Case 2: They DO have an account on the platform, log them in.
  useEffect(() => {
    if (isRedirectedWithAuth0 && isAuthenticated) {
      const getTokenAndQueryBackend = async () => {
        try {
          const response = await getAccessTokenSilently({
            cacheMode: "off"
          });

          const access_token = await Promise.resolve(response);
          const checksum = MD5(access_token).toString();
          const data = {
            ...user,
            access_token,
            checksum
          };

          handleAuth0LoginByBackend(data);
        } catch (e) {
          console.log(e);
        }
      };
      getTokenAndQueryBackend();
    }
  }, [isRedirectedWithAuth0, isAuthenticated]);

  // Scenario: A user lands on https://domainName.tld/login?auth0=true and they are NOT auth0 authenticated.
  // Case 1: If auth0 cookies are saved in the user's browser, the user will be logged in the platform automatically.
  // Case 2: If the cookies are NOT saved, the user will be navigate to auth0 login screen.
  useEffect(() => {
    if (redirectPathParam === "?auth0=true" && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isRedirectedWithAuth0]);

  useEffect(() => {
    if (!!opusedu_redirect_token) handleLoginWithToken();
  }, [opusedu_redirect_token]);

  return (
    <div
      className={`${classes["wrapper"]} ${classes["form-sm"]}`}
      data-theme={theme}
    >
      <h4
        className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]} ${classes["m-0"]}`}
      >
        Sign in to Opus!
      </h4>
      <form className={classes["form"]} onSubmit={(e) => e.preventDefault()}>
        <InputField
          title="E-mail"
          name="email"
          placeholder="Enter E-mail"
          onChange={handleChange}
          value={formData.email}
          error={formErrors.email}
        />
        <InputField
          title="Password"
          name="password"
          type="password"
          placeholder="Enter Password"
          onChange={handleChange}
          value={formData.password}
          error={formErrors.password}
        />
        <div className={classes["options"]}>
          <label className={classes["custom-checkbox"]}>
            <input
              type="checkbox"
              defaultChecked={formData.remember}
              onClick={() =>
                setFormData((prevState) => ({
                  ...prevState,
                  remember: !formData.remember
                }))
              }
            />
            <span className={classes["mark"]} />
            Stay logged in
          </label>
          <div
            className={classes["forgotten-password"]}
            onClick={() => {
              navigate("/forgot-password");
              clearToast();
            }}
          >
            Forgot Password?
          </div>
        </div>
        <div className={classes["btns-container"]}>
          <Button
            type="submit"
            minWidth="full"
            onClick={handleLogin}
            isDisabled={
              hasErrors(formErrors) ||
              !formData.email.length ||
              !formData.password.length
            }
            isFetching={isLoading || isLoadingHandleWithToken}
          >
            Sign In
          </Button>
          <Divider orientation="horizontal" />

          {AUTH0_CLIENT_ID && AUTH0_DOMAIN && (
            <Button
              type="submit"
              variant="outline-neutral"
              minWidth="full"
              onClick={loginWithAuth0}
              isFetching={isRedirectedWithAuth0}
              icon={Auth0Icon}
            >
              Continue with Kois Center login
            </Button>
          )}
        </div>
      </form>
      <div className={classes["registration-section"]}>
        <p>
          You are a student who is not registered yet?{" "}
          <span
            onClick={() => {
              navigate("/student-register");
              clearToast();
            }}
          >
            Sign up today!
          </span>
        </p>
        <p>
          Or want your school on OpusNFT?{" "}
          <span
            onClick={() => {
              navigate("/school-register");
              clearToast();
            }}
          >
            Apply for registration!
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
