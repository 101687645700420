import classes from "./TitleAndMsg.module.scss";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import Button from "components/buttons/Button";

interface ITitleAndMessage {
  title: string;
  msg: string;
  hasBackBtn?: boolean;
  onClick?: () => void;
}

const TitleAndMsg = (props: ITitleAndMessage) => {
  const { title, msg, hasBackBtn, onClick } = props;
  return (
    <div className={classes["title-and-msg"]}>
      {hasBackBtn && (
        <Button
          onClick={onClick}
          variant={"link"}
          size="large"
          isIconBtn
          icon={ArrowIcon}
          iconRotate={180}
        />
      )}
      <div
        className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--h4"]} ${classes["u-text--center"]}`}
      >
        {title}
      </div>
      <div
        className={`${classes["u-text--medium"]} ${classes["u-text--content"]} ${classes["u-text--center"]}`}
      >
        {msg}
      </div>
    </div>
  );
};
export default TitleAndMsg;
