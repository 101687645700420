import React from "react";
import { useNavigate } from "react-router-dom";
import useComponentVisible from "hooks/useComponentVisible";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import Avatar from "components/avatar/Avatar";
import Button from "components/buttons/Button";
import { IFetchedMeData } from "utils/apiDataTypes/AccountManagementDataTypes";
import classes from "./UserMobile.module.scss";
import PermissionsGate from "roles/PermissionsGate";
import { IItems } from "../User";
import { formatName } from "utils/format";

interface IUser {
  closeMobileMenu: () => void;
  handleLogout: () => void;
  menuItems: IItems[];
  meData: IFetchedMeData;
  isAdmin: boolean;
}

const UserMobile = ({
  closeMobileMenu,
  handleLogout,
  menuItems,
  meData,
  isAdmin
}: IUser) => {
  const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { pic, school } = meData;

  return (
    <div
      ref={ref}
      className={`${classes["user-container-mobile"]} ${classes["mobile"]}`}
      onClick={() => {
        setIsComponentVisible(!isComponentVisible);
      }}
    >
      <div className={classes["user-box"]}>
        <div className={classes["details"]}>
          <Avatar src={pic} type={isAdmin ? "school" : "user"} isActive />
          <div>
            <div
              className={`${classes["u-text--primary"]} ${classes["u-text--small"]} ${classes["u-bold"]}`}
            >
              {formatName(meData.first_name)} {meData.last_name}
            </div>
            <div
              className={`${classes["u-text--content"]} ${classes["u-text--small"]}`}
            >
              {meData.role.name}
            </div>
          </div>
        </div>
        <Button
          variant={"link"}
          size="medium"
          isIconBtn
          icon={ChevronIcon}
          iconRotate={isComponentVisible ? 180 : 0}
        />
      </div>

      <div
        className={`${classes["mobile-menu"]} ${
          classes[isComponentVisible ? "open" : ""]
        }`}
      >
        <div className={classes["c-menu"]}>
          {menuItems.map((item, i) => (
            <PermissionsGate
              permissions={
                i === 0 && item.link.includes("school") ? ["SCHOOL.UPDATE"] : []
              }
              key={i}
            >
              <div
                className={classes["c-menu-item"]}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.link === "/login") handleLogout();
                  navigate(item.link);
                  setIsComponentVisible(false);
                  closeMobileMenu();
                }}
              >
                {item.icon ? <item.icon /> : ""}
                {item.name}
              </div>
            </PermissionsGate>
          ))}
        </div>
      </div>
    </div>
  );
};

UserMobile.defaultProps = {
  isMobile: false,
  closeMobileMenu: null
};

export default UserMobile;
