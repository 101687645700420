import { useContext, useState } from "react";
import { ReactComponent as copyIcon } from "assets/icons/copy.svg";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import { formatAddress } from "utils/format";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "./common/ModalWrapper";
import PopupTitleAndMessage from "./common/PopupTitleAndMessage";
import Button from "components/buttons/Button";
import classes from "./DepositPopup.module.scss";
import { windowContext } from "context/windowsContext";

const DepositPopup = ({ walletAddress }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const { theme } = useContext(themeContext);

  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  return (
    <ModalWrapper size="md">
      <div data-theme={theme}>
        <div className={classes["deposit-popup"]}>
          <PopupTitleAndMessage
            title="How do I get digital currency into my wallet?"
            msg="You can send Matic, ETH or any token from another wallet. Simply copy the wallet address connected to your account and go to your existing wallet or exchange and send funds to your wallet address."
            msgSecondary="You can also request funds from a friend by sharing your wallet address."
          />
          <div className={classes["wallet-container"]}>
            <div
              className={`${classes["u-text--primary"]} ${classes["title"]}`}
            >
              <span className={classes["u-text--small"]}>
                Your Wallet Address
              </span>
            </div>
            <div className={classes["wallet"]}>
              <div>
                {formatAddress(
                  walletAddress,
                  !isMobile ? 10 : null,
                  !isMobile ? -10 : null
                )}
              </div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(walletAddress);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
                iconPosition="right"
                icon={!copied ? copyIcon : checkIcon}
              >
                {!copied ? "Copy" : "Copied"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DepositPopup;
