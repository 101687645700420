import { Fragment } from "react";
import { ReactComponent as checkIcon } from "assets/icons/check.svg";
import { colors } from "styles";
import classes from "./FormSteps.module.scss";

interface IFormSteps {
  title: string;
  steps: string[];
  currentStep: number;
  finishedSteps: number;
  setCurrentStep: (step: number) => void;
}

const FormSteps = (props: IFormSteps) => {
  const { title, steps, currentStep, finishedSteps, setCurrentStep } = props;
  const CheckIcon = checkIcon;

  const stepClickHandler = (step: number) => {
    if (finishedSteps <= currentStep && step <= currentStep) {
      setCurrentStep(step);
    }
  };

  const dynamicColumnsHandler = () => {
    let columnDef = "";
    for (let i = 0; i < steps.length; i++) {
      columnDef += "1fr 24px ";
    }
    columnDef += "1fr";
    return columnDef;
  };

  return (
    <>
      <div className={classes["header"]}>{title}</div>
      <div
        className={classes["progress-bar"]}
        style={{ gridTemplateColumns: `${dynamicColumnsHandler()}` }}
      >
        {steps.map((step, i) => {
          return (
            <Fragment key={i}>
              <div className={classes["line"]} />
              <div
                className={`${classes["step"]} ${
                  finishedSteps < i ? classes["disabled-step"] : ""
                }`}
                onClick={() => stepClickHandler(i)}
              >
                {finishedSteps < i + 1 ? (
                  i + 1
                ) : (
                  <CheckIcon fill={`rgb(${colors.neutral10})`} />
                )}
              </div>
            </Fragment>
          );
        })}
        <div className={classes["line"]} />
        {steps.map((step, i) => {
          return (
            <Fragment key={i}>
              <div />
              <div
                className={`${classes["step-title"]} ${
                  finishedSteps < i ? classes["disabled-title"] : ""
                }`}
              >
                <div>{step}</div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default FormSteps;
