import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { themeContext } from "context/themeContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import Button from "components/buttons/Button";
import Card from "./Card";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/templates.svg";
import { popupContext } from "context/popupContext";
import { fetchApi } from "utils/requests";
import GenericPopup from "components/popups/GenericPopup";
import classes from "./SuccessPopup.module.scss";

interface ISuccessPopup {
  id: string;
  name: string;
  pic?: string;
  startDate?: number;
  isEdit?: boolean;
}

const SuccessPopup = ({ id, name, pic, startDate, isEdit }: ISuccessPopup) => {
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);
  const { setPopup, clearPopup, popupIsLoading, setPopupLoading } =
    useContext(popupContext);

  const saveAsTemplate = async (id) =>
    await fetchApi("courses", `/course/${id}`, {
      method: "PUT",
      auth: true,
      data: {
        is_template: true
      }
    });

  // Update course mutation
  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["update-course", { id }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await saveAsTemplate(id);
    },
    onSuccess: async () => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="success"
          msg="You have successfully saved this course as a template!"
          buttonName={"Go to course"}
          buttonVariant={"contrast"}
          buttonAction={() => navigate(`courses/${id}`)}
          isClosable={false}
          bellowBtnComp={
            <Button
              onClick={() => {
                navigate("courses/templates");
                clearPopup();
              }}
              icon={TemplateIcon}
              variant={"link"}
              minWidth="full"
            >
              Show the templates
            </Button>
          }
        />
      );
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName={"Go to templates"}
          buttonVariant={"contrast"}
          buttonAction={() => navigate("courses/templates")}
          isClosable={false}
        />
      );
    }
  });

  return (
    <ModalWrapper size="lg" isClosable={false}>
      <div data-theme={theme} className={classes["success-popup"]}>
        <h4
          className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
        >
          Success
        </h4>
        <div
          className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
        >
          {`Course ${isEdit ? "updated" : "created"} successfully!`}
        </div>
        <div className={classes["card"]}>
          <Card
            name={name}
            _id={id}
            image={pic}
            startDate={startDate}
            onClick={() => {
              navigate(`/courses/${id}`);
              clearPopup();
            }}
          />
        </div>
        <div className={classes["buttons-container"]}>
          {!isEdit && (
            <Button
              variant="link"
              onClick={() => {
                handleUpdate(id);
              }}
              icon={TemplateIcon}
              iconPosition="right"
              isFetching={popupIsLoading}
            >
              Save as Template
            </Button>
          )}
          <Button
            onClick={() => {
              navigate(`/courses/${id}`);
              clearPopup();
            }}
            icon={ArrowIcon}
            iconPosition="right"
          >
            Go to Course
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SuccessPopup;
