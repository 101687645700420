import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import styled from "styled-components";
import { colors, fonts } from "styles";
import classes from "./ReactSelectWrapper.module.scss";
import ReactSelect from "components/react-select/ReactSelect";
import Label from "../input/Label";
import TooltipComp from "components/tooltip/TooltipComp";

interface IReactSelectWrapper {
  title?: string;
  subtitle?: string;
  showTooltipIcon?: string;
  tooltipContent?: React.ReactNode;
  isRequired?: boolean;
  name: string;
  placeholder: string;
  value:
    | { value: number | string; label: string }
    | { value: number | string; label: string }[];
  options: { value: number | string; label: string }[];
  isDisabled?: boolean;
  error?: string | any[];
  onChange: (e: any) => any;
  isLoading?: boolean;
  isMulti?: boolean;
  isSort?: boolean;
  hasAvatar?: boolean;
  onlyChevron?: boolean;
  left?: string;
  top?: string;
  isWidthFixed?: boolean;
  tooltipMsg?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
}

const SErrorMsg = styled.div`
  color: ${() => `rgb(${colors.error60})`};
  font-size: ${() => `${fonts.size.xsmall}`};
  margin-top: 4px;
`;

const ReactSelectWrapper = (props: IReactSelectWrapper) => {
  const {
    isSort,
    isDisabled,
    isRequired,
    options,
    value,
    placeholder,
    title,
    subtitle,
    showTooltipIcon,
    tooltipContent,
    onChange,
    error,
    name,
    isLoading,
    isMulti,
    hasAvatar,
    onlyChevron,
    left,
    top,
    isWidthFixed,
    tooltipMsg,
    isClearable,
    isSearchable
  } = props;
  const { theme } = useContext(themeContext);

  return (
    <div
      className={`${classes["input-with-label"]} ${
        classes[isWidthFixed ? "fixed-width" : ""]
      }`}
      data-theme={theme}
    >
      {title && (
        <Label
          name={name}
          title={title}
          isRequired={isRequired}
          showTooltipIcon={showTooltipIcon}
          tooltipContent={tooltipContent}
        />
      )}
      {subtitle && <div className={classes["subtitle"]}>{subtitle}</div>}
      <TooltipComp text={tooltipMsg ? tooltipMsg : ""} id={title}>
        <ReactSelect
          isSort={isSort}
          name={name}
          value={value}
          placeholder={placeholder}
          options={options}
          isMulti={isMulti}
          onChange={onChange}
          isDisabled={isDisabled}
          isLoading={isLoading}
          hasError={!!error}
          hasAvatar={hasAvatar}
          onlyChevron={onlyChevron}
          left={left || ""}
          top={top || ""}
          isClearable={isClearable}
          isSearchable={isSearchable}
        />
      </TooltipComp>
      {error && <SErrorMsg>{error}</SErrorMsg>}
    </div>
  );
};

ReactSelectWrapper.defaultProps = {
  isWidthFixed: false
};

export default ReactSelectWrapper;
