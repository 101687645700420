import React, { useState } from "react";
import Button from "components/buttons/Button";
import classes from "../Landing.module.scss";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";

const AccordionItem = ({
  question,
  answer
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <div
        className={classes["accordion__q"]}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Button
          variant={"link"}
          size="medium"
          isIconBtn
          icon={ChevronIcon}
          iconRotate={isOpen ? 180 : 0}
        />
        <h2>{question}</h2>
      </div>
      <p
        className={`${classes["accordion__a"]} ${
          isOpen ? classes["open"] : ""
        }`}
      >
        {answer}
      </p>
    </>
  );
};

export default AccordionItem;
