import React, { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { popupContext } from "context/popupContext";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { fetchApi } from "utils/requests";
import classes from "./VerifyUserForm.module.scss";
import { useSearchParams } from "react-router-dom";

const VerifyUserForm = () => {
  const { setPopup } = useContext(popupContext);

  const [searchParams] = useSearchParams();
  const uid = searchParams.get("u_id");
  const hash = searchParams.get("hash");

  const verify = async () => {
    await fetchApi("account", `/user/verify/${uid}/${hash}`, {
      method: "PUT"
    });
  };

  // Register mutation
  const { isLoading, mutate: handleVerify } = useMutation({
    mutationKey: ["user-verify"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      await verify();
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          msg="Your email has been successfully verified."
          size="sm"
          redirectPath="/login"
          buttonName="Go to Login"
          buttonVariant="contrast"
          isClosable={false}
        />
      );
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  return (
    <div className={`${classes["wrapper"]} ${classes["form-md"]}`}>
      <div className={classes["form-layout"]}>
        <div>
          <h3 className={classes["title"]}>Verify Registration</h3>
          <div
            className={`${classes["desc"]} ${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-text--center"]}`}
          >
            Verify email address for your OpusNFT account by clicking the link
            below.
          </div>
        </div>
        <form>
          <div className={classes["body"]}>
            <Button
              type="submit"
              onClick={handleVerify}
              icon={checkIcon}
              iconPosition="right"
              isFetching={isLoading}
            >
              Verify Account
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default VerifyUserForm;
