import Button from "components/buttons/Button";
import TooltipComp from "components/tooltip/TooltipComp";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as PowerOffIcon } from "assets/icons/power-off.svg";
import { ReactComponent as CheckCircleIcon } from "assets/icons/check-circle.svg";
import { useContext, useState } from "react";
import { Web3Context } from "context/web3Context";
import { toastContext } from "context/toastContext";
import { BLOCK_EXPLORER_URL } from "utils/constants";
import classes from "./Connect.module.scss";
import { windowContext } from "context/windowsContext";
import { useDisconnect } from "wagmi";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import { userContext } from "context/userContext";
import Wallet from "components/wallet/Wallet";
import { formatAddress } from "utils/format";

const ActionButtons = () => {
  const [copied, setCopied] = useState<boolean>(false);
  const { web3UserData } = useContext(Web3Context);
  const { setToast } = useContext(toastContext);
  const { windowSize } = useContext(windowContext);
  const { setPopup } = useContext(popupContext);
  const { userData } = useContext(userContext);
  const { isMobile } = windowSize;

  const { disconnect } = useDisconnect();

  const disconnectHandler = () => {
    localStorage.setItem("isWalletConnected", "false");
    disconnect();
    if (!!userData?.is_student)
      setPopup(
        <GenericPopup
          type="info"
          title="Disconnected!"
          msg={
            <span className={classes["u-flex-col--centered"]}>
              {userData?.wallet ? (
                <>
                  <span>
                    Please note that a wallet is still associated with your
                    profile. Your certificates will be minted to this address.{" "}
                  </span>
                  <br />
                  <Wallet
                    className={`${classes["u-justify--center"]}  ${classes["u-text--large"]} ${classes["u-semibold"]} ${classes["u-text--primary"]}`}
                    wallet={formatAddress(userData?.wallet)}
                  />
                </>
              ) : (
                "there is no wallet associated with your profile. You cannot receive certificates without a specified wallet address."
              )}
            </span>
          }
        />
      );
  };

  return (
    <div
      className={classes[`action-buttons-wrapper${isMobile ? "-mobile" : ""}`]}
    >
      <TooltipComp id="copy" text="Copy">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(web3UserData.wallet_address);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
            setToast({
              type: "info",
              msg: "Address copied successfully.",
              isSelfClosing: true,
              withTitle: false
            });
          }}
          icon={copied ? CheckCircleIcon : CopyIcon}
          variant={"link"}
          isIconBtn
        />
      </TooltipComp>
      <TooltipComp id="view" text="View on block explorer">
        <Button
          onClick={() =>
            window.open(
              `${BLOCK_EXPLORER_URL}/address/${web3UserData.wallet_address}`,
              "_blank"
            )
          }
          icon={LinkIcon}
          variant={"link"}
          isIconBtn
        />
      </TooltipComp>
      <TooltipComp id="disconnect" text="Disconnect">
        <Button
          onClick={disconnectHandler}
          icon={PowerOffIcon}
          variant={"link"}
          isIconBtn
        />
      </TooltipComp>
    </div>
  );
};

export default ActionButtons;
