import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { themeContext } from "context/themeContext";
import { popupContext } from "context/popupContext";
import { ModalWrapper } from "components/popups/common/ModalWrapper";
import PopupTitleAndMessage from "components/popups/common/PopupTitleAndMessage";
import { InputField } from "components/input/Input";
import EmptyPage from "components/empty-page/EmptyPage";
import NoResults from "components/no-results/NoResults";
import Button from "components/buttons/Button";
import Card from "./Card";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import NoCourses from "assets/images/dashboard/no-courses.png";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/templates.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import classes from "./CreateCoursePopup.module.scss";
import { ICourseData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { courseTemplatesDataQuery } from "query";
import { colors } from "styles";

const CreateCoursePopup = () => {
  const navigate = useNavigate();

  const { theme } = useContext(themeContext);
  const { clearPopup } = useContext(popupContext);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    courseTemplatesDataQuery(
      `?${searchValue.length > 1 ? `&search[name]=${searchValue}` : ""}`
    )
  );

  const renderStep = (step) => {
    if (step === 0) {
      return (
        <>
          <PopupTitleAndMessage
            title="Create Course"
            msg="Create a new course or choose from a previously saved course template."
          />
          <div className={classes["options-container"]}>
            <div
              className={classes["options"]}
              onClick={() => {
                navigate("/courses/create");
                clearPopup();
              }}
            >
              <PlusIcon
                width={48}
                height={48}
                fill={`rgb(${colors.orange60})`}
              />
              <span
                className={`${classes["u-text--primary"]} ${classes["u-text--small"]} ${classes["u-semibold"]}`}
              >
                Create New Course
              </span>
            </div>
            <div
              className={classes["options"]}
              onClick={() => setCurrentStep(1)}
            >
              <TemplateIcon
                width={48}
                height={48}
                fill={`rgb(${colors.orange60})`}
              />
              <span
                className={`${classes["u-text--primary"]} ${classes["u-text--small"]} ${classes["u-semibold"]}`}
              >
                Choose from Templates
              </span>
            </div>
          </div>
        </>
      );
    }

    const { result: templates } = data || {};
    const hasData = templates && templates.length > 0;

    return (
      <>
        <PopupTitleAndMessage
          title="Course Templates"
          hasBackBtn
          onBackBtnClick={() => setCurrentStep(0)}
        />
        <div className={classes["content-wrapper"]}>
          {(hasData || (!hasData && searchValue)) && (
            <InputField
              type={"search"}
              name="search"
              placeholder="Search by Name"
              onChange={(test) => setSearchValue(test.target.value)}
            />
          )}
          {isLoading && <Loader size="lg" withPadding />}
          {error && <ErrorComponent error={error} />}
          {hasData && !isLoading && (
            <div className={classes["templates-container"]}>
              {templates.map((template) => {
                return (
                  <Card
                    key={template._id}
                    name={template.name}
                    _id={template._id}
                    image={template.image}
                    isSimple
                    onClick={() => {
                      navigate(`/courses/${template._id}/createFromTemplate`);
                      clearPopup();
                    }}
                  />
                );
              })}
            </div>
          )}
          {!hasData && searchValue && !isLoading && (
            <NoResults className="wrapper-modal" />
          )}
          {!hasData && !searchValue && !isLoading && (
            <>
              <EmptyPage
                title="There are no course templates yet."
                message="You can create a new course, then save it as a template, or you can chose from one of the already created courses and turn it to template."
                icon={NoCourses}
                className="empty-wrapper-modal"
              />
              <div className={classes["buttons-container"]}>
                <Button
                  variant="neutral"
                  onClick={() => {
                    navigate("courses");
                    clearPopup();
                  }}
                  icon={SearchIcon}
                >
                  Browse Courses
                </Button>
                <Button
                  variant="neutral"
                  onClick={() => {
                    navigate("/courses/create");
                    clearPopup();
                  }}
                  icon={PlusIcon}
                >
                  Create Course
                </Button>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <ModalWrapper size="lg">
      <div data-theme={theme} className={classes["create-course-popup"]}>
        {renderStep(currentStep)}
      </div>
    </ModalWrapper>
  );
};

export default CreateCoursePopup;
