import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import Button from "components/buttons/Button";
import Badge from "components/badge/Badge";
import { ReactComponent as MenuDots } from "assets/icons/menu-dots.svg";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./Card.module.scss";
import useComponentVisible from "hooks/useComponentVisible";
import PermissionsGate from "roles/PermissionsGate";
import { fetchApi } from "utils/requests";
import GenericPopup from "components/popups/GenericPopup";
import TooltipComp from "components/tooltip/TooltipComp";
import { popupContext } from "context/popupContext";
import { userContext } from "context/userContext";
import { dateFormatDisplay } from "utils/staticOptions";

interface ICard extends Pick<ICourse, "_id" | "name" | "image"> {
  startDate?: string | number;
  onClick?: () => void;
  hasMenu?: boolean;
  isSimple?: boolean;
  isTemplate?: boolean;
  isOpen?: boolean;
  isDraft?: boolean;
  // TODO - pick but optional
  has_mints?: boolean;
  isCell?: boolean;
  className?: any;
}

const Card = ({
  _id: id,
  name,
  image,
  has_mints,
  startDate,
  onClick,
  hasMenu,
  isSimple,
  isTemplate,
  isOpen,
  isDraft,
  isCell,
  className
}: ICard) => {
  const { userData } = useContext(userContext);
  const { is_student } = userData || {};
  const navigate = useNavigate();
  const { setPopup, setPopupLoading, clearPopup } = useContext(popupContext);

  const queryClient = useQueryClient();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOnClick = () => {
    onClick && onClick();
  };

  const duplicateCourse = async (id) =>
    await fetchApi("courses", `/course/${id}/duplicate`, {
      method: "POST",
      auth: true
    });

  const deleteCourse = async (id) =>
    await fetchApi("courses", `/course/${id}`, {
      method: "DELETE",
      auth: true
    });

  const deleteCourseDraft = async (id) =>
    await fetchApi("courses", `/draft/${id}`, {
      method: "DELETE",
      auth: true
    });

  // Duplicate course mutation
  const { mutate: handleDuplicate } = useMutation({
    mutationKey: ["duplicate-course", { id }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await duplicateCourse(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["courses-data"] });
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully duplicated this course!"
        />
      );
      setPopupLoading(false);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
      setPopupLoading(false);
    }
  });

  // Delete course mutation
  const { mutate: handleDelete } = useMutation({
    mutationKey: ["delete-course", { id }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await deleteCourse(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["courses-data"] });
      setPopup(
        <GenericPopup
          type="success"
          msg="You have successfully deleted this course!"
        />
      );
      setPopupLoading(false);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
      setPopupLoading(false);
    }
  });

  // Delete course draft mutation
  const { mutate: handleDeleteDraft } = useMutation({
    mutationKey: ["delete-course-draft", { id }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await deleteCourseDraft(id);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["courses-drafts-data"] });
      setPopup(
        <GenericPopup
          type="success"
          msg="You have successfully deleted this draft!"
        />
      );
      setPopupLoading(false);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
      setPopupLoading(false);
    }
  });

  const showConfirmPopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Duplicate Course?"
        msg="Are you sure that you want to duplicate this course?"
        buttonAction={() => handleDuplicate(id)}
        buttonName="Duplicate"
        buttonVariant="contrast"
        bellowBtnComp={
          <Button
            variant="neutral"
            onClick={() => clearPopup()}
            minWidth="md"
            size="medium"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const showConfirmDeletePopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title={`Delete ${isDraft ? "Draft" : "Course"}?`}
        msg={`Are you sure that you want to delete this ${
          isDraft ? "draft" : "course"
        }?`}
        buttonAction={() =>
          isDraft ? handleDeleteDraft(id) : handleDelete(id)
        }
        buttonName="Delete"
        buttonVariant="contrast"
        bellowBtnComp={
          <Button
            variant="neutral"
            onClick={() => clearPopup()}
            minWidth="md"
            size="medium"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  return (
    <div
      className={`${classes["card"]} ${
        isSimple ? classes["card--simple"] : ""
      } ${className}`}
      onClick={handleOnClick}
    >
      {image ? (
        <div className={classes["card-pic"]}>
          <img src={`${FILES_PUBLIC_DOMAIN}${image}`} alt="Course" />
        </div>
      ) : (
        <div className={`${classes["card-pic"]} ${classes["card-pic--empty"]}`}>
          <BookIcon />
        </div>
      )}
      {(isTemplate || isOpen) && (
        <div className={classes["card-labels"]}>
          {isTemplate && <Badge text="Template" />}
          {isOpen && !is_student && <Badge text="Open Course" isOpen />}
        </div>
      )}

      {!isCell && (
        <div className={classes["card-content"]}>
          <div>
            <h6
              className={`${classes["u-title"]} ${
                classes[isSimple ? "u-text--small" : "u-text--medium"]
              } ${classes["u-bold"]} ${classes["m-0"]}`}
            >
              {name}
            </h6>
            {!!startDate && (
              <span
                className={`${classes["u-text--small"]} ${classes["u-text--content"]}`}
              >
                Start Date:&nbsp;
                <span className={classes["u-semibold"]}>
                  {moment(startDate).format(dateFormatDisplay)}
                </span>
              </span>
            )}
          </div>
          {hasMenu && (
            <>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsComponentVisible(!isComponentVisible);
                }}
                variant={"link"}
                size="medium"
                isIconBtn
                icon={MenuDots}
              />
              {isComponentVisible && (
                <div className={classes["c-menu-wrapper"]} ref={ref}>
                  <div className={classes["c-menu"]}>
                    {!isDraft && (<div
                      className={classes["c-menu-item"]}
                      onClick={() => navigate(id)}
                    >
                      Open
                    </div>)}
                    <PermissionsGate permissions={["COURSE.UPDATE"]}>
                      <div
                        className={`${classes["c-menu-item"]} ${
                          classes[has_mints ? "c-menu-item--disabled" : ""]
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!has_mints) navigate(`/courses${isDraft ? "-drafts" : ""}/${id}/edit`
                        );
                      }}
                      >  <TooltipComp
                          text={
                            has_mints
                              ? "A course cannot be edited after the first mint has occurred."
                              : ""
                          }
                          id="disabled-edit-btn"
                        >
                          Edit
                        </TooltipComp>
                      </div>
                    </PermissionsGate>
                    {!isDraft && (<PermissionsGate permissions={["COURSE.UPDATE"]}>
                      <div
                        className={classes["c-menu-item"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          showConfirmPopup();
                        }}
                      >
                        Duplicate
                      </div>
                    </PermissionsGate>)}
                    <PermissionsGate permissions={["COURSE.UPDATE"]}>
                      <div
                        className={`${classes["c-menu-item"]} ${classes["c-menu-item--danger"]}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          showConfirmDeletePopup();
                        }}
                      >
                        Delete
                      </div>
                    </PermissionsGate>{" "}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
