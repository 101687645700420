import Blockies from "components/blockies/Blockies";
import Button from "components/buttons/Button";
import { Web3Context } from "context/web3Context";
import { useContext } from "react";
import { formatAddress } from "utils/format";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import classes from "./Connect.module.scss";
import { windowContext } from "context/windowsContext";

interface IWallet {
  isVisible: boolean | number;
  setIsVisible: (isVisible: boolean) => void;
}

const Wallet = ({ isVisible, setIsVisible }: IWallet) => {
  const { web3UserData } = useContext(Web3Context);
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;

  return (
    <div
      className={classes[`wallet${isMobile ? "-mobile" : ""}`]}
      onClick={() => setIsVisible(!isVisible)}
    >
      <div className={classes["details"]}>
        <Blockies seed={web3UserData.wallet_address} size={9} scale={4} />
        <span
          className={`${classes["u-text--primary"]} ${classes["u-text--medium"]} ${classes["u-bold"]}`}
        >
          {formatAddress(web3UserData?.wallet_address)}
        </span>
      </div>
      <Button
        variant={"link"}
        size="medium"
        isIconBtn
        icon={ChevronIcon}
        iconRotate={isVisible ? 180 : 0}
      />
    </div>
  );
};

export default Wallet;
