import React, { useContext } from "react";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { popupContext } from "context/popupContext";
import useComponentVisible from "hooks/useComponentVisible";
import ConnectWalletPopup from "components/popups/ConnectWalletPopup";
import Button from "components/buttons/Button";
import { METAMASK_LOCAL_DEEPLINK } from "utils/constants";
import classes from "./Connect.module.scss";
import { Web3Context } from "context/web3Context";
import Wallet from "./Wallet";
import WalletInfo from "./WalletInfo";

interface IConnect {
  isMobile: boolean;
}

const Connect = ({ isMobile }: IConnect) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { setPopup } = useContext(popupContext);
  const { web3UserData } = useContext(Web3Context);

  if (web3UserData?.wallet_address)
    return (
      <>
        <div
          ref={ref}
          className={classes["wallet-container"]}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <Wallet
            isVisible={isComponentVisible}
            setIsVisible={setIsComponentVisible}
          />
          <WalletInfo isVisible={isComponentVisible} />
        </div>
      </>
    );

  return (
    <Button
      onClick={() => setPopup(<ConnectWalletPopup />)}
      variant={isMobile ? "outline" : "neutral"}
      icon={WalletIcon}
    >
      Connect Wallet
    </Button>
  );
};

Connect.defaultProps = {
  isMobile: false
};

export default Connect;
