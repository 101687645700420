import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { userContext } from "context/userContext";
import { IStudentDiploma } from "utils/apiDataTypes/CourseModuleDataTypes";
import Loader from "../loader/Loader";
import { studentSingleDiplomaDataQuery, singleDiplomaDataQuery } from "query";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import classes from "./Breadcrumbs.module.scss";

// Dynamic breadcrumbs component displaying entity name by param id.
// Specific `Diploma` breadcrumb component to prevent issue with more than 1 params in url.
const DiplomaBreadcrumb = () => {
  const { userData } = useContext(userContext);
  const { is_student } = userData || {};
  let { id, studentId, diplomaId } = useParams();

  const fetchedDiplomaTemplateData = useGetFetchQuery({
    ...(is_student
      ? studentSingleDiplomaDataQuery(diplomaId)
      : singleDiplomaDataQuery(studentId, diplomaId))
  }) as IStudentDiploma;

  const { isLoading: diplomaTemplateIsLoading, error: diplomaTemplateError } =
    useQuery<boolean, Error>({
      ...(is_student
        ? studentSingleDiplomaDataQuery(diplomaId)
        : singleDiplomaDataQuery(studentId, diplomaId)),
      enabled: !!diplomaId && !fetchedDiplomaTemplateData
    });

  if (diplomaId && diplomaTemplateIsLoading) {
    return (
      <span>
        <Loader size="sm" />
      </span>
    );
  }

  if (diplomaId && diplomaTemplateError) {
    return <div>{diplomaTemplateError && diplomaTemplateError.message}</div>;
  }

  if (diplomaId && fetchedDiplomaTemplateData)
    return (
      <div className={classes["last"]}>
        {fetchedDiplomaTemplateData.diploma_name}
      </div>
    );

  return <div className={classes["last"]}>{id}</div>;
};

export default DiplomaBreadcrumb;
