import { useState, useEffect } from "react";
import Padlock from "assets/images/login-page/login-padlock.png";
import ThumbUp from "assets/images/login-page/thumb-up.png";
import Diploma from "assets/images/dashboard/no-templates.png";
import Books from "assets/images/dashboard/no-courses.png";
import Person from "assets/images/dashboard/no-roles.png";
import Logo from "assets/images/login-page/opus-logo.png";
import Button from "components/buttons/Button";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import classes from "./Carousel.module.scss";

const carouselObjects = [
  {
    img: Diploma,
    title: "Get your NFT Certificate!",
    subtitle:
      "Sign in to receive your NFT Certificate so you can share it with the world."
  },
  {
    img: Books,
    title: "Award students NFT Certificates!",
    subtitle:
      "School members can initiate and approve the creation of NFT Certificates for qualified students."
  },
  {
    img: ThumbUp,
    title: "Manage course rosters!",
    subtitle:
      "School members can upload the details of the participants for each course and track student attendance."
  },
  {
    img: Person,
    title: "Manage team roles!",
    subtitle:
      "Sign in as a school admin to assign roles to the other school members."
  },
  {
    img: Logo,
    title: "Become a part of Opus!",
    subtitle:
      "New schools can submit an application form to be accepted on the platform."
  },
  {
    img: Padlock,
    title: "Claim your NFTs!",
    subtitle:
      "If your school is part of the Opus platform, the School Admin that administers the account has sent you login credentials via email."
  }
];

const Carousel = () => {
  const [nav, setNav] = useState(0);

  const navClickHandler = (dir: "left" | "right") => {
    if (dir === "left") {
      if (nav === 0) {
        setNav(carouselObjects.length - 1);
      } else {
        setNav(nav - 1);
      }
    }
    if (dir === "right") {
      if (nav === carouselObjects.length - 1) {
        setNav(0);
      } else {
        setNav(nav + 1);
      }
    }
  };

  // Loop carousel on every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setNav((nav) => {
        if (nav === carouselObjects.length - 1) {
          return 0;
        }
        return nav + 1;
      });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [navClickHandler]);

  return (
    <>
      <div className={classes["info-container"]}>
        <img
          className={classes["padlock-img"]}
          src={carouselObjects[nav].img}
          width={256}
          height={256}
          alt="Carousel"
        />
        <div className={classes["u-text--center"]}>
          <h4
            className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-title"]}`}
          >
            {carouselObjects[nav].title}
          </h4>
          <p
            className={`${classes["u-text--content"]} ${classes["u-text--small"]} ${classes["u-subtitle"]} ${classes["m-0"]}`}
          >
            {carouselObjects[nav].subtitle}
          </p>
        </div>
        <div className={classes["c-slider-nav"]}>
          <Button
            onClick={() => navClickHandler("left")}
            size="medium"
            isIconBtn
            icon={ArrowIcon}
            iconRotate={180}
          />
          {carouselObjects.map((e, i) => {
            return (
              <div
                key={i}
                className={`${classes["c-dot"]} ${
                  nav === i ? classes["c-dot--active"] : ""
                }`}
                onClick={() => setNav(i)}
              />
            );
          })}
          <Button
            onClick={() => navClickHandler("right")}
            size="medium"
            isIconBtn
            icon={ArrowIcon}
          />
        </div>
      </div>
    </>
  );
};

export default Carousel;
