import { tableColumnsWidths } from "./utils/table-utils";
import RenderCell from "./RenderCell";
import { dateFormatDisplay } from "utils/staticOptions";
import moment from "moment";

export const CoursesCols = () => {
  return [
    {
      Header: "Course",
      accessor: "school_course_mobile",
      Cell: ({ row }) => (
        <RenderCell type="school_course_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "Course Name",
      accessor: "course",
      minWidth: tableColumnsWidths.diplomaNameMin,
      Cell: ({ row }) => (
        <RenderCell type="school_course" rowProps={row.original} />
      )
    },
    {
      Header: "Start/End Date",
      accessor: "dates",
      minWidth: tableColumnsWidths.diplomaNameMin,
      Cell: ({ row }) => {
        const { start_date, end_date } = row.original.course;
        let element = <div>Open Course</div>;
        if (!!start_date || !!end_date) {
          element = (
            <div>
              {`${moment(start_date).format(dateFormatDisplay).toString()} - 
          ${moment(end_date).format(dateFormatDisplay).toString()}`}
            </div>
          );
        }
        return element;
      }
    }
  ];
};
