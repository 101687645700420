import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./context/themeContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastProvider } from "./context/toastContext";
import { UserProvider } from "./context/userContext";
import { BrowserRouter } from "react-router-dom";
import { PopupProvider } from "./context/popupContext";
import { PermissionsProvider } from "context/permissionsContext";
import { Web3Provider } from "context/web3Context";
import { WindowProvider } from "context/windowsContext";
import { CookiesProvider } from "react-cookie";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  PLANET_ID,
  PLANET2_ID,
  polygonAmoy
} from "utils/constants";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { polygon } from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "@wagmi/core/connectors/walletConnect";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygonAmoy, polygon],
  [alchemyProvider({ apiKey: PLANET_ID }), publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "OpusNFT"
      }
    }),
    new WalletConnectConnector({
      chains: [polygon],
      options: {
        projectId: PLANET2_ID
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/login?auth0=true`
        }}
      >
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <PermissionsProvider>
              <WindowProvider>
                <ToastProvider>
                  <PopupProvider>
                    <WagmiConfig config={config}>
                      <Web3Provider>
                        <ThemeProvider>
                          <BrowserRouter>
                            <App />
                          </BrowserRouter>
                        </ThemeProvider>
                      </Web3Provider>
                    </WagmiConfig>
                  </PopupProvider>
                </ToastProvider>
              </WindowProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </PermissionsProvider>
          </UserProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
