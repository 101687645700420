import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./Timepicker.module.scss";
import InputText from "./InputText";
import Error from "./Error";

interface ITimepickerProps {
  onChange: any;
  isDisabled?: boolean;
  placeholder?: string;
  error?: string;
  name?: string;
  value?: any;
  filterPassedTime?: any;
}

const Timepicker = (props: ITimepickerProps) => {
  const {
    onChange,
    isDisabled,
    placeholder,
    error,
    name,
    value,
    filterPassedTime
  } = props;

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <>
      <InputText
        onClick={onClick}
        ref={ref}
        type={"timepicker"}
        onChange={onChange}
        isDisabled={isDisabled}
        value={value}
        placeholder={placeholder}
        error={error}
        name={name}
        readOnly
      />
      <Error text={error} />
    </>
  ));

  return (
    <div className={classes["c-timepicker"]}>
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        timeCaption="Time"
        dateFormat="HH:mm aa"
        timeFormat="HH:mm aa"
        filterTime={filterPassedTime}
        customInput={<CustomInput />}
      />
    </div>
  );
};

export default Timepicker;
