import React from "react";
import { ReactComponent as Check } from "assets/icons/check-circle.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/error-big.svg";
import TooltipComp from "components/tooltip/TooltipComp";
import { colors } from "../../styles";
import classes from "./Status.module.scss";
import { ATTENDANCE_STATUS } from "utils/apiDataTypes/CourseModuleDataTypes";

interface IStatus {
  status: ATTENDANCE_STATUS;
  isBold?: boolean;
  isIcon?: boolean;
  id?: string;
}

const iconMapping = {
  Verified: <Check fill={`rgb(${colors.success50})`} width={16} height={16} />,
  NotVerified: <ErrorIcon width={16} height={16} />
};

const textMapping = {
  0: "Not Verified",
  1: "School Verified",
  2: "Student Verified"
};

const Status = ({ status, isBold, isIcon, id }: IStatus) => {
  return (
    <TooltipComp text={isIcon ? textMapping[status] : ""} id={id || "tooltip"}>
      <span className={classes["status-wrapper"]}>
        {iconMapping[status === 0 ? "NotVerified" : "Verified"]}
        {!isIcon && (
          <span
            className={`${classes["u-text--primary"]} ${
              isBold ? classes["u-bold"] : ""
            }`}
          >
            {textMapping[status]}
          </span>
        )}
      </span>
    </TooltipComp>
  );
};

export default Status;
