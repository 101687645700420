/**
 * Prompts a user when they exit the page
 */

import React, { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import GenericPopup from "../components/popups/GenericPopup";
import Button from "../components/buttons/Button";
import { popupContext } from "../context/popupContext";

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(message: string, when = true, action: () => void) {
  const { setPopup, clearPopup } = useContext(popupContext);

  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = setPopup(
      <GenericPopup
        type="info"
        title="Unsaved changes"
        msg="It looks like you have been creating a course. If you leave before saving, your changes will be lost. Please choose an option."
        buttonName="Save draft course"
        buttonVariant="contrast"
        buttonAction={action}
        buttonMinWidth="md"
        bellowBtnComp={
          <Button variant="link" onClick={clearPopup}>
            Leave without save draft
          </Button>
        }
      />
    );
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
