import React, { useContext, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormData } from "hooks/useFormData";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { popupContext } from "context/popupContext";
import { ReactComponent as CheckIconCircle } from "assets/icons/check-circle.svg";
import { InputField } from "components/input/Input";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import { fetchApi } from "utils/requests";
import classes from "./EditAuthForm.module.scss";
import { meDataQuery } from "query";
import {
  IAuthInfo,
  IFetchedMeData
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { yupSchemas } from "utils/yupSchemas";
import { hasErrors } from "utils/form";

const validationSchema: Yup.SchemaOf<IAuthInfo> = Yup.object().shape({
  email: yupSchemas.email,
  password: yupSchemas.password,
  new_pass: yupSchemas.new_pass,
  confirm_new_pass: yupSchemas.confirm_new_pass
});

const defaultPasswordFormData: IAuthInfo = {
  email: "",
  password: "",
  new_pass: "",
  confirm_new_pass: ""
};

const EditAuthForm = () => {
  const fetchedMeData = useGetFetchQuery(meDataQuery()) as IFetchedMeData;

  const { formData, formErrors, setFormData, handleChange } = useFormData(
    defaultPasswordFormData,
    validationSchema
  );
  const { setPopup } = useContext(popupContext);

  // Populate form values from cache
  useEffect(() => {
    if (fetchedMeData)
      setFormData((prevState) => ({
        ...prevState,
        email: fetchedMeData.email
      }));
  }, [fetchedMeData, setFormData]);

  // Fetch auth info in case cache data is missing
  const { isLoading, error } = useQuery<boolean, Error>({
    ...meDataQuery(),
    enabled: !fetchedMeData
  });

  const { isLoading: isEditAuthLoading, mutate: handleEditAuth } = useMutation({
    mutationKey: ["edit-auth"],
    mutationFn: async (event: HTMLFormElement) => {
      event.preventDefault();
      return await fetchApi("account", "/user/changePass", {
        method: "POST",
        auth: true,
        data: {
          old_pass: formData.password,
          new_pass: formData.confirm_new_pass
        }
      });
    },
    onSuccess: () => {
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully changed your password!"
          redirectPath={fetchedMeData.role_flag === 4 ? "/school" : "/profile"}
        />
      );
      setFormData(defaultPasswordFormData);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <form className={classes["form-layout"]}>
      <InputField
        title="E-mail"
        name="email"
        placeholder="Enter Email"
        onChange={handleChange}
        value={formData.email}
        error={formErrors.email}
        isDisabled
      />
      <InputField
        title="Current Password"
        type="password"
        name="password"
        placeholder="Enter Current Password"
        onChange={handleChange}
        value={formData.password}
        error={formErrors.password}
      />
      <InputField
        title="New Password"
        type="password"
        name="new_pass"
        placeholder="Enter New Password"
        onChange={handleChange}
        value={formData.new_pass}
        error={formErrors.new_pass}
        showPasswordTooltip
      />
      <InputField
        title="Confirm New Password"
        type="password"
        name="confirm_new_pass"
        placeholder="Confirm New Password"
        onChange={handleChange}
        value={formData.confirm_new_pass}
        error={formErrors.confirm_new_pass}
        showPasswordTooltip
      />
      <Button
        type="submit"
        minWidth="md"
        icon={CheckIconCircle}
        iconPosition="right"
        isDisabled={
          hasErrors(formErrors) ||
          !formData.password.length ||
          !formData.new_pass.length ||
          !formData.confirm_new_pass.length
        }
        onClick={handleEditAuth}
        isFetching={isEditAuthLoading}
      >
        Save Changes
      </Button>
    </form>
  );
};
export default EditAuthForm;
