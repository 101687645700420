import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PageHeader, { ISortOption } from "components/page-header/PageHeader";
import StudentsTable from "./components/StudentsTable";
import { IStudentsData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { calcItemsAmount } from "components/table/utils/table-utils";
import classes from "./Students.module.scss";
import GenericPopup from "components/popups/GenericPopup";
import Button from "components/buttons/Button";
import { fetchApi } from "utils/requests";
import { popupContext } from "context/popupContext";
import PermissionsGate from "roles/PermissionsGate";
import { windowContext } from "context/windowsContext";
import { userContext } from "context/userContext";
import {
  adminStudentsDataQuery,
  adminStudentsBySchoolDataQuery,
  studentsDataQuery
} from "query";
import { sortDirEnum } from "utils/staticOptions";
import { IFilterOption } from "components/filter/Filter";

export const sortOptions: ISortOption[] = [
  {
    id: null,
    label: "None",
    value: "",
    dir: ""
  },
  {
    id: 1,
    label: "First Name",
    value: "first_name",
    dir: "asc"
  },
  {
    id: 2,
    label: "Email",
    value: "email",
    dir: "asc"
  },
  {
    id: 3,
    label: "Status",
    value: "active",
    dir: "asc"
  }
];

const filterOptions: IFilterOption[] = [
  { id: 0, label: "None", value: "" },
  { id: 1, label: "Active", value: "active" },
  { id: 2, label: "Disabled", value: "disabled" },
  { id: 3, label: "Pending", value: "pending" }
];

const filterValueMapping = {
  active: "registered=true&is_blacklisted=false",
  disabled: "is_blacklisted=true",
  pending: "registered=false&active=false"
};
interface IStudentsProps {
  perPage?: number;
  isPaginationHidden?: boolean;
  hasNoActions?: boolean;
  hasViewAll?: boolean;
  hideHeaderControls?: boolean;
  type: "students-admin" | "students";
}

const Students = ({
  perPage = 8,
  isPaginationHidden,
  hasNoActions,
  hasViewAll,
  hideHeaderControls,
  type
}: IStudentsProps) => {
  const { userData } = useContext(userContext);
  const { is_super_admin } = userData || {};

  let { schoolId } = useParams();
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    sortDir,
    filter
  } = queryString.parse(urlParams);

  const { windowSize } = useContext(windowContext);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const queryClient = useQueryClient();
  const { isMobile } = windowSize;

  const defaultSortFilter: ISortOption = sort
    ? ({
        ...sortOptions.find((o) => o.value === sort),
        dir: sortDir
      } as ISortOption)
    : null;

  const defaultFilterValue: IFilterOption = filter
    ? ({
        ...filterOptions.find((o) => o.value === filter)
      } as IFilterOption)
    : filterOptions[0];

  const [sortFilter, setSortFilter] = useState<ISortOption>(defaultSortFilter);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ""
  );
  const [filterValue, setFilterValue] =
    useState<IFilterOption>(defaultFilterValue);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [selected, setSelected] = useState<
    { index: number; id: string; is_blacklisted: boolean }[]
  >([]);

  const params = `?${
    sortFilter && sortFilter.value
      ? `&sort=${sortDirEnum[sortFilter.dir]}${sortFilter.value}`
      : ""
  }&limit=${perPage}&page=${page}${
    searchValue.length > 1
      ? `&search[first_name]=${searchValue}&search[last_name]=${searchValue}&search[email]=${searchValue}`
      : ""
  }${
    filterValue && filterValue.value
      ? `&${filterValueMapping[filterValue.value]}`
      : ""
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, IStudentsData>(
    is_super_admin
      ? schoolId
        ? adminStudentsBySchoolDataQuery(schoolId, params)
        : adminStudentsDataQuery(params)
      : studentsDataQuery(params)
  );

  const showConfirmPopup = (
    obj: { id: string; is_blacklisted: boolean }[],
    activate: boolean
  ) => {
    setPopup(
      <GenericPopup
        type="info"
        title={`${!activate ? "Deactivate" : "Activate"} student${
          obj.length > 1 ? "s" : ""
        }?`}
        msg={`Are you sure you  want to ${
          !activate ? "deactivate" : "activate"
        } ${obj.length > 1 ? "these students" : "this student"}?`}
        buttonName={`${!activate ? "Deactivate" : "Activate"} student${
          obj.length > 1 ? "s" : ""
        }`}
        buttonVariant="outline"
        buttonAction={() => changeStatusHandler(activate)}
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            onClick={() => clearPopup()}
            minWidth="md"
          >
            Cancel
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const { isLoading: mutateIsLoading, mutate: changeStatusHandler } =
    useMutation({
      mutationKey: ["edit-student-status"],
      mutationFn: async (activate: boolean) => {
        setPopupLoading(true);
        const ids = selected.map((item) => item.id);
        const response = await fetchApi(
          "courses",
          "/blacklisted-users/blacklist",
          {
            method: activate ? "DELETE" : "POST",
            auth: true,
            data: {
              id_list: ids
            }
          }
        );
        return response;
      },
      onSuccess: (response: { message: string }) => {
        queryClient.invalidateQueries({ queryKey: ["students-data"] });
        setPopupLoading(false);
        setPopup(<GenericPopup type="success" msg={response.message} />);
        setSelected([]);
      },
      onError: (err: Error) => {
        setPopupLoading(false);
        setPopup(<GenericPopup type="error" msg={err.message} />);
      }
    });

  const renderButtons = () => {
    const includesActives = selected.some(
      ({ is_blacklisted }) => !is_blacklisted
    ) && (
      <Button
        size="medium"
        variant="neutral"
        isFetching={mutateIsLoading}
        onClick={() => showConfirmPopup(selected, false)}
        isDanger
      >
        {isMobile ? "Deactivate" : "Deactivate Students"}
      </Button>
    );
    const includesInactives = selected.some(
      ({ is_blacklisted }) => is_blacklisted
    ) && (
      <Button
        size="medium"
        variant="neutral"
        isFetching={mutateIsLoading}
        onClick={() => showConfirmPopup(selected, true)}
      >
        {isMobile ? "Activate" : "Activate Students"}
      </Button>
    );

    return (
      <PermissionsGate permissions={["SCHOOL.UPDATE"]}>
        {includesActives}
        {includesInactives}
      </PermissionsGate>
    );
  };

  const { result, all_records, total_results } = data || {};
  const hasData = result && result.length > 0;

  return (
    <div className={classes["content-wrapper"]}>
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortFilter) ||
        (!hasData && filterValue) ||
        page > 1) && (
        <PageHeader
          hasData={true}
          title="Students"
          itemsAmountMsg={calcItemsAmount({
            page: page,
            perPage: perPage,
            result: result,
            total_results,
            all_records: all_records
          })}
          hasSearch
          searchValue={searchValue}
          passSearchValueUp={(value: string) => {
            setSearchValue(value);
          }}
          setPage={setPage}
          hasSort
          passSortValueUp={(value: ISortOption) => {
            setSortFilter(value);
          }}
          sortValue={sortFilter}
          setSortValue={setSortFilter}
          sortOptions={sortOptions}
          searchPlaceholder="Search by first and last name"
          hasFilter
          filterOptions={filterOptions}
          passFilterValueUp={setFilterValue}
          filterValue={filterValue}
          filterLabel="Registration Status"
          hideHeaderControls={hideHeaderControls}
        >
          {selected.length > 0 && <>{renderButtons()}</>}
        </PageHeader>
      )}
      <StudentsTable
        searchValue={searchValue}
        filterValue={filterValue}
        showConfirmPopup={showConfirmPopup}
        setSelected={setSelected}
        page={page}
        setPage={setPage}
        itemsAmountMsg={calcItemsAmount({
          type: "Students ",
          page: page,
          perPage: perPage,
          result: result,
          total_results: total_results,
          all_records: all_records
        })}
        data={{ data, isLoading, error }}
        isPaginationHidden={isPaginationHidden}
        hasNoActions={hasNoActions}
        hasViewAll={hasViewAll}
        schoolId={schoolId}
        type={type}
      />
    </div>
  );
};

export default Students;
