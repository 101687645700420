import React from "react";
import FormWrapper from "components/forms/form-wrapper/FormWrapper";
import EditAuthForm from "components/forms/auth/EditAuthForm";
import EditForm from "./components/EditForm";
import Divider from "components/divider/Divider";

const EditSchool = () => {
  return (
    <FormWrapper title="Edit School Info">
      <EditAuthForm />
      <Divider orientation="horizontal" />
      <EditForm />
    </FormWrapper>
  );
};
export default EditSchool;
