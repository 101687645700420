import React, { useEffect, useState } from "react";
import classes from "./StudentDiplomas.module.scss";
import { IStudentDiploma } from "utils/apiDataTypes/CourseModuleDataTypes";
import { IPFS_GATEWAY } from "utils/constants";

interface ISlideshow {
  diplomas: IStudentDiploma[];
}

const Slideshow = ({ diplomas }: ISlideshow) => {
  const [nav, setNav] = useState(0);

  const diplomasSlider = diplomas.map((d) => ({
    id: d._id,
    img: d.ipfs_diploma_id
  }));

  const currentSlide = diplomasSlider[nav];

  // Loop certificates on every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setNav((nav) => {
        if (nav === diplomasSlider.length - 1) {
          return 0;
        }
        return nav + 1;
      });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes["card-pic"]}>
      <img src={`${IPFS_GATEWAY}${currentSlide.img}`} alt="Certificate" />
    </div>
  );
};

export default Slideshow;
