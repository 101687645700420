import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as DisabledIcon } from "assets/icons/disabled.svg";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import classes from "./Avatar.module.scss";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";

interface IAvatar {
  className?: string;
  src?: string;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  type?: "user" | "school";
  isActive?: boolean;
  role_flag?: any;
}

const Avatar = (props: IAvatar) => {
  const { src, className, size, type, isActive, role_flag } = props;

  if (!src)
    return (
      <div
        className={`${classes["c-avatar"]} ${classes["c-avatar--default"]} ${
          classes[`c-avatar--${size}`]
        } ${className}`}
      >
        {(role_flag === ROLE_FLAG["STUDENT"] && !isActive) ||
        (role_flag !== ROLE_FLAG["STUDENT"] && !isActive) ? (
          <DisabledIcon />
        ) : (
          <UserIcon />
        )}
      </div>
    );
  return (
    <img
      src={`${FILES_PUBLIC_DOMAIN}${src}`}
      className={`${classes["c-avatar"]} ${classes[`c-avatar--${size}`]} ${
        !isActive ? classes["disabled"] : ""
      } ${className}`}
      alt={`${type} avatar`}
    />
  );
};

Avatar.defaultProps = {
  size: "md",
  type: "user",
  alt: ""
};

export default Avatar;
