import Table from "components/table/Table";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ITemplatesData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { DiplomaCols } from "components/table/DiplomaCols";
import { useContext, useEffect, useMemo } from "react";
import { calcItemsAmount } from "components/table/utils/table-utils";
import { popupContext } from "context/popupContext";
import GenericPopup from "components/popups/GenericPopup";
import Button from "components/buttons/Button";
import { fetchApi } from "utils/requests";
import { toastContext } from "context/toastContext";
import { ReactComponent as ScreenIcon } from "assets/icons/screen.svg";
import { windowContext } from "context/windowsContext";
import classes from "../../Diplomas.module.scss";
import NoResults from "components/no-results/NoResults";
import EmptyPage from "components/empty-page/EmptyPage";
import DiplomaIcon from "assets/images/dashboard/no-templates.png";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";

interface IDiplomasTable {
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (amount: number) => void;
  data: ITemplatesData;
  params: string;
  status: "loading" | "error" | "no-results" | "empty" | null;
  error: Error;
}

const DiplomasTable = ({
  page,
  setPage,
  perPage,
  setPerPage,
  data,
  params,
  status,
  error
}: IDiplomasTable) => {
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const queryClient = useQueryClient();
  const { windowSize } = useContext(windowContext);
  const { isMobile } = windowSize;
  const { setToast } = useContext(toastContext);
  const navigate = useNavigate();

  const activePageHandler = (page: number) => {
    setPage(page);
  };

  const { isLoading: mutateIsLoading, mutate: mutateHandler } = useMutation({
    mutationKey: ["duplicate-delete-diploma"],
    mutationFn: async (obj: { id: string; action: "delete" | "duplicate" }) => {
      setPopupLoading(true);
      const route = `/template/${obj.id}${
        obj.action === "duplicate" ? "/duplicate" : ""
      }`;
      const method = obj.action === "delete" ? "DELETE" : "POST";
      await fetchApi("courses", route, {
        method: method,
        auth: true
      });
      return obj;
    },
    onSuccess: (obj: { id: string; action: "delete" | "duplicate" }) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg={`The template was successfully ${obj.action}d!`}
          buttonName="Close"
          buttonAction={() => clearPopup()}
        />
      );
      queryClient.invalidateQueries({
        queryKey: ["nft-templates-data"]
      });
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          title="An error occurred!"
          msg={err.message}
          buttonName="Close"
          buttonAction={() => clearPopup()}
        />
      );
    }
  });

  const showChangeTemplatePopup = (
    id: string,
    action: "delete" | "duplicate"
  ) => {
    setPopup(
      <GenericPopup
        type="info"
        title={`${action === "duplicate" ? "Duplicate" : "Delete"} template?`}
        msg={`Are you sure you want to ${action} this NFT certificate template?`}
        buttonName="Confirm"
        buttonVariant="contrast"
        buttonAction={() => mutateHandler({ id, action })}
        bellowBtnComp={
          <Button variant="link" minWidth="md" onClick={() => clearPopup()}>
            Close
          </Button>
        }
        clearPopupOnBtnAction={false}
      />
    );
  };

  const diplomaCols = useMemo(
    () => DiplomaCols(showChangeTemplatePopup, isMobile),
    []
  );

  useEffect(() => {
    isMobile &&
      setToast({
        type: "custom",
        withTitle: true,
        title: "Use Opus NFT on Desktop",
        msg: "In order to create a NFT Certificate Template, you need to use the desktop version of Opus NFT.",
        isSelfClosing: false,
        icon: ScreenIcon
      });
  }, [isMobile]);

  const { result, total_results } = data || {};
  const hasData = result && result.length > 0;

  if (status === "loading") return <Loader size="lg" hasText />;

  if (status === "error")
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );

  return (
    <>
      {hasData && (
        <Table
          type="diplomas"
          tableData={data}
          columns={diplomaCols}
          hiddenColumns={isMobile ? ["template_name"] : ["mobile"]}
          activePage={page}
          perPage={perPage}
          passActivePageUp={activePageHandler}
          showIs="none"
          itemsAmountMsg={calcItemsAmount({
            type: "Templates ",
            page: page,
            perPage: perPage,
            result: data.result,
            total_results,
            all_records: data.all_records
          })}
        />
      )}
      {status === "no-results" && <NoResults />}
      {status === "empty" && (
        <EmptyPage
          title="No Templates found."
          message={
            !isMobile
              ? "Create your first NFT template that can be applied to different courses."
              : null
          }
          icon={DiplomaIcon}
          buttonName={!isMobile ? "Create Template" : null}
          onClick={() =>
            navigate("/diplomas/create", {
              state: { schoolHasTemplates: false }
            })
          }
        />
      )}
    </>
  );
};

export default DiplomasTable;
