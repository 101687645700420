import { IData } from "./CourseModuleDataTypes";

export interface IDecodedToken {
  email: string;
  iat: string;
  exp: number;
  id: string;
  role_access: string;
  is_student: boolean;
  role_flag: number;
}

export interface ICountry {
  _id: string;
  name: string;
  states: IState[];
}

export interface IState {
  _id: string;
  name: string;
  state_code: string;
  country: string;
}

export interface ICity {
  name: string;
}

export interface ISchoolInfo {
  _id: string;
  name: string;
  country: string;
  state: {
    name: string;
    code: string;
  };
  city: string;
  zip: string;
  addr1: string;
  addr2: string;
  phone: string;
  fax: string;
  website: string;
  description: string;
  CERP: string | { name?: string; value?: string };
  CERP_provider_number: string;
  PACE: string | { name?: string; value?: string };
  PACE_provider_number: string;
  pic: string;
  banner: string;
  tenant?: string;
  is_blacklisted?: boolean;
  activation_status: SCHOOL_STATUS;
  deactivate_note?: string;
  is_deactivated: boolean;
  reg_date?: number;
}

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IExtendedStateSelectOption extends ISelectOption {
  name: string;
  code: string;
}

export interface ISchoolInfoForm
  extends Omit<
    ISchoolInfo,
    | "country"
    | "city"
    | "state"
    | "_id"
    | "activation_status"
    | "deactivate_note"
    | "is_deactivated"
  > {
  country: ISelectOption;
  city: ISelectOption;
  state: IExtendedStateSelectOption;
}

export interface IProfile {
  title?: ISelectOption;
  first_name: string;
  last_name: string;
  wallet: string;
  pic: string;
}

export interface IMemberFormFields {
  role_type: string;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  pic: string;
}

export interface ITeamData extends IData {
  result: IFetchedMeData[];
}

export interface IAuthInfo {
  email: string;
  password: string;
  new_pass?: string;
  confirm_new_pass?: string;
}

export enum ROLE_FLAG {
  STUDENT = 1,
  MANAGER = 2,
  REGISTRAR = 3,
  ADMIN = 4,
  SUPER_ADMIN = 5
}

export interface IFetchedMeData {
  active: boolean;
  date_created?: number;
  email: string;
  title: string;
  first_name: string;
  last_name: string;
  is_admin: boolean;
  is_student: boolean;
  last_reset_email?: number;
  pic: string;
  wallet: string | null;
  _id: string;
  role_flag: ROLE_FLAG;
  role_id: string;
  role: {
    name: string;
    role_access: number;
    sub_roles: [];
    role_flag: ROLE_FLAG;
    school: string;
    _id: string;
  };
  school: ISchoolInfo;
  phone: string;
}

export interface IMemberData extends IFetchedMeData {
  registered: boolean;
  title: string;
}

export interface IMemberFormData
  extends Omit<IMemberFormFields, "role_type" | "title"> {
  role_type: ISelectOption;
  title: ISelectOption;
}

export interface ISchoolRegRequestsData extends IData {
  result: ISchoolRegRequests[];
}

export enum SCHOOL_STATUS {
  PENDING = 0,
  ACTIVATED = 1,
  DEACTIVATED = 2
}

export interface ISchoolRegRequests {
  _id: string;
  date_requested: number;
  requested_by: {
    _id: string;
    email: string;
    active: boolean;
    registered: boolean;
    first_name: string;
    last_name: string;
    wallet: string;
    school: string;
    role_id: string;
    role_flag: ROLE_FLAG;
  };
  school: ISchoolInfo;
  status: SCHOOL_STATUS;
}

export interface ISchoolsData extends IData {
  result: ISchoolInfo[];
}

export interface IPlatformReport {
  schools: number;
  courses: number;
  students: number;
  mintedDiplomas: number;
}

export interface ISchoolReport {
  courses: number;
  students: number;
  mintedDiplomas: number;
  pendingDiplomas: number;
}
