import React, { ReactNode } from "react";
import Label from "./Label";
import classes from "./InputGroup.module.scss";

interface IInputGroupProps {
  name: string;
  title?: string;
  isRequired?: boolean;
  subtitle?: string;
  actionBtn?: ReactNode;
  children: ReactNode;
}

export const InputGroup = (props: IInputGroupProps) => {
  const { name, title, isRequired, actionBtn, subtitle, children } = props;
  return (
    <div>
      {title && (
        <div className={classes["label-container"]}>
          <Label name={name} title={title} isRequired={isRequired} />
          {actionBtn}
        </div>
      )}
      {subtitle && <div className={classes["subtitle"]}>{subtitle}</div>}
      {children}
    </div>
  );
};
