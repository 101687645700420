import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { Portal } from "react-portal";
import { Column } from "react-table";
import { userContext } from "context/userContext";
import { tableColumnsWidths } from "./utils/table-utils";
import useComponentVisible from "hooks/useComponentVisible";
import { ReactComponent as Dots } from "assets/icons/menu-dots.svg";
import Button from "components/buttons/Button";
import PermissionsGate from "roles/PermissionsGate";
import RenderCell from "./RenderCell";
import classes from "./TableCols.module.scss";
import { IndeterminateCheckbox } from "./InterdeterminateCheckbox";
import { ROLE_FLAG } from "utils/apiDataTypes/AccountManagementDataTypes";

interface IStudentsCols {
  showConfirmPopup: (
    obj: { index: number; id: string; is_blacklisted: boolean }[],
    activate: boolean
  ) => void;
  hasNoActions: boolean;
  type: "students-admin" | "students";
  isNotManagerAndReg: boolean;
}

export const StudentsCols = (
  showConfirmPopup: IStudentsCols["showConfirmPopup"],
  hasNoActions: IStudentsCols["hasNoActions"],
  type: IStudentsCols["type"],
  isNotManagerAndReg: IStudentsCols["isNotManagerAndReg"]
) => {
  const cols: Column = [
    {
      Header: "Student",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="student_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "First/Last Name",
      accessor: "names",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => <RenderCell type="names" rowProps={row.original} />
    },
    {
      Header: "Email",
      accessor: "email",
      minWidth: tableColumnsWidths.emailMin,
      Cell: ({ row }) => <RenderCell type="email" rowProps={row.original} />
    },
    {
      Header: "Registration",
      accessor: "registered",
      maxWidth: tableColumnsWidths.regMax,
      minWidth: tableColumnsWidths.regMin,
      width: tableColumnsWidths.reg,
      Cell: ({ row }) => (
        <RenderCell type="registration" rowProps={row.original} />
      )
    }
  ];

  if (type === "students") {
    cols.push({
      Header: "School Status",
      accessor: "student_school_status",
      maxWidth: tableColumnsWidths.regMax,
      minWidth: tableColumnsWidths.regMin,
      width: tableColumnsWidths.reg,
      Cell: ({ row }) => (
        <RenderCell type="student_school_status" rowProps={row.original} />
      )
    });
  }

  if (!hasNoActions && isNotManagerAndReg) {
    // Add an element to the beginning of columns
    cols.unshift({
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.getToggleAllRowsSelectedProps()}
            />
          </PermissionsGate>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.row.getToggleRowSelectedProps()}
            />
          </PermissionsGate>
        );
      }
    });

    // Add an element to the end of columns
    cols.push({
      Header: "",
      accessor: " ",
      maxWidth: tableColumnsWidths.actionsMax,
      minWidth: tableColumnsWidths.actionsMin,
      width: tableColumnsWidths.actions,
      Cell: (tableProps) => {
        const { ref, isComponentVisible, setIsComponentVisible } =
          useComponentVisible(false);

        const { userData } = useContext(userContext);
        const { role_flag } = userData;
        const isAdmin = role_flag === 4;

        const dropdownItems = (rowProps) => {
          let items = [
            {
              link: (
                <Link
                  className={classes["c-menu-item"]}
                  to={`/students/${rowProps.id}`}
                  key={rowProps.id}
                >
                  View
                </Link>
              )
            }
          ];

          if (isAdmin)
            items.push({
              link: (
                <Link
                  key={rowProps.id}
                  className={classes["c-menu-item"]}
                  to={`/students/${rowProps.id}/edit`}
                >
                  Edit
                </Link>
              )
            });

          return items;
        };

        return (
          <div
            className={classes["actions-container"]}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              size="medium"
              variant="link"
              icon={Dots}
              isIconBtn
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            {isComponentVisible && (
              <Portal node={document && document.getElementById("table-body")}>
                <div
                  className={classes["c-menu-wrapper"]}
                  ref={ref}
                  style={{ top: `${tableProps.row.distance_from_top}px` }}
                >
                  <div className={classes["c-menu"]}>
                    {dropdownItems(tableProps.row.original).map((item, i) => (
                      <Fragment key={i}>{item.link}</Fragment>
                    ))}
                    {isAdmin && tableProps.row.original.registered && (
                      <div
                        key={tableProps.row.original.id}
                        className={`${classes["c-menu-item"]} ${
                          classes[
                            !tableProps.row.original.is_blacklisted
                              ? "c-menu-item--danger"
                              : ""
                          ]
                        }`}
                        onClick={() => {
                          tableProps.toggleAllRowsSelected(false);
                          tableProps.row.toggleRowSelected(true);
                          showConfirmPopup(
                            [
                              {
                                index: tableProps.row.id,
                                id: tableProps.row.original.id,
                                is_blacklisted:
                                  tableProps.row.original.is_blacklisted
                              }
                            ],
                            tableProps.row.original.is_blacklisted
                          );
                        }}
                      >
                        {tableProps.row.original.is_blacklisted
                          ? "Activate"
                          : "Deactivate"}
                      </div>
                    )}
                  </div>
                </div>
              </Portal>
            )}
          </div>
        );
      }
    });
  }

  return cols;
};
