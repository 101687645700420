import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { ReactComponent as CrossIcon } from "assets/icons/clear.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as Dots } from "assets/icons/menu-dots.svg";
import useComponentVisible from "hooks/useComponentVisible";
import classes from "components/table/TableCols.module.scss";
import { colors } from "styles";
import { windowContext } from "context/windowsContext";
import { popupContext } from "context/popupContext";
import RejectPopup from "./RejectPopup";
import RejectReasonPopup from "./RejectReasonPopup";
import { fetchApi } from "utils/requests";
import { SCHOOL_STATUS } from "utils/apiDataTypes/AccountManagementDataTypes";

interface ISchoolActionsProps {
  schoolId: string;
  requestId: string;
  activation_status: SCHOOL_STATUS;
  note: string;
  isDeactivated: boolean;
}

const statusText = [
  {
    text: "Not Resolved",
    colorType: "error",
    activation_status: SCHOOL_STATUS["PENDING"]
  },
  {
    text: "Active",
    colorType: "active",
    activation_status: SCHOOL_STATUS["ACTIVATED"]
  },
  {
    text: "Inactive",
    colorType: "error",
    activation_status: SCHOOL_STATUS["DEACTIVATED"]
  }
];

const SchoolActions = ({
  schoolId,
  requestId,
  activation_status,
  note,
  isDeactivated
}: ISchoolActionsProps) => {
  const { windowSize } = useContext(windowContext);
  const navigate = useNavigate();
  const { isMobile } = windowSize;
  const queryClient = useQueryClient();

  const isRequest = !!requestId;

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { setPopup, clearPopup } = useContext(popupContext);

  const {
    isLoading: approveRegistrationRequestIsLoading,
    mutate: approveRegistrationRequest
  } = useMutation({
    mutationKey: ["approve-registration-request"],
    mutationFn: async () => {
      const response = await fetchApi(
        "courses",
        "/admin/registration-request/approve",
        {
          method: "PUT",
          auth: true,
          data: {
            request_ids: [requestId]
          }
        }
      );
      return response;
    },
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: ["schools-data", { id: schoolId }]
      });
      navigate("/admin-registration-requests");
      setPopup(<GenericPopup type="success" msg={message} />);
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  const showApprovePopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Activate School?"
        msg="Are you sure that you want to activate this school?"
        buttonMinWidth="md"
        buttonName="Activate School"
        buttonVariant="contrast"
        buttonAction={() => approveRegistrationRequest()}
        bellowBtnComp={
          <Button
            variant="neutral"
            size="medium"
            minWidth="md"
            onClick={() => clearPopup()}
          >
            Cancel
          </Button>
        }
      />
    );
  };

  const {
    isLoading: changeStatusMutationIsLoading,
    mutate: changeStatusMutation
  } = useMutation({
    mutationKey: ["change-school-status"],
    mutationFn: async (action: "activate" | "deactivate") => {
      const resp = await fetchApi(
        "courses",
        `/admin/schools/${schoolId}/${action}`,
        {
          method: "PUT",
          auth: true
        }
      );
      return resp;
    },
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: ["schools-data", { id: schoolId }]
      });
      queryClient.invalidateQueries({
        queryKey: ["team-data", { id: schoolId, limit: "3", page: "1" }]
      });
      setPopup(<GenericPopup type="success" msg={message} />);
    },
    onError: (e: Error) => {
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  const changeStatusHandler = (action: "activate" | "deactivate") => {
    if (action === "deactivate") {
      return setPopup(<RejectPopup schoolId={schoolId} />);
    }
    setPopup(
      <GenericPopup
        type="loading"
        title={`${
          action === "activate" ? "Activating" : "Deactivating"
        } school`}
        msg="Please wait..."
      />
    );
    changeStatusMutation(action);
  };

  if (isRequest)
    return (
      <>
        <Button
          size={"medium"}
          variant="outline"
          minWidth={"sm"}
          iconRotate={180}
          onClick={() =>
            setPopup(<RejectPopup schoolId={schoolId} requestId={requestId} />)
          }
          icon={CrossIcon}
          isDanger
        >
          Reject
        </Button>
        <Button
          size={"medium"}
          minWidth={"sm"}
          icon={CheckCircle}
          onClick={showApprovePopup}
          isFetching={approveRegistrationRequestIsLoading}
          isSuccess
        >
          Approve
        </Button>
      </>
    );

  return (
    <div className={classes["actions-container"]}>
      {statusText.map((status, i) => {
        if (status.activation_status === activation_status)
          return (
            <span
              key={i}
              className={`${classes["u-text-small"]} ${
                classes[`u-text--${status.colorType}`]
              }`}
            >
              {status.text}
            </span>
          );
      })}
      <Button
        variant="link"
        size="medium"
        icon={Dots}
        isIconBtn={!isMobile}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {isMobile ? "Actions" : null}
      </Button>
      {/* TODO - mobile menu wrapper position */}
      {isComponentVisible && (
        <div className={classes["c-menu-wrapper"]} ref={ref}>
          <div className={classes["c-menu"]}>
            {activation_status === 0 && (
              <div
                className={`${classes["c-menu-item"]}`}
                onClick={() => {
                  navigate("/admin-registration-requests");
                  setIsComponentVisible(false);
                }}
              >
                <LinkIcon />
                Go to requests
              </div>
            )}

            {activation_status === 1 && (
              <div
                className={`${classes["c-menu-item"]}  ${classes["c-menu-item--danger"]}`}
                onClick={() => {
                  changeStatusHandler("deactivate");
                  setIsComponentVisible(false);
                }}
              >
                <CrossIcon fill={`rgb(${colors.error40})`} />
                Deactivate School
              </div>
            )}

            {activation_status === 2 && (
              <div
                className={`${classes["c-menu-item"]}`}
                onClick={() => {
                  changeStatusHandler("activate");
                  setIsComponentVisible(false);
                }}
              >
                <CheckCircle />
                Activate School
              </div>
            )}

            {activation_status === 2 && (
              <div
                className={`${classes["c-menu-item"]}`}
                onClick={() => {
                  setPopup(<RejectReasonPopup note={note} />);
                  setIsComponentVisible(false);
                }}
              >
                <CrossIcon />
                Show Reason
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SchoolActions;
