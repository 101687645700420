import React from "react";
import { useQuery } from "@tanstack/react-query";
import { adminPlatformStatsDataQuery } from "query";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import StatisticsList from "./StatisticsList";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as StudentsIcon } from "assets/icons/students.svg";
import { ReactComponent as DiplomaIcon } from "assets/icons/diploma.svg";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import { IPlatformReport } from "utils/apiDataTypes/AccountManagementDataTypes";

const PlatformStatistics = () => {
  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, IPlatformReport>(
    adminPlatformStatsDataQuery()
  );

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) {
    return <ErrorComponent error={error} />;
  }

  const { schools, courses, students, mintedDiplomas } = data;

  return (
    <StatisticsList
      items={[
        {
          id: 1,
          name: "Schools",
          number: schools,
          icon: SchoolIcon,
          link: "/admin-schools"
        },
        {
          id: 2,
          name: "All Courses",
          number: courses,
          icon: BookIcon
        },
        {
          id: 3,
          name: "All Students",
          number: students,
          icon: StudentsIcon,
          link: "/admin-students"
        },
        {
          id: 4,
          name: "Minted NFT Certificates",
          number: mintedDiplomas,
          icon: DiplomaIcon
        }
      ]}
    />
  );
};

export default PlatformStatistics;
