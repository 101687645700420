import React, { useContext } from "react";
import { themeContext } from "context/themeContext";
import { useNavigate } from "react-router-dom";
import logoLight from "assets/images/logo-light.svg";
import logoDark from "assets/images/logo-dark.svg";
import classes from "./Login.module.scss";
import Carousel from "./components/carousel/Carousel";
import ThemeSwitcher from "components/theme-switcher/ThemeSwitcher";
import Header from "components/header/Header";

interface ILogin {
  children: React.ReactNode;
}

const Login = (props: ILogin) => {
  const navigate = useNavigate();
  const { theme } = useContext(themeContext);

  return (
    <div className={classes["wrapper"]} data-theme={theme}>
      <div className={classes["grid-container"]}>
        <div
          className={`${classes["left-col"]} ${
            theme === "dark" ? classes["dark"] : classes["light"]
          }`}
        >
          <img
            className={classes["c-logo"]}
            src={theme === "dark" ? logoLight : logoDark}
            height={56}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <Carousel />
          <ThemeSwitcher />
        </div>
        <div className={classes["right-col"]}>
          <div className={classes["right-col-header"]}>
            <Header variant="only-logo" isFullWidth />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Login;
