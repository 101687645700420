import React, { FC } from "react";
import StatsCard from "./StatsCard";
import classes from "./StatisticsList.module.scss";

export interface IStatsItem {
  id: number;
  name: string;
  number: number;
  icon: any;
  link?: string;
}

interface IStatisticsListProps {
  items: IStatsItem[];
}

const StatisticsList = ({ items }: IStatisticsListProps) => {
  return (
    <>
      <div className={classes["cards-wrapper"]}>
        {items.map(({ id, name, number, icon, link }) => {
          return (
            <StatsCard
              key={id}
              id={id}
              name={name}
              number={number}
              icon={icon}
              link={link}
            />
          );
        })}
      </div>
    </>
  );
};

export default StatisticsList;
