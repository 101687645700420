import { statusIconMapping } from "components/icons/IconMappings";
import classes from "./Status.module.scss";

const textMappingAttendees = {
  0: "Registered",
  1: "Pending"
};

const textMappingRest = {
  0: "Active",
  1: "Disabled",
  2: "Pending"
};

interface IRegisteredStatus {
  status: { active: boolean; registered: boolean };
  isAttendees: boolean;
  isIconOnly?: boolean;
}

const RegisteredStatus = ({
  status,
  isAttendees,
  isIconOnly
}: IRegisteredStatus) => {
  const { registered, active } = status;

  let statusIs: string;

  if (isAttendees) {
    statusIs = registered ? textMappingAttendees[0] : textMappingAttendees[1];
  } else {
    statusIs =
      registered && active
        ? textMappingRest[0]
        : registered && !active
        ? textMappingRest[1]
        : textMappingRest[2];
  }

  return (
    <div className={classes["status-container"]}>
      <div className={classes["icon-and-text"]}>
        {!isAttendees && statusIconMapping[statusIs]}
        {!isIconOnly && statusIs}
      </div>
    </div>
  );
};

RegisteredStatus.defaultProps = {
  isAttendees: false
};

export default RegisteredStatus;
