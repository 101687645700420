import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { userContext } from "context/userContext";
import {
  ICourse,
  IStudent,
  ITemplate,
  IStudentDiploma
} from "utils/apiDataTypes/CourseModuleDataTypes";
import {
  IMemberData,
  ISchoolInfo
} from "utils/apiDataTypes/AccountManagementDataTypes";
import Loader from "../loader/Loader";
import {
  schoolMemberDataQuery,
  singleCourseDataQuery,
  singleSchoolDataQuery,
  adminSingleSchoolDataQuery,
  singleStudentCourseDataQuery,
  studentDataQuery,
  singleDiplomaTemplateQuery,
  studentSingleDiplomaDataQuery,
  singleDiplomaDataQuery,
  singleCourseDraftDataQuery,
  adminStudentDataQuery,
  adminSchoolMemberDataQuery,
  adminSingleCourseDataQuery
} from "query";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import classes from "./Breadcrumbs.module.scss";

// Dynamic breadcrumbs component displaying entity name by param id
const DynamicBreadcrumb = () => {
  const { userData } = useContext(userContext);
  const { is_student, is_super_admin } = userData || {};

  let { id, schoolId, courseId,
    courseDraftId, memberId, studentId,
    templateId,
    diplomaId
  } = useParams();

  const fetchedSchoolData = useGetFetchQuery(
    is_super_admin
      ? adminSingleSchoolDataQuery(schoolId)
      : singleSchoolDataQuery(schoolId)
  ) as ISchoolInfo;

  const { isLoading: schoolIsLoading, error: schoolError } = useQuery<
    boolean,
    Error
    >({
    ...(is_super_admin
      ? adminSingleSchoolDataQuery(schoolId)
      : singleSchoolDataQuery(schoolId)),
    enabled:
      !!schoolId && !!userData && (is_super_admin ? true : !fetchedSchoolData)
  });

  const {
    isLoading: courseIsLoading,
    error: courseError,
    data: fetchedCourseData
  } = useQuery<boolean, Error, any>({
    ...(is_student
      ? singleStudentCourseDataQuery(courseId)
      : is_super_admin
      ? adminSingleCourseDataQuery(courseId)
      : singleCourseDataQuery(courseId)),
    enabled: !!courseId && !!userData
  });

  const {
    isLoading: courseDraftIsLoading,
    error: courseDraftError,
    data: fetchedCourseDraftData
  } = useQuery<boolean, Error, ICourse>({
    ...singleCourseDraftDataQuery(courseDraftId),
    enabled: !!courseDraftId
  });

  const fetchedMemberData = useGetFetchQuery(
    is_super_admin
      ? adminSchoolMemberDataQuery(memberId)
      : schoolMemberDataQuery(memberId)
  ) as IMemberData;

  const { isLoading: memberIsLoading, error: memberError } = useQuery<
    boolean,
    Error
  >({
    ...(is_super_admin
      ? adminSchoolMemberDataQuery(memberId)
      : schoolMemberDataQuery(memberId)),
    enabled: !!memberId && !!userData && !fetchedMemberData
  });

  const fetchedStudentData = useGetFetchQuery(
    is_super_admin
      ? adminStudentDataQuery(studentId)
      : studentDataQuery(studentId)
  ) as IStudent;

  const { isLoading: studentIsLoading, error: studentError } = useQuery<
    boolean,
    Error
  >({
    ...(is_super_admin
      ? adminStudentDataQuery(studentId)
      : studentDataQuery(studentId)),
    enabled: !!studentId && !!userData && !fetchedStudentData
  });

  const fetchedTemplateData = useGetFetchQuery(
    singleDiplomaTemplateQuery(templateId)
  ) as ITemplate;

  const { isLoading: templateIsLoading, error: templateError } = useQuery<
    boolean,
    Error
  >({
    ...singleDiplomaTemplateQuery(templateId),
    enabled: !!templateId && !fetchedTemplateData
  });

  const fetchedDiplomaTemplateData = useGetFetchQuery({
    ...(is_student
      ? studentSingleDiplomaDataQuery(diplomaId)
      : singleDiplomaDataQuery(studentId, diplomaId))
  }) as IStudentDiploma;

  const { isLoading: diplomaTemplateIsLoading, error: diplomaTemplateError } =
    useQuery<boolean, Error>({
      ...(is_student
        ? studentSingleDiplomaDataQuery(diplomaId)
        : singleDiplomaDataQuery(studentId, diplomaId)),
      enabled: !!diplomaId && !fetchedDiplomaTemplateData && !!userData
    });

  if (
    (schoolId && schoolIsLoading) ||
    (courseId && courseIsLoading) ||
    (courseDraftId && courseDraftIsLoading) ||
    (memberId && memberIsLoading) ||
    (studentId && studentIsLoading) ||
    (templateId && templateIsLoading) ||
    (diplomaId && diplomaTemplateIsLoading)
  ) {
    return (
      <span>
        <Loader size="sm" />
      </span>
    );
  }

  if (
    (schoolId && schoolError) ||
    (courseId && courseError) ||
    (courseDraftId && courseDraftError) ||
    (memberId && memberError) ||
    (studentId && studentError) ||
    (templateId && templateError) ||
    (diplomaId && diplomaTemplateError)
  ) {
    return (
      <div>
        {(schoolError && schoolError.message) ||
          (courseError && courseError.message) ||
          (courseDraftError && courseDraftError.message) ||
          (memberError && memberError.message) ||
          (studentError && studentError.message) ||
          (templateError && templateError.message) ||
          (diplomaTemplateError && diplomaTemplateError.message)}
      </div>
    );
  }

  if (schoolId && fetchedSchoolData)
    return <div className={classes["last"]}>{fetchedSchoolData.name}</div>;

  if (courseId && fetchedCourseData) {
    const course: ICourse = is_student
      ? fetchedCourseData.course
      : fetchedCourseData;
    return (
      <div className={classes["last"]}>
        {is_student ? course.name : course.name}
      </div>
    );
  }

  if (courseDraftId && fetchedCourseDraftData) {
    const courseName: string = fetchedCourseDraftData.name || "(No Name)";
    return <div className={classes["last"]}>{courseName}</div>;
  }

  if (memberId && fetchedMemberData)
    return (
      <div className={classes["last"]}>
        {fetchedMemberData.first_name + " " + fetchedMemberData.last_name}
      </div>
    );

  if (studentId && fetchedStudentData) {
    if (fetchedStudentData.first_name && fetchedStudentData.last_name)
      return (
        <div className={classes["last"]}>
          {fetchedStudentData.first_name + " " + fetchedStudentData.last_name}
        </div>
      );
    return <div className={classes["last"]}>{fetchedStudentData.email}</div>;
  }

  if (templateId && fetchedTemplateData)
    return <div className={classes["last"]}>{fetchedTemplateData.name}</div>;

  if (diplomaId && fetchedDiplomaTemplateData)
    return (
      <div className={classes["last"]}>
        {fetchedDiplomaTemplateData.diploma_name}
      </div>
    );

  return <div className={classes["last"]}>{id}</div>;
};

export default DynamicBreadcrumb;
