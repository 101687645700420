import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import classes from "./Banner.module.scss";
import React from "react";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";

interface IBanner {
  src?: string;
}

const Banner = (props: IBanner) => {
  const { src } = props;

  if (!src)
    return (
      <div className={`${classes["cover"]} ${classes["cover-empty"]}`}>
        <SchoolIcon />
      </div>
    );
  return (
    <div
      className={classes["cover"]}
      style={{
        background: `url(${FILES_PUBLIC_DOMAIN}${src}) no-repeat center`,
        backgroundSize: "cover"
      }}
    />
  );
};

export default Banner;
