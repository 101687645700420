import React, { FormEvent, ReactNode } from "react";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { InputField } from "components/input/Input";
import { colors } from "styles";
import classes from "./ImageInput.module.scss";
import Divider from "../divider/Divider";
import Error from "components/input/Error";

interface IImageInputProps {
  title?: string;
  subtitle?: string;
  pic: string;
  onChange: (e: FormEvent) => void;
  onClear: () => void;
  isFullWidth?: boolean;
  isSquare?: boolean;
  instructions?: ReactNode;
  fileType?: "image/png, image/jpeg, image/jpg" | "image/png";
  hasRightAlignedTitles?: boolean;
  isSignature?: boolean;
  error?: string;
}

const ImageInput = (props: IImageInputProps) => {
  const {
    title,
    subtitle,
    pic,
    onChange,
    onClear,
    isFullWidth,
    isSquare,
    instructions,
    fileType,
    hasRightAlignedTitles,
    isSignature,
    error
  } = props;
  return (
    <div
      className={`${classes["main-container"]} ${
        hasRightAlignedTitles ? classes["main-container--flex-row"] : ""
      }`}
    >
      {!hasRightAlignedTitles && title && (
        <div className={classes["title"]}>{title}</div>
      )}
      {!hasRightAlignedTitles && subtitle && (
        <div className={classes["subtitle"]}>{subtitle}</div>
      )}
      <div
        className={`${classes["input-container"]} ${
          isFullWidth ? classes["full-width"] : ""
        } ${isSquare ? classes["square"] : ""}`}
      >
        <label
          className={`${classes["custom-file-upload"]} ${
            isFullWidth ? classes["full-width"] : ""
          } ${!pic ? classes["custom-file-upload--empty"] : ""}`}
        >
          {!pic ? (
            <div className={classes["instructions"]}>
              <PlusIcon
                className={classes["img"]}
                fill={`rgb(${colors.greyishBlue30})`}
              />
              {instructions}
            </div>
          ) : (
            <div
              className={`${classes["actions"]} ${
                isFullWidth ? classes["actions-full-width"] : ""
              }`}
            >
              <EditIcon className={classes["img"]} />
              <Divider orientation="vertical" />
              <TrashIcon
                onClick={(e) => {
                  e.preventDefault();
                  onClear();
                }}
                className={classes["img"]}
                width={20}
                height={20}
              />
            </div>
          )}
          <InputField
            type="file"
            fileType={fileType}
            isHidden
            onChange={onChange}
          />
        </label>
        {pic && (
          <div
            className={`${classes["image-comp"]} ${
              isFullWidth ? classes["full-width"] : ""
            } ${isSignature ? classes["signature"] : ""}`}
            style={{
              backgroundImage: `url(${pic})`
            }}
          />
        )}
      </div>
      {hasRightAlignedTitles && (
        <div className={classes["d-flex"]}>
          {title && <div className={classes["title"]}>{title}</div>}
          {subtitle && <div className={classes["subtitle"]}>{subtitle}</div>}
        </div>
      )}
      <Error text={error} />
    </div>
  );
};

export default ImageInput;
