import queryString from "query-string";

export const formatAddress = (addr: string, start?: number, end?: number) =>
  `${addr.slice(0, start || 5)}...${addr.slice(end || -4)}`;

export const shortenUrl = (url: string, symbols?: number) =>
  `${url.slice(0, symbols || 5)}...`;

export const formatName = (name: string) => {
  if (name.length >= 10) {
    return name.charAt(0) + ".";
  }
  return name;
};

export const formatNumber = (number: number) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const getFileNameFromUrl = (url: string) => {
  const splitedUrl = url.split("__");
  const splitedName = splitedUrl[1].split("?");
  return splitedName[0];
};

export const getFileName = (str: string) => {
  const splitedUrl = str.split(".");
  return splitedUrl[0];
};

export const removeSpaces = (str: string) => {
  const newString = str?.replace(/\s+/g, "-");
  return newString;
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const rgbToHex = (rgb: string) => {
  const [r, g, b] = rgb.split(",");

  const red = parseInt(r);
  const green = parseInt(g);
  const blue = parseInt(b);

  // Use bit shifting and hexadecimal formatting to combine the values into a single hex value
  const hex = ((red << 16) | (green << 8) | blue).toString(16).padStart(6, "0");

  return "#" + hex;
};

export const formatSchoolAddress = (
  addr1,
  addr2,
  city,
  zip,
  state,
  country
) => {
  const schoolAddress: string[] = [
    addr1,
    addr2,
    city,
    `${state} ${zip || ""}`,
    country
  ];

  return schoolAddress
    .filter(Boolean)
    .map((el) => el.trim())
    .join(", ");
};

export const formatCourseLocation = (city, state) => {
  const schoolAddress: string[] = [city, state];

  return schoolAddress
    .filter(Boolean)
    .map((el) => el.trim())
    .join(", ");
};

export const trimInput = (input: string, type?: "no-spaces") => {
  // Remove spaces before the first word
  input = input.replace(/^\s+/, "");

  switch (type) {
    case "no-spaces":
      input = input.replace(/\s{1,}$/, "");
      break;

    default:
      input = input.replace(/\s{2,}$/, " ");
      break;
  }

  return input;
};

export const navigateWithParams = (
  urlParams,
  newParams,
  navigate,
  pathname
) => {
  const parsed = queryString.parse(urlParams);

  navigate({
    pathname,
    search: `?${queryString.stringify(
      {
        ...parsed,
        ...newParams
      },
      { skipEmptyString: true, sort: false }
    )}`
  });
};
