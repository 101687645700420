import React, { useContext } from "react";
import { tableColumnsWidths } from "./utils/table-utils";
import Button from "components/buttons/Button";
import RenderCell from "./RenderCell";
import classes from "./TableCols.module.scss";
import moment from "moment";
import { dateFormatDisplay } from "utils/staticOptions";
import useComponentVisible from "hooks/useComponentVisible";
import { ReactComponent as Dots } from "assets/icons/menu-dots.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle.svg";
import { ReactComponent as CrossIcon } from "assets/icons/clear.svg";
import { colors } from "styles";
import { Portal } from "react-portal";
import { Link } from "react-router-dom";
import { popupContext } from "context/popupContext";
import RejectReasonPopup from "routes/dashboard/school/components/RejectReasonPopup";

interface ISchoolsCols {
  isMobile: boolean;
  changeSchoolStatus: (obj: {
    action: "activate" | "deactivate";
    id: string;
  }) => void;
}

export const SchoolsCols = (
  isMobile: ISchoolsCols["isMobile"],
  changeSchoolStatus: ISchoolsCols["changeSchoolStatus"]
) => {
  return [
    {
      Header: "School",
      accessor: "schools-mobile",
      Cell: ({ row }) => (
        <RenderCell type="schools_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "School Name",
      accessor: "school-name",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => (
        <RenderCell type="school-name" rowProps={row.original} />
      )
    },
    {
      Header: "Registration Date",
      accessor: "reg_date",
      maxWidth: tableColumnsWidths.dateMax,
      minWidth: tableColumnsWidths.dateMin,
      width: tableColumnsWidths.date,
      Cell: ({ row }) => {
        const date = moment(row.original.reg_date)
          .format(dateFormatDisplay)
          .toString();
        return <div>{date}</div>;
      }
    },
    {
      Header: "Status",
      accessor: "school_status",
      maxWidth: tableColumnsWidths.statusMax,
      minWidth: tableColumnsWidths.statusMin,
      width: tableColumnsWidths.status,
      Cell: ({ row }) => (
        <RenderCell type="school_status" rowProps={row.original} />
      )
    },
    // {
    //   Header: "Payment Due",
    //   accessor: "payment_due",
    //   maxWidth: tableColumnsWidths.dateMax,
    //   minWidth: tableColumnsWidths.dateMin,
    //   width: tableColumnsWidths.date,
    //   Cell: ({ row }) => {
    //     const date = moment(row.original.date_requested)
    //       .format(dateFormatDisplay)
    //       .toString();
    //     return <div>{date}</div>;
    //   }
    // },
    {
      Header: "",
      accessor: "action",
      maxWidth: !isMobile && tableColumnsWidths.actionsXlMax,
      minWidth: !isMobile && tableColumnsWidths.actionsXlMin,
      width: !isMobile && tableColumnsWidths.actionsXl,
      Cell: (tableProps) => {
        const { ref, isComponentVisible, setIsComponentVisible } =
          useComponentVisible(false);

        const { setPopup } = useContext(popupContext);

        const { row } = tableProps;
        return (
          <div
            className={classes["actions-container"]}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              variant="link"
              size="medium"
              icon={Dots}
              isIconBtn
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            {isComponentVisible && (
              <Portal node={document && document.getElementById("table-body")}>
                <div
                  className={classes["c-menu-wrapper"]}
                  ref={ref}
                  style={{ top: `${tableProps.row.distance_from_top}px` }}
                >
                  <div className={classes["c-menu"]}>
                    <Link
                      className={` ${classes["c-menu-item"]} ${classes["m-0"]} `}
                      to={`/admin-schools/${tableProps.row.original._id}`}
                      onClick={() => {
                        setIsComponentVisible(false);
                      }}
                    >
                      View School
                    </Link>
                    {row.original.activation_status === 2 && (
                      <>
                        <div
                          className={`${classes["c-menu-item"]}`}
                          onClick={() => {
                            changeSchoolStatus({
                              action: "activate",
                              id: row.original._id
                            });
                            setIsComponentVisible(false);
                          }}
                        >
                          <CheckCircle />
                          Activate School
                        </div>
                        <div
                          className={`${classes["c-menu-item"]}`}
                          onClick={() => {
                            setPopup(
                              <RejectReasonPopup
                                note={row.original.deactivate_note}
                              />
                            );
                            setIsComponentVisible(false);
                          }}
                        >
                          <CrossIcon fill={`rgb(${colors.error40})`} />
                          Show Reason
                        </div>
                      </>
                    )}
                    {row.original.activation_status === 1 && (
                      <div
                        className={`${classes["c-menu-item"]}  ${classes["c-menu-item--danger"]}`}
                        onClick={() => {
                          changeSchoolStatus({
                            action: "deactivate",
                            id: row.original._id
                          });
                          setIsComponentVisible(false);
                        }}
                      >
                        <CrossIcon fill={`rgb(${colors.error40})`} />
                        Deactivate School
                      </div>
                    )}
                  </div>
                </div>
              </Portal>
            )}
          </div>
        );
      }
    }
  ];
};
