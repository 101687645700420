import React from "react";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./Label.module.scss";
import TooltipComp from "../tooltip/TooltipComp";

interface ILabelProps {
  name: string;
  title: string;
  isRequired?: boolean;
  showTooltipIcon?: string;
  tooltipContent?: React.ReactNode;
  subtitle?: string;
  hasNoMargin?: boolean;
}

const Label = (props: ILabelProps) => {
  const {
    name,
    title,
    isRequired,
    showTooltipIcon,
    tooltipContent,
    subtitle,
    hasNoMargin
  } = props;

  if (!title) return null;

  return (
    <div
      className={`${classes["label-wrapper"]} ${
        hasNoMargin ? classes["label-wrapper--no-margin"] : ""
      }`}
    >
      <div className={classes["title-container"]}>
        <label>{`${title}${isRequired ? "*" : ""}`}&nbsp;</label>
        {showTooltipIcon && (
          <TooltipComp
            id={`tooltip-${name}`}
            text={showTooltipIcon}
            tooltipContent={tooltipContent}
          >
            <InfoIcon className={classes["info-icon"]} width={16} height={16} />
          </TooltipComp>
        )}
      </div>
      {subtitle && <div className={classes["subtitle"]}>{subtitle}</div>}
    </div>
  );
};

export default Label;
