import FeatureEffortless from "assets/landing/feature-effortless.svg";
import FeaturePower from "assets/landing/feature-power.svg";
import FeatureTransformation from "assets/landing/feature-transformation.svg";
import FeaturePaperless from "assets/landing/feature-paperless.svg";
import FeatureSecurity from "assets/landing/feature-security.svg";
import FeatureVerification from "assets/landing/feature-verification.svg";
import Onboarding_School_Admin_1 from "assets/landing/onboarding/Onboarding_School_Admin_1.svg";
import Onboarding_School_Admin_2 from "assets/landing/onboarding/Onboarding_School_Admin_2.svg";
import Onboarding_School_Admin_3 from "assets/landing/onboarding/Onboarding_School_Admin_3.svg";
import Onboarding_School_Admin_4 from "assets/landing/onboarding/Onboarding_School_Admin_4.svg";
import Onboarding_School_Admin_5 from "assets/landing/onboarding/Onboarding_School_Admin_5.svg";
import Onboarding_School_Admin_1_dark from "assets/landing/onboarding/Onboarding_School_Admin_1_dark.svg";
import Onboarding_School_Admin_2_dark from "assets/landing/onboarding/Onboarding_School_Admin_2_dark.svg";
import Onboarding_School_Admin_3_dark from "assets/landing/onboarding/Onboarding_School_Admin_3_dark.svg";
import Onboarding_School_Admin_4_dark from "assets/landing/onboarding/Onboarding_School_Admin_4_dark.svg";
import Onboarding_School_Admin_5_dark from "assets/landing/onboarding/Onboarding_School_Admin_5_dark.svg";
import Onboarding_School_Team_1 from "assets/landing/onboarding/Onboarding_School_Team_1.svg";
import Onboarding_School_Team_2 from "assets/landing/onboarding/Onboarding_School_Team_2.svg";
import Onboarding_School_Team_3 from "assets/landing/onboarding/Onboarding_School_Team_3.svg";
import Onboarding_School_Team_4 from "assets/landing/onboarding/Onboarding_School_Team_4.svg";
import Onboarding_School_Team_5 from "assets/landing/onboarding/Onboarding_School_Team_5.svg";
import Onboarding_School_Team_1_dark from "assets/landing/onboarding/Onboarding_School_Team_1_dark.svg";
import Onboarding_School_Team_2_dark from "assets/landing/onboarding/Onboarding_School_Team_2_dark.svg";
import Onboarding_School_Team_3_dark from "assets/landing/onboarding/Onboarding_School_Team_3_dark.svg";
import Onboarding_School_Team_4_dark from "assets/landing/onboarding/Onboarding_School_Team_4_dark.svg";
import Onboarding_School_Team_5_dark from "assets/landing/onboarding/Onboarding_School_Team_5_dark.svg";
import Onboarding_Student_1 from "assets/landing/onboarding/Onboarding_Student_1.svg";
import Onboarding_Student_2 from "assets/landing/onboarding/Onboarding_Student_2.svg";
import Onboarding_Student_3 from "assets/landing/onboarding/Onboarding_Student_3.svg";
import Onboarding_Student_4 from "assets/landing/onboarding/Onboarding_Student_4.svg";
import Onboarding_Student_5 from "assets/landing/onboarding/Onboarding_Student_5.svg";
import Onboarding_Student_1_dark from "assets/landing/onboarding/Onboarding_Student_1_dark.svg";
import Onboarding_Student_2_dark from "assets/landing/onboarding/Onboarding_Student_2_dark.svg";
import Onboarding_Student_3_dark from "assets/landing/onboarding/Onboarding_Student_3_dark.svg";
import Onboarding_Student_4_dark from "assets/landing/onboarding/Onboarding_Student_4_dark.svg";
import Onboarding_Student_5_dark from "assets/landing/onboarding/Onboarding_Student_5_dark.svg";

export const featuresLeft = [
  {
    icon: FeatureEffortless,
    title: "Effortless",
    desc: "Issue and verify NFT certificates with our web3 platform"
  },
  {
    icon: FeaturePower,
    title: "The power of web3",
    desc: "Experience the power of blockchain-based certification with our intuitive application"
  },
  {
    icon: FeatureTransformation,
    title: "Digital Transformation",
    desc: "Set the standard for your industry and lead them into the Web 3 future."
  }
];

export const featuresRight = [
  {
    icon: FeaturePaperless,
    title: "Paperless",
    desc: "Say goodbye to paper-based certificates and hello to secure, immutable digital records"
  },
  {
    icon: FeatureSecurity,
    title: "Increased Security Measures",
    desc: "Experience the power of blockchain-based certification with our intuitive application"
  },
  {
    icon: FeatureVerification,
    title: "Quick third party verification",
    desc: "Using DLT third parties can verify credentials by reference the blockchain directly... no more between parties."
  }
];

export const faqs = [
  {
    q: "What is an NFT?",
    a: "NFT stands for Non-Fungible Token. NFTs are a unique digital identifier that is recorded on a blockchain, and is used to certify ownership and authenticity. It cannot be copied, substituted, or subdivided. The ownership of an NFT is recorded in the blockchain and can be transferred by the owner, allowing NFTs to be sold and traded."
  },
  {
    q: "Can I connect any digital wallet to my account?",
    a: "Yes, you can connect any digital wallet to your Opus account."
  },
  {
    q: "How do I use Opus to verify my attendance?",
    a: "A QR code will be passed around to each participant. Once scanned, your attendance will be linked to the course in question. If you have not registered on Opus, you will be prompted via email as well as QR code during the course to sign up."
  },
  {
    q: "What happens if I don't create an account right away to claim my NFTs?",
    a: "Your NFTs will be available to you to claim later; you will not lose them."
  },
  {
    q: "How does my NFT get minted?",
    a: "After you complete your course, a Course Manager will upload the attendance roster from the course. From there, the school Registrar will verify attendance, and upon approval, the NFT for each student is minuted. The NFT will be automatically distributed to students or stored for later redemption."
  },
  {
    q: "Can others see my credentials?",
    a: "Yes, regulators and other third parties may request verification of credentials. When a request is made, the user will be able to grant access to that third party for a certain duration of time. If the request is approved by the user, the third party will receive an email taking them to the verified NFT on the blockchain."
  }
];

export const schoolAdminOnboardingItems = [
  {
    q: "Go to website",
    a: "Unlock the full potential with OpusNFT, the ultimate online NFT issuing platform."
  },
  {
    q: "Apply for Registration",
    a: "Becoming a registered school is quick and easy. Just click on apply here link."
  },
  {
    q: "Add Personal Information  and School Details",
    a: "Your personal information is securely stored, and you're all set to enjoy a more personalized and rewarding experience with us."
  },
  {
    q: "Review Details and Confirm",
    a: 'Once you\'ve reviewed and updated your information, click the "Send Application" button to apply for registration.'
  },
  {
    q: "Add and Manage Team Members",
    a: "Take control of your leadership journey with our intuitive tools and unlock the full potential of your team."
  }
];

export const schoolAdminOnboardingImagesLight = [
  Onboarding_School_Admin_1,
  Onboarding_School_Admin_2,
  Onboarding_School_Admin_3,
  Onboarding_School_Admin_4,
  Onboarding_School_Admin_5
];

export const schoolAdminOnboardingImagesDark = [
  Onboarding_School_Admin_1_dark,
  Onboarding_School_Admin_2_dark,
  Onboarding_School_Admin_3_dark,
  Onboarding_School_Admin_4_dark,
  Onboarding_School_Admin_5_dark
];

export const schoolTeamOnboardingItems = [
  {
    q: "Connect with School Admin",
    a: "Unlock the full potential with OpusNFT, the ultimate online NFT issuing platform."
  },
  {
    q: "School Admin adds you as a member",
    a: "In order to proceed with the activation of the Team, the School Admin should create a School Member profile."
  },
  {
    q: "Check your email",
    a: "Once the School Admin creates the profile, the School Member should check his/her email to confirm the profile."
  },
  {
    q: "Finish Registration",
    a: "In order to use the platform the School Member, should activate his/her profile by finishing the registration process."
  },
  {
    q: "All-setup",
    a: "Now the School Member may edit NFT certificates, add attendees to a course and send minting requests for approval."
  }
];

export const schoolTeamOnboardingImagesLight = [
  Onboarding_School_Team_1,
  Onboarding_School_Team_2,
  Onboarding_School_Team_3,
  Onboarding_School_Team_4,
  Onboarding_School_Team_5
];

export const schoolTeamOnboardingImagesDark = [
  Onboarding_School_Team_1_dark,
  Onboarding_School_Team_2_dark,
  Onboarding_School_Team_3_dark,
  Onboarding_School_Team_4_dark,
  Onboarding_School_Team_5_dark
];

export const studentOnboardingItems = [
  {
    q: "Go to website",
    a: "Unlock your full potential with OpusNFT, the online learning platform."
  },
  {
    q: "Apply for Registration",
    a: 'Visit the platform\'s website and look for a "Sign Up today"  button. Click on it to begin the registration process.'
  },
  {
    q: "Add Personal Information  and School Details",
    a: "Your personal information is securely stored, and you're all set to enjoy a more personalized and rewarding experience with us."
  },
  {
    q: "Review Details and Confirm",
    a: "Once you've completed all the necessary steps, you'll need to confirm that your data is correct."
  },
  {
    q: "Go to Courses",
    a: "Congratulations! You are now a member of the platform. You can log in with your credentials and start exploring the platform."
  }
];

export const studentOnboardingImagesLight = [
  Onboarding_Student_1,
  Onboarding_Student_2,
  Onboarding_Student_3,
  Onboarding_Student_4,
  Onboarding_Student_5
];

export const studentOnboardingImagesDark = [
  Onboarding_Student_1_dark,
  Onboarding_Student_2_dark,
  Onboarding_Student_3_dark,
  Onboarding_Student_4_dark,
  Onboarding_Student_5_dark
];
