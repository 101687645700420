import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loader from "../loader/Loader";
import { adminSingleCourseDataQuery } from "query";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { ICourse } from "utils/apiDataTypes/CourseModuleDataTypes";
import classes from "./Breadcrumbs.module.scss";

// Dynamic breadcrumbs component displaying entity name by param id.
// Specific `Course` breadcrumb component to prevent issue with more than 1 params in url.
//
// Currently used only on admin route. Please have in mind to update query in any other case.
const CourseBreadcrumb = () => {
  let { id, courseId } = useParams();

  const fetchedCourseData = useGetFetchQuery(
    adminSingleCourseDataQuery(courseId)
  ) as ICourse;

  const { isLoading: courseIsLoading, error: courseError } = useQuery<
    boolean,
    Error
  >({
    ...adminSingleCourseDataQuery(courseId),
    enabled: !!courseId && !fetchedCourseData
  });

  if (courseId && courseIsLoading) {
    return (
      <span>
        <Loader size="sm" />
      </span>
    );
  }

  if (courseId && courseError) {
    return <div>{courseError && courseError.message}</div>;
  }

  if (courseId && fetchedCourseData)
    return <div className={classes["last"]}>{fetchedCourseData.name}</div>;

  return <div className={classes["last"]}>{id}</div>;
};

export default CourseBreadcrumb;
