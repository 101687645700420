import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { userContext } from "context/userContext";
import { popupContext } from "context/popupContext";
import { themeContext } from "context/themeContext";
import { ReactComponent as DiplomaIcon } from "assets/icons/diploma.svg";
import { ReactComponent as PrintIcon } from "assets/icons/print.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeHiddenIcon } from "assets/icons/eye-hidden.svg";
import { ReactComponent as ReturnArrow } from "assets/icons/arrow-return.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import { BLOCK_EXPLORER_URL, IPFS_GATEWAY } from "utils/constants";
import classes from "./Single.module.scss";
import {
  singleDiplomaDataPublicQuery,
  singleDiplomaDataQuery,
  studentSingleDiplomaDataQuery
} from "query";
import { IStudentDiploma } from "utils/apiDataTypes/CourseModuleDataTypes";
import moment from "moment";
import {
  formatAddress,
  formatCourseLocation,
  formatSchoolAddress
} from "utils/format";
import { renderAGD, renderInstructors } from "components/utils/renderComponent";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import GenericPopup from "components/popups/GenericPopup";
import { dateFormatDisplay } from "utils/staticOptions";
import { fetchApi } from "utils/requests";
import { ethers } from "ethers";
import { diplomaContract } from "contracts/contracts";
import { Web3Context } from "context/web3Context";
import TooltipComp from "components/tooltip/TooltipComp";
import { useAccount } from "wagmi";
import Wallet from "components/wallet/Wallet";

interface ISingleDiploma {
  isPublicPage?: boolean;
}

const SingleDiploma = ({ isPublicPage }: ISingleDiploma) => {
  const { theme } = useContext(themeContext);
  let { studentId, diplomaId } = useParams();
  const { userData } = useContext(userContext);
  const { is_student } = userData || {};
  const [isPrintPage, setIsPrintPage] = useState<boolean | undefined>(
    undefined
  );
  const { web3UserData } = useContext(Web3Context);
  const navigate = useNavigate();

  const { setPopup, popupIsLoading, setPopupLoading, clearPopup } =
    useContext(popupContext);
  const queryClient = useQueryClient();
  const { connector: activeConnector } = useAccount();

  const didMount = React.useRef(false);

  useEffect(() => {
    if (isPrintPage === undefined) {
      return;
    }

    window.print();
  }, [isPrintPage]);

  useEffect(() => {
    if (popupIsLoading)
      setPopup(
        <GenericPopup
          type="loading"
          msg="Updating visibility options..."
          hideActionBtn
        />
      );
  }, [popupIsLoading]);

  const changeVisibility = async (id) =>
    await fetchApi("courses", "/course-student/my-diplomas", {
      method: "PUT",
      auth: true,
      data: {
        token_ids: [id],
        is_public: !is_public
      }
    });

  // Update certificate mutation
  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["student-diplomas-data-edit-visibility", { id: diplomaId }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await changeVisibility(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["student-diplomas-data", { id: diplomaId }]
      });
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully updated visibility options!"
        />
      );
    },
    onError: (err: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
    }
  });

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, IStudentDiploma>({
    ...(isPublicPage
      ? singleDiplomaDataPublicQuery(studentId, diplomaId)
      : is_student
      ? studentSingleDiplomaDataQuery(diplomaId)
      : singleDiplomaDataQuery(studentId, diplomaId)),
    enabled: !!userData
  });

  const handleBurnTx = async () => {
    let error;
    let receipt;

    if (!web3UserData?.wallet_address) error = "Please connect your wallet.";
    if (error) return setPopup(<GenericPopup type="error" msg={error} />);

    setPopup(
      <GenericPopup
        type="loading"
        msg="Approve this transaction from your wallet."
      />
    );

    let walletProvider;
    await activeConnector.getProvider().then((provider) => {
      walletProvider = provider;
    });

    let provider = new ethers.providers.Web3Provider(walletProvider, "any");
    let signer = provider.getSigner(web3UserData.wallet_address);

    const contract = new ethers.Contract(
      diplomaContract.address,
      diplomaContract.abi,
      signer
    );

    try {
      const tx = await contract.burn(token_id);
      receipt = await tx.wait();
    } catch (e: any) {
      error = e.reason;
      if (e.code === "ACTION_REJECTED") {
        error = "Transaction cancelled.";
      }
      if (e.code === "UNPREDICTABLE_GAS_LIMIT") {
        error =
          "Only the owner of the NFT can burn it. Please check your connected wallet.";
      }
      if (e?.data?.code === -32000 || e?.code === "INSUFFICIENT_FUNDS") {
        throw Error("Insufficient funds.");
      }
      return setPopup(<GenericPopup type="error" msg={error} />);
    }
    queryClient.invalidateQueries({ queryKey: ["student-diplomas-data"] });
    setPopup(
      <GenericPopup
        type="success"
        msg="Certificate successfully burnt. A mint request for reissuing the certificate was submitted and will be resolved soon!"
        buttonName="Go to certificates"
        buttonVariant="contrast"
        buttonAction={() => navigate("/diplomas")}
        bellowBtnComp={
          <Button
            onClick={() =>
              window.open(
                `${BLOCK_EXPLORER_URL}/tx/${receipt.transactionHash}`,
                "_blank"
              )
            }
            icon={LinkIcon}
            variant={"link"}
            isIconBtn
            minWidth="full"
          >
            View in Explorer
          </Button>
        }
        isClosable={false}
      />
    );
  };

  if (isLoading) return <Loader size="lg" hasText />;

  if (error) {
    return (
      <div className={classes["content-wrapper"]}>
        <ErrorComponent error={error} />
      </div>
    );
  }

  const {
    diploma_name,
    date_received,
    course,
    course_id,
    school_id,
    ipfs_diploma_id,
    is_public,
    token_id,
    student_address
  } = data;

  const {
    name: courseName,
    start_date,
    end_date,
    instructors,
    credit_hours,
    school,
    location,
    AGD
  } = course;

  const VisibilityIcon = is_public ? EyeIcon : EyeHiddenIcon;

  const renderMeta = (isPrint?: boolean) => {
    return (
      <div className={classes["details-wrapper"]}>
        <div className={classes["details-col"]}>
          <div className={classes["detail-block-wrapper"]}>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>School Name</div>
              <div className={classes["detail-desc"]}>
                {isPublicPage ? (
                  school.name
                ) : (
                  <Link
                    className={!isPrint ? classes["u-underline"] : ""}
                    to={is_student ? `/school/${school_id}` : "/school"}
                  >
                    {school.name}
                  </Link>
                )}
              </div>
            </div>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>School Address</div>
              <div className={classes["detail-desc"]}>
                {formatSchoolAddress(
                  school.addr1,
                  school.addr2,
                  school.city,
                  school.zip,
                  school.state ? school.state.code : null,
                  school.country
                )}
              </div>
            </div>
          </div>
          <div className={classes["detail-block-wrapper"]}>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>Course Name</div>
              <div className={classes["detail-desc"]}>
                {isPublicPage ? (
                  courseName
                ) : (
                  <Link
                    className={!isPrint ? classes["u-underline"] : ""}
                    to={`/courses/${course_id}`}
                  >
                    {courseName}
                  </Link>
                )}
              </div>
            </div>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>
                NFT Certificate Received On
              </div>
              <div className={classes["detail-desc"]}>
                {moment(date_received).format(dateFormatDisplay)}
              </div>
            </div>
          </div>
          <div className={classes["detail-block-wrapper"]}>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>Course Start Date</div>
              <div className={classes["detail-desc"]}>
                {start_date
                  ? moment(start_date).format(dateFormatDisplay)
                  : "-"}
              </div>
            </div>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>Course End Date</div>
              <div className={classes["detail-desc"]}>
                {end_date ? moment(end_date).format(dateFormatDisplay) : "-"}
              </div>
            </div>
          </div>
          <div className={classes["details-col"]}>
            <div className={classes["detail-block-wrapper"]}>
              <div className={classes["detail-block"]}>
                <div className={classes["detail-title"]}>Wallet Address</div>
                <div className={classes["detail-desc"]}>
                  <Wallet wallet={formatAddress(student_address)} />
                </div>
              </div>
              {location ? (
                <div className={classes["detail-block"]}>
                  <div className={classes["detail-title"]}>Course Location</div>
                  <div className={classes["detail-desc"]}>
                    {formatCourseLocation(
                      location.city,
                      location.state ? location.state.code : null
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className={classes["detail-block-wrapper"]}>
            <div className={classes["detail-block"]}>
              <div className={classes["detail-title"]}>
                {`Primary Instructor${
                  instructors.filter((ins) => ins.primary).length > 1 ? "s" : ""
                } `}
              </div>
              {renderInstructors(true, instructors)}
            </div>
            {instructors.filter((ins) => !ins.primary).length > 0 ? (
              <div className={classes["detail-block"]}>
                <div
                  className={classes["detail-title"]}
                >{`Additional Course Instructor${
                  instructors.filter((ins) => !ins.primary).length > 1
                    ? "s"
                    : ""
                }`}</div>
                {renderInstructors(false, instructors)}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes["details-col"]}>
          <div className={classes["detail-block-wrapper"]}>
            {AGD && AGD.length > 0 ? (
              <div className={classes["detail-block"]}>
                <div className={classes["detail-title"]}>AGD Subject Codes</div>
                <div className={classes["detail-desc"]}>{renderAGD(AGD)}</div>
              </div>
            ) : null}
            {credit_hours ? (
              <div className={classes["detail-block"]}>
                <div className={classes["detail-title"]}>Credit Hours</div>
                <div className={classes["detail-desc"]}>{credit_hours}</div>
              </div>
            ) : null}
          </div>
          <div className={classes["detail-block-wrapper"]}>
            {school.CERP_provider_number ? (
              <div className={classes["detail-block"]}>
                <div className={classes["detail-title"]}>
                  CERP Provider Number
                </div>
                <div className={classes["detail-desc"]}>
                  {school.CERP_provider_number}
                </div>
              </div>
            ) : null}
            {school.PACE_provider_number ? (
              <div className={classes["detail-block"]}>
                <div className={classes["detail-title"]}>
                  PACE Provider Number
                </div>
                <div className={classes["detail-desc"]}>
                  {school.PACE_provider_number}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      data-theme={theme}
      className={`${classes["content-wrapper"]} ${
        isPublicPage ? classes["content-wrapper--public"] : ""
      }`}
    >
      <div className={classes["print-wrapper"]}>
        <div className={classes["flex-container"]}>
          <div className={classes["main-info"]}>
            <div className={classes["card-pic"]}>
              <img src={`${IPFS_GATEWAY}${ipfs_diploma_id}`} alt="Diploma" />
            </div>
          </div>
          {isPrintPage && renderMeta(true)}
        </div>
      </div>
      <div className={classes["diploma-wrapper"]}>
        {ipfs_diploma_id ? (
          <div className={classes["card-pic"]}>
            <img src={`${IPFS_GATEWAY}${ipfs_diploma_id}`} alt="Diploma" />
          </div>
        ) : (
          <div
            className={`${classes["card-pic"]} ${classes["card-pic--empty"]}`}
          >
            <DiplomaIcon />
          </div>
        )}
        <div>
          <div className={classes["main-wrapper"]}>
            <h4
              className={`${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["m-0"]}`}
            >
              {diploma_name} {is_student && !isPublicPage && <VisibilityIcon />}
            </h4>
            <Divider />

            {is_student && !isPublicPage && (
              <div className={classes["actions-wrapper"]}>
                <div className={classes["btn-group"]}>
                  <Button
                    onClick={() => setIsPrintPage(false)}
                    icon={PrintIcon}
                    variant={"link"}
                  >
                    Print NFT Certificate
                  </Button>
                  <Button
                    onClick={() => setIsPrintPage(true)}
                    icon={PrintIcon}
                    variant={"link"}
                  >
                    Print Page
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        BLOCK_EXPLORER_URL +
                          `/token/${diplomaContract.address}?a=${diplomaId}`
                      )
                    }
                    icon={LinkIcon}
                    variant={"link"}
                  >
                    View In Block Explorer
                  </Button>
                </div>
                <div className={classes["btn-group"]}>
                  <Button
                    onClick={() => handleUpdate(diplomaId)}
                    icon={is_public ? EyeHiddenIcon : EyeIcon}
                    variant={"link"}
                  >
                    {`Make ${is_public ? "private" : "public"}`}
                  </Button>
                  <Button
                    variant="link"
                    isDanger
                    icon={ReturnArrow}
                    onClick={() =>
                      setPopup(
                        <GenericPopup
                          type="warning"
                          msg={
                            <div>
                              This action will
                              <span className={classes["u-bold"]}>
                                {" "}
                                burn your certificate NFT{" "}
                              </span>
                              and submit a mint request for a new certificate.
                            </div>
                          }
                          buttonName="Return my certificate"
                          buttonVariant="contrast"
                          buttonAction={handleBurnTx}
                          bellowBtnComp={
                            <Button
                              variant="neutral"
                              size="medium"
                              minWidth="md"
                              onClick={clearPopup}
                            >
                              Cancel
                            </Button>
                          }
                        />
                      )
                    }
                    isDisabled={!web3UserData?.wallet_address}
                  >
                    <TooltipComp
                      id="return-diploma-btn"
                      text={
                        !web3UserData?.wallet_address
                          ? "Connect you wallet first."
                          : ""
                      }
                    >
                      Return Certificate
                    </TooltipComp>
                  </Button>
                </div>
              </div>
            )}
          </div>
          {renderMeta()}
        </div>
      </div>
    </div>
  );
};

export default SingleDiploma;
