import classes from "./TableCols.module.scss";
import { Link } from "react-router-dom";
import Button from "components/buttons/Button";
import moment from "moment";
import {
  IMintRequest,
  RequestStatus,
  RequestType
} from "utils/apiDataTypes/CourseModuleDataTypes";
import { tableColumnsWidths } from "./utils/table-utils";
import { dateFormatDisplay } from "utils/staticOptions";
import { useContext } from "react";
import { Web3Context } from "context/web3Context";
import Tooltip from "components/tooltip/TooltipComp";
import RenderCell from "./RenderCell";
import PermissionsGate from "roles/PermissionsGate";
import { IndeterminateCheckbox } from "./InterdeterminateCheckbox";

interface IRequestCols {
  showMintRequestPopup: (request: IMintRequest[]) => void;
  isMobile: boolean;
}

export const RequestsCols = (
  showMintRequestPopup: IRequestCols["showMintRequestPopup"],
  isMobile: IRequestCols["isMobile"]
) => {
  return [
    {
      id: "selection",
      Header: ({ page, toggleRowSelected, isAllPageRowsSelected }) => {
        const modifiedOnChange = (event) => {
          page.forEach((row) => {
            // Select all non-resolved requests
            if (row.original.status === 0) {
              toggleRowSelected(row.id, event.currentTarget.checked);
            }
          });
        };
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox onChange={modifiedOnChange} />
          </PermissionsGate>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: (tableProps) => {
        return (
          <PermissionsGate permissions={["STUDENT.UPDATE"]}>
            <IndeterminateCheckbox
              {...tableProps.row.getToggleRowSelectedProps()}
              isDisabled={!!tableProps.row.original.status}
              status={tableProps.row.original.status}
            />
          </PermissionsGate>
        );
      }
    },
    {
      Header: "Request",
      accessor: "mobile",
      Cell: ({ row }) => (
        <RenderCell type="request_mobile" rowProps={row.original} />
      )
    },
    {
      Header: "Course Name",
      accessor: "course_name",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => (
        <Link
          to={`/courses/${row.original.course._id}`}
          style={{ textDecoration: "underline" }}
        >
          {row.original?.course?.name}
        </Link>
      )
    },
    {
      Header: "Request Type",
      accessor: "request_type",
      maxWidth: tableColumnsWidths.typeMax,
      minWidth: tableColumnsWidths.typeMin,
      width: tableColumnsWidths.type,
      Cell: ({ row }) => <div>{RequestType[row.original.request_type]}</div>
    },
    {
      Header: "Requested By",
      accessor: "requested_by",
      minWidth: tableColumnsWidths.nameMin,
      Cell: ({ row }) => {
        const { title, first_name, last_name, _id } = row.original.requested_by;
        return (
          <div className={classes["names-container"]}>
            <Link
              style={{ textDecoration: "underline" }}
              to={`/students/${_id}`}
            >
              {`${title || ""} ${first_name} ${last_name}`}
            </Link>
          </div>
        );
      }
    },
    {
      Header: "Requested For",
      accessor: "attendees_amount",
      maxWidth: tableColumnsWidths.typeMax,
      minWidth: tableColumnsWidths.typeMin,
      width: tableColumnsWidths.type,
      Cell: ({ row }) => {
        const numberOfAttendees = row.original.requested_for.length;
        return (
          <div>
            {numberOfAttendees + ` Attendee${numberOfAttendees > 1 ? "s" : ""}`}
          </div>
        );
      }
    },
    {
      Header: "Request Date",
      accessor: "date",
      maxWidth: tableColumnsWidths.dateMax,
      minWidth: tableColumnsWidths.dateMin,
      width: tableColumnsWidths.date,
      Cell: ({ row }) => {
        const date = moment(row.original.request_date)
          .format(dateFormatDisplay)
          .toString();
        return <div>{date}</div>;
      }
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: !isMobile && tableColumnsWidths.actionsXlMax,
      minWidth: !isMobile && tableColumnsWidths.actionsXlMin,
      width: isMobile ? "unset" : tableColumnsWidths.actionsXl,
      Cell: ({ row }) => {
        const { web3UserData } = useContext(Web3Context);
        return (
          <div
            className={`${classes["status-container"]} ${
              classes[
                row.original.status === RequestStatus["Approved"] ||
                row.original.status === RequestStatus["Minted"]
                  ? "approved"
                  : row.original.status === RequestStatus["Rejected"]
                  ? "rejected"
                  : ""
              ]
            }`}
          >
            {row.original.status === 0 && (
              <Tooltip
                id={`request-id-${row.original.request_id}`}
                text={
                  !web3UserData?.wallet_address
                    ? "Please connect your wallet."
                    : ""
                }
              >
                <Button
                  variant="neutral"
                  size="medium"
                  onClick={() => {
                    showMintRequestPopup(row.original);
                  }}
                  isDisabled={!web3UserData?.wallet_address}
                >
                  Resolve
                </Button>
              </Tooltip>
            )}
            {row.original.status === 1 && "Approved"}
            {row.original.status === 2 && "Rejected"}
            {row.original.status === 3 && "Minted In Course"}
          </div>
        );
      }
    }
  ];
};
