export const titleOptions = ["Dr.", "Mr.", "Ms.", "Mrs."];
export const agdTypeOptions = ["Lecture", "Participation"];

export const degreeOptions = {
  AACD: "American Academy of Cosmetic Dentistry",
  DDS: "Doctor of Dental Surgery",
  DMD: "Doctor of Medicine in Dentistry or Doctor of Dental Medicine",
  MSD: "Master of Science in Dentistry",
  DScD: "The Doctor of Science in Dentistry",
  BDS: "Bachelor of Dental Surgery",
  MDS: "Master of Dental Surgery",
  BDSc: "Bachelor of Dental Science",
  RDH: "Registered Dental Hygienist"
};

export enum sortDirEnum {
  "asc" = "",
  "desc" = "-"
}

export const fileInstructions = {
  pdf: "Format: PDF. Max size: 4MB.",
  pfp: "500 x 500 Recommended. Format: JPEG, JPG, PNG. Max size: 10MB.",
  banner: "1280 x 340 Recommended. Format: JPEG, JPG, PNG. Max size: 10MB.",
  signature: "500 x 500 Recommended. Format: PNG. Max size: 10MB.",
  nftTemplate: "Orientation: Landscape. Format: JPEG, JPG, PNG. Max size: 10MB."
};

export const dateFormatUrl = "MM-DD-yyyy";
export const dateFormatField = "MM/DD/yyyy";
export const dateFormatDisplay = "MMM DD, yyyy";
export const dateFormatDisplayNoYear = "MMM DD";
export const dateFormatDisplayDiploma = "MMMM DD, yyyy";
export const dateFormatDisplayDiplomaNoYear = "MMMM DD";
