import React, { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormData } from "hooks/useFormData";
import * as Yup from "yup";
import Loader from "components/loader/Loader";
import ErrorComponent from "components/error/Error";
import ReactSelectWrapper from "components/react-select/ReactSelectWrapper";
import GenericPopup from "components/popups/GenericPopup";
import Button from "components/buttons/Button";
import { popupContext } from "context/popupContext";
import { fetchApi } from "utils/requests";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import { ITemplatesData } from "utils/apiDataTypes/CourseModuleDataTypes";
import { IFetchedMeData } from "utils/apiDataTypes/AccountManagementDataTypes";
import { meDataQuery, nftTemplatesDataQuery } from "query";
import { fieldTitles } from "../../components/CourseForm";

interface ICourseInfo {
  template_id: string;
}

const validationSchema: Yup.SchemaOf<ICourseInfo> = Yup.object().shape({
  template_id: Yup.string()
});

interface ITemplateFormProps {
  courseId: string;
  templateId: string;
  hasMints: boolean;
}

const defaultFormData: ICourseInfo = {
  template_id: ""
};

const TemplateForm = ({
  courseId,
  templateId,
  hasMints
}: ITemplateFormProps) => {
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const queryClient = useQueryClient();

  // Fetch info in case cache data is missing
  const {
    isLoading: templatesIsLoading,
    error: templatesError,
    data: fetchedNftTemplatesData
  } = useQuery<boolean, Error, ITemplatesData>(nftTemplatesDataQuery());

  const fetchedMeData = useGetFetchQuery(meDataQuery()) as IFetchedMeData;

  const { formData, setFormData, formErrors } = useFormData(
    defaultFormData,
    validationSchema
  );

  useEffect(() => {
    if (fetchedNftTemplatesData && templateId) {
      setFormData({
        template_id: fetchedNftTemplatesData.result.find(
          (template) => template._id === templateId
        ).name
      });
    }
  }, [fetchedNftTemplatesData]);

  const updateTemplate = async (id) => {
    return await fetchApi("courses", `/course/${courseId}`, {
      method: "PUT",
      auth: true,
      data: {
        template_id: id
      }
    });
  };

  // Update course mutation
  const { mutate: handleUpdate } = useMutation({
    mutationKey: ["update-course", { id: courseId }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await updateTemplate(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["courses-data", { id: courseId }]
      });
      setPopup(
        <GenericPopup
          type="success"
          title="Success!"
          msg="You have successfully changed the NFT Certificate template!"
        />
      );
      setPopupLoading(false);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
        />
      );
      setPopupLoading(false);
    }
  });

  if (templatesIsLoading) return <Loader size="md" />;

  if (templatesError) return <ErrorComponent error={templatesError} />;

  const buildTemplatesOptions = () => {
    if (!fetchedNftTemplatesData) return [];

    return fetchedNftTemplatesData.result.map((template) => {
      return {
        value: template._id,
        label: template.name
      };
    });
  };

  const selected = fetchedNftTemplatesData.result.find(
    (d) => d._id === templateId
  );

  const isCourseManager = fetchedMeData && fetchedMeData?.role_flag === 2;

  return (
    <ReactSelectWrapper
      title={fieldTitles.template_id}
      showTooltipIcon="Keep in mind that you can’t change the Course NFT Certificate once you’ve minted the first time."
      name="template_id"
      placeholder="Select NFT Certificate Template"
      options={buildTemplatesOptions()}
      isSearchable
      isLoading={templatesIsLoading}
      value={
        selected
          ? {
              value: selected._id,
              label: selected.name
            }
          : null
      }
      tooltipMsg={
        hasMints
          ? "The template cannot be edited after the first mint has occurred."
          : isCourseManager
          ? "In order to update the NFT template please contact your school admin or registrar of the course."
          : ""
      }
      isDisabled={hasMints || isCourseManager}
      error={templatesError ? templatesError.message : formErrors.template_id}
      onChange={(e) => {
        // Check if has no value OR selected value is different from target value to trigger the confirmation modal
        if (!selected || selected._id !== e.value) {
          setPopup(
            <GenericPopup
              type="info"
              size={"sm"}
              title="Confirm Change NFT Template"
              msg="Are you sure you want to change the NFT Certificate template?"
              buttonName="Change"
              buttonVariant="contrast"
              buttonAction={() => {
                const options = buildTemplatesOptions();
                setFormData({ ...formData, template_id: e });
                handleUpdate(
                  options.find((template) => template.label === e.label).value
                );
              }}
              clearPopupOnBtnAction={false}
              bellowBtnComp={
                <Button
                  variant="neutral"
                  size="medium"
                  onClick={() => clearPopup()}
                  minWidth="md"
                >
                  Cancel
                </Button>
              }
            />
          );
        }
      }}
    />
  );
};

export default TemplateForm;
